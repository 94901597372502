import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { ifProp } from 'styled-tools';
import { Typography } from '@dls/react-beta-typography';
import componentColor, { dls } from '@dls/react-colors';

const StyledTitle = styled.div`
  flex: 1;
`;

const StyledAction = styled.div`
  flex: 0 0 auto;
`;

const StyledCardHeader = styled.header`
  align-items: center;
  display: flex;
  flex: 0;
  padding: 0 16px;
  min-height: 56px;
  ${ifProp(
    'borderBottom',
    css`
      border-bottom: 1px solid
        ${componentColor(dls.separator.background.content.normal)};
    `,
  )}
`;

const CardHeader = (props) => {
  const { disableTypography, titleTypographyProps, action } = props;

  let { title } = props;
  if (title && title.type !== Typography && !disableTypography) {
    title = (
      <Typography {...titleTypographyProps} weight="600">
        {title}
      </Typography>
    );
  }
  return (
    <StyledCardHeader {...props}>
      {title && <StyledTitle>{title}</StyledTitle>}
      {action && <StyledAction>{action}</StyledAction>}
    </StyledCardHeader>
  );
};

CardHeader.propTypes = {
  action: PropTypes.node,
  borderBottom: PropTypes.bool,
  disableTypography: PropTypes.bool,
  title: PropTypes.node,
  titleTypographyProps: PropTypes.object,
};

CardHeader.defaultProps = {
  borderBottom: true,
  disableTypography: false,
  titleTypographyProps: {
    bold: true,
    marginBottom: '0',
    variant: 'h5',
  },
};

export default CardHeader;
