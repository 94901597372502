import React, { FunctionComponent, SVGProps } from 'react';

const BrandLOT37X24 = (props) => (
  <svg viewBox="0 0 37 24" width="1em" height="1em" {...props}>
    <path d="M10.073 6.628v8.142h3.571v1.643H8.233V6.628h1.85zM18.223 6.462c2.562 0 4.218 2.114 4.218 5.086 0 2.972-1.545 5.068-4.218 5.068-2.674 0-4.237-2.17-4.237-5.059 0-2.889 1.665-5.095 4.236-5.095zm0 8.51c1.48 0 2.321-1.402 2.321-3.424 0-2.021-.925-3.452-2.322-3.452-1.396 0-2.33 1.357-2.33 3.452 0 2.096.85 3.425 2.33 3.425zM29.443 6.628v1.68h-2.359v8.179h-1.85V8.308h-2.405v-1.68h6.605z" />
    <path d="M35.15 1.846v20.308H1.85V1.846h33.3zM37 0H0v24h37V0z" />
  </svg>
);

export default BrandLOT37X24;
