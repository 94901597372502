import React, { FunctionComponent, SVGProps } from 'react';

const WaterBottle = (props) => (
  <svg viewBox="0 0 48 48" width="1em" height="1em" {...props}>
    <path
      fill="#231f20"
      d="M27 4h-6V2h6v2zm5 13a7.997 7.997 0 00-5-7.413V6h-6v3.587A7.998 7.998 0 0016 17v23h.025a5.5 5.5 0 005.475 6c.901 0 1.749-.221 2.5-.605a5.474 5.474 0 002.5.605 5.5 5.5 0 005.475-6H32V17z"
    />
  </svg>
);

export default WaterBottle;
