import componentColor, { dls } from '@dls/react-colors';
import MuiBox, { BoxProps } from '@material-ui/core/Box';
import { FC } from 'react';
import styled from 'styled-components';
import { em } from '@dls/react-theme';

export const Box: FC<BoxProps> = styled(MuiBox).attrs((props) => {
  return {
    className: `shadowLevel${props.boxShadow}`,
  } as BoxProps;
})`
  && {
    &.MuiBox-root {
      width: ${em(200)};
      height: ${em(150)};
      background-color: ${componentColor(
        dls.content.background.primary.normal,
      )};
      color: ${componentColor(dls.label.text.regular.normal)};
      text-align: center;

      &.shadowLevel0 {
        box-shadow: 0 1px 2px 0
          ${componentColor(dls.shadow.shadow.levelOne.disabled)};
      }
      &.shadowLevel1 {
        box-shadow: 0 1px 2px 0
          ${componentColor(dls.shadow.shadow.levelOne.normal)};
      }
      &.shadowLevel2 {
        box-shadow: 0 2px 4px 0
          ${componentColor(dls.shadow.shadow.levelTwo.normal)};
      }
      &.shadowLevel3 {
        box-shadow: 0 8px 24px 0
          ${componentColor(dls.shadow.shadow.levelThree.normal)};
      }
    }
  }
`;
