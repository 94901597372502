import React, { useState } from 'react';
import { ArrowDown } from '@dls/react-icons';
import componentColor, { dls } from '@dls/react-colors';
import { useCombobox } from 'downshift';
import { ComboboxProps, ComboboxItemProps } from './types';
import {
  ComboboxButton,
  ComboboxInput,
  ComboboxInputWrapper,
  ComboboxItem,
  ComboboxItemList,
  ComboboxWrapper,
} from './styles';

export const Combobox: React.FunctionComponent<ComboboxProps> = (
  props: ComboboxProps,
) => {
  const {
    disabled,
    fullWidth,
    initialValue,
    items,
    onSelectedItem,
    opened,
    placeholder,
    selectedItem,
    ...other
  } = props;
  const [inputItems, setInputItems] = useState(items);
  const {
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
  } = useCombobox({
    items: inputItems,
    initialIsOpen: opened,
    itemToString: (item) => (item ? item.value : ''),
    initialSelectedItem: initialValue,
    onInputValueChange: ({ inputValue }) => {
      setInputItems(
        items.filter((item: ComboboxItemProps) =>
          item.value.toLowerCase().startsWith(inputValue.toLowerCase()),
        ),
      );
    },
    onSelectedItemChange: ({ selectedItem: nextSelectedItem }) => {
      onSelectedItem && onSelectedItem(nextSelectedItem);
    },
    selectedItem: selectedItem,
  });
  return (
    <ComboboxWrapper
      className="combobox-wrapper"
      disabled={disabled}
      {...other}>
      <ComboboxInputWrapper
        pressed={isOpen}
        fullWidth={fullWidth}
        {...getComboboxProps()}>
        <ComboboxInput
          disabled={disabled}
          {...getInputProps({
            placeholder: placeholder,
            ...getToggleButtonProps(),
          })}
        />
        <ComboboxButton {...getToggleButtonProps()} aria-label="toggle menu">
          <ArrowDown />
        </ComboboxButton>
      </ComboboxInputWrapper>
      <ComboboxItemList
        pressed={isOpen}
        fullWidth={fullWidth}
        {...getMenuProps({})}>
        {isOpen &&
          inputItems.map((item, index) => (
            <ComboboxItem
              style={
                /* istanbul ignore next */
                highlightedIndex === index
                  ? {
                      backgroundColor: componentColor(
                        dls.listItem.background.default.normalOn,
                      )(),
                      color: componentColor(
                        dls.listItem.text.default.normalOn,
                      )(),
                    }
                  : {}
              }
              key={`${item.key}+${item.value}`}
              {...getItemProps({
                item,
                index,
              })}>
              {item.value}
            </ComboboxItem>
          ))}
      </ComboboxItemList>
    </ComboboxWrapper>
  );
};
