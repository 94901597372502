import { FC } from 'react';
import styled, { css } from 'styled-components';
import { em } from '@dls/react-theme';
import componentColor, { dls } from '@dls/react-colors';
import MuiBadge, { BadgeProps } from '@material-ui/core/Badge';

/// MARK:- REGION Badge Styles
export type BadgeVariant =
  | 'default'
  | 'signalError'
  | 'signalInfo'
  | 'signalSuccess'
  | 'signalWarning'
  | 'accent';

/// MARK:- REGION Badge Styles
export type sizeVariant = 'default' | 'small';
export type colorVariant = 'true' | 'false' | undefined; // giving 2 variations of sigal text colors- white and black

interface BadgeCustomProps extends BadgeProps {
  dls_variant?: BadgeVariant;
  lightcolortext?: colorVariant;
  size?: sizeVariant;
}

const styles = (size: number, fontsize: number) =>
  css`
    font-size: ${em(fontsize)};
    min-width: ${em(size, fontsize)};
    min-height: ${em(size, fontsize)};
    line-height: ${em(size, fontsize)};
    border-radius: ${em(12, fontsize)};
    padding: 0 ${em(4, fontsize)};
    transform: none;
    top: ${em(-size / 2, fontsize)};
    right: ${em(-size / 2, fontsize)};
  `;

/// MARK:- Badge Styles
const BadgeStyles = (props: BadgeCustomProps) =>
  props.dls_variant !== undefined &&
  css`
    &.${props.dls_variant} .MuiBadge-badge {
      background-color: ${componentColor(
        dls.notificationBadge.background[props.dls_variant].normal,
      )};
      color: ${componentColor(
        dls.notificationBadge.text[props.dls_variant].normal,
      )};
      box-shadow: 0 1px 2px 0
        ${componentColor(dls.shadow.shadow.levelOne.normal)};
    }

    &.normal-shadow .MuiBadge-badge {
      box-shadow: 0 1px 2px 0
        ${componentColor(dls.shadow.shadow.levelOne.normal)};
    }
    &.disabled-shadow .MuiBadge-badge {
      box-shadow: 0 1px 2px 0
        ${componentColor(dls.shadow.shadow.levelOne.disabled)};
    }
  `;

/// MARK:- Signal Badge Styles
const SignalBadgeStyles = (props: BadgeCustomProps) =>
  props.dls_variant !== undefined &&
  css`
    &.${props.dls_variant} .MuiBadge-badge {
      color: ${props.lightcolortext === 'true'
        ? componentColor(dls.notificationBadge.text.default.normal)
        : componentColor(dls.notificationBadge.text.signal.normal)};
      background-color: ${componentColor(
        dls.notificationBadge[
          'background' + props.dls_variant.replace('signal', '')
        ].signal.normal,
      )};
      box-shadow: 0 1px 2px 0
        ${componentColor(dls.shadow.shadow.levelOne.normal)};
    }
    &.normal-shadow .MuiBadge-badge {
      box-shadow: 0 1px 2px 0
        ${componentColor(dls.shadow.shadow.levelOne.normal)};
    }
    &.disabled-shadow .MuiBadge-badge {
      box-shadow: 0 1px 2px 0
        ${componentColor(dls.shadow.shadow.levelOne.disabled)};
    }
  `;

export const Badge: FC<BadgeCustomProps> = styled(MuiBadge).attrs(
  (props: BadgeCustomProps) => {
    return {
      className: props.dls_variant
        ? ` ${props.dls_variant} ${props.className} ${props.size}`
        : `${props.className}  ${props.size}`,
    } as BadgeCustomProps;
  },
)`
  && {
    &.MuiBadge-root {
      ${(props) =>
        props.dls_variant && props.dls_variant.indexOf('signal') !== -1
          ? SignalBadgeStyles
          : BadgeStyles};
      & .MuiBadge-badge {
        font-family: CentraleSans, Tahoma, Arial, Helvetica, sans-serif;
        font-weight: normal;
        font-style: normal;
        ${() => styles(24, 12)};
      }

      &.small .MuiBadge-badge {
        ${() => styles(20, 10)};
      }
      & .MuiBadge-dot {
        ${() => styles(12, 6)};
        padding: unset;
        border-radius: ${em(6, 6)};
        top: ${em(-8, 6)};
        right: ${em(-8, 6)};
      }
    }
  }
`;
