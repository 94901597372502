import styled from 'styled-components';
import { em } from '@dls/react-theme';
import componentColor, { dls } from '@dls/react-colors';
import MuiSlider, {
  SliderProps as MuiSliderProps,
} from '@material-ui/core/Slider';
import React from 'react';

export const SliderView = styled(MuiSlider).attrs((props: MuiSliderProps) => {
  return {
    ...props,
  } as MuiSliderProps;
})`
  && {
    /** Slider styles here */
    &.MuiSlider-root {
      color: ${componentColor(dls.track.background.default.normalOn)};
      height: ${em(4)};
      padding: 0;
    }

    &.MuiSlider-root .MuiSlider-rail {
      height: ${em(4)};
      background-color: ${componentColor(
        dls.track.background.default.normalOff,
      )};
    }

    &.MuiSlider-root .MuiSlider-track {
      height: ${em(4)};
      background-color: ${componentColor(
        dls.track.background.default.normalOn,
      )};
    }

    &.MuiSlider-root .MuiSlider-thumb {
      display: block;
      z-index: 3;
      position: absolute;
      margin-top: -${em(6)};
      width: ${em(16)};
      height: ${em(16)};
      background-color: ${componentColor(dls.thumb.background.default.normal)};
      border-radius: 50%;
      box-shadow: 0 0 0.25em 0.125em
        ${componentColor(dls.shadow.shadow.shadowThumb.normal)};
      border: ${componentColor(dls.thumb.border.default.normal)};

      &:hover:not(:focus) {
        width: ${em(24)};
        height: ${em(24)};
        margin-top: -${em(10)};
        margin-left: -${em(10)};
      }

      &:focus {
        width: ${em(16)};
        height: ${em(16)};
        box-shadow: 0 0 0 0.75em
          ${componentColor(dls.thumb.focusOutline.default.focusPressed)};
      }

      &.MuiSlider-active {
        width: ${em(24)};
        height: ${em(24)};
        margin-top: -${em(10)};
        margin-left: -${em(10)};
        box-shadow: 0 0 0 0.5em
          ${componentColor(dls.thumb.focusOutline.default.focusPressed)};
      }
    }

    &.MuiSlider-root .MuiSlider-mark {
      height: ${em(4)};
      width: ${em(2)};
      background-color: ${componentColor(
        dls.content.background.primary.normal,
      )};
    }

    &.MuiSlider-root.Mui-disabled {
      & .MuiSlider-rail {
        background-color: ${componentColor(
          dls.track.background.default.disabled,
        )};
      }
      & .MuiSlider-track {
        background-color: ${componentColor(
          dls.track.background.default.disabled,
        )};
      }

      & .MuiSlider-thumb {
        background-color: ${componentColor(
          dls.thumb.background.default.disabled,
        )};
        box-shadow: 0 0 0.25em 0.125em
          ${componentColor(dls.shadow.shadow.shadowThumb.disabled)};
      }
    }

    &.MuiSlider-root.MuiSlider-vertical {
      height: 100%;

      & .MuiSlider-rail {
        height: 100%;
        width: ${em(4)};
      }

      & .MuiSlider-track {
        height: 100%;
        width: ${em(4)};
      }

      & .MuiSlider-thumb {
        margin-left: -${em(6)};
        &.MuiSlider-active {
          margin-left: -${em(10)};
        }
      }
    }
  }
`;

const Container = styled.div<MuiSliderProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${(props: MuiSliderProps) =>
    props.orientation === 'vertical' ? '100%' : em(40)};
  width: ${(props: MuiSliderProps) =>
    props.orientation === 'vertical' ? em(40) : '100%'};

  .pt-touch & {
    height: ${(props: MuiSliderProps) =>
      props.orientation === 'vertical' ? '100%' : em(48)};
    width: ${(props: MuiSliderProps) =>
      props.orientation === 'vertical' ? em(48) : '100%'};
  }
`;

export const Slider = (props: MuiSliderProps) => {
  return (
    <Container orientation={props.orientation}>
      <SliderView {...props} />
    </Container>
  );
};
