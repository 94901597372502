import React, { FC } from 'react';
import PropTypes from 'prop-types';
import { ifProp } from 'styled-tools';
import styled, { css } from 'styled-components';
import { BaseComponent } from '@dls/react-beta-base-component';
import { toPx, FONT_DEFAULT } from '@dls/react-beta-utils';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type BaseButtonProps = any;

const densities = {
  touch: {
    minHeight: 40,
    horzPadding: 16,
    vertPadding: 8,
  },
  regular: {
    minHeight: 40,
    horzPadding: 16,
    vertPadding: 8,
  },
  dense: {
    minHeight: 32,
    horzPadding: 16,
    vertPadding: 4,
  },
};

const StyledBaseButton = styled<BaseButtonProps>(BaseComponent)`
  appearance: none;
  font-family: ${FONT_DEFAULT};
  font-size: 16px;
  font-weight: 400;
  user-select: none;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  outline: none;
  display: ${ifProp('fullWidth', 'flex', 'inline-flex')};
  min-height: ${(props) => toPx(densities[props.density].minHeight)};
  padding: ${(props) =>
    `${toPx(densities[props.density].vertPadding)} ${toPx(
      densities[props.density].horzPadding,
    )}`};
  ${ifProp(
    'topIcon',
    css`
      flex-direction: column;
    `,
  )}
  >.icon-font,
  > svg {
    ${ifProp(
      'leftIcon',
      css`
        margin-right: 8px;
      `,
    )}
    ${ifProp(
      'rightIcon',
      css`
        margin-left: 8px;
      `,
    )}
  }
`;

const BaseButton: FC<BaseButtonProps> = (props) => {
  const { disabled, topIcon, leftIcon, rightIcon } = props;
  const { children, tabIndex, ...other } = props;
  return (
    <StyledBaseButton
      role="button"
      tabIndex={disabled ? '-1' : tabIndex}
      {...other}>
      {topIcon || leftIcon}
      {children}
      {rightIcon}
    </StyledBaseButton>
  );
};

BaseButton.propTypes = {
  children: PropTypes.node,
  density: PropTypes.oneOf(['touch', 'regular', 'dense']),
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  leftIcon: PropTypes.node,
  rightIcon: PropTypes.node,
  tabIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  topIcon: PropTypes.node,
};

BaseButton.defaultProps = {
  density: 'regular',
  tabIndex: 0,
  borderRadius: '2px',
};

export default BaseButton;
