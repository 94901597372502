import MuiGridList, {
  GridListProps as MuiGridListProps,
} from '@material-ui/core/GridList';
import MuiGridListTile, {
  GridListTileProps as MuiGridListTileProps,
} from '@material-ui/core/GridListTile';

import MuiGridListTileBar, {
  GridListTileBarProps as MuiGridListTileBarProps,
} from '@material-ui/core/GridListTileBar';

import ListSubheader from '@material-ui/core/ListSubheader';

import { em } from '@dls/react-theme';
import componentColor, { dls } from '@dls/react-colors';
import styled from 'styled-components';
import clsx from 'clsx';
import React from 'react';
import { Typography } from '@dls/react-mui-typography';

/** Grid View with its content */
export const GridList = styled(MuiGridList).attrs((props: MuiGridListProps) => {
  return {
    ...props,
  } as MuiGridListProps;
})``;

export type GridTileType = 'default' | 'overlay' | 'quiet';
export type GridVariant = 'disabled';

export interface GridListTileCustomProps extends MuiGridListTileProps {
  tile_type: GridTileType;
  variant?: GridVariant;
}

export const GridListTile = styled(MuiGridListTile).attrs(
  (props: GridListTileCustomProps) => {
    return {
      ...props,
      className: clsx(props.variant),
    } as GridListTileCustomProps;
  },
)`
  && {
    /** Adding Padding inbetween the grid items required(Mark as important)*/
    &.MuiGridListTile-root {
      padding: ${em(2)} !important;
    }

    &.MuiGridListTile-root .MuiGridListTile-tile .MuiListSubheader-root {
      height: ${em(40)};
      line-height: ${em(40)};
      background-color: ${componentColor(
        dls.gridView.headerBackground.primary.normal,
      )};
      font-size: unset;
      font-family: unset;
      font-weight: unset;
      color: unset;
      display: flex;
      justify-content: left;
      align-items: center;
    }

    &.MuiGridListTile-root .MuiGridListTile-tile .grid-image {
      /** Apply this class for scaling image properly.*/
      width: 100%;
      height: ${(props: GridListTileCustomProps) =>
        props.tile_type === 'default' || props.tile_type === 'quiet'
          ? '75%'
          : '100%'};

      &:focus {
        opacity: 0.5;
      }
      &:active {
        opacity: 0.8;
      }
      &:hover {
        opacity: 0.6;
      }
    }

    &.MuiGridListTile-root
      .MuiGridListTile-tile
      .MuiGridListTileBar-titlePositionBottom {
      bottom: ${(props: GridListTileCustomProps) =>
        props.tile_type === 'default'
          ? '3px'
          : props.tile_type === 'quiet'
          ? '9px'
          : '0px'};
      background: ${(props: GridListTileCustomProps) =>
        props.tile_type === 'default' || props.tile_type === 'quiet'
          ? componentColor(dls.gridView.headerBackground.secondary.normal)
          : componentColor(
              dls.gridView.headerBackground.overlay.normalHoverPressed,
            )};
    }

    &.MuiGridListTile-root .MuiGridListTile-tile {
      /**Normal Style*/
      .MuiGridListTileBar-titlePositionBottom .MuiGridListTileBar-titleWrap {
        & .MuiGridListTileBar-title {
          color: ${(props: GridListTileCustomProps) =>
            props.tile_type === 'default' || props.tile_type === 'quiet'
              ? componentColor(dls.gridView.titleText.default.normal)
              : componentColor(
                  dls.gridView.titleText.overlay.normalHoverPressed,
                )};
        }

        & .MuiGridListTileBar-subtitle {
          color: ${() =>
            componentColor(dls.gridView.subtitleText.default.normal)};
        }
      }
      /** Hover style */
      &:hover {
        .MuiGridListTileBar-titlePositionBottom .MuiGridListTileBar-titleWrap {
          & .MuiGridListTileBar-title {
            color: ${(props: GridListTileCustomProps) =>
              props.tile_type === 'default' || props.tile_type === 'quiet'
                ? componentColor(dls.gridView.titleText.default.hover)
                : componentColor(
                    dls.gridView.titleText.overlay.normalHoverPressed,
                  )};
          }
          & .MuiGridListTileBar-subtitle {
            color: ${(props: GridListTileCustomProps) =>
              props.tile_type === 'default' || props.tile_type === 'quiet'
                ? componentColor(dls.gridView.subtitleText.default.hover)
                : componentColor(
                    dls.gridView.subTitleText.overlay.normalHoverPressed,
                  )};
          }
        }
      }

      /** Active style */
      &:active {
        .MuiGridListTileBar-titlePositionBottom .MuiGridListTileBar-titleWrap {
          & .MuiGridListTileBar-title {
            color: ${(props: GridListTileCustomProps) =>
              props.tile_type === 'default' || props.tile_type === 'quiet'
                ? componentColor(dls.gridView.titleText.default.pressed)
                : componentColor(
                    dls.gridView.titleText.overlay.normalHoverPressed,
                  )};
          }
          & .MuiGridListTileBar-subtitle {
            color: ${(props: GridListTileCustomProps) =>
              props.tile_type === 'default' || props.tile_type === 'quiet'
                ? componentColor(dls.gridView.subtitleText.default.pressed)
                : componentColor(
                    dls.gridView.subTitleText.overlay.normalHoverPressed,
                  )};
          }
        }
      }
    }
    /** Disabled Class style */
    &.MuiGridListTile-root.disabled .MuiGridListTile-tile {
      /**Disabled Style*/
      .MuiGridListTileBar-titlePositionBottom .MuiGridListTileBar-titleWrap {
        & .MuiGridListTileBar-title {
          color: ${(props: GridListTileCustomProps) =>
            props.tile_type === 'default' || props.tile_type === 'quiet'
              ? componentColor(dls.gridView.titleText.default.disabled)
              : componentColor(dls.gridView.titleText.overlay.disabled)};
        }

        & .MuiGridListTileBar-subtitle {
          color: ${(props: GridListTileCustomProps) =>
            props.tile_type === 'default' || props.tile_type === 'quiet'
              ? componentColor(dls.gridView.subtitleText.default.disabled)
              : componentColor(dls.gridView.subTitleText.overlay.disabled)};
        }
      }

      img {
        opacity: 0.35;
      }
    }

    &.MuiGridListTile-root.disabled
      .MuiGridListTile-tile
      .MuiGridListTileBar-titlePositionBottom {
      background: ${(props: GridListTileCustomProps) =>
        props.tile_type === 'default' || props.tile_type === 'quiet'
          ? componentColor(dls.gridView.headerBackground.secondary.normal)
          : componentColor(dls.gridView.headerBackground.overlay.disabled)};
    }
  }
`;

export interface GridListTileBarCustomProps extends MuiGridListTileBarProps {
  tile_type: GridTileType;
}

export const GridListTileBar = styled(MuiGridListTileBar).attrs(
  (props: GridListTileBarCustomProps) => {
    return {
      ...props,
    } as GridListTileBarCustomProps;
  },
)`
  && {
    &.MuiGridListTileBar-root {
      height: ${(props: GridListTileBarCustomProps) =>
        props.tile_type === 'default'
          ? em(46)
          : props.tile_type === 'quiet'
          ? em(40)
          : em(48)};
    }

    &.MuiGridListTileBar-root.MuiGridListTileBar-titlePositionBottom.MuiGridListTileBar-rootSubtitle {
      height: ${(props: GridListTileBarCustomProps) =>
        props.tile_type === 'default'
          ? em(64)
          : props.tile_type === 'quiet'
          ? em(58)
          : em(66)};

      & .MuiGridListTileBar-actionIcon {
        padding-right: ${em(4)};
      }
    }

    &.MuiGridListTileBar-root .MuiGridListTileBar-titleWrap {
      margin: 0;
      height: 100%;
      & .MuiGridListTileBar-title {
        line-height: ${em(22)};
        font-size: unset;
        padding-top: ${(props: GridListTileBarCustomProps) =>
          props.tile_type === 'default'
            ? em(12)
            : props.tile_type === 'quiet'
            ? em(9)
            : em(13)};

        padding-left: ${(props: GridListTileBarCustomProps) =>
          props.tile_type === 'default'
            ? em(12)
            : props.tile_type === 'quiet'
            ? em(0)
            : em(16)};

        padding-right: ${(props: GridListTileBarCustomProps) =>
          props.tile_type === 'default'
            ? em(12)
            : props.tile_type === 'quiet'
            ? em(0)
            : em(16)};
      }

      & .MuiGridListTileBar-subtitle {
        line-height: ${em(18)};
        font-size: unset;
        padding-left: ${(props: GridListTileBarCustomProps) =>
          props.tile_type === 'default'
            ? em(12)
            : props.tile_type === 'quiet'
            ? em(0)
            : em(16)};
        padding-right: ${(props: GridListTileBarCustomProps) =>
          props.tile_type === 'default'
            ? em(12)
            : props.tile_type === 'quiet'
            ? em(0)
            : em(16)};
      }
    }

    &.MuiGridListTileBar-root.MuiGridListTileBar-titlePositionTop {
      background: transparent;
      height: ${em(28)};

      & .MuiGridListTileBar-actionIcon {
        padding-top: ${em(8)};
        padding-right: ${em(8)};
      }
    }
  }
`;

/** Declare Grid type data*/
export type GridListModel = {
  id: number | string;
  image: string;
  title?: string;
  subtitle?: string;
};

/** Export type declration for examples */
export interface IGridListModelProps extends Array<GridListModel> {
  [index: number]: GridListModel;
}
// export type IGridListModelProps = GridListModel[];

/** GridView Custom Props */
export interface GridViewCustomProps {
  width: number;
  cols?: number;
  cellHeight?: number;
  headerTitle?: string;
  gridData?: IGridListModelProps;
  gridBar?: boolean;
  gridType?: GridTileType;
  gridVariant?: GridVariant;
}

/** Grid Container */
const GridContainer = styled.div<GridViewCustomProps>`
  width: ${(props: GridViewCustomProps) => em(props.width)};
`;

/** Grid View */
export const GridView = (props: GridViewCustomProps) => {
  const {
    width,
    cols,
    cellHeight,
    headerTitle,
    gridData,
    gridBar,
    gridType,
    gridVariant,
  } = props;

  return (
    <GridContainer width={width}>
      <GridList cols={cols} cellHeight={cellHeight}>
        {headerTitle && (
          <GridListTile key="subheader" cols={cols} style={{ height: 'auto' }}>
            <ListSubheader component="div" disableGutters>
              <Typography variant="subtitle1">{headerTitle}</Typography>
            </ListSubheader>
          </GridListTile>
        )}
        {gridData &&
          gridData.map((tile: any) => (
            <GridListTile
              key={tile.image}
              tile_type={gridType}
              variant={gridVariant}>
              <img src={tile.image} alt={tile.title} className={'grid-image'} />
              {gridBar && (
                <GridListTileBar
                  tile_type={gridType}
                  title={
                    <Typography
                      variant="h6"
                      color="inherit"
                      style={{ marginBottom: 0, fontWeight: 'normal' }}>
                      {tile.title}
                    </Typography>
                  }
                  subtitle={
                    tile.subtitle && (
                      <Typography
                        variant="subtitle1"
                        color="inherit"
                        style={{ marginBottom: 0 }}>
                        {tile.subtitle}
                      </Typography>
                    )
                  }
                />
              )}
            </GridListTile>
          ))}
      </GridList>
    </GridContainer>
  );
};
