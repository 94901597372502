import React from 'react';
import {
  ISplitButtonProps,
  ISplitButtonStyle,
  SplitButtonVariant,
  SplitButtonType,
  SplitButtonGroup,
  StyledIcon,
} from '.';
import MuiToggleButton, {
  ToggleButtonProps,
} from '@material-ui/lab/ToggleButton';
import styled, { css } from 'styled-components';
import componentColor, { dls } from '@dls/react-colors';
import { Button, ButtonVariant } from '@dls/react-mui-button';
import { Icon, IconName } from '@dls/react-icon';
import { em, touchStyles, svgScaling } from '@dls/react-theme';
import {
  Menu,
  MenuItem,
  PrefixMenuItemWithOnlyIcon,
} from '@dls/react-mui-menu';
import { Label } from '@dls/react-mui-typography';

interface ToggleCustomProps extends ToggleButtonProps, ISplitButtonStyle {}

const ToggleButton = styled(MuiToggleButton).attrs(
  (props: ToggleCustomProps) => {
    return {
      ...props,
      className: props.button_type,
    } as ToggleCustomProps;
  },
)`
  && {
    &.MuiToggleButton-root {
      border-radius: unset;
      border: unset;
      padding-left: ${em(12)};
      padding-right: ${em(12)};
      min-height: ${em(40)};
      height: ${em(40)};
      text-transform: unset;
      font-size: ${em(16)};
      /* stylelint-disable */
      font-family: 'CentraleSans', Tahoma, Arial, Helvetica, sans-serif;
      /* stylelint-enable */
      font-weight: normal;
      font-style: normal;

      & .MuiTouchRipple-root {
        display: none;
      }
    }

    &.MuiToggleButton-root.secondary {
      background-color: ${componentColor(
        dls.splitToggleButton.backgroundButtonAction.secondary.normalOff,
      )};
      color: ${componentColor(dls.splitToggleButton.text.secondary.normalOff)};

      svg {
        color: ${componentColor(
          dls.splitToggleButton.icon.secondary.normalOff,
        )};
      }

      &:hover {
        background-color: ${componentColor(
          dls.splitToggleButton.backgroundButtonAction.secondary.hoverOff,
        )};
        color: ${componentColor(dls.splitToggleButton.text.secondary.hoverOff)};
      }

      &:focus {
        &.MuiButtonGroup-groupedContainedHorizontal {
          border: unset !important;
        }

        background-color: ${componentColor(
          dls.splitToggleButton.backgroundButtonAction.secondary.focusOff,
        )};

        color: ${componentColor(dls.splitToggleButton.text.secondary.focusOff)};
        outline: ${em(3)} solid
          ${componentColor(
            dls.splitToggleButton.focusOutlineAction.secondary.focusPressed,
          )};
        outline-offset: ${em(1)};
        z-index: 1;
      }

      &:active {
        background-color: ${componentColor(
          dls.splitToggleButton.backgroundButtonAction.secondary.pressedOff,
        )};
        color: ${componentColor(
          dls.splitToggleButton.text.secondary.pressedOff,
        )};
        outline: ${em(3)} solid
          ${componentColor(
            dls.splitToggleButton.focusOutlineAction.secondary.focusPressed,
          )};
        outline-offset: ${em(1)};
      }
    }

    &.MuiToggleButton-root.quietDefault {
      background-color: ${componentColor(
        dls.splitToggleButton.backgroundButtonAction.inputQuiet.normalOff,
      )};
      color: ${componentColor(dls.splitToggleButton.text.inputQuiet.normalOff)};

      svg {
        color: ${componentColor(
          dls.splitToggleButton.icon.inputQuiet.normalOff,
        )};
      }

      &:hover {
        background-color: ${componentColor(
          dls.splitToggleButton.backgroundButtonAction.inputQuiet.hoverOff,
        )};
        color: ${componentColor(
          dls.splitToggleButton.text.inputQuiet.hoverOff,
        )};
      }

      &:focus {
        &.MuiButtonGroup-groupedContainedHorizontal {
          border: unset !important;
        }
        background-color: ${componentColor(
          dls.splitToggleButton.backgroundButtonAction.inputQuiet.focusOff,
        )};

        color: ${componentColor(
          dls.splitToggleButton.text.inputQuiet.focusOff,
        )};
        outline: ${em(3)} solid
          ${componentColor(
            dls.splitToggleButton.focusOutline.inputQuiet.focusPressedOnOff,
          )};
        outline-offset: ${em(1)};
        z-index: 1;
      }

      &:active {
        background-color: ${componentColor(
          dls.splitToggleButton.backgroundButtonAction.inputQuiet.pressedOff,
        )};
        color: ${componentColor(
          dls.splitToggleButton.text.inputQuiet.pressedOff,
        )};
        outline: ${em(3)} solid
          ${componentColor(
            dls.splitToggleButton.focusOutline.inputQuiet.focusPressedOnOff,
          )};
        outline-offset: ${em(1)};
      }
    }

    &.MuiToggleButton-root.Mui-selected {
      &.secondary {
        background-color: ${componentColor(
          dls.splitToggleButton.backgroundButtonAction.secondary.normalOn,
        )};
        color: ${componentColor(dls.splitToggleButton.text.secondary.normalOn)};

        svg {
          color: ${componentColor(
            dls.splitToggleButton.icon.secondary.normalOn,
          )};
        }

        &:hover {
          background-color: ${componentColor(
            dls.splitToggleButton.backgroundButtonAction.secondary.hoverOn,
          )};
          color: ${componentColor(
            dls.splitToggleButton.text.secondary.hoverOn,
          )};
        }

        &:focus {
          background-color: ${componentColor(
            dls.splitToggleButton.backgroundButtonAction.secondary.focusOn,
          )};

          color: ${componentColor(
            dls.splitToggleButton.text.secondary.focusOn,
          )};

          outline: ${em(3)} solid
            ${componentColor(
              dls.splitToggleButton.focusOutlineAction.secondary.focusPressed,
            )};
          outline-offset: ${em(1)};
          z-index: 1;
        }

        &:active {
          background-color: ${componentColor(
            dls.splitToggleButton.backgroundButtonAction.secondary.pressedOn,
          )};
          color: ${componentColor(
            dls.splitToggleButton.text.secondary.pressedOn,
          )};
          outline: ${em(3)} solid
            ${componentColor(
              dls.splitToggleButton.focusOutlineAction.secondary.focusPressed,
            )};
          outline-offset: ${em(1)};
        }
      }

      &.quietDefault {
        border-top-left-radius: ${em(2)};
        border-bottom-left-radius: ${em(2)};

        &.MuiButtonGroup-groupedContainedHorizontal:not(:last-child) {
          border-right: 1px solid
            ${componentColor(
              dls.splitToggleButton.separator.inputQuiet.normalOn,
            )} !important;
        }

        background-color: ${componentColor(
          dls.splitToggleButton.backgroundButtonAction.inputQuiet.normalOn,
        )};
        color: ${componentColor(
          dls.splitToggleButton.text.inputQuiet.normalOn,
        )};
        svg {
          color: ${componentColor(
            dls.splitToggleButton.icon.inputQuiet.normalOn,
          )};
        }

        &:hover {
          background-color: ${componentColor(
            dls.splitToggleButton.backgroundButtonAction.inputQuiet.hoverOn,
          )};
          color: ${componentColor(
            dls.splitToggleButton.text.inputQuiet.hoverOn,
          )};
        }

        &:focus {
          background-color: ${componentColor(
            dls.splitToggleButton.backgroundButtonAction.inputQuiet.focusOn,
          )};

          color: ${componentColor(
            dls.splitToggleButton.text.inputQuiet.focusOn,
          )};
          outline: ${em(3)} solid
            ${componentColor(
              dls.splitToggleButton.focusOutline.inputQuiet.focusPressedOnOff,
            )};
          outline-offset: ${em(1)};
          z-index: 1;
        }

        &:active {
          background-color: ${componentColor(
            dls.splitToggleButton.backgroundButtonAction.inputQuiet.pressedOn,
          )};
          color: ${componentColor(
            dls.splitToggleButton.text.inputQuiet.pressedOn,
          )};
          outline: ${em(3)} solid
            ${componentColor(
              dls.splitToggleButton.focusOutline.inputQuiet.focusPressedOnOff,
            )};
          outline-offset: ${em(1)};
        }
      }

      &.Mui-disabled {
        &.secondary {
          background-color: ${componentColor(
            dls.splitToggleButton.backgroundButtonAction.secondary.disabledOn,
          )};
          color: ${componentColor(
            dls.splitToggleButton.text.secondary.disabledOn,
          )};

          svg {
            color: ${componentColor(
              dls.splitToggleButton.icon.secondary.disabledOn,
            )};
          }
        }

        &.quietDefault {
          background-color: ${componentColor(
            dls.splitToggleButton.backgroundButtonAction.inputQuiet.disabledOn,
          )};
          color: ${componentColor(
            dls.splitToggleButton.text.inputQuiet.disabledOn,
          )};
          svg {
            color: ${componentColor(
              dls.splitToggleButton.icon.inputQuiet.disabledOn,
            )};
          }
        }
      }
    }

    .pt-dense & {
      padding-left: ${em(8)};
      padding-right: ${em(8)};
      min-height: ${em(32)};
      height: ${em(32)};
    }

    .pt-touch & {
      ${touchStyles(em(4))};
    }

    /** Toggle BUTTON ICON SCALING */
    &.MuiToggleButton-root .MuiToggleButton-label span {
      ${svgScaling(em(24))};
      & svg {
        top: 0;
        left: 0;
      }
    }

    &.MuiToggleButton-root.Mui-disabled {
      &.secondary {
        background-color: ${componentColor(
          dls.splitToggleButton.backgroundButtonAction.secondary.disabledOff,
        )};
        color: ${componentColor(
          dls.splitToggleButton.text.secondary.disabledOff,
        )};

        svg {
          color: ${componentColor(
            dls.splitToggleButton.icon.secondary.disabledOff,
          )};
        }
      }

      &.quietDefault {
        background-color: ${componentColor(
          dls.splitToggleButton.backgroundButtonAction.inputQuiet.disabledOff,
        )};
        color: ${componentColor(
          dls.splitToggleButton.text.inputQuiet.disabledOff,
        )};
        svg {
          color: ${componentColor(
            dls.splitToggleButton.icon.inputQuiet.disabledOff,
          )};
        }
      }
    }
  }
`;

const StyledIconButton = styled(Button)<ISplitButtonStyle>`
  ${(props: ISplitButtonStyle) =>
    css`
      &.MuiButton-root.${props.button_type} {
        border-radius: unset;
        padding-left: ${em(4)};
        padding-right: ${em(4)};
        min-height: ${em(40)};
        height: ${em(40)};
        transition: unset;

        background-color: ${props.button_type === 'secondary'
          ? componentColor(
              dls.splitToggleButton.backgroundButtonDropdown.secondary.normal,
            )
          : componentColor(
              dls.splitToggleButton.backgroundButtonDropdown.inputQuiet.normal,
            )} !important;

        .pt-dense & {
          padding-left: 0;
          padding-right: 0;
          min-height: ${em(32)};
          height: ${em(32)};
        }

        .pt-touch & {
          ${touchStyles(em(4))};
        }

        /**============== Active Style REGION============*/
        &:active {
          background-color: ${props.button_type === 'secondary'
            ? componentColor(
                dls.splitToggleButton.backgroundButtonDropdown.secondary
                  .pressed,
              )
            : componentColor(
                dls.splitToggleButton.backgroundButtonDropdown.inputQuiet
                  .pressed,
              )} !important;

          border: ${props.button_type === 'secondary'
            ? ''
            : componentColor(
                dls.splitToggleButton.border.inputQuiet.hoverOffFocusPressed,
              )};
          outline: ${em(3)} solid
            ${props.button_type === 'secondary'
              ? ''
              : componentColor(
                  dls.splitToggleButton.focusOutline.inputQuiet
                    .focusPressedOnOff,
                )};
          outline-offset: ${em(1)};
        }
        /**============== Active Style End REGION============*/

        /**============== Hover Style REGION============*/
        &:hover {
          background-color: ${props.button_type === 'secondary'
            ? componentColor(
                dls.splitToggleButton.backgroundButtonDropdown.secondary.hover,
              )
            : componentColor(
                dls.splitToggleButton.backgroundButtonDropdown.inputQuiet.hover,
              )} !important;

          border: ${props.button_type === 'secondary'
            ? componentColor(dls.splitToggleButton.border.secondary.hover)
            : componentColor(
                dls.splitToggleButton.border.inputQuiet.hoverOffFocusPressed,
              )};
        }
        /**============== Hover Style End REGION============*/

        /**============== Focus Style REGION============*/
        &:focus {
          &.MuiButtonGroup-groupedHorizontal:not(:first-child) {
            border-left: ${em(1)} solid
              ${componentColor(dls.splitButton.separator.inputQuiet.focus)};
          }

          background-color: ${props.button_type === 'secondary'
            ? componentColor(
                dls.splitToggleButton.backgroundButtonDropdown.secondary.focus,
              )
            : componentColor(
                dls.splitToggleButton.backgroundButtonDropdown.inputQuiet.focus,
              )} !important;

          border: ${props.button_type === 'secondary'
            ? ''
            : componentColor(
                dls.splitToggleButton.border.inputQuiet.hoverOffFocusPressed,
              )};

          outline: ${em(3)} solid
            ${props.button_type === 'secondary'
              ? componentColor(
                  dls.splitToggleButton.focusOutlineDropdown.secondary
                    .focusPressed,
                )
              : componentColor(
                  dls.splitToggleButton.focusOutline.inputQuiet
                    .focusPressedOnOff,
                )};
          outline-offset: ${em(1)};
          z-index: 1; /*  to bring the outline on top of element */
        }
        /**============== Focus Style End REGION============*/
      }
      /** BUTTON ICON SCALING */
      &.MuiButton-root span {
        ${svgScaling(em(24))};
        & svg {
          top: 0;
          left: 0;
        }
      }

      &.MuiButton-root.Mui-disabled {
        background-color: ${props.button_type === 'secondary'
          ? componentColor(
              dls.splitToggleButton.backgroundButtonDropdown.secondary.disabled,
            )
          : componentColor(
              dls.splitToggleButton.backgroundButtonDropdown.inputQuiet
                .disabled,
            )} !important;

        span {
          & svg {
            color: ${props.button_type === 'secondary'
              ? componentColor(dls.splitToggleButton.arrow.secondary.disabled)
              : componentColor(
                  dls.splitToggleButton.arrow.inputQuiet.disabled,
                )};
          }
        }
      }
    `}
`;

const StyledIconWithText = styled(Icon)<ISplitButtonStyle>`
  ${(props: ISplitButtonStyle) =>
    css`
      margin-right: ${props.button_variant === 'icon_text' ? em(12) : '0'};

      .pt-dense & {
        margin-right: ${props.button_variant === 'icon_text' ? em(8) : '0'};
      }
    `}
`;

interface ISplitToggleButtonProps extends ISplitButtonProps {
  onClickToggle?: (
    _event: React.FormEvent<HTMLButtonElement>,
    toggleState: boolean,
  ) => void;
  toggleOn?: boolean;
}

export const SplitToggleButton = (props: ISplitToggleButtonProps) => {
  const {
    options,
    button_type,
    button_variant,
    onSelectedMenuItem,
    onClickToggle,
    prefixMenu,
    disabled,
    toggleOn,
    arrowIconName,
    prefixMenuOnlyIcon,
  } = props;

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  /** UseState for Toggle Button */
  const [selected, setSelected] = React.useState(toggleOn);

  /** Handle Button Toggle Button Click*/
  /* istanbul ignore next */
  const handleToggleButton = (_event: React.FormEvent<HTMLButtonElement>) => {
    setSelected(!selected);

    if (onClickToggle !== undefined) {
      onClickToggle(_event, !selected);
    }
  };

  /// TypeChecking Props
  const _buttonVariant: SplitButtonVariant =
    button_variant === undefined ? 'text' : button_variant;

  const getButtonType = (_buttonType?: SplitButtonType): ButtonVariant => {
    return _buttonType === undefined ? 'secondary' : _buttonType;
  };

  /** Handle Button DropDown Click*/
  /* istanbul ignore next */
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  /** Handle ClickAway listner from Menu List Item Click*/
  /* istanbul ignore next */
  const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setOpen(false);
  };

  /** Handle the Menu Item Click Listner */
  /* istanbul ignore next */
  const handleMenuItemClick = (
    _event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number,
  ) => {
    setSelectedIndex(index);
    setOpen(false);

    if (onSelectedMenuItem !== undefined) {
      onSelectedMenuItem(_event, index);
    }
  };

  /** Render the Start Button */
  const startButton =
    _buttonVariant === 'text' ? (
      <ToggleButton
        value="check"
        selected={selected}
        onChange={handleToggleButton}
        button_type={getButtonType(button_type) as SplitButtonType}>
        {options[selectedIndex].title}
      </ToggleButton>
    ) : _buttonVariant === 'icon' ? (
      <ToggleButton
        value="check"
        selected={selected}
        onChange={handleToggleButton}
        button_type={getButtonType(button_type) as SplitButtonType}>
        <Icon name={options[selectedIndex].icon as IconName} size={'m'} />
      </ToggleButton>
    ) : (
      <ToggleButton
        value="check"
        selected={selected}
        onChange={handleToggleButton}
        button_type={getButtonType(button_type) as SplitButtonType}>
        <StyledIconWithText
          name={options[selectedIndex].icon as IconName}
          button_variant={_buttonVariant}
        />
        {options[selectedIndex].title}
      </ToggleButton>
    );

  const _arrowIcon = arrowIconName === undefined ? 'ArrowDown' : arrowIconName;

  /** Render the end DropDown button*/
  const endButton = (
    <StyledIconButton
      size="small"
      dls_variant={getButtonType(button_type)}
      aria-controls={open ? 'split-toggle-button-menu' : undefined}
      aria-expanded={open ? 'true' : undefined}
      aria-label="end-drop-down-toggle-button"
      aria-haspopup="menu"
      onClick={handleToggle}
      button_type={getButtonType(button_type) as SplitButtonType}>
      <StyledIcon
        name={_arrowIcon as IconName}
        size={'m'}
        button_type={getButtonType(button_type) as SplitButtonType}
      />
    </StyledIconButton>
  );

  /* istanbul ignore next */
  const MenuPopup = (
    <Menu
      id="default-menu"
      anchorEl={anchorRef.current}
      open={open}
      onClose={handleClose}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      PaperProps={{
        className: 'DLSMenuList',
      }}>
      {options.map((option: any, index: number) => (
        <MenuItem
          key={option.id}
          selected={index === selectedIndex}
          onClick={(event: React.MouseEvent<HTMLLIElement, MouseEvent>) =>
            handleMenuItemClick(event, index)
          }>
          {prefixMenu ? (
            <>
              <PrefixMenuItemWithOnlyIcon>
                <Icon name={option.icon as IconName} size={'m'} />
              </PrefixMenuItemWithOnlyIcon>
              <Label label_variant="value">{option.title}</Label>
            </>
          ) : prefixMenuOnlyIcon ? (
            <PrefixMenuItemWithOnlyIcon>
              <Icon name={option.icon as IconName} size={'m'} />
            </PrefixMenuItemWithOnlyIcon>
          ) : (
            <Label label_variant="value">{option.title}</Label>
          )}
        </MenuItem>
      ))}
    </Menu>
  );

  return (
    <React.Fragment>
      <SplitButtonGroup
        variant="contained"
        ref={anchorRef}
        button_type={button_type}
        aria-label="split toggle button"
        disabled={disabled}
        button_disabled={disabled ? 'disabledOn' : 'disabledOff'}
        button_selected={selected ? 'toggleOn' : 'toggleOff'}>
        {startButton}
        {endButton}
      </SplitButtonGroup>
      {MenuPopup}
    </React.Fragment>
  );
};

const SplitToggleRadioGroupContext = React.createContext<any>(null);
/* istanbul ignore next */
function useRadioContext() {
  const context = React.useContext(SplitToggleRadioGroupContext);
  if (!context) {
    throw new Error(
      `SplitToggleGroupContext components cannot be rendered outside the Split Button component`,
    );
  }
  return context;
}

interface SplitToggleRadioGroupProps {
  children: React.ReactNodeArray;
  defaultValue: string;
  onChange: (_event: React.FormEvent<HTMLButtonElement>) => void;
}

export const SplitToggleRadioGroup = (props: SplitToggleRadioGroupProps) => {
  const { defaultValue, children, onChange } = props;

  const [state, setState] = React.useState('');

  /* istanbul ignore next */
  const handleOnChange = (value: any) => {
    setState(value);
    onChange(value);
  };

  React.useEffect(() => {
    setState(defaultValue);
  }, [defaultValue]);

  return (
    <SplitToggleRadioGroupContext.Provider value={[state, handleOnChange]}>
      <div
        role="radiogroup"
        style={{
          display: 'flex',
          alignContent: 'center',
          flexDirection: 'row',
        }}>
        {children}
      </div>
    </SplitToggleRadioGroupContext.Provider>
  );
};

interface SplitToggleRadioButtonProps extends ISplitToggleButtonProps {
  value: any;
}

export const SplitToggleRadioButton = (props: SplitToggleRadioButtonProps) => {
  const {
    value,
    options,
    button_variant,
    button_type,
    disabled,
    prefixMenu,
    prefixMenuOnlyIcon,
    arrowIconName,
  } = props;

  const [state, onChange] = useRadioContext();
  const checked = value === state;

  /* istanbul ignore next */
  const handleToggleButton = (_event: React.FormEvent<HTMLButtonElement>) => {
    onChange(_event.currentTarget.value);
  };

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  /// TypeChecking Props
  const _buttonVariant: SplitButtonVariant =
    button_variant === undefined ? 'text' : button_variant;

  const getButtonType = (_buttonType?: SplitButtonType): ButtonVariant => {
    return _buttonType === undefined ? 'secondary' : _buttonType;
  };

  /** Handle ClickAway listner from Menu List Item Click*/
  /* istanbul ignore next */
  const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setOpen(false);
  };

  /** Handle the Menu Item Click Listner */
  /* istanbul ignore next */
  const handleMenuItemClick = (
    _event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number,
  ) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  /** Handle Button DropDown Click*/
  /* istanbul ignore next */
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  /** Render the Start Button */
  const startButton =
    _buttonVariant === 'text' ? (
      <ToggleButton
        value={value}
        selected={checked}
        onChange={handleToggleButton}
        button_type={getButtonType(button_type) as SplitButtonType}>
        {options[selectedIndex].title}
      </ToggleButton>
    ) : _buttonVariant === 'icon' ? (
      <ToggleButton
        value={value}
        selected={checked}
        onChange={handleToggleButton}
        button_type={getButtonType(button_type) as SplitButtonType}>
        <Icon name={options[selectedIndex].icon as IconName} size={'m'} />
      </ToggleButton>
    ) : (
      <ToggleButton
        value={value}
        selected={checked}
        onChange={handleToggleButton}
        button_type={getButtonType(button_type) as SplitButtonType}>
        <StyledIconWithText
          name={options[selectedIndex].icon as IconName}
          button_variant={_buttonVariant}
        />
        {options[selectedIndex].title}
      </ToggleButton>
    );

  const _arrowIcon = arrowIconName === undefined ? 'ArrowDown' : arrowIconName;

  /** Render the end DropDown button*/
  const endButton = (
    <StyledIconButton
      size="small"
      dls_variant={getButtonType(button_type)}
      aria-controls={open ? 'split-toggle-radio-button-menu' : undefined}
      aria-expanded={open ? 'true' : undefined}
      aria-label="end-drop-down-toggle-button"
      aria-haspopup="menu"
      onClick={handleToggle}
      button_type={getButtonType(button_type) as SplitButtonType}>
      <StyledIcon
        name={_arrowIcon as IconName}
        size={'m'}
        button_type={getButtonType(button_type) as SplitButtonType}
      />
    </StyledIconButton>
  );

  /* istanbul ignore next */
  const MenuPopup = (
    <Menu
      id="default-menu"
      anchorEl={anchorRef.current}
      open={open}
      onClose={handleClose}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      PaperProps={{
        className: 'DLSMenuList',
      }}>
      {options.map((option: any, index: number) => (
        <MenuItem
          key={option.id}
          selected={index === selectedIndex}
          onClick={(event: React.MouseEvent<HTMLLIElement, MouseEvent>) =>
            handleMenuItemClick(event, index)
          }>
          {prefixMenu ? (
            <>
              <PrefixMenuItemWithOnlyIcon>
                <Icon name={option.icon as IconName} size={'m'} />
              </PrefixMenuItemWithOnlyIcon>
              <Label label_variant="value">{option.title}</Label>
            </>
          ) : prefixMenuOnlyIcon ? (
            <PrefixMenuItemWithOnlyIcon>
              <Icon name={option.icon as IconName} size={'m'} />
            </PrefixMenuItemWithOnlyIcon>
          ) : (
            <Label label_variant="value">{option.title}</Label>
          )}
        </MenuItem>
      ))}
    </Menu>
  );
  return (
    <React.Fragment>
      <SplitButtonGroup
        ref={anchorRef}
        variant="contained"
        button_type={button_type}
        aria-label="split toggle radio button"
        disabled={disabled}
        button_selected={checked ? 'toggleOn' : 'toggleOff'}>
        {startButton}
        {endButton}
      </SplitButtonGroup>
      {MenuPopup}
    </React.Fragment>
  );
};

SplitToggleRadioGroup.SplitRadioButton = SplitToggleRadioButton;
