import React from 'react';
import styled from 'styled-components';
import { Typography } from '@dls/react-beta-typography';
import { wrapStringChildWith } from '@dls/react-beta-utils';

const wrapChildren = wrapStringChildWith(({ children }) => (
  <Typography variant="h5">{children}</Typography>
));

export const PopoverHeader: any = styled.div.attrs(wrapChildren)`
  display: flex;
  align-items: center;
  padding: 16px;
`;
