export enum DateTimePickerCellType {
  Neutral = 'Neutral',
  Disabled = 'Disabled',
  Selected = 'Selected',
  Highlighted = 'Highlighted',
}

export type DateTimePickerCellProps = Readonly<{
  type: DateTimePickerCellType;
  onClick: () => void | undefined;
}>;
