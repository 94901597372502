import React from 'react';
import {
  dlsIcons24,
  DlsIconFont,
  DlsIconFontIcon,
} from '@dls/react-beta-dls-icon-font';
import {
  ReferenceGrid,
  GaugeBackground,
  Value,
  Unit,
  Icon,
  DEFAULT_ICON_SIZE,
  GaugeContainer,
  Title,
  Subtitle,
  TitlesContainer,
} from './styles';
import { GaugeProps } from './types';

export const renderIcon = ({
  icon,
  iconSize,
}: Pick<GaugeProps, 'icon' | 'iconSize'>): React.ReactNode => {
  const dlsIconKeys = Object.keys(dlsIcons24);
  if (typeof icon === 'string' && dlsIconKeys.includes(icon)) {
    return (
      <DlsIconFont
        icon={icon as DlsIconFontIcon}
        fontSize={iconSize}
        width={iconSize}
        height={iconSize}
      />
    );
  }

  return icon;
};

export const Gauge: React.FC<GaugeProps> = ({
  icon,
  iconSize = DEFAULT_ICON_SIZE,
  value,
  unit,
  title,
  subtitle,
  colors = {},
}) => {
  const titleContent = title ? (
    <Title gutterBottom variant="h6" customColor={colors.titleText}>
      {title}
    </Title>
  ) : null;

  const subtitleContent = subtitle ? (
    <Subtitle variant="subtitle2" customColor={colors.subtitleText}>
      {subtitle}
    </Subtitle>
  ) : null;

  const titles =
    titleContent || subtitleContent ? (
      <TitlesContainer>
        {titleContent}
        {subtitleContent}
      </TitlesContainer>
    ) : null;

  return (
    <GaugeContainer>
      <ReferenceGrid customColor={colors.valueIndicator}>
        <GaugeBackground customColor={colors.gaugeBackground}>
          <Icon iconSize={iconSize} customColor={colors.icon}>
            {renderIcon({ icon, iconSize })}
          </Icon>
          <Value customColor={colors.valueText}>{value || '--'}</Value>
          <Unit customColor={colors.unitText}>{unit}</Unit>
        </GaugeBackground>
      </ReferenceGrid>
      {titles}
    </GaugeContainer>
  );
};
