import React from 'react';
import { Typography, Label } from '@dls/react-mui-typography';
import { Icon } from '@dls/react-icon';

const withIcon = {
  className: 'withIcon',
};

const icon = {
  className: 'dlsIcon',
};

export const Banner = {
  WithoutHeader: (
    <div>
      <Typography extra_variant="body3">
        Via Service Message - Via MessageService
      </Typography>
    </div>
  ),

  WithHeader: (
    <div>
      <Typography variant="h6">Service Message</Typography>
      <Label label_variant="basic">- Via Service Message</Label>
    </div>
  ),

  WithCloseButtons: (
    <div>
      <Typography variant="h6">Service Message</Typography>
      <Label label_variant="basic">- Via Service Message</Label>
    </div>
  ),

  WithButtons: (
    <div>
      <Typography variant="h6">Service Message</Typography>
      <Label label_variant="basic">- Via Service Message</Label>
    </div>
  ),

  WithIcon: (
    <div>
      <Icon name={'Shop'} size="m" {...withIcon} />
      <Typography variant="h6">Service Message</Typography>
      <Label label_variant="basic">- Via Service Message</Label>
    </div>
  ),

  Info: (
    <div>
      <Icon name={'InformationCircle'} size="m" {...icon} />
      <Typography variant="h6">Service Message</Typography>
      <Label label_variant="basic">- Via Service Message</Label>
    </div>
  ),

  Warning: (
    <div>
      <Icon name={'InformationCircle'} size="m" {...icon} />
      <Typography variant="h6">Service Message</Typography>
      <Label label_variant="basic">- Via Service Message</Label>
    </div>
  ),

  Error: (
    <div>
      <Icon name={'InformationCircle'} size="m" {...icon} />
      <Typography variant="h6">Service Message</Typography>
      <Label label_variant="basic">- Via Service Message</Label>
    </div>
  ),
  Success: (
    <div>
      <Icon name={'InformationCircle'} size="m" {...icon} />
      <Typography variant="h6">Service Message</Typography>
      <Label label_variant="basic">- Via Service Message</Label>
    </div>
  ),
};
