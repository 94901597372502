/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import { useSelect } from 'downshift';
import componentColor, { dls } from '@dls/react-colors';
import { ArrowDown } from '@dls/react-icons';
import { DropdownProps, ReactChild } from './types';
import {
  DropdownWrapper,
  DropdownInputWrapper,
  DropdownInput,
  DropdownIconWrapper,
  DropdownItemList,
  DropdownButton,
  DropdownValue,
} from './styles';
import { ListItem } from '@dls/react-beta-list-item';

export const Dropdown: React.FunctionComponent<DropdownProps> = React.forwardRef(
  (props, ref) => {
    const [customItem, setCustomItem] = useState(undefined);
    const {
      children,
      closeOnClick,
      customListItemStyle,
      customStyle,
      disabled,
      initialText,
      items,
      onItemClick,
      opened,
      variant,
      initialSelectedItem,
      ...other
    } = props;
    const {
      closeMenu,
      getItemProps,
      getMenuProps,
      getToggleButtonProps,
      highlightedIndex,
      isOpen,
      selectedItem,
    } = useSelect({
      items,
      initialIsOpen: opened,
      initialSelectedItem,
      selectedItem: initialSelectedItem,
    });

    const iconWrapper = (): React.ReactElement => (
      <DropdownIconWrapper className="icon-wrapper">
        <ArrowDown />
      </DropdownIconWrapper>
    );

    const setHighlightedIndex = (
      highlightedIndex: number,
      index: number,
    ): any => {
      const dropdownHoverColor = {
        backgroundColor: componentColor(
          dls.listItem.background.default.normalOn,
        )(),
        color: componentColor(dls.listItem.text.default.normalOn)(),
      };
      /* istanbul ignore next */
      return highlightedIndex === index
        ? {
            ...dropdownHoverColor,
          }
        : {};
    };

    const functionEventHandler = (
      e: React.FormEvent,
      functionProps: any,
    ): void => {
      if (typeof functionProps === 'function') functionProps(e);
    };

    const childrenClickHandler = (
      e: React.FormEvent,
      value: React.Props<string>,
      customOnItemClickFunction: any,
    ): void => {
      if (closeOnClick) closeMenu();
      functionEventHandler(e, customOnItemClickFunction);
      setCustomItem(value);
    };

    const childrenWithProps = React.Children.map(
      children,
      (child: ReactChild) =>
        React.cloneElement(child, {
          onClick: (e: React.FormEvent) =>
            childrenClickHandler(
              e,
              child.props.children,
              onItemClick && onItemClick(e),
            ),
        }),
    );

    const menuProps = getMenuProps({}, { suppressRefError: true });

    return (
      <DropdownWrapper
        className="dropdown-wrapper"
        disabled={disabled}
        {...other}>
        {!variant ? (
          <DropdownInputWrapper
            className="dropdown-input-wrapper"
            pressed={isOpen}
            {...getToggleButtonProps()}>
            <DropdownInput style={customStyle} disabled={disabled} readOnly />
            <DropdownValue>
              {!customItem ? selectedItem || initialText : customItem}
            </DropdownValue>
            {iconWrapper()}
          </DropdownInputWrapper>
        ) : (
          <DropdownButton
            variant={variant}
            disabled={disabled}
            style={customStyle}
            {...getToggleButtonProps()}>
            {!customItem ? selectedItem || initialText : customItem}
            {iconWrapper()}
          </DropdownButton>
        )}
        {isOpen && (
          <DropdownItemList
            ref={ref}
            style={customListItemStyle}
            {...menuProps}>
            {!children
              ? items.map((item, index) => (
                  <ListItem
                    style={setHighlightedIndex(highlightedIndex, index)}
                    key={`${item}${index}`}
                    {...getItemProps({
                      item,
                      index,
                      onClick: (e: React.FormEvent): void =>
                        functionEventHandler(e, onItemClick),
                      refKey: 'innerRef',
                    })}>
                    {item}
                  </ListItem>
                ))
              : childrenWithProps}
          </DropdownItemList>
        )}
      </DropdownWrapper>
    );
  },
);

Dropdown.defaultProps = {
  items: [],
  initialText: '',
};
