import React from 'react';
import styled from 'styled-components';
import componentColor, { dls } from '@dls/react-colors';
import { em } from '@dls/react-theme';

/**
 * Dot Navigation Container Position default set to 'center'
 */
export type DotNavigationAlign = 'left' | 'center' | 'right';

/**
 * Interface for the dot state
 */
interface IDotState {
  active: boolean;
}

/**
 * Interface for the dot container
 */
interface IDotContainer {
  align?: DotNavigationAlign;
}

/**
 * Styles for the Touch container
 */
export const TouchDotNavigationContainer = styled.div<IDotContainer>`
  display: flex;
  justify-content: ${(props: IDotContainer) => props.align};
`;

/**
 * Styles for the Parent Container of Dots
 */
export const DotNavigationContainer = styled.ul`
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
  align-items: center;
  cursor: default;
  -webkit-touch-callout: none;
  user-select: none;
  /* .pt-touch & {
    height: em(48);
  } */
`;

/**
 * Styles for Dots element
 */
export const DotsContainer = styled.li`
  width: ${em(24)};
  height: ${em(24)};
  position: relative;
  display: block;
  float: left;
  cursor: pointer;
  outline: none;

  /* .pt-touch & {
    width: em(16);
    height: em(16);
  } */
`;

/**
 * Styles for the dots
 */
export const Dot = styled.a<IDotState>`
  top: 0;
  left: 0;
  width: ${em(12)};
  height: ${em(12)};
  outline: none;
  border-radius: 50%;
  text-indent: -999em;
  /* make the text accessible to screen readers */
  cursor: pointer;
  position: absolute;
  margin: ${em(6)};
  /** Background based on the Dot State active or not */
  background: ${(props: IDotState) =>
    props.active
      ? componentColor(dls.dotNavigation.background.default.normalOn)
      : componentColor(dls.dotNavigation.background.default.normalOff)};

  &:hover {
    background: ${componentColor(dls.dotNavigation.background.default.hover)};
  }

  /* .pt-touch & {
    width: em(8);
    height: em(8);
    margin: em(4);
  } */
`;

/**
 * Dot navigation props
 */
interface IDotNavigationProps {
  count: number;
  initialSelected?: number;
  align?: DotNavigationAlign;
  /**
   * https://reactjs.org/blog/2017/09/08/dom-attributes-in-react-16.html
   */
  onselected?: (event: React.MouseEvent<HTMLElement>, index: number) => void;
  keydown?: (event: React.KeyboardEvent<HTMLElement>, index: number) => void;
}

/**
 * Keys enum for Keyboard Interaction.
 */
enum Key {
  End = 35,
  Home = 36,
  LeftArrow = 37,
  RightArrow = 39,
}

/**
 *  Dot Navigation component with its Custom Props.
 */
export const DotNavigation = (props: IDotNavigationProps) => {
  /// Passing Props
  const { count, onselected, initialSelected, align, keydown } = props;

  /**
   * Set Dot for keyboard and State of active dot
   **/
  const [currentDotIndex, setCurrentDotIndex] = React.useState(
    initialSelected ? initialSelected : 0,
  );

  /// Passing dots count and allocate array for mapping
  const data: Array<{ id: string }> = [];

  for (let index = 0; index < count; index++) {
    const object = {
      id: index.toString(),
    };
    data.push(object);
  }

  /* istanbul ignore next */
  const handleClick = (
    _event: React.MouseEvent<HTMLElement>,
    index: number,
  ) => {
    setCurrentDotIndex(index);

    if (onselected !== undefined) {
      onselected(_event, index);
    }
  };

  /* istanbul ignore next */
  const getValidKey = (keyCode: number) => {
    if (
      keyCode === Key.LeftArrow ||
      keyCode === Key.RightArrow ||
      keyCode === Key.Home ||
      keyCode === Key.End
    ) {
      return true;
    }
    return false;
  };

  /* istanbul ignore next */
  const handleKeyBoardInteraction = (
    _event: React.KeyboardEvent<HTMLElement>,
  ) => {
    let dot = currentDotIndex;

    if (currentDotIndex > 0 && _event.keyCode === Key.LeftArrow) {
      dot -= 1;
    } else if (
      currentDotIndex < count - 1 &&
      _event.keyCode === Key.RightArrow
    ) {
      dot += 1;
    } else if (currentDotIndex > 0 && _event.keyCode === Key.Home) {
      dot = 0;
    } else if (currentDotIndex < count - 1 && _event.keyCode === Key.End) {
      dot = count - 1;
    }

    /// Validate the current keys input
    if (getValidKey(_event.keyCode)) {
      /// Active the current dot
      setCurrentDotIndex(dot);

      /// Callback the props to the user end
      if (keydown !== undefined) {
        keydown(_event, dot);
      }
    }
  };

  /// TypeChecking Props
  const alignProps: DotNavigationAlign = align === undefined ? 'center' : align;

  return (
    <TouchDotNavigationContainer align={alignProps} {...props}>
      <DotNavigationContainer {...props}>
        {data.map((item, _index) => (
          <DotsContainer
            onClick={(_event: React.MouseEvent<HTMLElement>) =>
              handleClick(_event, _index)
            }
            onKeyDown={handleKeyBoardInteraction}
            tabIndex={0}
            key={item.id}>
            <Dot active={_index === currentDotIndex} />
          </DotsContainer>
        ))}
      </DotNavigationContainer>
    </TouchDotNavigationContainer>
  );
};
