import React, { FunctionComponent, SVGProps } from 'react';

const CurveDraw = (props) => (
  <svg viewBox="0 0 48 48" width="1em" height="1em" {...props}>
    <path
      fill="#231f20"
      d="M30 38v2.088C8.638 32.797 7.017 9.323 7.001 9.057l1.997-.114C9.057 9.955 10.538 31.007 30 38zm11-1v-5h-4v5h-5v4h5v5h4v-5h5v-4h-5z"
    />
  </svg>
);

export default CurveDraw;
