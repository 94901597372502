const icons = {
  '2dstitching': '\f04a',
  '3d': '\f0de',
  '3d-outline': '\f0dd',
  about: '\f0df',
  airplane: '\f033',
  alarm: '\f063',
  'alarm-off': '\f0b6',
  'alarm-silent-iec': '\f0e0',
  'align-center': '\f023',
  'align-justify': '\f025',
  'align-left': '\f022',
  'align-right': '\f024',
  'allele-alignment-tool': '\f0e1',
  'anatomical-brain': '\f0e2',
  'anatomical-cervix': '\f0e3',
  'anatomical-colon': '\f0e4',
  'anatomical-knee': '\f0e5',
  'anatomical-liver': '\f0e6',
  'anatomical-prostate': '\f0e7',
  'anatomical-respiratory': '\f0e8',
  'anatomical-spine': '\f0e9',
  'anatomical-teeth': '\f0ea',
  'anatomical-vein': '\f0eb',
  animation: '\f0ec',
  'annotation-arrow': '\f0ed',
  'annotation-arrow-text': '\f05d',
  'annotation-box': '\f0ee',
  'annotation-circle': '\f050',
  'annotation-copy-to-all': '\f0ef',
  'annotation-delete-group': '\f0f0',
  'annotation-ellipse': '\f038',
  'annotation-freehand': '\f0f1',
  'annotation-polygon': '\f0f2',
  'annotation-smooth-polygon': '\f0f3',
  'annotation-text': '\f0f4',
  apple: '\f0f5',
  'aquisition-patient': '\f0f6',
  'archive-checkmark': '\f0f7',
  'archive-error': '\f0f8',
  'archive-hour-glass': '\f0f9',
  'arrow-down': '\f001',
  'arrow-left': '\f002',
  'arrow-right': '\f003',
  'arrow-up': '\f004',
  articles: '\f0fa',
  'assembly-tool': '\f0fb',
  asterisk: '\f0fc',
  attachment: '\f066',
  audio: '\f01f',
  'axes-long-short-centroid': '\f0fd',
  balance: '\f0fe',
  'balloon-speech': '\f067',
  'balloon-speech-off': '\f0ff',
  'balloon-text': '\f100',
  'bars-up': '\f101',
  batch: '\f102',
  'batch-input-data': '\f103',
  'batch-sorting': '\f104',
  'batch-type-horizontal': '\f105',
  'batch-type-horizontal-rotate-left': '\f106',
  'batch-type-horizontal-rotate-right': '\f107',
  'batch-type-vertical': '\f108',
  'batch-type-vertical-rotate-down': '\f109',
  'batch-type-vertical-rotate-up': '\f10a',
  battery: '\f068',
  battery0: '\f10b',
  battery10: '\f069',
  battery20: '\f10c',
  battery25: '\f10d',
  battery30: '\f10e',
  battery40: '\f10f',
  battery50: '\f110',
  battery60: '\f111',
  battery70: '\f112',
  battery75: '\f113',
  battery80: '\f114',
  battery90: '\f115',
  'battery-charging': '\f116',
  bed: '\f117',
  'bed-making': '\f118',
  'bed-time': '\f119',
  'blood-pressure': '\f11b',
  'blood-pressure-alternative': '\f11a',
  'blood-sugar': '\f11c',
  bluetooth: '\f032',
  bookmark: '\f11d',
  'bookmark-open': '\f11e',
  boolean: '\f11f',
  'border-bottom': '\f02d',
  'border-left': '\f02f',
  'border-right': '\f030',
  'border-top': '\f02e',
  'bottle-feeding': '\f06a',
  bounce: '\f120',
  'brand-ce_33x24': '\f0a6',
  'brand-ec-rep_52x24': '\f0a7',
  'brand-factory-filled_26x24': '\f0b0',
  'brand-factory-outline_26x24': '\f0a8',
  'brand-gs1_37x24': '\f0a9',
  'brand-lot_37x24': '\f0aa',
  'brand-quantity_71x24': '\f0ab',
  'brand-ref_37x24': '\f0af',
  'brand-read-manual_31x24': '\f0ac',
  'brand-rx-only_54x24': '\f0ad',
  'brand-warning_28x24': '\f0ae',
  'breast-feeding': '\f121',
  'breast-pump': '\f122',
  'breath-hold': '\f123',
  brightness: '\f058',
  'brightness-time': '\f124',
  buzz: '\f020',
  calendar: '\f03b',
  'calendar-data': '\f125',
  'calendar-date': '\f126',
  'calendar-night': '\f127',
  'calibration-in-bore': '\f128',
  'calibration-manual': '\f129',
  'calibration-out-bore': '\f12a',
  call: '\f12b',
  'calory-burn': '\f06b',
  'camera-switch': '\f12c',
  capture: '\f06c',
  'capture-outline': '\f12d',
  cardiac: '\f12e',
  'care-plan': '\f12f',
  centerpoint: '\f130',
  checkmark: '\f006',
  'checkmark-circle': '\f049',
  'chevron-down': '\f131',
  'chevron-left': '\f132',
  'chevron-right': '\f133',
  'chevron-up': '\f134',
  clinic: '\f06d',
  clipboard: '\f135',
  'clipboard-checklist': '\f136',
  clock: '\f06e',
  clothing: '\f137',
  cloud: '\f138',
  'cloud-upload': '\f139',
  code: '\f03d',
  connection: '\f13a',
  'connection-camera-connected': '\f13b',
  'connection-cloud-connected': '\f13c',
  contacts: '\f13d',
  contrast: '\f13e',
  'contrast-brightness': '\f01e',
  'contrast-liquid-dynamics': '\f13f',
  'conversation-speech': '\f140',
  'cook-hat': '\f141',
  cooking: '\f142',
  copy: '\f06f',
  'copy-tab': '\f143',
  counter: '\f144',
  cross: '\f005',
  'cross-circle': '\f00e',
  'cross-circle-outline': '\f145',
  crying: '\f146',
  'curve-delete': '\f147',
  'curve-draw': '\f148',
  'curve-edit': '\f149',
  'curve-mode': '\f14a',
  'curve-move': '\f14b',
  'curve-rename': '\f14c',
  cut: '\f070',
  cycle: '\f14d',
  'cycle-reverse': '\f14e',
  cycling: '\f14f',
  dna: '\f157',
  dancing: '\f150',
  dawn: '\f151',
  'delete-trash': '\f00f',
  device: '\f152',
  diabetes: '\f153',
  diameter: '\f154',
  disc: '\f0d1',
  'disc-burn': '\f155',
  'dish-washing': '\f156',
  'dna-variant-caller-tool': '\f158',
  'doctor-treatment-plan': '\f159',
  document: '\f017',
  'document-doc': '\f045',
  'document-final16': '\f15a',
  'document-gcs': '\f15b',
  'document-label': '\f15c',
  'document-multiple': '\f15d',
  'document-nihss': '\f15e',
  'document-pdf': '\f02c',
  'document-ppt': '\f03e',
  'document-report': '\f15f',
  'document-text': '\f042',
  'document-xls': '\f03f',
  ecg: '\f160',
  edit: '\f05b',
  education: '\f019',
  'education-teaching': '\f161',
  emergency: '\f162',
  'endocrine-disorders': '\f163',
  'exclamation-mark-circle': '\f165',
  'exclamation-mark-circle-outline': '\f166',
  exclamationmark: '\f164',
  exercise: '\f167',
  expand: '\f071',
  'expand-fullscreen': '\f010',
  experience: '\f168',
  export: '\f169',
  'file-code': '\f044',
  'file-image': '\f040',
  'file-video': '\f041',
  'file-zip': '\f043',
  'filling-order-horizontal': '\f16a',
  'filling-order-vertical': '\f16b',
  filter: '\f072',
  'floor-cleaning': '\f16c',
  folder: '\f0d2',
  'folder-close': '\f016',
  'folder-open': '\f03c',
  'font-size': '\f16d',
  'font-size-large': '\f16e',
  'font-size-medium': '\f16f',
  'font-size-small': '\f170',
  food: '\f073',
  'form-new-grouping': '\f171',
  gastrointestinal: '\f172',
  'gender-female': '\f173',
  'gender-male': '\f174',
  'gender-phantom': '\f175',
  'gender-transgender': '\f176',
  gene: '\f177',
  'general-copy': '\f178',
  genitourinary: '\f179',
  glucose: '\f17a',
  'groups-of-tools': '\f17b',
  hand: '\f05a',
  'hand-wash': '\f17c',
  hdd00: '\f17d',
  heart: '\f05f',
  'heavy-physical-exercise': '\f17e',
  hide: '\f0a4',
  home: '\f074',
  'horintal-split': '\f17f',
  'hospital-location': '\f180',
  idea: '\f181',
  'image-general': '\f182',
  'image-info-edit': '\f183',
  'image-information': '\f184',
  'image-overview': '\f027',
  'image-photo': '\f075',
  'images-photos': '\f185',
  import: '\f0d6',
  'in-progress': '\f189',
  'infectious-disease': '\f186',
  information: '\f0d7',
  'information-circle': '\f021',
  'information-circle-outline': '\f187',
  'information-image-insert': '\f188',
  'input-data': '\f18a',
  'insert-inset-plan-scan': '\f18b',
  invert: '\f04f',
  'iron-clothes': '\f18c',
  'key-findings': '\f18f',
  'keyboard-arrow-shift': '\f18d',
  'keyboard-delete': '\f18e',
  layout1x1: '\f190',
  layout1x2: '\f191',
  'layout1x2reference-bottom': '\f192',
  'layout1x2reference-right': '\f076',
  layout1x3: '\f193',
  'layout1x3reference-bottom': '\f194',
  'layout1x3reference-right': '\f195',
  layout1x4: '\f196',
  'layout1x-reference-right': '\f197',
  layout2x1: '\f198',
  layout2x2: '\f199',
  layout2x3: '\f19a',
  layout2x4: '\f19b',
  layout3x1: '\f19c',
  layout3x2: '\f19d',
  layout3x3: '\f19e',
  layout3x4: '\f19f',
  layout4x1: '\f1a0',
  layout4x2: '\f1a1',
  layout4x3: '\f1a2',
  layout4x4: '\f1a3',
  'layout-auto': '\f1a4',
  'layout-more': '\f1a5',
  'life-style': '\f1a6',
  'light-bulb': '\f077',
  'light-bulb-off': '\f0b3',
  'light-center': '\f1a7',
  'like-thumb': '\f1a8',
  'limits-iec': '\f1a9',
  'line-thickness': '\f1aa',
  'line-thickness-large': '\f1ab',
  'line-thickness-medium': '\f1ac',
  'line-thickness-small': '\f1ad',
  link: '\f078',
  'link-external': '\f0b7',
  'link-smart': '\f079',
  liquid: '\f1ae',
  'list-view': '\f026',
  location: '\f07a',
  'lock-unlocked': '\f1af',
  'low-physical-exercise': '\f1b0',
  'lunch-rice-bowl': '\f1b1',
  'machine-learning-tool': '\f1b2',
  malignancy: '\f1b3',
  manage: '\f0d9',
  'manual-table-mode': '\f1b4',
  matrix: '\f1b5',
  maximize: '\f046',
  'measrurement-angle': '\f1b6',
  'measrurement-open-angle': '\f1b7',
  measurement: '\f037',
  'measurement-freehand-line': '\f1b8',
  'measurement-line': '\f039',
  'measurement-pixel-value': '\f1b9',
  'measurement-point-location': '\f1ba',
  'measurement-point-location2d': '\f1bb',
  'measurement-poly-line': '\f1bc',
  'measurement-smooth-poly-line': '\f1bd',
  'medication-bottle': '\f07b',
  'medium-physical-exercise': '\f1be',
  menu: '\f011',
  message: '\f07c',
  microphone: '\f07d',
  'microphone-mute': '\f07e',
  microscope: '\f1bf',
  milestone: '\f07f',
  'milk-bottle': '\f081',
  minimize: '\f0ce',
  minus: '\f1c0',
  'mobi-view': '\f059',
  'mobi-view-series': '\f1c1',
  moon: '\f082',
  morning: '\f083',
  'movement-down-alternate': '\f007',
  'movement-left-alternate': '\f0da',
  'movement-right-alternate': '\f047',
  'movement-up-alternate': '\f008',
  movie: '\f084',
  'movie-frame-rate': '\f1c2',
  'movie-next-image': '\f1c3',
  'movie-next-series': '\f1c4',
  'movie-previous-image': '\f1c5',
  'movie-previous-series': '\f1c6',
  music: '\f0b8',
  'music-off': '\f0b2',
  'navigation-dimension-chemical-shift': '\f1c7',
  'navigation-dimension-echoes': '\f1c8',
  'navigation-dimension-image-type': '\f1c9',
  'navigation-dimension-slices': '\f1ca',
  'navigation-dimension-stacks': '\f1cb',
  'navigation-down': '\f009',
  'navigation-down-alternate': '\f1cc',
  'navigation-left': '\f00a',
  'navigation-left-alternate': '\f1cd',
  'navigation-right': '\f00b',
  'navigation-right-alternate': '\f270',
  'navigation-up': '\f00c',
  'navigation-up-alternate': '\f1ce',
  next: '\f04d',
  notes: '\f085',
  'notes-edit': '\f1cf',
  nuclear: '\f1d0',
  'number-box': '\f1d1',
  nutrition: '\f018',
  'nutrition-bread': '\f1d2',
  'nutrition-fats': '\f1d3',
  'nutrition-grains': '\f087',
  'nutrition-meat': '\f1d4',
  'nutrition-vegetable': '\f1d5',
  nuts: '\f088',
  'output-data': '\f1d6',
  'page-down': '\f1d7',
  'page-next': '\f1d8',
  'page-previous': '\f1d9',
  'page-up': '\f1da',
  paste: '\f1db',
  'patient-coil-body': '\f1dc',
  'patient-coil-head': '\f1dd',
  'patient-coil-knee': '\f1de',
  'patient-embryo': '\f1df',
  'patient-position-feet-first': '\f1e0',
  'patient-position-feet-first-prone': '\f1e1',
  'patient-position-head-first': '\f1e2',
  'patient-position-head-first-prone': '\f1e3',
  'patient-wheelchair': '\f1e4',
  pause: '\f0b4',
  person: '\f089',
  'person-baby': '\f01a',
  'person-community': '\f0b5',
  'person-female': '\f08a',
  'person-female-pregnant': '\f1e5',
  'person-infant': '\f08b',
  'person-nurse': '\f1e6',
  'person-portrait': '\f064',
  'person-portrait-circle': '\f061',
  'person-portrait-circle-screening': '\f1e7',
  'person-profile': '\f01c',
  'person-warning': '\f1e8',
  'pictorial-data-type-not-imported': '\f1e9',
  'pictorial-index': '\f1ea',
  'pictorial-index-flag': '\f0a3',
  'pictorial-index-selected': '\f1eb',
  'pictorial-type-biplane': '\f1ec',
  'pictorial-type-biplane-series-frontal': '\f1ed',
  'pictorial-type-biplane-series-lateral': '\f1ee',
  'pictorial-type-bolus-chase': '\f1ef',
  'pictorial-type-derived-object': '\f1f0',
  'pictorial-type-ecg': '\f1f1',
  'pictorial-type-exposure': '\f1f2',
  'pictorial-type-fluoroscopy': '\f1f3',
  'pictorial-type-movie': '\f1f4',
  'pictorial-type-qlab': '\f1f5',
  'pictorial-type-series': '\f1f6',
  'pictorial-type-stent': '\f1f7',
  'pictorial-type-view-trace': '\f1f8',
  'pictorial-type-volume': '\f1f9',
  'pill-capsule': '\f1fa',
  'pill-round': '\f1fe',
  pillow: '\f1fb',
  'pillow-head': '\f1fc',
  'pillow-interrupted': '\f1fd',
  pills: '\f01b',
  pipelines: '\f1ff',
  'plan-scan-center-alt': '\f203',
  'planned-rectangle': '\f200',
  planning: '\f201',
  'planning-rectangle': '\f202',
  plus: '\f00d',
  previous: '\f04e',
  print: '\f08e',
  'print-application-film': '\f204',
  'print-setting': '\f205',
  processor: '\f08f',
  'programming-logic-tool': '\f206',
  'projection-slab-mode': '\f207',
  publish: '\f208',
  'push-pin-unpin': '\f091',
  pushpin: '\f090',
  'question-mark': '\f209',
  'questionmark-circle': '\f20a',
  'questionmark-circle-outline': '\f20b',
  radar: '\f20c',
  recipes: '\f092',
  redo: '\f036',
  refresh: '\f093',
  reminder: '\f20d',
  'remove-split': '\f20e',
  reset: '\f20f',
  restore: '\f094',
  ringdown: '\f210',
  'rna-seq-analysis-tool': '\f211',
  'rotate-ccw': '\f212',
  'rotate-cw': '\f213',
  'rotate-cwplanbox': '\f214',
  'rotate-step-ccw': '\f215',
  'ruler-height': '\f216',
  run: '\f034',
  save: '\f095',
  'save-alt': '\f217',
  scale: '\f096',
  'scan-pause-iec': '\f219',
  'scan-start-iec': '\f21a',
  'scan-stop-iec': '\f21b',
  'scanner-group': '\f218',
  scroll: '\f05c',
  'scroll-bottom': '\f21c',
  'scroll-down': '\f21d',
  'scroll-here': '\f21e',
  'scroll-left': '\f21f',
  'scroll-left-edge': '\f220',
  'scroll-right': '\f221',
  'scroll-right-edge': '\f222',
  'scroll-top': '\f223',
  'scroll-up': '\f224',
  'seafood-fish': '\f097',
  search: '\f012',
  'search-favourites': '\f225',
  'select-all': '\f226',
  'send-image-to-print': '\f227',
  'send-series-to-print': '\f228',
  'sensor-humidity-alert': '\f229',
  'sensor-light-alert': '\f22a',
  'sensor-noise-alert': '\f22b',
  'sensor-temperature-alert': '\f22c',
  server: '\f22d',
  settings: '\f0a5',
  share: '\f062',
  sheep: '\f22e',
  shop: '\f099',
  show: '\f060',
  'show-series-info': '\f22f',
  'skip-to-end': '\f04c',
  'skip-to-start': '\f04b',
  'slab-stitching': '\f230',
  'sleep-baby': '\f231',
  'sleep-score': '\f232',
  'sleep-time': '\f233',
  slices: '\f234',
  'slices-ascendant': '\f235',
  'slices-descendant': '\f236',
  'slow-change': '\f237',
  'smart-sleep': '\f238',
  soccer: '\f01d',
  'soft-stitching': '\f239',
  soybean: '\f23a',
  speedometer: '\f09b',
  'stack-reconstruction': '\f23b',
  star: '\f028',
  'start-manual': '\f23c',
  statistics: '\f09c',
  stethoscope: '\f09d',
  stitched: '\f23d',
  stopwatch: '\f09e',
  store: '\f23e',
  sun: '\f09f',
  support: '\f23f',
  swimming: '\f240',
  syringe: '\f241',
  'tab-add': '\f242',
  'table-movement-horizontal': '\f243',
  'table-tennis': '\f244',
  talking: '\f245',
  temperature: '\f0a0',
  'text-bold': '\f029',
  'text-box': '\f246',
  'text-italic': '\f02a',
  'text-underline': '\f02b',
  textfield: '\f247',
  'theming-configuration': '\f065',
  'three-dots-horizontal': '\f013',
  'three-dots-vertical': '\f014',
  thrombotic: '\f248',
  'time-intensity-display': '\f24a',
  'time-intensity-display-calculate': '\f24b',
  timedifference: '\f249',
  'timer-countdown': '\f24c',
  'top-of-list': '\f24d',
  'transfer-arrows': '\f24e',
  'transfer-arrows-error': '\f24f',
  'trim-tool': '\f250',
  'un-stitched': '\f251',
  undo: '\f035',
  upload: '\f252',
  vascular: '\f253',
  'vertical-split': '\f254',
  'view-axial-top': '\f255',
  'view-coronal-front': '\f256',
  'view-oblique': '\f257',
  'view-plane-coronal': '\f258',
  'view-plane-saggital': '\f259',
  'view-plane-transversal': '\f25a',
  'view-sagittal-left': '\f25b',
  'view-sagittal-right': '\f25c',
  'volume-stitching': '\f25d',
  warning: '\f048',
  'warning-iso': '\f25e',
  'water-bottle': '\f25f',
  weight: '\f0a1',
  'wi-fi-connection': '\f031',
  'window-cleaning': '\f260',
  'wlan-connection-broken': '\f261',
  'wlan-established': '\f262',
  zoom: '\f05e',
  'zoom-in': '\f263',
  'zoom-out': '\f264',
  'padding-24': '\f0b1',
  placeholder: '\f015',
};

export default icons;
