import React, { FunctionComponent, SVGProps } from 'react';

const TextBox = (props) => (
  <svg viewBox="0 0 48 48" width="1em" height="1em" {...props}>
    <path
      fill="#1d1d1b"
      d="M21 18h-9v-6h24v6h-9v18h-6V18zM46 4v40H2V4h44zm-4 4H6v32h36V8z"
    />
  </svg>
);

export default TextBox;
