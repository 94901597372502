import React, { FunctionComponent, SVGProps } from 'react';

const Matrix = (props) => (
  <svg viewBox="0 0 48 48" width="1em" height="1em" {...props}>
    <path
      fill="#1d1d1b"
      d="M2 4v40h44V4H2zm15 36H6v-4h11v4zm0-6H6v-4h11v4zm0-6H6v-4h11v4zm0-6H6v-4h11v4zm0-6H6v-4h11v4zm13 24H19v-4h11v4zm0-6H19v-4h11v4zm0-6H19v-4h11v4zm0-6H19v-4h11v4zm0-6H19v-4h11v4zm12 24H32v-4h10v4zm0-6H32v-4h10v4zm0-6H32v-4h10v4zm0-6H32v-4h10v4zm0-6H32v-4h10v4z"
    />
  </svg>
);

export default Matrix;
