import React, { useState, useCallback } from 'react';
import componentColor, { dls } from '@dls/react-colors';
import styled, { css } from 'styled-components';

import MuiInput, { InputProps as MuiInputProps } from '@material-ui/core/Input';
import MuiTextField, {
  TextFieldProps as MuiTextFieldProps,
} from '@material-ui/core/TextField';
import MuiLinearProgress, {
  LinearProgressProps as MuiLinearProgressProps,
} from '@material-ui/core/LinearProgress';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';

import { em, svgScaling, touchStyles } from '@dls/react-theme';
import { Icon } from '@dls/react-icon';
import { ITextInputStyleProps } from './TextInputStyleProps';
import { Typography } from '@dls/react-mui-typography';

export const TextInputStyle = (props: ITextInputStyleProps) => css`
  border: ${em(1)} solid ${componentColor(dls.textBox.border.default.normal)};
  background-color: ${componentColor(dls.textBox.background.default.normal)};
  color: ${componentColor(dls.textBox.text.default.normal)};
  min-height: ${em(40)};
  padding: 0 ${em(12)};
  width: ${props.width ? em(props.width) : em(240)};
  box-sizing: border-box;
  border-radius: ${em(2)};

  &:active {
    background-color: ${componentColor(dls.textBox.background.default.pressed)};
    border-color: ${componentColor(dls.textBox.border.default.pressed)};
    outline: ${em(3)} solid
      ${componentColor(dls.textBox.focusOutline.default.focusPressed)};
    outline-offset: ${em(1)};
  }

  &:hover {
    background-color: ${componentColor(dls.textBox.background.default.hover)};
    border-color: ${componentColor(dls.textBox.border.default.hover)};
  }

  &.Mui-focused {
    background-color: ${componentColor(dls.textBox.background.default.focus)};
    border-color: ${componentColor(dls.textBox.border.default.focus)};
    outline: ${em(3)} solid
      ${componentColor(dls.textBox.focusOutline.default.focusPressed)};
    outline-offset: ${em(1)};
  }

  /** Disabled styles start here*/
  &.Mui-disabled {
    background-color: ${componentColor(
      dls.textBox.background.default.disabled,
    )};
    border-color: ${componentColor(dls.textBox.border.default.disabled)};
    color: ${componentColor(dls.textBox.text.default.disabled)};
  }

  &.Mui-error {
    border-color: ${componentColor(dls.textBox.border.default.validated)};
    background-color: ${componentColor(
      dls.textBox.background.default.validated,
    )};
    color: ${componentColor(dls.textBox.text.default.validated)};
  }

  /** Font Styles start here */
  font-size: ${em(16)};
  /* stylelint-disable */
  font-family: CentraleSans, Tahoma, Arial, Helvetica, sans-serif;
  /* stylelint-enable */
  font-weight: normal;
  font-style: normal;

  &.MuiInputBase-adornedEnd {
    padding-right: 0;
  }

  & .MuiInput-input {
    &::placeholder {
      color: ${componentColor(dls.textBox.hintText.default.normal)};
      font-style: italic;
    }
  }

  &.MuiInput-root .MuiIconButton-root {
    font-size: ${em(24)};
    color: ${componentColor(dls.textBox.icon.default.normal)};
    padding: 0;

    &:hover {
      background: transparent;
    }

    & .MuiTouchRipple-root {
      display: none;
    }

    & .MuiIconButton-label {
      color: ${componentColor(dls.textBox.showHideIcon.default.normal)};
    }
  }

  &.Mui-error .MuiIconButton-root {
    & .MuiIconButton-label {
      color: ${componentColor(dls.textBox.icon.default.validated)};
    }
  }

  /**Padding for Right Side Icon */
  &.MuiInput-root .MuiInputAdornment-positionEnd {
    padding-right: ${em(8)};
    padding-left: ${em(12)};
    margin-left: 0;
    .pt-dense & {
      padding-right: ${em(4)};
      padding-left: ${em(12)};
    }
  }

  /** Scaling icon*/
  &.MuiInput-root .MuiIconButton-root .MuiIconButton-label span {
    ${svgScaling(em(16))};
    & svg {
      top: 0;
      left: 0;
    }
  }
  /**---------------*/

  /** Dense and touch mode for text box */
  .pt-dense & {
    height: ${em(32)};
    min-height: ${em(32)};
    padding-left: ${em(8)};
    &:not(.MuiInputBase-adornedEnd) {
      padding-right: ${em(8)};
    }
  }
  .pt-touch & {
    ${touchStyles(em(5))};
  }
  /**---------------*/

  /** Read Only styles */
  &.dls-text-field-readonly {
    user-select: text;
    background-color: ${componentColor(
      dls.textBox.background.default.disabled,
    )};
    border-color: ${componentColor(dls.textBox.border.default.normal)};
  }
  /**---------------*/

  &.MuiInput-fullWidth {
    width: 100%;
  }

  /* Styles related to Searchbox */
  &.DLS-SearchBox {
    padding: 0 ${em(8)} 0 ${em(12)};
    .pt-dense & {
      padding: 0 ${em(8)} 0 ${em(8)};
    }
    &.Mui-disabled {
      &.MuiInput-root .MuiInputAdornment-positionEnd {
        & .MuiIconButton-root {
          & .MuiIconButton-label {
            color: ${componentColor(dls.searchBox.iconButton.default.disabled)};
          }
        }
      }
    }
    &.MuiInput-root .MuiInputAdornment-positionEnd {
      padding: ${em(0)};
      margin-left: 0;
      & .MuiIconButton-root {
        & .MuiIconButton-label {
          & .Search-icon {
            color: ${componentColor(dls.searchBox.iconButton.default.normal)};
          }
        }
      }
      /* Fix me by creating a style for Search Divider in Divider Component */
      & .MuiDivider-root {
        height: ${em(24)};
        background: ${componentColor(dls.textBox.separator.default.normal)};
        .pt-dense & {
          margin: 0 ${em(8)} 0 ${em(8)};
        }
      }
    }
  }
`;

/** TextField with its content */
export const Input = styled(MuiInput).attrs((props) => {
  return {
    autoComplete: 'off',
    disableUnderline: true,
    ...props,
  } as MuiInputProps;
})`
  && {
    &.MuiInput-root {
      ${TextInputStyle}
    }

    &.MuiInput-root .MuiInput-input {
      &[type='number']::-webkit-outer-spin-button,
      &[type='number']::-webkit-inner-spin-button {
        /* stylelint-disable */
        -webkit-appearance: none;
        /* stylelint-enable */
        margin: ${em(0)};
      }

      &:placeholder-shown {
        text-overflow: ellipsis;
      }

      &:read-only {
        background-color: ${componentColor(
          dls.textBox.background.default.disabled,
        )};
        border-color: ${componentColor(dls.textBox.border.default.disabled)};
      }
    }

    &.text-aligned-right {
      &.MuiInput-root .MuiInput-input {
        text-align: right;
      }
    }

    &.text-aligned-left {
      &.MuiInput-root .MuiInput-input {
        text-align: left;
      }
    }

    &.text-aligned-center {
      &.MuiInput-root .MuiInput-input {
        text-align: center;
      }
    }
  }
`;

export const TextField = styled(MuiTextField).attrs((props) => {
  return {
    autoComplete: 'off',
    ...props,
  } as MuiTextFieldProps;
})`
  && {
    &.MuiTextField-root .MuiInput-root {
      &.MuiInput-underline::before {
        border-bottom: 0;
        border-bottom-color: white;
      }
      &.MuiInput-underline::after {
        border-bottom: 0;
        border-bottom-color: white;
      }
      &.MuiInput-underline:hover:before {
        border-bottom: 0;
        border-bottom-color: white;
      }
      /** Including basic styles here */
      ${TextInputStyle}
    }

    &.MuiTextField-root .MuiFormHelperText-root {
      font-size: ${em(12)};
      margin-top: ${em(8, 12)};
      /* stylelint-disable */
      font-family: CentraleSans, Tahoma, Arial, Helvetica, sans-serif;
      /* stylelint-enable */
      font-weight: normal;
      font-style: normal;

      &.Mui-error {
        height: ${em(18)};
        color: ${componentColor(dls.textBox.text.default.validated)};
      }

      /** Validation Text style */
      & .error {
        height: ${em(18, 12)};
        line-height: ${em(18, 12)};
        display: block;

        span {
          ${svgScaling(em(16, 12))};
          margin-right: ${em(8, 12)};
        }
      }
    }
  }
`;

///MARK:- Strength Password Field
export type LinearProgressTypes =
  | 'Very weak'
  | 'Weak'
  | 'Fair'
  | 'Strong'
  | 'Very strong';

export interface PasswordLinearProgressProps extends MuiLinearProgressProps {
  password_variant: LinearProgressTypes;
  width?: number;
  fullWidth?: boolean;
}

const getColor = (variant: LinearProgressTypes) => {
  switch (variant) {
    case 'Very weak':
      return componentColor(dls.track.background.danger.normalOn);
    case 'Weak':
      return componentColor(dls.track.background.danger.normalOn);
    case 'Fair':
      return componentColor(dls.track.background.warning.normalOn);
    case 'Strong':
      return componentColor(dls.track.background.success.normalOn);
    case 'Very strong':
      return componentColor(dls.track.background.success.normalOn);
  }
};

export const PasswordLinearProgressStyles = (
  props: PasswordLinearProgressProps,
) =>
  props.password_variant !== undefined &&
  css`
    &.MuiLinearProgress-root .MuiLinearProgress-barColorPrimary {
      background-color: ${(props: PasswordLinearProgressProps) =>
        getColor(props.password_variant)};
    }
  `;

export const PasswordLinearProgress = styled(MuiLinearProgress).attrs(
  (props: PasswordLinearProgressProps) => {
    return {
      ...props,
    } as PasswordLinearProgressProps;
  },
)`
  && {
    /** Progress Styles here*/
    &.MuiLinearProgress-root {
      width: ${(props: PasswordLinearProgressProps) =>
        props.width ? em(props.width) : props.fullWidth ? '100%' : em(240)};
      margin-top: ${em(8)};
      margin-bottom: ${em(8)};
    }
    &.MuiLinearProgress-colorPrimary {
      background-color: ${componentColor(
        dls.track.background.detail.normalOff,
      )};
    }
    ${PasswordLinearProgressStyles};
  }
`;

export const MessageDiv = styled.div<PasswordLinearProgressProps>`
  ${css`
    color: ${(props: PasswordLinearProgressProps) =>
      getColor(props.password_variant)};
  `}
`;

type ContainerType = {
  width?: number;
  fullWidth?: boolean;
};

export const Container = styled.div<ContainerType>`
  ${css`
    width: ${(props: ContainerType) =>
      props.width ? em(props.width) : props.fullWidth ? '100%' : em(240)};
  `}
`;

export const InstructionDiv = styled.div`
  ${css`
    color: ${componentColor(dls.contentItem.text.primary.normal)};
    background-color: ${componentColor(dls.content.background.tertiary.normal)};
    padding: ${em(8)} ${em(12)};
    box-sizing: border-box;
    border-radius: ${em(2)};
  `}
`;

export interface IPasswordProps extends MuiInputProps {
  change_event?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  strength?: boolean;
  with_icon?: boolean;
  width?: number;
}

/// Get Password Strength from Regx
export function getPasswordStrength(
  value: string,
): { type: LinearProgressTypes; value: number } {
  const weak = new RegExp(
    '^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9])))',
  );
  const fair = new RegExp(
    '^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})',
  );
  const strongRegex = new RegExp(
    '^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{8,})',
  );
  const veryStrongRegex = new RegExp(
    '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})',
  );

  if (veryStrongRegex.test(value)) {
    return { type: 'Very strong', value: 100 };
  } else if (strongRegex.test(value)) {
    return { type: 'Strong', value: 80 };
  } else if (fair.test(value)) {
    return { type: 'Fair', value: 60 };
  } else if (weak.test(value)) {
    return { type: 'Weak', value: 40 };
  } else {
    return { type: 'Very weak', value: 20 };
  }
}

export const Password = (props: IPasswordProps) => {
  ///Props from strories
  const { value, strength, with_icon, change_event, width, fullWidth } = props;

  /// Show Password
  const [showPassword, setShowPassword] = useState(false);

  /// Show Progress
  const [showProgress, setShowProgress] = useState(false);

  /// Password Strength
  const [passwordStrength, setPasswordStrength] = useState<LinearProgressTypes>(
    'Very weak',
  );

  const [strengthValue, setStrengthValue] = useState(0);

  const instructionMessage = (
    <>
      Password must be at least 8 characters and must contain at least 2 of 3
      types of characters: letters(A-Z, a-z), digits(0-9) and symbols (_.@$).
    </>
  );

  /// Toggle Click Password Hide/ unhide
  const handleTogglePassword = useCallback(() => {
    setShowPassword(!showPassword);
  }, [showPassword]);

  /// OnChanging text
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { type, value } = getPasswordStrength(event.target.value);
    setPasswordStrength(type);
    setStrengthValue(value);
    setShowProgress(true);
    if (change_event !== undefined) {
      change_event(event);
    }
  };

  return (
    <Container width={width} fullWidth={fullWidth}>
      <Input
        {...props}
        type={showPassword ? 'text' : 'password'}
        value={value}
        onChange={handleChange}
        endAdornment={
          with_icon && (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleTogglePassword}>
                {showPassword ? (
                  <Icon name="Hide" size={'m'} />
                ) : (
                  <Icon name="Show" size={'m'} />
                )}
              </IconButton>
            </InputAdornment>
          )
        }
        width={width}
      />

      {strength && showProgress && (
        <>
          <PasswordLinearProgress
            variant="determinate"
            password_variant={passwordStrength}
            value={strengthValue}
            width={width}
            fullWidth={fullWidth}
          />
          <MessageDiv password_variant={passwordStrength}>
            <Typography align="right" variant="subtitle2" color="inherit">
              {passwordStrength}
            </Typography>
          </MessageDiv>
          <InstructionDiv>
            <Typography variant="caption" color="inherit">
              {instructionMessage}
            </Typography>
          </InstructionDiv>
        </>
      )}
    </Container>
  );
};
