import { DeepPartial, DefaultTheme } from './types';
import { css } from 'styled-components';

export const makeTheme = (config: DeepPartial<DefaultTheme> = {}) => (
  parentTheme: DeepPartial<DefaultTheme> = {},
): DefaultTheme => {
  const {
    color = 'group-blue',
    accent = 'orange',
    tone = 'ultra-light',
    density = 'regular',
  } = {
    ...parentTheme,
    ...config,
  };

  return {
    color,
    accent,
    tone,
    density,
  };
};

export const em = function ($pixels: number, $browserContext = 16) {
  return $pixels / $browserContext + 'em';
};

export const svgScaling = (size: any) => css`
  position: relative;
  width: ${size};
  height: ${size};
  & svg {
    position: absolute;
    width: ${size};
    height: ${size};
  }
`;

/// Touch Style function
export const touchStyles = (size: any, optionalSize: any = 0) => css`
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: -${size};
    left: -${optionalSize};
    right: -${optionalSize};
    bottom: -${size};
  }
`;
