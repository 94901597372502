import styled from 'styled-components';
import componentColor, { dls } from '@dls/react-colors';
import { em } from '@dls/react-theme';
import { Dialog, DialogContent } from '@dls/react-mui-dialog';
import MuiTypography, { TypographyProps } from '@material-ui/core/Typography';

/**
 * About Screen Header Styles
 */
export const AboutScreenHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: unset;
  margin-bottom: ${em(20)};
`;

/**
 * Shielg Logo Styles
 */
export const ShieldLogoContainer = styled.div`
  svg {
    margin-top: 9vh;
    margin-bottom: 9vh;
    height: 9vh;
    width: 9vh;
    fill: ${componentColor(dls.aboutScreen.shield.default.normal)};
  }
`;

/**
 * About Screen Title Style
 */
export const AboutTitle = styled(MuiTypography).attrs(
  (props: TypographyProps) => {
    return {
      ...props,
    };
  },
)`
  &.MuiTypography-root {
    color: ${componentColor(dls.aboutScreen.title.default.normal)};
    font-size: ${em(20)};
    /* stylelint-disable */
    font-family: CentraleSans, Tahoma, Arial, Helvetica, sans-serif;
    /* stylelint-enable */
    font-weight: 300;
    font-style: normal;
    line-height: ${em(24, 20)};
  }
`;

export const CloseButton = styled.div`
  cursor: pointer;
  position: absolute;
  right: 0;
  color: ${componentColor(dls.aboutScreen.closeIcon.default.normal)};
  margin-top: ${em(20)};
  margin-right: ${em(12)};
`;

/**
 * About Screen Text styles
 */
export const AboutTextTypography = styled(MuiTypography).attrs(
  (props: TypographyProps) => {
    return {
      ...props,
    };
  },
)`
  &.MuiTypography-root.MuiTypography-subtitle2 {
    color: ${componentColor(dls.aboutScreen.text.default.normal)};
    font-size: ${em(12)};
    /* stylelint-disable */
    font-family: CentraleSans, Tahoma, Arial, Helvetica, sans-serif;
    /* stylelint-enable */
    font-weight: 300;
    font-style: normal;
    line-height: ${em(18, 12)};
  }
`;

/**
 * Sub title Section Styles
 */
export const AboutScreenSubtitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AboutScreenSubtitleTypography = styled(MuiTypography).attrs(
  (props: TypographyProps) => {
    return {
      ...props,
    };
  },
)`
  &.MuiTypography-root.MuiTypography-subtitle1 {
    color: ${componentColor(dls.aboutScreen.subtitle.default.normal)};
    font-size: ${em(16)};
    /* stylelint-disable */
    font-family: CentraleSans, Tahoma, Arial, Helvetica, sans-serif;
    /* stylelint-enable */
    font-weight: 300;
    font-style: normal;
    line-height: ${em(22, 16)};
  }
`;

/**
 * Brand Icon Container Styles
 */
export const BrandIconsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: unset;
  justify-content: space-between;
  overflow: unset;
  padding-top: ${em(16)};
  padding-bottom: ${em(16)};
`;

export const BrandAlignLeft = styled.div`
  display: flex;
  flex-direction: row;
  flex: unset;
  overflow: unset;
  align-items: center;
`;

export const BrandAlignRight = styled.div`
  display: flex;
  flex-direction: row;
  flex: unset;
  overflow: unset;
  align-items: center;
`;

type PaddingProp = {
  left?: number;
  right?: number;
};

export const BrandTextTypography = styled(MuiTypography).attrs(
  (props: TypographyProps & PaddingProp) => {
    return {
      ...props,
    };
  },
)`
  &.MuiTypography-root.MuiTypography-subtitle2 {
    color: ${componentColor(dls.aboutScreen.text.default.normal)};
    font-size: ${em(12)};
    /* stylelint-disable */
    font-family: CentraleSans, Tahoma, Arial, Helvetica, sans-serif;
    /* stylelint-enable */
    font-weight: 300;
    font-style: normal;
    line-height: ${em(18, 12)};

    padding-left: ${(props: PaddingProp) =>
      props.left !== undefined ? em(props.left, 12) : 0};

    padding-right: ${(props: PaddingProp) =>
      props.right !== undefined ? em(props.right, 12) : 0};
  }
`;

export const BrandSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: unset;
`;

export const BrandAddressContainer = styled.div`
  display: flex;
  flex-direction: row;
  overflow-y: unset;
  align-items: center;
  padding-top: ${em(16)};
`;

/**
 * About Screen footer styles
 */
export const AboutFooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  overflow-y: unset;
  justify-content: space-between;
  padding-top: ${em(40)};
`;

export const AboutFooterIconsContainer = styled.div`
  display: flex;
  flex-direction: row;
  overflow-y: unset;
  align-items: flex-end;
  flex: 0;
  justify-content: flex-start;
`;

export const AboutFooterRightContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  overflow-y: unset;
`;

export const AboutQrCodeImg = styled.img`
  height: ${em(50)};
  width: ${em(50)};
  margin-left: ${em(8)};
`;

export const AboutScreenContent = styled(DialogContent)``;

export const AboutScreenDialog = styled(Dialog)`
  & .MuiDialog-paper {
    background-color: ${componentColor(
      dls.aboutScreen.background.dialog.normal,
    )} !important;
  }
`;
