export default {
  false: 0,
  xxs: '4px',
  xs: '8px',
  s: '16px',
  m: '24px',
  l: '36px',
  xl: '48px',
  xxl: '60px',
};
