import React, { useMemo, useState, FC } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { isFunction } from 'lodash';
import { ifProp } from 'styled-tools';
import { Content } from '@dls/react-beta-content';
import componentColor, { dls } from '@dls/react-colors';
import TabsContext from './TabsContext';

export type TabsProps = any;

const StyledTabs = styled(Content)`
  ${css`
    flex: 0 0 auto;
    flex-wrap: nowrap;
    overflow-y: hidden;
    overflow-x: auto;
    &::-webkit-scrollbar {
      width: 0 !important;
      height: 0 !important;
    }
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;
    ${ifProp(
      'centered',
      css`
        justify-content: center;
      `,
    )}
    ${ifProp(
      'borderBottom',
      css`
        border-bottom: 1px solid
          ${componentColor(dls.separator.background.content.normal)};
      `,
    )}
  `};
`;

const updateChildrenWithIndex = (children) => {
  return React.Children.map(children, (child, index) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        index,
      } as any);
    }
  });
};

const Tabs: FC<TabsProps> = (props) => {
  const {
    onBeforeTabChange,
    onTabChange,
    activatedTabIndex,
    minWidthTab,
    fixedWidth,
    children,
    ...other
  } = props;

  const [activatedTab, setActivatedTab] = useState(
    parseInt(activatedTabIndex, 10),
  );

  const context = useMemo(() => ({ activatedTab, minWidthTab, fixedWidth }), [
    activatedTab,
    minWidthTab,
    fixedWidth,
  ]);

  const handlerTabClick = (e) => {
    const tab = e.target.closest('.tab');

    if (tab && !tab.attributes.disabled) {
      const children = Array.from(tab.parentNode.children);
      const index = children.indexOf(tab);
      const value = tab.getAttribute('data-value');

      if (
        isFunction(onBeforeTabChange) &&
        onBeforeTabChange({ e, index, tab, value }) === false
      ) {
        return false;
      }

      setActivatedTab(index);

      if (isFunction(onTabChange) && onTabChange) {
        onTabChange({ e, index, tab, value });
      }
    }
  };

  return (
    <TabsContext.Provider value={context}>
      <StyledTabs className="tabs" {...other} onClick={handlerTabClick}>
        {updateChildrenWithIndex(children)}
      </StyledTabs>
    </TabsContext.Provider>
  );
};

Tabs.defaultProps = {
  activatedTabIndex: 0,
  display: 'flex',
};

Tabs.propTypes = {
  activatedTabIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  borderBottom: PropTypes.bool,
  centered: PropTypes.bool,
  children: PropTypes.node,
  fixedWidth: PropTypes.bool,
  minWidthTab: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onBeforeTabChange: PropTypes.func,
  onTabChange: PropTypes.func,
};

export default Tabs;
