import React, { Children, FC, ReactNode } from 'react';

export const wrapStringChildWith = (WrapperComponent: FC) => (props: {
  children: any;
}): ReactNode => {
  const { children } = props;
  return {
    children: Children.map(children, (child) => {
      if (typeof child === 'string') {
        return <WrapperComponent>{child}</WrapperComponent>;
      }
      return child;
    }),
  };
};
