import styled, { css } from 'styled-components';
import { ifProp } from 'styled-tools';

import componentColor, { dls, DlsStyle } from '@dls/react-colors';

import {
  density,
  DensityProps,
  FONT_DEFAULT,
  ThemeProviderProps,
} from '@dls/react-beta-utils';
import { ButtonProps, ButtonStates, ButtonVariants } from './types';

const getButtonColor = (state: ButtonStates = 'normal') => (
  props: ButtonProps & ThemeProviderProps,
): string => {
  const variant: ButtonVariants = props.variant || 'secondary';
  const buttonSpecialStates: ButtonVariants[] = [
    'quietDefault',
    'quietEmphasis',
  ];

  if (
    (state === 'hover' || state === 'pressed' || state === 'focus') &&
    !buttonSpecialStates.includes(variant)
  ) {
    return undefined;
  }

  return componentColor(dls.button.text[variant][state])(props);
};

const getBackgroundColor = (state: ButtonStates = 'normal') => ({
  variant = 'secondary',
  theme,
}: ButtonProps & ThemeProviderProps): string => {
  switch (variant) {
    case 'quietDefault': {
      return 'transparent';
    }

    case 'quietEmphasis': {
      if (state === 'hover' || state === 'focus') {
        return componentColor(dls.button.background.quietEmphasis.focus)({
          theme,
        });
      }

      return componentColor(dls.button.background.quietEmphasis.normal)({
        theme,
      });
    }

    case 'accent':
    case 'secondary':
    case 'primary':
    case 'signalWarning':
    case 'signalSuccess':
    case 'signalError': {
      if (state === 'focus' && variant === 'signalWarning') {
        return componentColor(dls.button.focusOutline[variant][state])({
          theme,
        });
      } else if (state === 'focus') {
        return componentColor(
          dls.button.focusOutline[variant]['focusPressed'],
        )({ theme });
      }

      return componentColor(dls.button.background[variant][state])({ theme });
    }
  }
};

const getFocusColorForComponent = () => (
  props: ButtonProps & ThemeProviderProps,
): DlsStyle => {
  const variant = props.variant || 'secondary';

  if (variant === 'quietEmphasis') {
    return css`
      background-color: ${getBackgroundColor('focus')};
    `;
  }

  return css`
    box-shadow: 0 0 0 4px ${getBackgroundColor('focus')};
  `;
};

export const Button = styled.button<ButtonProps & DensityProps>`
  display: ${ifProp('fullWidth', 'flex', 'inline-flex')};
  width: ${ifProp('fullWidth', '100%')};
  position: relative;
  cursor: pointer;

  border: none;
  border-radius: 2px;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  font-family: ${FONT_DEFAULT};
  align-items: center;
  min-height: ${density({ regular: 40, dense: 32, touch: 40 })};

  color: ${getButtonColor()};
  background-color: ${getBackgroundColor()};

  &:hover {
    color: ${getButtonColor('hover')};
    background-color: ${getBackgroundColor('hover')};
  }

  &:focus {
    outline: none;
    color: ${getButtonColor('focus')};
    ${getFocusColorForComponent()};
  }

  &:active {
    color: ${getButtonColor('pressed')};
    background-color: ${getBackgroundColor('pressed')};
  }

  &:disabled {
    pointer-events: none;
    cursor: default;
    color: ${getButtonColor('disabled')};
    background-color: ${getBackgroundColor('disabled')};
  }
`;

Button.defaultProps = {
  type: 'button',
};
