import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Content } from '@dls/react-beta-content';
import { FONT_DEFAULT } from '@dls/react-beta-utils';

const StyledExpanderItemContent = styled(Content)`
  font-family: ${FONT_DEFAULT};
  font-weight: normal;
  font-style: normal;
  padding: 12px 16px;
`;

const ExpanderItemContent = (props) => (
  <StyledExpanderItemContent className="expander-item-content" {...props} />
);

ExpanderItemContent.propTypes = {
  children: PropTypes.node,
};

export default ExpanderItemContent;
