import React, { FunctionComponent, SVGProps } from 'react';

const TransferArrows = (props) => (
  <svg viewBox="0 0 48 48" width="1em" height="1em" {...props}>
    <path
      fill="#231f20"
      d="M32 22H12v5L2 18l10-9v5h20v8zm14 8l-10-9v5H16v8h20v5l10-9z"
    />
  </svg>
);

export default TransferArrows;
