import React, { FunctionComponent, SVGProps } from 'react';

const WlanEstablished = (props) => (
  <svg viewBox="0 0 48 48" width="1em" height="1em" {...props}>
    <path
      fill="#231f20"
      d="M32 15v-4h-7v12h7v-4h10v10H32v-4h-7v12h7v-4h10v10H32v-4h-9V25h-7v4H6V19h10v4h7V9h9V5h10v10H32z"
    />
  </svg>
);

export default WlanEstablished;
