import React, { FunctionComponent, SVGProps } from 'react';

const ImageInfoEdit = (props) => (
  <svg viewBox="0 0 48 48" width="1em" height="1em" {...props}>
    <path
      fill="#231f20"
      d="M6 18h12v2H6v-2zm12-8H6v2h12v-2zM6 16h12v-2H6v2zm16.988 12H4V4h30v12.987l2-2V2H2v28h18.988l2-2zM6 24h18v-2H6v2zM24 6H6v2h18V6zm-6.284 30.1l5.657 5.657 16.971-16.971-5.657-5.657L17.716 36.1zm22.627-22.627L36.1 17.716l5.657 5.657L46 19.13l-5.657-5.657zM13.473 46L22 43.13l-5.698-5.615L13.473 46z"
    />
  </svg>
);

export default ImageInfoEdit;
