import React from 'react';
import PropTypes from 'prop-types';
import { Content, ContentProps } from '@dls/react-beta-content';

const CardContent = (props: ContentProps): JSX.Element => (
  <Content {...props} />
);

CardContent.propTypes = {
  children: PropTypes.node,
};

CardContent.defaultProps = {
  flex: 1,
};

export default CardContent;
