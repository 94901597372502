import componentColor, { dls } from '@dls/react-colors';
import { useTheme } from '@dls/react-beta-utils';

export const useDefaultDataset = (data) => {
  const theme = useTheme();
  data = data || {};
  if (Array.isArray(data.datasets)) {
    data.datasets = data.datasets.map((dataset, i) => {
      const legendIndicator = `legendIndicator${i + 1}`;
      const lineColor = componentColor(
        dls.dataVisualization[legendIndicator].charts.normal,
      )({ theme });
      return {
        fill: false,
        backgroundColor: componentColor(
          dls.dataVisualization.targetBackground.charts.normal,
        )({ theme }),
        pointStyle: 'circle',
        lineTension: 0,
        pointRadius: 3,
        borderWidth: '2',
        borderColor: lineColor,
        pointBackgroundColor: lineColor,
        ...dataset,
      };
    });
  } else {
    data.datasets = [];
  }
  return data;
};
