import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import { Content } from '@dls/react-beta-content';

const TabContent = (props): ReactElement => <Content {...props} />;

TabContent.propTypes = {
  children: PropTypes.node,
};

export default TabContent;
