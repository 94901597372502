import React, { FunctionComponent, SVGProps } from 'react';

const ThreeDotsHorizontal = (props) => (
  <svg viewBox="0 0 24 24" width="1em" height="1em" {...props}>
    <circle cx={18} cy={12} r={2} />
    <circle cx={12} cy={12} r={2} />
    <circle cx={6} cy={12} r={2} />
  </svg>
);

export default ThreeDotsHorizontal;
