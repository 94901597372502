import React, { useEffect, useState, useRef } from 'react';
import Chart from 'chart.js';
import PropTypes from 'prop-types';
import { merge } from 'lodash';
import 'chartjs-plugin-style';
import 'chartjs-plugin-crosshair';
import { useDefaultOptions } from './useDefaultOptions';
import { BaseComponent } from '@dls/react-beta-base-component';

const ChartComponent = (props) => {
  const { type, data, ...other } = props;
  const initialOptions = useDefaultOptions();
  const chart = useRef(null);
  const canvasEl = useRef(null);
  const [options] = useState(
    merge(initialOptions, props.options || initialOptions),
  );

  useEffect(() => {
    const ctx = canvasEl.current.getContext('2d');
    chart.current = new (Chart as any)(ctx, {
      type,
      data,
      options,
    });
    return () => {
      chart.current.destroy();
    };
  }, [type, data, options]);

  return (
    <BaseComponent {...other}>
      <canvas aria-label={`${options.title.text || ''} chart`} ref={canvasEl} />
    </BaseComponent>
  );
};

ChartComponent.propTypes = {
  data: PropTypes.shape({
    labels: PropTypes.array,
    datasets: PropTypes.array,
  }).isRequired,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  minHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  minWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  options: PropTypes.object,
  type: PropTypes.oneOf([
    'line',
    'bar',
    'horizontalBar',
    'radar',
    'doughnut',
    'polarArea',
    'bubble',
    'pie',
    'scatter',
  ]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

ChartComponent.defaultProps = {
  type: 'line',
  heigth: 150,
};

export default ChartComponent;
