import componentColor, { dls } from '@dls/react-colors';
import MuiSnackbar, { SnackbarProps } from '@material-ui/core/Snackbar';
import MuiSnackbarContent, {
  SnackbarContentProps,
} from '@material-ui/core/SnackbarContent';

import { FC } from 'react';
import styled from 'styled-components';

import { em } from '@dls/react-theme';

type SignalStyleVariants =
  | 'SignalInfo'
  | 'SignalError'
  | 'SignalSuccess'
  | 'SignalWarning'
  | '';

interface DLSSnackBarProps extends SnackbarProps {
  dls_variant?: SignalStyleVariants;
}

export const Snackbar: FC<DLSSnackBarProps> = styled(MuiSnackbar).attrs(
  (props: DLSSnackBarProps) => {
    return {
      className: props.dls_variant
        ? 'SignalVariation ' + props.dls_variant
        : '',
      ...props,
    } as DLSSnackBarProps;
  },
)`
  && {
    overflow: hidden;
    border-radius: ${em(2)};
    display: block;
    font-size: ${em(16)};

    box-shadow: 0 ${em(8)} ${em(24)}
      ${componentColor(dls.notification.shadowLevelTwo.default.normal)};

    & .MuiSnackbarContent-root {
      overflow: hidden;
      display: block;
      font-size: ${em(16)};
      padding: ${em(16, 16)} ${em(16, 16)} ${em(16, 16)} ${em(12, 16)};
      border-radius: ${em(2, 16)};
      border: ${em(1, 16)} solid
        ${componentColor(dls.notification.border.default.normal)};
      border-left: ${em(4, 16)} solid;
      border-left-color: ${componentColor(
        dls.notification.icon.default.normal,
      )};
      background-color: ${componentColor(
        dls.notification.background.default.normal,
      )};

      font-family: 'CentraleSans', Tahoma, Arial, Helvetica, sans-serif;
      font-weight: normal;
      font-style: normal;
      color: ${componentColor(dls.notification.text.default.normal)};
      & .MuiSnackbarContent-message {
        padding: unset;
        & h6 {
          margin-bottom: ${em(8)} !important;
          color: ${componentColor(dls.notification.header.default.normal)};
        }
        &.Icon {
          margin-right: ${em(8)};
          color: ${componentColor(dls.notification.icon.default.normal)};
        }
      }

      & .MuiSnackbarContent-action {
        float: right;
        top: 0;
        position: absolute;
        right: 0;
        margin-right: 0;
        padding-left: 0;
        font-size: ${em(16)};
        & .closeIcon {
          font-size: ${em(16)};
          height: ${em(40)};
          width: ${em(40)};
          display: flex;
          margin: ${em(-8)} ${em(8)};
          span {
            margin: auto;
          }
        }
        & .toastCloseIcon {
          font-size: ${em(16)};
          height: ${em(40)};
          width: ${em(40)};
          display: flex;
          margin: ${em(8)};
          span {
            height: ${em(24)};
            width: ${em(24)};
            margin: auto;
          }
        }
        button {
          padding: unset;
          font-size: ${em(16)};
          height: ${em(40)};
          width: ${em(40)};
          margin-top: ${em(4)};
          margin-right: ${em(4)};
          margin-left: ${em(8)};
          &.toastButtonAction {
            margin: ${em(8)};
          }
          & .snackbar-icon {
            height: ${em(24)};
            width: ${em(24)};
          }
        }
      }
    }

    &.withButtons {
      & .MuiSnackbarContent-root {
        font-size: ${em(16)};
        padding: unset;
        & .MuiSnackbarContent-message {
          div {
            padding: ${em(16)} ${em(16)} ${em(16)} ${em(12)};
            & .heading-section {
              padding: unset;
              display: flex;
              margin-bottom: ${em(8)};
              & .Icon {
                margin-right: ${em(8)};
                color: ${componentColor(dls.notification.icon.default.normal)};
              }
            }
          }
        }

        & .MuiSnackbarContent-action {
          top: unset;
          position: unset;

          button {
            margin-bottom: ${em(4)};
          }

          button:nth-child(2) {
            margin-right: ${em(8)};
          }
        }
      }
      &.Info {
        & .MuiSnackbarContent-root {
          border-left-color: ${componentColor(
            dls.notification.iconInfo.default.normal,
          )};
        }
        & .dlsIcon {
          color: ${componentColor(dls.notification.iconInfo.default.normal)};
        }

        h6 {
          color: ${componentColor(dls.notification.headerInfo.default.normal)};
        }
      }
      &.InfoHigh {
        border-left-color: ${componentColor(
          dls.notification.iconInfo.default.normal,
        )};
        & .dlsIcon {
          color: ${componentColor(dls.notification.iconInfo.default.normal)};
        }
        h6 {
          color: ${componentColor(
            dls.notification.headerInfoHigh.default.normal,
          )};
        }
      }
      &.InfoHigher {
        border-left-color: ${componentColor(
          dls.notification.iconInfo.default.normal,
        )};
        & .dlsIcon {
          color: ${componentColor(dls.notification.iconInfo.default.normal)};
        }
        h6 {
          font-size: ${em(16)};
          color: ${componentColor(
            dls.notification.headerInfoHigher.default.normal,
          )};
        }
      }
      &.InfoHighest {
        border-left-color: ${componentColor(
          dls.notification.iconInfo.default.normal,
        )};
        & .dlsIcon {
          color: ${componentColor(dls.notification.iconInfo.default.normal)};
        }

        h6 {
          font-size: ${em(16)};
          color: ${componentColor(
            dls.notification.headerInfoHighest.default.normal,
          )};
        }
      }
      &.InfoOnPar {
        border-left-color: ${componentColor(
          dls.notification.iconInfo.default.normal,
        )};
        & .dlsIcon {
          color: ${componentColor(dls.notification.iconInfo.default.normal)};
        }
        h6 {
          color: ${componentColor(
            dls.notification.headerInfoOnpar.default.normal,
          )};
        }
      }
      &.Warning {
        border-left-color: ${componentColor(
          dls.notification.iconWarning.default.normal,
        )};
        & .dlsIcon {
          color: ${componentColor(dls.notification.iconWarning.default.normal)};
        }
        h6 {
          font-size: ${em(16)};
          color: ${componentColor(
            dls.notification.headerWarning.default.normal,
          )};
        }
      }
      &.WarningHigh {
        border-left-color: ${componentColor(
          dls.notification.iconWarning.default.normal,
        )};
        & .dlsIcon {
          color: ${componentColor(dls.notification.iconWarning.default.normal)};
        }
        h6 {
          font-size: ${em(16)};
          color: ${componentColor(
            dls.notification.headerWarningHigh.default.normal,
          )};
        }
      }
      &.Success {
        border-left-color: ${componentColor(
          dls.notification.iconSuccess.default.normal,
        )};
        & .dlsIcon {
          color: ${componentColor(dls.notification.iconSuccess.default.normal)};
        }
        h6 {
          font-size: ${em(16)};
          color: ${componentColor(
            dls.notification.headerSuccess.default.normal,
          )};
        }
      }
      &.Error {
        border-left-color: ${componentColor(
          dls.notification.iconError.default.normal,
        )};
        & .dlsIcon {
          color: ${componentColor(dls.notification.iconError.default.normal)};
        }
        h6 {
          color: ${componentColor(dls.notification.headerError.default.normal)};
        }
      }

      &.ErrorHigh {
        border-left-color: ${componentColor(
          dls.notification.iconError.default.normal,
        )};
        & .dlsIcon {
          color: ${componentColor(dls.notification.iconError.default.normal)};
        }
        h6 {
          color: ${componentColor(
            dls.notification.headerErrorHigh.default.normal,
          )};
        }
      }
    }

    &.SignalVariation {
      box-shadow: 0 ${em(8)} ${em(24)}
        ${componentColor(dls.notification.shadowLevelTwo.signal.normal)};
      color: ${componentColor(dls.notification.text.signal.normal)};
      & .heading-section {
        padding: unset;
        display: flex;
        margin-bottom: ${em(8)};
        & .dlsIcon {
          margin-right: ${em(8)};
          color: ${componentColor(dls.notification.icon.signal.normal)};
        }
        h6 {
          color: ${componentColor(dls.notification.header.signal.normal)};
        }
      }

      &.SignalInfo {
        & .MuiSnackbarContent-root {
          background: ${componentColor(
            dls.notification.backgroundInfoRedundant.signal.normal,
          )};
        }
      }

      &.SignalError {
        & .MuiSnackbarContent-root {
          background: ${componentColor(
            dls.notification.backgroundErrorRedundant.signal.normal,
          )};
        }
      }
      &.SignalWarning {
        & .MuiSnackbarContent-root {
          background: ${componentColor(
            dls.notification.backgroundWarningRedundant.signal.normal,
          )};
        }
      }
      &.SignalSuccess {
        & .MuiSnackbarContent-root {
          background: ${componentColor(
            dls.notification.backgroundSuccessRedundant.signal.normal,
          )};
        }
      }
    }
  }
`;

export const SnackbarContent: FC<SnackbarContentProps> = styled(
  MuiSnackbarContent,
).attrs((props) => {
  return {
    ...props,
  } as SnackbarContentProps;
})`
  && {
    display: none;
    font-size: ${em(16)};
    padding: ${em(16)} ${em(16)} ${em(16)} ${em(12)};
    overflow: hidden;
    border-radius: ${em(2)};
    color: ${componentColor(dls.notification.text.default.normal)};
    border: 1px solid ${componentColor(dls.notification.border.inline.normal)};
    border-left: ${em(4)} solid;
    border-left-color: ${componentColor(dls.notification.icon.default.normal)};
    background-color: ${componentColor(
      dls.notification.background.inline.normal,
    )};

    font-family: 'CentraleSans', Tahoma, Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;

    &.textOnly {
      display: block;
    }
    &.closeButton {
      display: flex;

      & .closeIcon {
        font-size: ${em(14, 14)} !important;
        height: ${em(40, 14)};
        width: ${em(40, 14)};
        display: flex;
        margin: ${em(-8, 14)} ${em(8, 14)};
        span {
          height: ${em(24, 14)};
          width: ${em(24, 14)};
          margin: auto;
        }
      }
    }
    &.withButtons {
      display: flex;

      button {
        font-size: ${em(14, 14)};
        height: ${em(40, 14)};
        margin: ${em(-8, 14)} ${em(8, 14)};
        padding: 0;
      }
    }
  }
`;
