import React, { FunctionComponent, SVGProps } from 'react';

const Hide = (props) => (
  <svg viewBox="0 0 24 24" width="1em" height="1em" {...props}>
    <path d="M11.655 6C6.125 6 2 11.503 2 11.503v.028s4.103 5.49 9.655 5.503c5.531-.013 9.655-5.503 9.655-5.503.007-.007-.069-.007-.207-.014.138-.007.214-.007.207-.014 0 0-3.448-5.49-9.655-5.503zm.007 9.655c-3.565-.007-6.29-2.738-7.593-4.138C5.366 10.11 8.083 7.38 11.655 7.38c4.007.014 6.448 2.766 7.586 4.138-1.275 1.386-3.986 4.131-7.579 4.138z" />
    <path d="M3.977 20.414L20.911 4.023 19.52 2.586 2.586 18.977z" />
  </svg>
);

export default Hide;
