import styled, { css } from 'styled-components';
import componentColor, { dls } from '@dls/react-colors';
import { ifNotProp, ifProp } from 'styled-tools';
import { toPx } from '@dls/react-beta-utils';
import { ColumnDensities, ColumnProps } from './types';
import { BaseComponent } from '@dls/react-beta-base-component';

const getPaddingByDensity = (props: ColumnProps) => {
  const padding =
    ColumnDensities[props.density].horzPadding +
    ColumnDensities[props.density].horzSpace;
  return toPx(padding);
};

export const StyledColumn = styled(BaseComponent)<ColumnProps>`
  cursor: pointer;
  font-size: 14px;
  font-family: 'CentraleSans', sans-serif;
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  min-width: 50px;
  :not(:last-child) {
    border-right: 1px solid
      ${componentColor(dls.separator.background.content.normal)};
  }

  ${(props) =>
    css`
      height: ${(props: ColumnProps): string =>
        toPx(ColumnDensities[props.density].minHeight)};
      padding: 12px ${getPaddingByDensity(props)};
    `};

  ${ifProp(
    'hidden',
    css`
      display: none;
    `,
  )};

  ${ifNotProp(
    'width',
    css`
      flex-grow: 1;
    `,
  )};
`;

export const StyledInnerColumn = styled.div`
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
