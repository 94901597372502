import React, { FunctionComponent, SVGProps } from 'react';

const ViewPlaneSaggital = (props) => (
  <svg viewBox="0 0 48 48" width="1em" height="1em" {...props}>
    <path
      fill="#231f20"
      d="M41.616 11.073l-19.508 6.465-.108.036v25.929L4 37.538v-26L23.503 5l18.113 6.073zM26 20.462v26L45.5 40V14L26 20.462z"
    />
  </svg>
);

export default ViewPlaneSaggital;
