import styled from 'styled-components';
import { DateTimePickerCellType } from '../types';
import { DateTimePickerCell } from './Cell';
import { getFont, FontWeight } from '@dls/react-beta-utils';
import componentColor, { dls } from '@dls/react-colors';

export const DateTimePickerCellWeekDay = styled(DateTimePickerCell).attrs({
  type: DateTimePickerCellType.Neutral,
  onClick: undefined,
})`
  color: ${componentColor(dls.datePicker.weekLetterText.desktop.normal)};
  cursor: default;
  ${getFont(FontWeight.Medium)};
`;
