import moment from 'moment';
import { SelectOption } from '@dls/react-beta-select';
import { MINUTES_IN_HOUR, HOURS_IN_DAY } from '@dls/react-beta-utils';

const dateToSelectOption = (
  date: moment.Moment,
  format: string,
): SelectOption => ({
  value: date.valueOf().toString(),
  label: date.format(format),
});

export const getTimeOptions = (
  currentDate: Date,
  minutesInterval: number,
  timeFormat: string,
  maxDate?: Date,
): SelectOption[] => {
  const startOfDay = moment(currentDate).startOf('day');
  const numberOptions = (MINUTES_IN_HOUR / minutesInterval) * HOURS_IN_DAY;

  return new Array(numberOptions)
    .fill(undefined)
    .reduce<SelectOption[]>((acc, _, index): SelectOption[] => {
      const newDate = startOfDay.clone().add(minutesInterval * index, 'minute');

      if (maxDate === undefined || newDate.isBefore(maxDate)) {
        return [...acc, dateToSelectOption(newDate, timeFormat)];
      }

      return acc;
    }, []);
};
