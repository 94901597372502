import React, { FunctionComponent, SVGProps } from 'react';

const GroupsOfTools = (props) => (
  <svg viewBox="0 0 48 48" width="1em" height="1em" {...props}>
    <path
      fill="#231f20"
      d="M46 24a5 5 0 01-10 0c0-.407.062-.797.154-1.175l-8.163-3.841C27.079 20.201 25.638 21 24 21s-3.079-.799-3.991-2.017l-8.163 3.841c.092.379.154.769.154 1.176 0 .489-.092.951-.223 1.398l8.381 4.437C21.076 28.723 22.446 28 24 28a5 5 0 11-5 5c0-.489.092-.951.223-1.398l-8.381-4.437C9.924 28.277 8.554 29 7 29a5 5 0 010-10c1.638 0 3.079.799 3.991 2.017l8.163-3.841A4.96 4.96 0 0119 16a5 5 0 0110 0c0 .407-.062.797-.154 1.175l8.163 3.841C37.921 19.799 39.362 19 41 19a5 5 0 015 5z"
    />
  </svg>
);

export default GroupsOfTools;
