import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { ifProp } from 'styled-tools';
import { isFunction } from 'lodash';
import componentColor, { dls } from '@dls/react-colors';
import { BaseComponent } from '@dls/react-beta-base-component';
import { DlsIconFont } from '@dls/react-beta-dls-icon-font';
import { FONT_DEFAULT } from '@dls/react-beta-utils';

const getColor = (item, state) => {
  return componentColor(dls.token[item].default[state]);
};
const StyledToken = styled(BaseComponent)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-height: 32px;
  border-radius: 16px;
  background-color: ${getColor('background', 'normal')};
  &:hover {
    background-color: ${getColor('background', 'hover')};
  }
  &:focus {
    background-color: ${getColor('background', 'focus')};
    outline: none;
  }
  &:active {
    background-color: ${getColor('background', 'pressed')};
  }
  ${ifProp(
    'disabled',
    css`
      color: ${getColor('background', 'disabled')};
      background-color: ${getColor('background', 'disabled')};
      &:hover {
        background-color: ${getColor('background', 'disabled')};
      }
    `,
  )}
`;
const StyledLabel = styled(BaseComponent)`
  font-family: ${FONT_DEFAULT};
  font-weight: 500;
  font-style: normal;
  color: ${getColor('text', 'normal')};
  display: flex;
  flex: 1;
  justify-content: center;
  user-select: none;
  &:focus {
    color: ${getColor('text', 'focus')};
    outline: none;
  }
  ${ifProp(
    'disabled',
    css`
      color: ${getColor('text', 'disabled')};
      &:focus {
        color: ${getColor('text', 'disabled')};
      }
    `,
  )}
`;
const StyledIcon = styled(BaseComponent)`
  display: flex;
  background-color: ${getColor('circleBackground', 'normal')};
  border-radius: 50%;
  min-height: inherit;
  margin-right: 8px;
  .icon-font,
  img {
    width: 32px;
    height: 32px;
    color: ${getColor('circleText', 'normal')};
    &:focus {
      color: ${getColor('circleText', 'focus')};
      outline: none;
    }
  }
  ${ifProp(
    'disabled',
    css`
      background-color: ${getColor('circleBackground', 'disabled')};
      .icon-font {
        color: ${getColor('circleText', 'disabled')};
      }
    `,
  )}
`;
const StyledCloseIcon = styled(DlsIconFont)`
  color: ${getColor('closeIcon', 'normal')};
  margin: 0 4px;
  min-height: 24px;
  border-radius: 50%;
  &:hover {
    color: ${getColor('closeIcon', 'hover')};
  }
  &:active {
    color: ${getColor('closeIcon', 'pressed')};
  }
  &:focus {
    color: ${getColor('closeIcon', 'focus')};
    outline: none;
  }
  ${ifProp(
    'disabled',
    css`
      color: ${getColor('closeIcon', 'disabled')};
    `,
  )}
`;

const Token = (props) => {
  const { disabled, value, icon, label, onClose, closable, ...other } = props;
  const customLabelStyle = {
    paddingLeft: icon && 0,
    paddingRight: closable && 0,
  };
  const handleClose = () => {
    if (isFunction(onClose)) {
      onClose(value);
    }
  };
  return (
    <StyledToken disabled={disabled} {...other}>
      {icon && <StyledIcon>{icon}</StyledIcon>}
      <StyledLabel
        disabled={disabled}
        padding={icon ? [0, 12, 0, 8] : [0, 12]}
        style={customLabelStyle}
        tabIndex={disabled ? -1 : 0}>
        {label}
      </StyledLabel>
      {closable && (
        <StyledCloseIcon
          className="close-icon"
          disabled={disabled}
          icon="cross-circle"
          onClick={!disabled ? handleClose : null}
        />
      )}
    </StyledToken>
  );
};

Token.propTypes = {
  children: PropTypes.node,
  closable: PropTypes.bool,
  disabled: PropTypes.bool,
  icon: PropTypes.node,
  label: PropTypes.string,
  onClose: PropTypes.func,
  value: PropTypes.any,
};

export default Token;
