import React from 'react';
import { Icon } from '@dls/react-icon';
import { StyledReactSelect, Wrapper } from './styles';
import { SelectProps, SelectOption } from './types';
import { findSelectedOption } from './findSelectedOption';
import { NoOptionSelected } from './NoOptionSelected';

export const DropdownIndicator: React.FC = () => (
  <Wrapper>
    <Icon name="ArrowDown" />
  </Wrapper>
);

export function Select<Value extends string>(
  props: SelectProps<Value>,
): JSX.Element {
  const selectedOption:
    | SelectOption<Value>
    | NoOptionSelected = findSelectedOption(props.options, props.value);

  const onChange = (option: SelectOption<Value>) => {
    props.onChange(option, props.name);
  };

  return (
    <StyledReactSelect
      name={props.name}
      value={selectedOption}
      options={props.options}
      placeholder={props.placeholder}
      components={{ DropdownIndicator }}
      classNamePrefix="react-select"
      onChange={onChange}
    />
  );
}
