import React, { FunctionComponent, SVGProps } from 'react';

const AssemblyTool = (props) => (
  <svg viewBox="0 0 48 48" width="1em" height="1em" {...props}>
    <path
      fill="#231f20"
      d="M6 12v34H2V2h4v10zm4 28h16a4 4 0 000-8H10a4 4 0 000 8zm23-24h2a4 4 0 000-8h-2a4 4 0 000 8zm-23 0h9a4 4 0 000-8h-9a4 4 0 000 8zm32 4H10a4 4 0 000 8h32a4 4 0 000-8z"
    />
  </svg>
);

export default AssemblyTool;
