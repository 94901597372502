import styled from 'styled-components';
import { em } from '@dls/react-theme';
import MuiPopper, {
  PopperProps as MuiPopperProps,
} from '@material-ui/core/Popper';
import componentColor, { dls } from '@dls/react-colors';

export const Popper = styled(MuiPopper).attrs((props) => {
  return {
    ...props,
  } as MuiPopperProps;
})`
  && {
    z-index: 1;
    box-shadow: 0 0 0 ${em(1)} rgba(0, 0, 0, 0.05),
      0 ${em(2)} ${em(4)} 0 ${componentColor(dls.shadow.shadow.levelTwo.normal)};
    background-color: ${componentColor(dls.popover.background.default.normal)};
    border-radius: ${em(2)};
    border: ${em(1)} solid ${componentColor(dls.popover.border.default.normal)};
    &.warning {
      background-color: ${componentColor(
        dls.popover.background.warning.normal,
      )};
      &[x-placement*='bottom'] .arrow {
        border-bottom: 1em solid
          ${componentColor(dls.popover.background.warning.normal)};
      }
      &[x-placement*='top'] .arrow {
        border-top: 1em solid
          ${componentColor(dls.popover.background.warning.normal)};
      }
      &[x-placement*='left'] .arrow {
        border-left: 1em solid
          ${componentColor(dls.popover.background.warning.normal)};
      }
      &[x-placement*='right'] .arrow {
        border-right: 1em solid
          ${componentColor(dls.popover.background.warning.normal)};
      }
    }
    &.error {
      background-color: ${componentColor(dls.popover.background.error.normal)};
      &[x-placement*='bottom'] .arrow {
        border-bottom: 1em solid
          ${componentColor(dls.popover.background.error.normal)};
      }
      &[x-placement*='top'] .arrow {
        border-top: 1em solid
          ${componentColor(dls.popover.background.error.normal)};
      }
      &[x-placement*='left'] .arrow {
        border-left: 1em solid
          ${componentColor(dls.popover.background.error.normal)};
      }
      &[x-placement*='right'] .arrow {
        border-right: 1em solid
          ${componentColor(dls.popover.background.error.normal)};
      }
    }
    &[x-placement*='bottom'] {
      margin-top: ${em(12)};
    }
    &[x-placement*='top'] {
      margin-bottom: ${em(12)};
    }
    &[x-placement*='left'] {
      margin-right: ${em(12)};
    }
    &[x-placement*='right'] {
      margin-left: ${em(12)};
    }
    &[x-placement*='bottom'] .arrow {
      top: ${em(-10, 8)};
      border-left: 1em solid transparent;
      border-right: 1em solid transparent;
      border-bottom: 1em solid
        ${componentColor(dls.popover.background.default.normal)};

      &:before {
        border-width: '0 1em 1em 1em';
        border-color: 'transparent transparent white transparent';
      }
    }
    &[x-placement*='bottom-start'] .arrow {
      left: ${em(8, 8)} !important;
    }
    &[x-placement*='bottom-end'] .arrow {
      transform: translate3d(${em(50, 8)}, 0px, 0px);
    }
    &[x-placement*='top'] .arrow {
      bottom: ${em(-10, 8)};
      border-left: 1em solid transparent;
      border-right: 1em solid transparent;
      border-top: 1em solid
        ${componentColor(dls.popover.background.default.normal)};

      &:before {
        border-width: 1em 1em 0 1em;
        border-color: white transparent transparent transparent;
      }
    }
    &[x-placement*='top-start'] .arrow {
      left: ${em(8, 8)} !important;
    }

    &[x-placement*='top-end'] .arrow {
      transform: translate3d(${em(38, 8)}, 0px, 0px);
    }

    &[x-placement*='right'] .arrow {
      left: ${em(-10, 8)};
      width: ${em(10, 8)};
      height: ${em(10, 8)};
      border-top: 1em solid transparent;
      border-bottom: 1em solid transparent;
      border-right: 1em solid
        ${componentColor(dls.popover.background.default.normal)};

      &:before {
        border-width: 1em 1em 1em 0;
        border-color: transparent white transparent transparent;
      }
    }

    &[x-placement*='right-start'] .arrow {
      top: ${em(8, 8)} !important;
    }

    &[x-placement*='right-end'] .arrow {
      top: ${em(72, 8)} !important;
    }

    &[x-placement*='left'] .arrow {
      right: ${em(-10, 8)};
      width: ${em(10, 8)};
      height: ${em(10, 8)};
      border-top: 1em solid transparent;
      border-bottom: 1em solid transparent;
      border-left: 1em solid
        ${componentColor(dls.popover.background.default.normal)};

      &:before {
        border-width: 1em 0 1em 1em;
        border-color: transparent transparent transparent white;
      }
    }

    &[x-placement*='left-start'] .arrow {
      top: ${em(8, 8)} !important;
    }

    &[x-placement*='left-end'] .arrow {
      top: ${em(72, 8)} !important;
    }

    .arrow {
      position: absolute;
      font-size: ${em(8)};
      width: ${em(16, 8)};
      height: ${em(10, 8)};

      &:before {
        /* content:'""', */
        margin: auto;
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
      }
    }
  }
`;

export const PopperHeader = styled.div`
  display: flex;
  align-items: center;
  padding: ${em(16)};
`;

export type PopperContentProps = { noSpaceAbove?: boolean };

export const PopperContent = styled.div<PopperContentProps>`
  padding: ${(props) =>
    /* istanbul ignore next */
    props.noSpaceAbove
      ? `0em ${em(16)} ${em(16)} ${em(16)}`
      : `${em(16)} ${em(16)} ${em(16)} ${em(16)}`};
`;
