import componentColor, { dls } from '@dls/react-colors';
import MuiToolbar, { ToolbarProps } from '@material-ui/core/Toolbar';
import { FC } from 'react';
import styled from 'styled-components';
import { em, svgScaling } from '@dls/react-theme';

export const Toolbar: FC<ToolbarProps> = styled(MuiToolbar).attrs((props) => {
  return {
    ...props,
  } as ToolbarProps;
})`
  && {
    &.MuiToolbar-root {
      /* Used for gallery purpose */
      & .header {
        align-items: center;
        cursor: pointer;
        outline: none;
        display: flex;
        flex-shrink: 0;
        padding: ${em(7)};
        & a {
          color: ${componentColor(dls.navigation.text.primary.normal)};
        }
      }
      &.dls-toolbar {
        /* this is taken from persistent sidebar */
        padding: ${em(16)};
        justify-content: space-between;

        [role='button'] {
          &:focus {
            outline: none !important;
          }
        }
      }

      &.dls-toolbar-container {
        justify-content: left;
        min-height: ${em(48)};
        padding: unset;
        &.dls-onImage-toolbar {
          background-color: ${componentColor(
            dls.toolbar.background.onImage.normal,
          )};
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          & .dls-toolbar-paper {
            background-color: unset;
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            box-shadow: none;
          }
          & .dls-toolbar-items {
            background-color: unset;
          }
        }
        & .DLSDropdown {
          &:before {
            content: none;
          }
          background-color: unset;
          border: unset;
          border-bottom-color: unset;
        }

        & .dls-paper-section {
          display: flex;
          flex-wrap: wrap;
          box-shadow: none;
          width: 100%;
          min-height: ${em(48)};

          &.primary {
            background-color: ${componentColor(
              dls.content.background.primary.normal,
            )};
            color: ${componentColor(dls.contentItem.text.primary.normal)};
            & .dls-toolbar-items {
              background-color: ${componentColor(
                dls.content.background.primary.normal,
              )};
              color: ${componentColor(dls.contentItem.text.primary.normal)};
            }
          }
          &.secondary {
            background-color: ${componentColor(
              dls.content.background.secondary.normal,
            )};
            color: ${componentColor(dls.contentItem.text.secondary.normal)};
            & .dls-toolbar-items {
              background-color: ${componentColor(
                dls.content.background.secondary.normal,
              )};
              color: ${componentColor(dls.contentItem.text.secondary.normal)};
            }
          }
        }
        & .dls-toolbar-items {
          padding: 0 ${em(8)};
          align-items: center;
          & .DLS-divider {
            width: 100%;
          }
          &.horizontal-anchor {
            margin-left: auto;
          }
          & .vertical-anchor {
            margin-top: auto;
          }
          & .dls-vertical {
            display: flex;
            flex-flow: column;

            & .dls-toggleButton {
              height: ${em(48)};
              width: ${em(48)};
              min-width: ${em(48)};
            }
          }
          &.first {
            padding-left: ${em(16)};
          }
          & .dls-toggleButton.Mui-selected {
            font-size: ${em(16)};
            margin: 0 ${em(4)};
            border-left: unset;
            background-color: ${componentColor(
              dls.button.background.quietEmphasis.focus,
            )};
          }
          & .dls-toggleButton {
            font-size: ${em(16)};
            margin: 0 ${em(4)};
            border: none;
            & .DLSIcon {
              ${svgScaling(em(24))};
              & svg {
                position: unset;
              }
              &:hover {
                color: ${componentColor(dls.button.icon.quietEmphasis.hover)};
              }
              &:focus {
                color: ${componentColor(dls.button.icon.quietEmphasis.focus)};
              }
              &:active {
                color: ${componentColor(dls.button.icon.quietEmphasis.pressed)};
              }
              color: ${componentColor(dls.button.icon.quietEmphasis.normal)};
            }
          }

          & .dls-toolbar-toggle {
            display: none;
          }
        }
        &.dls-vertical-container {
          min-width: ${em(48)};
          width: ${em(48)};

          border: none;
          & .dls-vertical-anchor-paper {
            display: flex;
            align-self: flex-start;
            height: 100%;
            flex-wrap: wrap;
            box-shadow: none;
            background-color: ${componentColor(
              dls.content.background.primary.normal,
            )};

            &.primary {
              background-color: ${componentColor(
                dls.content.background.primary.normal,
              )};
              color: ${componentColor(dls.contentItem.text.primary.normal)};
              & .dls-toolbar-items {
                background-color: ${componentColor(
                  dls.content.background.primary.normal,
                )};
                color: ${componentColor(dls.contentItem.text.primary.normal)};
              }
            }
            &.secondary {
              background-color: ${componentColor(
                dls.content.background.secondary.normal,
              )};
              color: ${componentColor(dls.contentItem.text.secondary.normal)};
              & .dls-toolbar-items {
                background-color: ${componentColor(
                  dls.content.background.secondary.normal,
                )};
                color: ${componentColor(dls.contentItem.text.secondary.normal)};
              }
            }
          }
          & .dls-toolbar-items {
            width: ${em(48)};
            padding: unset;
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            & .DLS-divider {
              width: 100%;
            }
            & .dls-vertical {
              padding: ${em(8)} 0;

              & .dls-toggleButton {
                padding: unset;
                margin: ${em(4)} 0;

                & .DLSVerticalIcon {
                  ${svgScaling(em(24))};
                  & svg {
                    position: unset;
                  }
                  &:hover {
                    color: ${componentColor(
                      dls.button.icon.quietEmphasis.hover,
                    )};
                  }
                  &:focus {
                    color: ${componentColor(
                      dls.button.icon.quietEmphasis.focus,
                    )};
                  }
                  &:active {
                    color: ${componentColor(
                      dls.button.icon.quietEmphasis.pressed,
                    )};
                  }
                  color: ${componentColor(
                    dls.button.icon.quietEmphasis.normal,
                  )};
                }
              }
            }
          }
        }
      }
    }
  }
`;
