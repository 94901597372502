import React, { FC } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { ifProp } from 'styled-tools';
import { Avatar } from '@dls/react-beta-avatar';
import { BaseComponent } from '@dls/react-beta-base-component';
import { Button } from '@dls/react-beta-button';
import componentColor, { dls } from '@dls/react-colors';
import { DlsIconFont } from '@dls/react-beta-dls-icon-font';
import { Typography } from '@dls/react-beta-typography';
import { NavigationTheme } from '@dls/react-beta-utils';
import { FONT_DEFAULT } from '@dls/react-beta-utils';

export type PatientInfoProps = any;

const StyledPatientInfo = styled(BaseComponent)`
  background-color: ${componentColor(
    dls.navigation.background.secondary.normal,
  )};
  padding: 0 16px;
  .icon-font {
    color: ${componentColor(dls.patientInfo.patientIcon.default.normal)};
  }
  div:first-of-type {
    margin-left: 0;
  }
  div:last-of-type {
    margin-right: 0;
  }
`;
const StyledInfo = styled(BaseComponent)`
  position: relative;
  flex-direction: row;
  margin-right: 16px;
  &::after {
    content: '';
    position: absolute;
    width: 2px;
    height: 2px;
    background: ${componentColor(
      dls.patientInfo.secondaryInfoDescriptorText.default.normal,
    )};
    right: -17px;
    bottom: 9px;
    margin: 0 8px;
  }
  &:last-child {
    margin-right: 0;
    &::after {
      content: none;
    }
  }
`;
const StyledPatientNameLabel = styled(Typography)`
  font-family: ${FONT_DEFAULT};
  font-weight: 400;
  font-style: normal;
  font-size: 20px;
  color: ${componentColor(dls.patientInfo.patientNameText.default.normal)};
  line-height: 29px;
  margin-bottom: 1px;
`;
const StyledPatientLabel = styled(Typography)`
  font-size: 16px;
  line-height: 20px;
  min-height: 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  ${ifProp(
    'patientDescriptor',
    css`
      color: ${componentColor(
        dls.patientInfo.patientInfoDescriptorText.default.normal,
      )};
    `,
  )};
  ${ifProp(
    'patientText',
    css`
      color: ${componentColor(dls.patientInfo.patientInfoText.default.normal)};
    `,
  )}
`;
const renderInfo = (label, value) => {
  const customLabelMargin = {
    marginRight: 5,
  };
  return (
    <StyledInfo display="flex">
      <StyledPatientLabel variant="body" style={customLabelMargin}>
        {label}
      </StyledPatientLabel>
      <StyledPatientLabel variant="body">{value}</StyledPatientLabel>
    </StyledInfo>
  );
};
const getHorizontalInfo = (label, value) => {
  return value && renderInfo(label, value);
};
const renderPrimaryContent = (data) => {
  return (
    <BaseComponent
      layout="vflex"
      maxWidth="500"
      minWidth="300"
      margin={[1, 32, 0, 0]}>
      <StyledPatientNameLabel variant="h2">{data.name}</StyledPatientNameLabel>
      <BaseComponent display="flex">
        {getHorizontalInfo('MRN', data.mrn)}
        {getHorizontalInfo('DOB', data.dob)}
        {getHorizontalInfo('Gender', data.gender)}
      </BaseComponent>
    </BaseComponent>
  );
};

const PatientInfo: FC<PatientInfoProps> = (props) => {
  const { children, hideGoBackButton, patientData = {}, ...other } = props;
  return (
    <NavigationTheme>
      <StyledPatientInfo
        display="flex"
        alignItems="center"
        minHeight="80"
        {...other}>
        {!hideGoBackButton && (
          <Button variant="quietDefault">
            <DlsIconFont icon="navigation-left-alternate" />
          </Button>
        )}
        {patientData.avatar ? (
          <Avatar
            src={patientData.avatar}
            alt="Patient Photo"
            margin={[5, 15, 15, 0]}
          />
        ) : (
          <Avatar margin={[5, 15, 15, 0]}>
            <DlsIconFont fontSize="30" icon="person-portrait-circle" />
          </Avatar>
        )}
        {renderPrimaryContent(patientData)}
        {children}
      </StyledPatientInfo>
    </NavigationTheme>
  );
};

PatientInfo.propTypes = {
  children: PropTypes.node,
  hideGoBackButton: PropTypes.bool,
  patientData: PropTypes.shape({
    avatar: PropTypes.string,
    dob: PropTypes.string,
    gender: PropTypes.string,
    mrn: PropTypes.string,
    name: PropTypes.string,
  }),
};

export default PatientInfo;
