import React, { FunctionComponent, SVGProps } from 'react';

const AlleleAlignmentTool = (props) => (
  <svg viewBox="0 0 48 48" width="1em" height="1em" {...props}>
    <path
      fill="#231f20"
      d="M46 24a4 4 0 01-4 4H27v4h6a4 4 0 010 8h-6v6h-4v-6H6a4 4 0 010-8h17v-4h-3a4 4 0 010-8h3v-4H9a4 4 0 010-8h14V2h4v6h2a4 4 0 010 8h-2v4h15a4 4 0 014 4z"
    />
  </svg>
);

export default AlleleAlignmentTool;
