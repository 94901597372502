import componentColor, { dls } from '@dls/react-colors';
import MuiFormControlLabel from '@material-ui/core/FormControlLabel';
import styled from 'styled-components';
import { em } from '@dls/react-theme';

export const FormControlLabel = styled(MuiFormControlLabel).attrs((props) => {
  return {
    ...props,
  };
})`
  && {
    &.MuiFormControlLabel-root {
      height: ${em(40)};
      margin-right: ${em(12)};
      &.Mui-disabled {
        & .Mui-disabled {
          background-color: transparent;
          & .MuiIconButton-label {
            background-color: ${componentColor(
              dls.radioButton.background.default.disabled,
            )};
            &:before {
              background-color: ${componentColor(
                dls.radioButton.icon.default.disabled,
              )};
            }
          }
        }
      }
      &.radioButton {
        margin-left: 0;
      }
      &.checkbox {
        margin-left: 0;
      }
      .pt-touch & {
        &.radioButton {
          height: ${em(48)};
        }
        &.checkbox {
          height: ${em(48)};
        }
      }
      .pt-dense & {
        &.radioButton {
          height: ${em(32)};
        }
        &.checkbox {
          height: ${em(32)};
        }
      }
      & .MuiRadio-colorSecondary.Mui-checked {
        &:focus-within:hover {
          background-color: ${componentColor(
            dls.radioButton.focusOutline.default.focusPressed,
          )};
        }
        &:hover {
          background-color: transparent;
        }
      }
      &.MuiFormControlLabel-labelPlacementStart {
        margin-left: ${em(12)};
      }
    }
    & .MuiFormControlLabel-label {
      color: ${componentColor(dls.label.text.regular.normal)};
      &.Mui-disabled {
        color: ${componentColor(dls.label.text.regular.disabled)};
      }
    }
  }
`;
