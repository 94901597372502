import React, { FunctionComponent, SVGProps } from 'react';

const CurveMode = (props) => (
  <svg viewBox="0 0 48 48" width="1em" height="1em" {...props}>
    <path
      fill="#231f20"
      d="M9.548 19.669a44.019 44.019 0 01-1.737-5.689c.064.003.124.02.189.02a4.93 4.93 0 001.727-.326 40.165 40.165 0 001.607 5.393 4.95 4.95 0 00-1.786.602zM12 21a3 3 0 100 6 3 3 0 000-6zM11 9a3 3 0 10-6 0 3 3 0 006 0zm27 29a3 3 0 100 6 3 3 0 000-6zm-10.226 1.243a35.533 35.533 0 005.229 1.73c.004-.693.15-1.353.41-1.953a34.05 34.05 0 01-4.671-1.512 4.952 4.952 0 01-.968 1.735zM24 33a3 3 0 100 6 3 3 0 000-6zm-8.279-5.692c-.418.47-.919.855-1.486 1.141a32.47 32.47 0 005.151 5.633 5.025 5.025 0 011.071-1.604 30.147 30.147 0 01-4.736-5.17z"
    />
  </svg>
);

export default CurveMode;
