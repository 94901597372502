import { useContext, createContext } from 'react';
import { DialogContextProps } from './types';

const DialogContext = createContext<DialogContextProps>({});

export const useDialogContext = () => {
  return useContext(DialogContext);
};

export default DialogContext;
