import styled, { css } from 'styled-components';
import { getFont, FontWeight } from '@dls/react-beta-utils';
import { getCellStylesByType } from './getCellStylesByType';
import { DLSGray75 } from '../styles';
import { DateTimePickerCellType, DateTimePickerCellProps } from './types';

export const DateTimePickerCell = styled.div<DateTimePickerCellProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  font-size: 12px;
  line-height: 18px;
  margin: 2px;
  border-radius: 50%;
  color: ${DLSGray75};

  ${getFont(FontWeight.Normal)};

  ${(props) => {
    if (props.type !== DateTimePickerCellType.Disabled) {
      return css`
        cursor: pointer;
      `;
    }

    return css`
      cursor: default;
    `;
  }}

  ${(props) => getCellStylesByType(props.type)}
`;
