import React, { FunctionComponent, SVGProps } from 'react';

const Star16 = (props) => (
  <svg viewBox="0 0 16 16" width="1em" height="1em" {...props}>
    <path
      fillRule="evenodd"
      d="M11.709 9.944l3.652-3.596-5.069-.764L8 1 5.708 5.584l-5.069.764 3.652 3.596L3.45 15 8 12.639 12.549 15z"
    />
  </svg>
);

export default Star16;
