import React, { FunctionComponent, SVGProps } from 'react';

const CrossCircle = (props) => (
  <svg
    id="DLS_x5F_CrossCircle_x5F_24"
    x={0}
    y={0}
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    width="1em"
    height="1em"
    {...props}>
    <style>{'.st0{fill:#fff}'}</style>
    <path d="M16 8l-8 8 8-8zM8 8l8 8-8-8z" className="st0" />
    <path d="M12 4c-4.42 0-8 3.58-8 8s3.58 8 8 8 8-3.58 8-8-3.58-8-8-8zm4.71 11.29L15.3 16.7 12 13.41 8.71 16.7 7.3 15.29 10.59 12l-3.3-3.29L8.7 7.3l3.3 3.29 3.29-3.29 1.41 1.41L13.41 12l3.3 3.29z" />
  </svg>
);

export default CrossCircle;
