import React from 'react';
import {
  InputIconButton,
  TextBoxIconContainer,
  TextBoxContainer,
  Input,
} from '@dls/react-beta-text-box';
import moment from 'moment';
import { dateTimePickerTestIds } from '../tests/testIds';
import { CalendarIcon } from './icon';
import { DateTimePickerInputProps } from './types';
import { InputAlignment } from '@dls/react-beta-text-box';

export const DateTimePickerInput = (
  props: DateTimePickerInputProps,
): JSX.Element => {
  const value = props.dateTime
    ? moment(props.dateTime).format(props.dateFormat)
    : props.placeholder;

  const onChange = (): void => {
    // Do nothing, readonly input
  };

  return (
    <TextBoxContainer>
      <Input
        type="button"
        density="regular"
        value={value}
        hasIcon
        onFocus={props.onOpen}
        onChange={onChange}
        alignment={props.alignment ?? InputAlignment.Left}
      />
      <TextBoxIconContainer>
        <InputIconButton
          type="button"
          onClick={props.onOpen}
          aria-label={props.iconAriaLabel}
          data-test-id={dateTimePickerTestIds.Input.InputIconButton}>
          <CalendarIcon />
        </InputIconButton>
      </TextBoxIconContainer>
    </TextBoxContainer>
  );
};
