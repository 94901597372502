import { DateTimePickerCellType } from './types';
import moment from 'moment';

export const getCellType = (
  startOfSelected: Date,
  day: Date,
  visibleMonth: Date,
  maxDate?: Date,
): DateTimePickerCellType => {
  const momentDay = moment(day);
  if (startOfSelected.getTime() === day.getTime()) {
    return DateTimePickerCellType.Selected;
  } else if (
    day.getMonth() !== moment(visibleMonth).month() ||
    (maxDate !== undefined && momentDay.isAfter(maxDate))
  ) {
    return DateTimePickerCellType.Disabled;
  } else if (momentDay.isSame(new Date(), 'day')) {
    return DateTimePickerCellType.Highlighted;
  } else {
    return DateTimePickerCellType.Neutral;
  }
};
