import React from 'react';
import styled from 'styled-components';
import componentColor, { dls } from '@dls/react-colors';

import { density } from '@dls/react-beta-utils';
import { RadioButtonProps } from './types';

const FocusOutline = styled.div`
  position: absolute;
  top: -10px;
  left: -10px;
  box-sizing: content-box;
  width: 20px;
  height: 20px;
  transition: border-color 0.15s ease-in-out;
  border: 10px solid
    ${componentColor(dls.radioButton.focusOutline.default.focusPressed)};
  border-radius: 50%;
  background-color: transparent;
  opacity: 0;

  input:focus ~ & {
    opacity: 1;
  }
`;

type SelectionDotProps = Pick<RadioButtonProps, 'disabled'>;
const SelectionDot = styled.div<SelectionDotProps>`
  position: absolute;
  top: 6px;
  left: 6px;
  width: 8px;
  height: 8px;
  transform: scale(0);
  transition: transform 0.15s ease-in-out;
  border-radius: 50%;
  background-color: ${(props) =>
    componentColor(
      dls.radioButton.icon.default[props.disabled ? 'disabled' : 'normalOff'],
    )};
`;

const CircleWrapper = styled.span`
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
`;

type CircleProps = Pick<RadioButtonProps, 'disabled'>;
const Circle = styled.span<CircleProps>`
  display: inline-block;
  position: absolute;
  width: 20px;
  height: 20px;
  transition: background-color 0.15s;
  border-radius: 50%;
  outline: none;
  background-color: ${(props) =>
    componentColor(
      dls.radioButton.background.default[
        props.disabled ? 'disabled' : 'normalOff'
      ],
    )};
  vertical-align: middle;

  input:checked ~ & {
    background-color: ${(props) =>
      componentColor(
        dls.radioButton.background.default[
          props.disabled ? 'disabled' : 'normalOn'
        ],
      )};
  }

  input:checked ~ & > ${SelectionDot} {
    transform: scale(1);
    transition: all 0.15s;
    background-color: ${(props) =>
      componentColor(
        dls.radioButton.icon.default[props.disabled ? 'disabled' : 'normalOn'],
      )};
  }
`;

type ContainerProps = Pick<RadioButtonProps, 'density' | 'disabled'>;
const Container = styled.label<ContainerProps>`
  display: inline-flex;
  align-items: center;
  height: ${density({ touch: 40, regular: 40, dense: 32 })};
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  pointer-events: ${(props) => (props.disabled ? 'none' : null)};

  &:active {
    ${FocusOutline} {
      opacity: 1;
    }
  }
`;

type TextLabelProps = Pick<RadioButtonProps, 'disabled'>;
const TextLabel = styled.span<TextLabelProps>`
  margin-left: 0.75rem;
  color: ${(props) =>
    props.disabled
      ? componentColor(dls.label.text.regular.disabled)
      : componentColor(dls.label.text.regular.normal)};
`;

export const RadioButton = React.forwardRef<HTMLInputElement, RadioButtonProps>(
  (props, ref) => {
    const {
      children,
      className,
      density,
      name,
      value,
      selected,
      defaultSelected,
      disabled,
      onChange,
    } = props;

    return (
      <Container className={className} disabled={disabled} density={density}>
        <CircleWrapper>
          <input
            type="radio"
            name={name}
            value={value}
            checked={selected}
            defaultChecked={defaultSelected}
            disabled={disabled}
            onChange={onChange}
            ref={ref}
          />
          <Circle disabled={disabled}>
            <SelectionDot disabled={disabled} />
          </Circle>
          <FocusOutline />
        </CircleWrapper>
        {children && <TextLabel disabled={disabled}>{children}</TextLabel>}
      </Container>
    );
  },
);
RadioButton.displayName = 'RadioButton';
