import React, { useMemo, useCallback } from 'react';
import { Button } from '@dls/react-beta-button';
import { Select } from '@dls/react-beta-select';
import { DateTimePickerCalendar } from '../Calendar';
import { getTimeOptions } from '../getTimeOptions';
import styled from 'styled-components';
import { SelectOption } from '@dls/react-beta-select';
import { dateTimePickerTestIds } from '../../tests/testIds';
import { DateTimePickerCalendarOverlayProps } from './types';
import moment from 'moment';

export const DateTimePickerCalendarOverlay = ({
  timeFormat = 'HH:mm',
  ...props
}: DateTimePickerCalendarOverlayProps): JSX.Element => {
  const timeOptions = useMemo(
    () =>
      getTimeOptions(
        props.selectedDate,
        props.minuteInterval,
        timeFormat,
        props.maxDate,
      ),
    [props.selectedDate, props.minuteInterval, timeFormat, props.maxDate],
  );

  const onChangeTime = useCallback(
    (option: SelectOption): void => {
      const epoch: number = parseInt(option.value, 10);
      props.onChange(new Date(epoch));
    },
    [props],
  );

  const onDateSelect = (date: Date) => {
    // we grab the originally selected date
    const currentDate = moment(props.selectedDate);

    // and then modify the newly selected date with the original selected time
    const updatedDate = moment(date)
      .set('hours', currentDate.get('hours'))
      .set('minutes', currentDate.get('minutes'))
      .set('seconds', currentDate.get('seconds'))
      .set('millisecond', currentDate.get('millisecond'));

    // finally we notify the date change
    props.onChange(updatedDate.toDate());
  };

  return (
    <StyledOverlayOuter
      data-test-id={dateTimePickerTestIds.CalendarOverlay.OverlayOuter}>
      <StyledOverlayContainer>
        <DateTimePickerCalendar
          onSelect={onDateSelect}
          selected={props.selectedDate}
          maxDate={props.maxDate}
          weekDaysFormat={props.weekDaysFormat}
        />
        {props.showTime && (
          <StyledTimeSelectContainer
            data-test-id={dateTimePickerTestIds.CalendarOverlay.TimePicker}>
            <Select
              name="datetime"
              onChange={onChangeTime}
              options={timeOptions}
              value={props.selectedDate.getTime().toString()}
            />
          </StyledTimeSelectContainer>
        )}
        <StyledOverlayActionContainer>
          <Button type="button" variant="quietDefault" onClick={props.onCancel}>
            {props.cancelText}
          </Button>
          <Button type="button" variant="primary" onClick={props.onSave}>
            {props.okText}
          </Button>
        </StyledOverlayActionContainer>
      </StyledOverlayContainer>
    </StyledOverlayOuter>
  );
};

const StyledOverlayOuter = styled.div`
  position: relative;
`;

const StyledOverlayContainer = styled.div`
  position: absolute;
  top: 2px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  padding-left: 16px;
  padding-right: 16px;
  background: white;
  border: 1px solid #c4c4c4;
`;

const StyledTimeSelectContainer = styled.div`
  margin-top: 12px;
  margin-bottom: 12px;
  position: relative;
`;

const StyledOverlayActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 12px 0;

  & > button {
    flex: 1;
    display: block;
    text-align: center;
  }
`;
