import React, { FunctionComponent, SVGProps } from 'react';

const BrandREF37X24 = (props) => (
  <svg viewBox="0 0 37 24" width="1em" height="1em" {...props}>
    <path d="M35.15 1.846v20.308H1.85V1.846h33.3zM37 0H0v24h37V0z" />
    <path d="M11.803 6.637c.776.018 1.51.352 2.034.923a2.84 2.84 0 01.741 2.105 2.769 2.769 0 01-1.628 2.704l1.943 4.08h-1.99l-1.692-3.784H9.962v3.784h-1.85V6.637h3.7-.009zm-1.85 4.33h1.591a1.25 1.25 0 001.249-1.349 1.208 1.208 0 00-1.193-1.31H9.962v2.686l-.009-.028zM21.959 6.637v1.671h-3.996v2.566h3.46v1.624h-3.46v2.271h4.071v1.643h-5.921V6.637h5.846zM29.378 6.637v1.671h-4.005v2.658h3.459v1.625h-3.459v3.877h-1.85V6.637h5.846z" />
  </svg>
);

export default BrandREF37X24;
