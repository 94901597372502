import componentColor, { dls } from '@dls/react-colors';
import { FC } from 'react';
import styled from 'styled-components';
import { em } from '@dls/react-theme';
import { ArrowUp } from '@dls/react-icons';
import { ArrowDown } from '@dls/react-icons';

import MuiTableContainer, {
  TableContainerProps as MuiTableContainerProps,
} from '@material-ui/core/TableContainer';
import MuiTable, { TableProps as MuiTableProps } from '@material-ui/core/Table';
import MuiTableHead, {
  TableHeadProps as MuiTableHeadProps,
} from '@material-ui/core/TableHead';
import MuiTableRow, {
  TableRowProps as MuiTableRowProps,
} from '@material-ui/core/TableRow';
import MuiTableCell, {
  TableCellProps as MuiTableCellProps,
} from '@material-ui/core/TableCell';
import MuiTableBody, {
  TableBodyProps as MuiTableBodyProps,
} from '@material-ui/core/TableBody';
import MuiTableSortLabel, {
  TableSortLabelProps as MuiTableSortLabelProps,
} from '@material-ui/core/TableSortLabel';

export const TableContainer: FC<MuiTableContainerProps> = styled(
  MuiTableContainer,
).attrs((props) => {
  return {
    ...props,
  } as MuiTableContainerProps;
})`
  && {
    &.MuiTableContainer-root {
      border-top: ${em(1)} solid
        ${componentColor(dls.separator.background.content.normal)};
      border-right: ${em(1)} solid
        ${componentColor(dls.separator.background.content.normal)};
      border-left: ${em(1)} solid
        ${componentColor(dls.separator.background.content.normal)};
    }
  }
`;

export const Table: FC<MuiTableProps> = styled(MuiTable).attrs((props) => {
  return {
    ...props,
  } as MuiTableProps;
})``;

export const TableHead: FC<MuiTableHeadProps> = styled(MuiTableHead).attrs(
  (props) => {
    return {
      ...props,
    } as MuiTableHeadProps;
  },
)`
  && {
    &.MuiTableHead-root {
      & .MuiTableRow-head {
        & .MuiTableCell-paddingCheckbox {
          min-height: ${em(49, 14)};
          padding-right: ${em(4, 14)};
          padding-top: 0;
          padding-bottom: 0;
          & .MuiCheckbox-root {
            font-size: ${em(24, 14)};
            padding-right: 0;
          }
          .pt-dense & {
            padding-right: ${em(12, 14)};
          }
        }
      }
    }
  }
`;

export const TableRow: FC<MuiTableRowProps> = styled(MuiTableRow).attrs(
  (props) => {
    return {
      ...props,
    } as MuiTableRowProps;
  },
)`
  && {
    &.MuiTableRow-root {
      &.toggle-row {
        & .MuiTableCell-root {
          &.toggle-cell {
            padding-top: 0;
            padding-bottom: 0;
            width: ${em(48, 16)};
            & .MuiFab-root {
              font-size: ${em(15.7, 16)};
            }
          }
        }
      }
    }
  }
`;

export const TableCell: FC<MuiTableCellProps> = styled(MuiTableCell).attrs(
  (props) => {
    return {
      ...props,
    } as MuiTableCellProps;
  },
)`
  && {
    &.MuiTableCell-root {
      padding: ${em(10, 16)} ${em(20, 16)} ${em(9, 16)} ${em(20, 16)};
      .pt-dense & {
        padding: ${em(6, 16)} ${em(12, 16)} ${em(5, 16)} ${em(12, 16)};
      }
      .pt-touch & {
        padding: ${em(14, 16)} ${em(20, 16)} ${em(13, 16)} ${em(20, 16)};
      }
      &:first-of-type {
        padding-left: ${em(24, 16)};
        .pt-dense & {
          padding-left: ${em(24, 16)};
        }
        .pt-touch & {
          padding-left: ${em(24, 16)};
        }
      }
      &:last-of-type {
        padding-right: ${em(24, 16)};
        .pt-dense & {
          padding-right: ${em(24, 16)};
        }
        .pt-touch & {
          padding-right: ${em(24, 16)};
        }
      }
      border-bottom: ${em(1)} solid
        ${componentColor(dls.separator.background.content.normal)};

      font-size: ${em(16)};
      line-height: ${em(20, 16)};
      font-family: CentraleSans, Tahoma, Arial, Helvetica, sans-serif;
      font-weight: normal;
      font-style: normal;
    }
    &.MuiTableCell-head {
      padding: ${em(17, 14)} ${em(20, 14)} ${em(17, 14)} ${em(20, 14)};
      color: ${componentColor(dls.dataGrid.headerText.default.normalOn)};
      font-size: ${em(14)};
      line-height: ${em(14, 14)};
      font-family: CentraleSans, Tahoma, Arial, Helvetica, sans-serif;
      font-weight: normal;
      font-style: normal;
      .pt-dense & {
        padding: ${em(13, 14)} ${em(12, 14)} ${em(13, 14)} ${em(12, 14)};
      }
      .pt-touch & {
        padding: ${em(21, 14)} ${em(20, 14)} ${em(21, 14)} ${em(20, 14)};
      }
      &:first-of-type {
        padding-left: ${em(24, 14)};
        .pt-dense & {
          padding-left: ${em(24, 14)};
        }
        .pt-touch & {
          padding-left: ${em(24, 14)};
        }
      }
      &:last-of-type {
        padding-right: ${em(24, 14)};
        .pt-dense & {
          padding-right: ${em(24, 14)};
        }
        .pt-touch & {
          padding-right: ${em(24, 14)};
        }
      }
      &.sorting-header {
        padding-top: 0;
        padding-bottom: 0;
        & .MuiTableSortLabel-root {
          min-height: ${em(48, 14)};
          .pt-dense & {
            min-height: ${em(40, 14)};
          }
          .pt-touch & {
            min-height: ${em(56, 14)};
          }
          & .MuiTableSortLabel-icon {
            font-size: ${em(24, 14)};
          }
        }
      }
    }
  }
`;

export const TableBody: FC<MuiTableBodyProps> = styled(MuiTableBody).attrs(
  (props) => {
    return {
      ...props,
    } as MuiTableBodyProps;
  },
)`
  && {
    &.MuiTableBody-root {
      & .MuiTableRow-root {
        & .MuiTableCell-root {
          color: ${componentColor(dls.contentItem.text.primary.normal)};
          &.MuiTableCell-paddingCheckbox {
            padding-right: ${em(4, 16)};
            & .MuiCheckbox-root {
              padding-right: 0;
            }
            .pt-dense & {
              padding-right: ${em(12, 16)};
            }
          }
        }
        &:hover {
          background-color: ${componentColor(
            dls.listItem.background.default.hover,
          )};
        }
        &.Mui-selected {
          background-color: ${componentColor(
            dls.listItem.background.default.normalOn,
          )};
        }
      }
    }
  }
`;

export const TableSortLabel: FC<MuiTableSortLabelProps> = styled(
  MuiTableSortLabel,
).attrs((props) => {
  return {
    active: true,
    IconComponent: props.direction === 'asc' ? ArrowUp : ArrowDown,
    ...props,
  } as MuiTableSortLabelProps;
})`
  && {
    &.MuiTableSortLabel-root {
      &:hover {
        color: inherit;
        & .MuiTableSortLabel-icon {
          opacity: inherit;
        }
      }
      &.MuiTableSortLabel-active {
        color: inherit;
        & .MuiTableSortLabel-icon {
          fill: ${componentColor(dls.dataGrid.sortIcon.default.normalOn)};
        }
      }
      & .MuiTableSortLabel-icon {
        opacity: inherit;
        fill: ${componentColor(dls.dataGrid.sortIcon.default.normalOff)};
        &.MuiTableSortLabel-iconDirectionDesc {
          transform: none;
        }
        &.MuiTableSortLabel-iconDirectionAsc {
          transform: none;
        }
      }
    }
  }
`;
