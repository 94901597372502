import React from 'react';
import PropTypes from 'prop-types';
import { useDefaultDataset } from './useDefaultDataset';
import { ChartComponent } from '@dls/react-beta-chart';

export const LineChart = (props) => {
  const data = useDefaultDataset(props.data || {});

  return <ChartComponent {...props} type="line" data={data} />;
};

LineChart.propTypes = {
  data: PropTypes.shape({
    labels: PropTypes.array,
    datasets: PropTypes.array,
  }),
  options: PropTypes.object,
};

export default LineChart;
