import React from 'react';
import PropTypes from 'prop-types';
import componentColor, { dls } from '@dls/react-colors';
import { createGlobalStyle, css } from 'styled-components';
import { spacing } from '@dls/react-beta-utils';
import { FONT_DEFAULT } from '@dls/react-beta-utils';

const scrollbarStyles = () => {
  return css`
    ::-webkit-scrollbar {
      height: 16px;
      width: 16px;
    }
    /* Bar */
    ::-webkit-scrollbar-thumb {
      background-color: ${componentColor(
        dls.thumb.background.scrollbar.normal,
      )};
      background-clip: padding-box;
      border: solid transparent;
      border-width: 4px;
      min-height: 24px;
      box-sizing: padding-box;
      border-radius: 8px;
    }

    /* Corner bottom XY */
    ::-webkit-scrollbar-corner,
    ::-webkit-scrollbar-track {
      background-color: ${componentColor(
        dls.track.background.scrollbar.normal,
      )};
    }

    ::-webkit-scrollbar-thumb:hover {
      background-color: ${componentColor(dls.thumb.background.scrollbar.hover)};
    }
    /* Active bar */
    ::-webkit-scrollbar-thumb:active {
      background-color: ${componentColor(
        dls.thumb.background.scrollbar.pressed,
      )};
    }
  `;
};

const RootStyles = createGlobalStyle<any>`
  html {
    font-size: 16px;
    box-sizing: border-box;
    /* stylelint-disable property-no-vendor-prefix */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* stylelint-enable */
  }
  body {
    font-size: 1rem;
    font-family: ${FONT_DEFAULT};
    background: ${componentColor(
      dls.content.background.secondaryNeutral.normal,
    )};
    color: ${componentColor(dls.contentItem.text.primary.normal)};
    margin: ${(props) => spacing[props.margin] || `${props.margin}px`};
    padding: ${(props) => spacing[props.padding] || `${props.padding}px`};
  }
  *, *::before, *::after {
    box-sizing: inherit;
  }
  ${scrollbarStyles()}
`;

const GlobalStyle = (props) => {
  const { children } = props;
  return (
    <React.Fragment>
      <RootStyles {...props} />
      {children}
    </React.Fragment>
  );
};

GlobalStyle.defaultProps = {
  margin: 0,
  padding: 0,
};

GlobalStyle.propTypes = {
  children: PropTypes.node,
  margin: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.oneOf(['xxs', 'xs', 's', 'm', 'l', 'xl', 'xxl', false]),
  ]),
  padding: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.oneOf(['xxs', 'xs', 's', 'm', 'l', 'xl', 'xxl', false]),
  ]),
};

export default GlobalStyle;
