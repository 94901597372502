import styled from 'styled-components';
import MuiSwitch, {
  SwitchProps as MuiSwitchProps,
} from '@material-ui/core/Switch';
import componentColor, { dls } from '@dls/react-colors';
import { em } from '@dls/react-theme';

/** Switch with its content */
export const Switch = styled(MuiSwitch).attrs((props: MuiSwitchProps) => {
  return {
    ...props,
  } as MuiSwitchProps;
})`
  && {
    &.MuiSwitch-root {
      /** Switch Content Area Style */
      width: ${em(44)};
      height: ${em(44)};
      /**
       ** This padding is require because of need to set width and height of track bar.
       * track width = Switch_Content.Width - PaddingLeft
      */
      padding: ${em(14)} ${em(2)};
      overflow: unset;
      align-items: center;

      & .MuiSwitch-switchBase {
        top: unset;
        left: unset;

        & .MuiSwitch-input {
          left: 20%;
          top: -5%;
          width: 110%;
          height: 110%;
        }
      }

      & .MuiIconButton-root {
        padding: ${em(8)};
        margin-left: -${em(10)};
        font-size: ${em(16)};

        &:hover {
          /** 
          ** Make a transparent hover ring for Stay the focus ring.
          */
          background-color: rgba(0, 0, 0, 0.001);
        }

        /**
        ** Focus is not applied so used focus within for Thumb.
        */
        &:focus-within {
          background-color: ${componentColor(
            dls.thumb.focusOutline.default.focusPressed,
          )};
        }

        &:active {
          background-color: ${componentColor(
            dls.thumb.focusOutline.default.focusPressed,
          )};
        }
      }

      & .Mui-checked {
        color: unset;

        & .MuiSwitch-input {
          left: -30%;
        }
      }

      & .MuiSwitch-switchBase.Mui-checked {
        transform: translateX(1.25em);
      }

      & .MuiSwitch-track {
        border-radius: ${em(8)};
        background-color: ${componentColor(
          dls.track.background.default.normalOff,
        )};
      }

      & .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
        background-color: ${componentColor(
          dls.track.background.default.normalOn,
        )};
      }

      & .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
        opacity: 1;
      }

      & .MuiSwitch-switchBase.Mui-disabled + .MuiSwitch-track {
        opacity: 1;
        background-color: ${componentColor(
          dls.track.background.default.disabled,
        )};
      }

      & .MuiSwitch-thumb {
        width: ${em(24)};
        height: ${em(24)};

        border: ${em(1)} solid
          ${componentColor(dls.thumb.border.default.normal)};

        background-color: ${componentColor(
          dls.thumb.background.default.normal,
        )};
        box-shadow: 0 ${em(1)} ${em(2)} 0
          ${componentColor(dls.shadow.shadow.shadowThumb.normal)};

        &:focus {
          background-color: ${componentColor(
            dls.thumb.background.default.focus,
          )};
        }

        &:active {
          background-color: ${componentColor(
            dls.thumb.background.default.pressed,
          )};
        }
      }

      & .Mui-disabled .MuiSwitch-thumb {
        background-color: ${componentColor(
          dls.thumb.background.default.disabled,
        )};

        box-shadow: 0 ${em(1)} ${em(2)} 0
          ${componentColor(dls.shadow.shadow.shadowThumb.disabled)};
      }

      /** Disable the touch ripple effect.*/
      & .MuiTouchRipple-root span {
        display: none;
      }
    }
  }
`;
