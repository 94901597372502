import React, { useState, useCallback, useEffect } from 'react';
import ClickOutHandler from 'react-onclickout';
import { DateTimePickerCalendarOverlay } from './Calendar/CalendarOverlay/CalendarOverlay';
import styled from 'styled-components';
import { DateTimePickerInput } from './Input/Input';
import { DateTimePickerProps } from './types';
import { roundTime } from '@dls/react-beta-utils';

export const DateTimePicker = ({
  cancelText = 'Cancel',
  okText = 'Ok',
  minuteInterval = 30,
  startOpen = false,
  hideTimePicker = false,
  ...props
}: DateTimePickerProps): JSX.Element => {
  const [isOpen, setIsOpen] = useState<boolean>(startOpen);
  const [selectedDate, setSelectedDate] = useState<Date>(() =>
    roundTime(props.value || new Date(), 1000 * 60 * minuteInterval),
  );

  useEffect(() => {
    setSelectedDate(
      roundTime(props.value || new Date(), 1000 * 60 * minuteInterval),
    );
  }, [props.value, minuteInterval]);

  const onOpen = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);

  const onClose = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  const onSave = useCallback(() => {
    props.onSave(selectedDate);
    onClose();
  }, [selectedDate, onClose, props]);

  const dateFormat =
    props.dateFormat || (hideTimePicker ? 'DD-MM-YYYY' : 'DD-MM-YYYY HH:mm');

  return (
    <ClickOutHandler onClickOut={onClose}>
      <DateTimePickerContainer>
        <DateTimePickerInput
          placeholder={props.placeholder}
          dateTime={props.value}
          dateFormat={dateFormat}
          onOpen={onOpen}
          alignment={props.alignment}
        />
        {!isOpen ? null : (
          <DateTimePickerCalendarOverlay
            selectedDate={selectedDate}
            onCancel={onClose}
            onChange={setSelectedDate}
            onSave={onSave}
            maxDate={props.maxDate}
            cancelText={cancelText}
            okText={okText}
            minuteInterval={minuteInterval}
            weekDaysFormat={props.weekDaysFormat}
            timeFormat={props.timeFormat}
            showTime={!hideTimePicker}
          />
        )}
      </DateTimePickerContainer>
    </ClickOutHandler>
  );
};

const DateTimePickerContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
