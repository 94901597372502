import React from 'react';
import MuiButtonGroup, {
  ButtonGroupProps,
} from '@material-ui/core/ButtonGroup';
import { Icon, IconName } from '@dls/react-icon';
import styled, { css } from 'styled-components';
import { Button, ButtonVariant } from '@dls/react-mui-button';
import { em, touchStyles, svgScaling } from '@dls/react-theme';
import componentColor, { dls } from '@dls/react-colors';
import {
  Menu,
  MenuItem,
  PrefixMenuItemWithOnlyIcon,
} from '@dls/react-mui-menu';
import { Label } from '@dls/react-mui-typography';

import clsx from 'clsx';

export type SplitButtonType = 'secondary' | 'quietDefault';
export type SplitButtonVariant = 'text' | 'icon_text' | 'icon';

export interface ISplitButtonStyle {
  button_type?: SplitButtonType;
  button_variant?: SplitButtonVariant;
}

export interface ButtonGroupCustomProps
  extends ButtonGroupProps,
    ISplitButtonStyle {
  /**This variant is used for `Hover` on Selected toggle button Group*/
  button_selected?: 'toggleOn' | 'toggleOff';

  /**This variant is used for `Disable` the Hover on disabled button Group*/
  button_disabled?: 'disabledOn' | 'disabledOff';
}

export const SplitButtonGroup = styled(MuiButtonGroup).attrs(
  (props: ButtonGroupCustomProps) => {
    return {
      ...props,
      className: clsx(
        props.button_type,
        props.button_selected,
        props.button_disabled,
      ),
    } as ButtonGroupCustomProps;
  },
)`
  && {
    /** Button Group style will goes here*/
    &.MuiButtonGroup-root {
      border-radius: ${em(2)};

      ${(props: ButtonGroupCustomProps) =>
        props.button_type === 'quietDefault'
          ? css`
              border: ${em(1)} solid
                ${componentColor(dls.splitButton.separator.inputQuiet.normal)};
            `
          : css`
              border: ${componentColor(
                dls.splitButton.separator.secondary.normal,
              )};
            `}

      &:hover {
        ${(props: ButtonGroupCustomProps) =>
          props.button_type === 'quietDefault'
            ? css`
                border: ${em(1)} solid
                  ${componentColor(dls.splitButton.separator.inputQuiet.hover)};
              `
            : css`
                border: ${componentColor(
                  dls.splitButton.separator.secondary.hover,
                )};
              `}
      }

      &:active {
        ${(props: ButtonGroupCustomProps) =>
          props.button_type === 'quietDefault'
            ? css`
                border: ${em(1)} solid
                  ${componentColor(
                    dls.splitButton.separator.inputQuiet.pressed,
                  )};
              `
            : css`
                border: ${componentColor(
                  dls.splitButton.separator.secondary.pressed,
                )};
              `}
      }

      &:focus-within {
        ${(props: ButtonGroupCustomProps) =>
          props.button_type === 'quietDefault'
            ? css`
                border: ${em(1)} solid
                  ${componentColor(dls.splitButton.separator.inputQuiet.normal)};
              `
            : css`
                border: ${componentColor(
                  dls.splitButton.separator.secondary.normal,
                )};
              `};
      }

      :not(.toggleOff) {
        ${(props: ButtonGroupCustomProps) =>
          props.button_type === 'quietDefault'
            ? css`
                border-color: ${componentColor(
                  dls.splitButton.separator.inputQuiet.pressed,
                )};
              `
            : css`
                border-color: ${componentColor(
                  dls.splitButton.separator.secondary.pressed,
                )};
              `}
      }

      :not(.disabledOff):not(.toggleOn):hover {
        ${(props: ButtonGroupCustomProps) =>
          props.button_type === 'quietDefault'
            ? css`
                border: ${em(1)} solid
                  ${componentColor(
                    dls.splitButton.separator.inputQuiet.disabled,
                  )};
              `
            : css`
                border: ${componentColor(
                  dls.splitButton.separator.secondary.disabled,
                )};
              `}
      }
    }

    &.MuiButtonGroup-contained {
      box-shadow: unset;
    }

    & .MuiButtonGroup-groupedContainedHorizontal:not(:last-child) {
      ${(props: ButtonGroupCustomProps) =>
        props.button_type === 'quietDefault'
          ? css`
              border-right: ${em(1)} solid
                ${componentColor(dls.splitButton.separator.inputQuiet.normal)};
            `
          : css`
              border-top-left-radius: ${em(2)};
              border-bottom-left-radius: ${em(2)};
              border-right: ${em(1)} solid
                ${componentColor(dls.splitButton.separator.secondary.normal)};
            `}

      &:active {
        ${(props: ButtonGroupCustomProps) =>
          props.button_type === 'quietDefault'
            ? css`
                border-right: ${em(1)} solid
                  ${componentColor(
                    dls.splitButton.separator.inputQuiet.pressed,
                  )};
              `
            : css`
                border-right: ${em(1)} solid
                  ${componentColor(dls.splitButton.separator.secondary.pressed)};
              `}
      }
      &:hover {
        ${(props: ButtonGroupCustomProps) =>
          props.button_type === 'quietDefault'
            ? css`
                border-right: ${em(1)} solid
                  ${componentColor(dls.splitButton.separator.inputQuiet.hover)};
              `
            : css`
                border-right: ${em(1)} solid
                  ${componentColor(dls.splitButton.separator.secondary.hover)};
              `}
      }
      &:focus {
        ${(props: ButtonGroupCustomProps) =>
          props.button_type === 'quietDefault'
            ? css`
                border-right: ${em(1)} solid
                  ${componentColor(dls.splitButton.separator.inputQuiet.focus)};
              `
            : css`
                border-right: ${em(1)} solid
                  ${componentColor(dls.splitButton.separator.secondary.focus)};
              `}
      }
    }

    & .MuiButtonGroup-grouped {
      min-width: ${em(32)};
    }

    & .MuiButtonGroup-groupedHorizontal:not(:first-child) {
      ${(props: ButtonGroupCustomProps) =>
        props.button_type === 'quietDefault'
          ? css`
              border-left: ${em(1)} solid
                ${componentColor(dls.splitButton.separator.inputQuiet.normal)};
            `
          : css`
              border-top-right-radius: ${em(2)};
              border-bottom-right-radius: ${em(2)};
            `}

      &:hover {
        ${(props: ButtonGroupCustomProps) =>
          props.button_type === 'quietDefault'
            ? css`
                border-left: ${em(1)} solid
                  ${componentColor(dls.splitButton.separator.inputQuiet.hover)};
              `
            : css``}
      }
      &:active {
        ${(props: ButtonGroupCustomProps) =>
          props.button_type === 'quietDefault'
            ? css`
                border-left: ${em(1)} solid
                  ${componentColor(
                    dls.splitButton.separator.inputQuiet.pressed,
                  )};
              `
            : css``}
      }
    }

    .pt-dense & {
      & .MuiButtonGroup-grouped {
        min-width: ${em(24)};
      }
    }
  }
`;

const StyledButton = styled(Button)<ISplitButtonStyle>`
  ${(props: ISplitButtonStyle) =>
    css`
      &.MuiButton-root.${props.button_type} {
        /**============== Normal Style REGION============*/
        border-radius: unset;
        padding-left: ${em(12)};
        padding-right: ${em(12)};
        min-height: ${em(40)};
        height: ${em(40)};
        transition: unset;
        background-color: ${props.button_type === 'secondary'
          ? componentColor(
              dls.splitButton.backgroundButtonAction.secondary.normal,
            )
          : componentColor(
              dls.splitButton.backgroundButtonAction.inputQuiet.normal,
            )} !important;

        color: ${props.button_type === 'secondary'
          ? componentColor(dls.splitButton.text.secondary.normal)
          : componentColor(dls.splitButton.text.inputQuiet.normal)} !important;
        border: ${props.button_type === 'secondary'
          ? ''
          : componentColor(dls.splitButton.border.inputQuiet.normal)};
        & .MuiButton-startIcon {
          margin-right: ${props.button_variant === 'icon_text' ? em(12) : '0'};
          color: ${props.button_type === 'secondary'
            ? componentColor(dls.splitButton.icon.secondary.normal)
            : componentColor(dls.splitButton.icon.inputQuiet.normal)};
        }

        .pt-dense & {
          padding-left: ${em(8)};
          padding-right: ${em(8)};
          min-height: ${em(32)};
          height: ${em(32)};

          & .MuiButton-startIcon {
            margin-right: ${props.button_variant === 'icon_text' ? em(8) : '0'};
          }
        }

        .pt-touch & {
          ${touchStyles(em(4))};
        }
        /**============== Normal Style End REGION============*/

        /**============== Active Style REGION============*/
        &:active {
          background-color: ${props.button_type === 'secondary'
            ? componentColor(
                dls.splitButton.backgroundButtonAction.secondary.pressed,
              )
            : componentColor(
                dls.splitButton.backgroundButtonAction.inputQuiet.pressed,
              )} !important;

          color: ${props.button_type === 'secondary'
            ? componentColor(dls.splitButton.text.secondary.pressed)
            : componentColor(
                dls.splitButton.text.inputQuiet.pressed,
              )} !important;
          border: ${props.button_type === 'secondary'
            ? ''
            : componentColor(
                dls.splitButton.border.inputQuiet.hoverFocusPressed,
              )};
          outline: ${em(3)} solid
            ${props.button_type === 'secondary'
              ? componentColor(
                  dls.splitButton.focusOutlineDropdown.secondary.focusPressed,
                )
              : componentColor(
                  dls.splitButton.focusOutline.inputQuiet.focusPressed,
                )};
          outline-offset: ${em(1)};
          & .MuiButton-startIcon {
            color: ${props.button_type === 'secondary'
              ? componentColor(dls.splitButton.icon.secondary.pressed)
              : componentColor(dls.splitButton.icon.inputQuiet.pressed)};
          }
        }
        /**============== Active Style End REGION============*/

        /**============== Hover Style REGION============*/
        &:hover {
          background-color: ${props.button_type === 'secondary'
            ? componentColor(
                dls.splitButton.backgroundButtonAction.secondary.hover,
              )
            : componentColor(
                dls.splitButton.backgroundButtonAction.inputQuiet.hover,
              )} !important;

          color: ${props.button_type === 'secondary'
            ? componentColor(dls.splitButton.text.secondary.hover)
            : componentColor(dls.splitButton.text.inputQuiet.hover)} !important;
          border: ${props.button_type === 'secondary'
            ? ''
            : componentColor(
                dls.splitButton.border.inputQuiet.hoverFocusPressed,
              )};
          & .MuiButton-startIcon {
            color: ${props.button_type === 'secondary'
              ? componentColor(dls.splitButton.icon.secondary.hover)
              : componentColor(dls.splitButton.icon.inputQuiet.hover)};
          }
        }
        /**============== Hover Style End REGION============*/

        /**============== Focus Style REGION============*/
        &:focus {
          &.MuiButtonGroup-groupedContainedHorizontal {
            border: unset !important;
          }

          background-color: ${props.button_type === 'secondary'
            ? componentColor(
                dls.splitButton.backgroundButtonAction.secondary.focus,
              )
            : componentColor(
                dls.splitButton.backgroundButtonAction.inputQuiet.focus,
              )} !important;

          color: ${props.button_type === 'secondary'
            ? componentColor(dls.splitButton.text.secondary.focus)
            : componentColor(dls.splitButton.text.inputQuiet.focus)} !important;
          border: ${props.button_type === 'secondary'
            ? ''
            : componentColor(
                dls.splitButton.border.inputQuiet.hoverFocusPressed,
              )};
          & .MuiButton-startIcon {
            color: ${props.button_type === 'secondary'
              ? componentColor(dls.splitButton.icon.secondary.focus)
              : componentColor(dls.splitButton.icon.inputQuiet.focus)};
          }
          outline: ${em(3)} solid
            ${props.button_type === 'secondary'
              ? componentColor(
                  dls.splitButton.focusOutlineDropdown.secondary.focusPressed,
                )
              : componentColor(
                  dls.splitButton.focusOutline.inputQuiet.focusPressed,
                )};
          outline-offset: ${em(1)};
          z-index: 1; /*  to bring the outline on top of element */
        }
        /**============== Focus Style End REGION============*/
      }

      &.MuiButton-root.Mui-disabled {
        background-color: ${props.button_type === 'secondary'
          ? componentColor(
              dls.splitButton.backgroundButtonAction.secondary.disabled,
            )
          : componentColor(
              dls.splitButton.backgroundButtonAction.inputQuiet.disabled,
            )} !important;

        color: ${props.button_type === 'secondary'
          ? componentColor(dls.splitButton.text.secondary.disabled)
          : componentColor(
              dls.splitButton.text.inputQuiet.disabled,
            )} !important;
        border: ${props.button_type === 'secondary'
          ? ''
          : componentColor(dls.splitButton.border.inputQuiet.disabled)};

        & .MuiButton-startIcon {
          color: ${props.button_type === 'secondary'
            ? componentColor(dls.splitButton.icon.secondary.disabled)
            : componentColor(dls.splitButton.icon.inputQuiet.disabled)};
        }
      }
    `}
`;

const StyledIconButton = styled(Button)<ISplitButtonStyle>`
  ${(props: ISplitButtonStyle) =>
    css`
      &.MuiButton-root.${props.button_type} {
        border-radius: unset;
        padding-left: ${em(4)};
        padding-right: ${em(4)};
        min-height: ${em(40)};
        height: ${em(40)};
        transition: unset;

        background-color: ${props.button_type === 'secondary'
          ? componentColor(
              dls.splitButton.backgroundButtonDropdown.secondary.normal,
            )
          : componentColor(
              dls.splitButton.backgroundButtonDropdown.inputQuiet.normal,
            )} !important;

        .pt-dense & {
          padding-left: 0;
          padding-right: 0;
          min-height: ${em(32)};
          height: ${em(32)};
        }

        .pt-touch & {
          ${touchStyles(em(4))};
        }

        /**============== Active Style REGION============*/
        &:active {
          background-color: ${props.button_type === 'secondary'
            ? componentColor(
                dls.splitButton.backgroundButtonDropdown.secondary.pressed,
              )
            : componentColor(
                dls.splitButton.backgroundButtonDropdown.inputQuiet.pressed,
              )} !important;
        }
        /**============== Active Style End REGION============*/

        /**============== Hover Style REGION============*/
        &:hover {
          background-color: ${props.button_type === 'secondary'
            ? componentColor(
                dls.splitButton.backgroundButtonDropdown.secondary.hover,
              )
            : componentColor(
                dls.splitButton.backgroundButtonDropdown.inputQuiet.hover,
              )} !important;
        }
        /**============== Hover Style End REGION============*/

        /**============== Focus Style REGION============*/
        &:focus {
          &.MuiButtonGroup-groupedHorizontal:not(:first-child) {
            border-left: ${em(1)} solid
              ${componentColor(dls.splitButton.separator.inputQuiet.focus)};
          }

          background-color: ${props.button_type === 'secondary'
            ? componentColor(
                dls.splitButton.backgroundButtonDropdown.secondary.focus,
              )
            : componentColor(
                dls.splitButton.backgroundButtonDropdown.inputQuiet.focus,
              )} !important;

          outline: ${em(3)} solid
            ${props.button_type === 'secondary'
              ? componentColor(
                  dls.splitButton.focusOutlineDropdown.secondary.focusPressed,
                )
              : componentColor(
                  dls.splitButton.focusOutline.inputQuiet.focusPressed,
                )};
          outline-offset: ${em(1)};
          z-index: 1; /*  to bring the outline on top of element */
        }
        /**============== Focus Style End REGION============*/
      }

      /** BUTTON ICON SCALING */
      &.MuiButton-root span {
        ${svgScaling(em(24))};
        & svg {
          top: 0;
          left: 0;
        }
      }

      &.MuiButton-root.Mui-disabled {
        background-color: ${props.button_type === 'secondary'
          ? componentColor(
              dls.splitButton.backgroundButtonDropdown.secondary.disabled,
            )
          : componentColor(
              dls.splitButton.backgroundButtonDropdown.inputQuiet.disabled,
            )} !important;

        span {
          & svg {
            color: ${props.button_type === 'secondary'
              ? componentColor(dls.splitButton.arrow.secondary.disabled)
              : componentColor(dls.splitButton.arrow.inputQuiet.disabled)};
          }
        }
      }
    `}
`;

export const StyledIcon = styled(Icon)<ISplitButtonStyle>`
  ${(props: ISplitButtonStyle) =>
    css`
      color: ${props.button_type === 'secondary'
        ? componentColor(dls.splitButton.arrow.secondary.normal)
        : componentColor(dls.splitButton.arrow.inputQuiet.normal)};

      &:active {
        color: ${props.button_type === 'secondary'
          ? componentColor(dls.splitButton.arrow.secondary.pressed)
          : componentColor(dls.splitButton.arrow.inputQuiet.pressed)};
      }
      &:hover {
        color: ${props.button_type === 'secondary'
          ? componentColor(dls.splitButton.arrow.secondary.hover)
          : componentColor(dls.splitButton.arrow.inputQuiet.hover)};
      }
      &:focus {
        color: ${props.button_type === 'secondary'
          ? componentColor(dls.splitButton.arrow.secondary.focus)
          : componentColor(dls.splitButton.arrow.inputQuiet.focus)};
      }
    `}
`;

type SplitButtonListModel = {
  id: number | string;
  icon?: string;
  title?: string;
};

export interface ISplitButtonListModel extends Array<SplitButtonListModel> {
  [index: number]: SplitButtonListModel;
}

export interface ISplitButtonProps extends ISplitButtonStyle {
  onSelectedMenuItem?: (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number,
  ) => void;
  onClickAction?: (event: React.MouseEvent<HTMLElement>) => void;
  onClickToggle?: (
    _event: React.FormEvent<HTMLButtonElement>,
    toggleState: boolean,
  ) => void;
  prefixMenu?: boolean;
  prefixMenuOnlyIcon?: boolean;
  disabled?: boolean;
  options: ISplitButtonListModel;
  arrowIconName?: string;
}

export const SplitButton = (props: ISplitButtonProps) => {
  const {
    options,
    button_type,
    button_variant,
    onSelectedMenuItem,
    onClickAction,
    arrowIconName,
    disabled,
    prefixMenu,
    prefixMenuOnlyIcon,
  } = props;

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  /** Handle Button Action Click */
  /* istanbul ignore next */
  const handleClick = (_event: React.MouseEvent<HTMLElement>) => {
    if (onClickAction !== undefined) {
      onClickAction(_event);
    }
  };

  /** Handle the Menu Item Click Listner */
  /* istanbul ignore next */
  const handleMenuItemClick = (
    _event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number,
  ) => {
    setSelectedIndex(index);
    setOpen(false);

    if (onSelectedMenuItem !== undefined) {
      onSelectedMenuItem(_event, index);
    }
  };

  /** Handle Button DropDown Click*/
  /* istanbul ignore next */
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  /** Handle ClickAway listner from Menu List Item Click*/
  /* istanbul ignore next */
  const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setOpen(false);
  };

  /// TypeChecking Props
  const _buttonVariant: SplitButtonVariant =
    button_variant === undefined ? 'text' : button_variant;

  const getButtonType = (_buttonType?: SplitButtonType): ButtonVariant => {
    return _buttonType === undefined ? 'secondary' : _buttonType;
  };

  /** Render the Start Button */
  const startButton =
    _buttonVariant === 'text' ? (
      <StyledButton
        dls_variant={getButtonType(button_type)}
        onClick={handleClick}
        button_type={getButtonType(button_type) as SplitButtonType}
        button_variant={button_variant}>
        {options[selectedIndex].title}
      </StyledButton>
    ) : _buttonVariant === 'icon' ? (
      <StyledButton
        dls_variant={getButtonType(button_type)}
        startIcon={
          <Icon name={options[selectedIndex].icon as IconName} size={'m'} />
        }
        onClick={handleClick}
        button_type={getButtonType(button_type) as SplitButtonType}
        button_variant={button_variant}
      />
    ) : (
      <StyledButton
        dls_variant={getButtonType(button_type)}
        startIcon={
          <Icon name={options[selectedIndex].icon as IconName} size={'m'} />
        }
        onClick={handleClick}
        button_type={getButtonType(button_type) as SplitButtonType}
        button_variant={button_variant}>
        {options[selectedIndex].title}
      </StyledButton>
    );

  const _arrowIcon = arrowIconName === undefined ? 'ArrowDown' : arrowIconName;

  /** Render the end DropDown button*/
  const endButton = (
    <StyledIconButton
      size="small"
      dls_variant={getButtonType(button_type)}
      aria-controls={open ? 'split-button-menu' : undefined}
      aria-expanded={open ? 'true' : undefined}
      aria-label="end-drop-down-button"
      aria-haspopup="menu"
      onClick={handleToggle}
      button_type={getButtonType(button_type) as SplitButtonType}>
      <StyledIcon
        name={_arrowIcon as IconName}
        size={'m'}
        button_type={getButtonType(button_type) as SplitButtonType}
      />
    </StyledIconButton>
  );

  /* istanbul ignore next */
  const MenuPopup = (
    <Menu
      id="default-menu"
      anchorEl={anchorRef.current}
      open={open}
      onClose={handleClose}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      PaperProps={{
        className: 'DLSMenuList',
      }}>
      {options.map((option: any, index: number) => (
        <MenuItem
          key={option.id}
          selected={index === selectedIndex}
          onClick={(event: React.MouseEvent<HTMLLIElement, MouseEvent>) =>
            handleMenuItemClick(event, index)
          }>
          {prefixMenu ? (
            <>
              <PrefixMenuItemWithOnlyIcon>
                <Icon name={option.icon as IconName} size={'m'} />
              </PrefixMenuItemWithOnlyIcon>
              <Label label_variant="value">{option.title}</Label>
            </>
          ) : prefixMenuOnlyIcon ? (
            <PrefixMenuItemWithOnlyIcon>
              <Icon name={option.icon as IconName} size={'m'} />
            </PrefixMenuItemWithOnlyIcon>
          ) : (
            <Label label_variant="value">{option.title}</Label>
          )}
        </MenuItem>
      ))}
    </Menu>
  );

  return (
    <React.Fragment>
      <SplitButtonGroup
        variant="contained"
        ref={anchorRef}
        button_type={button_type}
        aria-label="split button"
        disabled={disabled}
        button_selected={'toggleOff'}
        button_disabled={disabled ? 'disabledOn' : 'disabledOff'}>
        {startButton}
        {endButton}
      </SplitButtonGroup>
      {MenuPopup}
    </React.Fragment>
  );
};
