import { useContext, createContext } from 'react';
import { DataGridProps } from './types';

const DataGridContext = createContext<DataGridProps>(null);

export const useDataGridContext = (): DataGridProps => {
  return useContext(DataGridContext);
};

export default DataGridContext;
