import React, { FunctionComponent, SVGProps } from 'react';

const Articles = (props) => (
  <svg viewBox="0 0 48 48" width="1em" height="1em" {...props}>
    <path
      fill="#231f20"
      d="M2 42h21V6H2v36zm4-27h13v2H6v-2zm0 8h13v2H6v-2zm0 8h13v2H6v-2zM25 6v36h21V6H25zm17 27H29v-2h13v2zm0-8H29v-2h13v2zm0-8H29v-2h13v2z"
    />
  </svg>
);

export default Articles;
