import styled, { css } from 'styled-components';
import { switchProp, ifProp, ifNotProp } from 'styled-tools';
import { BaseComponent } from '@dls/react-beta-base-component';
import { toPx } from '@dls/react-beta-utils';
import { CellDensities, CellProps } from './types';
import { Density } from '@dls/react-beta-utils';

const getPaddingByDensity = (density: Density): string => {
  const padding =
    CellDensities[density].horzPadding + CellDensities[density].horzSpace;
  return toPx(padding);
};

export const StyledCell = styled(BaseComponent)<CellProps>`
  font-size: 14px;
  font-family: 'CentraleSans', sans-serif;
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 50px;

  ${(props) =>
    css`
      padding: 12px ${getPaddingByDensity(props.density)};
    `}
  ${switchProp('datatype', {
    number: css`
      text-align: right;
    `,
  })};

  ${ifNotProp(
    'width',
    css`
      flex-grow: 1;
    `,
  )}

  ${ifProp(
    'multiline',
    css`
      min-height: ${(props: CellProps): string =>
        toPx(CellDensities[props.density].minHeight)};
    `,
    css`
      height: ${(props: CellProps): string =>
        toPx(CellDensities[props.density].minHeight)};
    `,
  )}
`;

export const StyledInnerCell = styled.div<CellProps>`
  ${ifProp(
    'multiline',
    css`
      word-break: break-all;
      display: -webkit-box;
      line-height: 22px;
      overflow: hidden;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
    `,
    css`
      box-sizing: border-box;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    `,
  )};
`;
