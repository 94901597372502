import styled from 'styled-components';
import { em, touchStyles } from '@dls/react-theme';
import { Card } from '@dls/react-mui-card';
import { CardProps } from '@material-ui/core';
import componentColor, { dls } from '@dls/react-colors';

import MuiToggleButton, {
  ToggleButtonProps,
} from '@material-ui/lab/ToggleButton';
import MuiToggleButtonGroup, {
  ToggleButtonGroupProps,
} from '@material-ui/lab/ToggleButtonGroup';

import { FC } from 'react';
type indicatorClass = 'dls_indicator';

interface ToggleButtonCustomProps extends ToggleButtonProps {
  dls_indicator?: indicatorClass;
  dls_variant?: 'quietDefault';
}
export const ToggleButton: FC<ToggleButtonCustomProps> = styled(
  MuiToggleButton,
).attrs((props: ToggleButtonCustomProps) => {
  return {
    className: props.dls_indicator + ' ' + props.dls_variant,
    ...props,
  } as ToggleButtonCustomProps;
})`
  && {
    height: ${em(40)};
    border-radius: ${em(2)};
    padding: 0 ${em(12)};
    text-transform: none;
    font-size: ${em(16)};
    /* stylelint-disable */
    font-family: 'CentraleSans', Tahoma, Arial, Helvetica, sans-serif;
    /* stylelint-enable */
    font-weight: normal;
    font-style: normal;

    color: ${componentColor(
      dls.toggleButton.text.secondary.normalOffHoverFocusPressed,
    )};
    background-color: ${componentColor(
      dls.toggleButton.background.secondary.normalOffPressedFocusHover,
    )};
    border: ${em(1)} solid
      ${componentColor(
        dls.toggleButton.border.secondary.normalOffHoverFocusPressed,
      )};
    svg {
      fill: ${componentColor(
        dls.toggleButton.icon.secondary.normalOffHoverFocusPressed,
      )};
      height: ${em(24)};
      width: ${em(24)};
    }

    &.Mui-disabled {
      color: ${componentColor(dls.toggleButton.text.secondary.disabledOn)};
      background-color: ${componentColor(
        dls.toggleButton.background.secondary.disabledOn,
      )};
      border: ${em(1)} solid
        ${componentColor(dls.toggleButton.border.secondary.disabledOn)};
      svg {
        fill: ${componentColor(dls.toggleButton.icon.secondary.disabledOn)};
      }
    }

    &.Mui-selected {
      color: ${componentColor(dls.toggleButton.text.secondary.normalOn)};
      background-color: ${componentColor(
        dls.toggleButton.background.secondary.normalOn,
      )};
      border: ${em(1)} solid
        ${componentColor(dls.toggleButton.border.secondary.normalOn)};
      svg {
        fill: ${componentColor(dls.toggleButton.icon.secondary.normalOn)};
      }
      &:hover {
        color: ${componentColor(dls.toggleButton.text.secondary.hoverOn)};
        background-color: ${componentColor(
          dls.toggleButton.background.secondary.hoverOn,
        )};
        border: ${em(1)} solid
          ${componentColor(dls.toggleButton.border.secondary.hoverOn)};
        svg {
          fill: ${componentColor(dls.toggleButton.icon.secondary.hoverOn)};
        }
      }
    }
    &:hover {
      color: ${componentColor(dls.toggleButton.text.secondary.hoverOn)};
      background-color: ${componentColor(
        dls.toggleButton.background.secondary.hoverOn,
      )};
      border: ${em(1)} solid
        ${componentColor(dls.toggleButton.border.secondary.hoverOn)};
      svg {
        fill: ${componentColor(dls.toggleButton.icon.secondary.hoverOn)};
      }
    }
    &:focus {
      color: ${componentColor(dls.toggleButton.text.secondary.focusOn)};
      background-color: ${componentColor(
        dls.toggleButton.background.secondary.focusOn,
      )};
      border: ${em(1)} solid
        ${componentColor(dls.toggleButton.border.secondary.focusOn)};
      outline: ${em(3)} solid
        ${componentColor(dls.toggleButton.focusOutline.secondary.focusPressed)};
      outline-offset: ${em(1)};
      z-index: 1; /*  to bring the outline on top of element */
      svg {
        fill: ${componentColor(dls.toggleButton.icon.secondary.focusOn)};
      }
    }
    &:active {
      color: ${componentColor(dls.toggleButton.text.secondary.pressedOn)};
      background-color: ${componentColor(
        dls.toggleButton.background.secondary.pressedOn,
      )};
      border: ${em(1)} solid
        ${componentColor(dls.toggleButton.border.secondary.pressedOn)};
      outline: ${em(3)} solid
        ${componentColor(dls.toggleButton.focusOutline.secondary.focusPressed)};
      outline-offset: ${em(1)};
      z-index: 1;
      svg {
        fill: ${componentColor(dls.toggleButton.icon.secondary.pressedOn)};
      }
    }

    .pt-dense & {
      height: ${em(32)};
      border-radius: ${em(2)};
      padding: 0 ${em(8)};
    }

    .pt-touch & {
      ${touchStyles(em(4))};
    }
    & .MuiToggleButton-label {
      span {
        height: ${em(24)};
        width: ${em(24)};
      }
      & .textwithicon {
        margin-right: ${em(8)};

        .pt-dense & {
          margin-right: ${em(4)};
        }
      }
    }
    &.dls_indicator {
      padding: 0 ${em(24)};
    }

    &.dls_indicator:after {
      content: '';
      background: ${componentColor(
        dls.toggleButton.ledIndicator.secondary.normalOff,
      )};

      width: ${em(6)};
      height: ${em(6)};
      border-radius: 50%;
      position: absolute;
      right: ${em(4)};
      bottom: ${em(4)};
    }
    &:hover::after {
      background: ${componentColor(
        dls.toggleButton.ledIndicator.secondary.hoverOn,
      )};
    }
    &:focus::after {
      background: ${componentColor(
        dls.toggleButton.ledIndicator.secondary.focusOn,
      )};
    }
    &:active::after {
      background: ${componentColor(
        dls.toggleButton.ledIndicator.secondary.pressedOn,
      )};
    }
    &.dls_indicator.Mui-selected:after {
      background: ${componentColor(
        dls.toggleButton.ledIndicator.secondary.normalOn,
      )};
    }
    &.dls_indicator.Mui-disabled:after {
      background: ${componentColor(
        dls.toggleButton.ledIndicator.secondary.disabledOn,
      )};
    }

    &.quietDefault {
      color: ${componentColor(dls.toggleButton.text.inputQuiet.normalOff)};
      background-color: ${componentColor(
        dls.toggleButton.background.inputQuiet.normalOff,
      )};
      border: ${em(1)} solid
        ${componentColor(dls.toggleButton.border.inputQuiet.normalOff)};
      svg {
        fill: ${componentColor(dls.toggleButton.icon.inputQuiet.normalOff)};
      }
      border: 0;
      &:focus {
        color: ${componentColor(dls.toggleButton.text.inputQuiet.focusOn)};
        background-color: ${componentColor(
          dls.toggleButton.background.inputQuiet.focusOn,
        )};
        border: ${em(1)} solid
          ${componentColor(dls.toggleButton.border.inputQuiet.focusOn)};
        outline: ${em(3)} solid
          ${componentColor(
            dls.toggleButton.focusOutline.inputQuiet.focusPressed,
          )};
        outline-offset: ${em(1)};
        svg {
          fill: ${componentColor(dls.toggleButton.icon.inputQuiet.focusOn)};
        }
      }

      &:active {
        color: ${componentColor(dls.toggleButton.text.inputQuiet.pressedOn)};
        background-color: ${componentColor(
          dls.toggleButton.background.inputQuiet.pressedOn,
        )};
        border: ${em(1)} solid
          ${componentColor(dls.toggleButton.border.inputQuiet.pressedOn)};
        outline: ${em(3)} solid
          ${componentColor(
            dls.toggleButton.focusOutline.inputQuiet.focusPressed,
          )};
        outline-offset: ${em(1)};
        svg {
          fill: ${componentColor(dls.toggleButton.icon.inputQuiet.pressedOn)};
        }
      }

      &:hover {
        color: ${componentColor(dls.toggleButton.text.inputQuiet.hoverOn)};
        background-color: ${componentColor(
          dls.toggleButton.background.inputQuiet.hoverOn,
        )};
        border: ${em(1)} solid
          ${componentColor(dls.toggleButton.border.inputQuiet.hoverOn)};
        svg {
          fill: ${componentColor(dls.toggleButton.icon.inputQuiet.hoverOn)};
        }
      }
      &.Mui-selected {
        color: ${componentColor(dls.toggleButton.text.inputQuiet.normalOn)};
        background-color: ${componentColor(
          dls.toggleButton.background.inputQuiet.normalOn,
        )};
        border: ${em(1)} solid
          ${componentColor(dls.toggleButton.border.inputQuiet.normalOn)};
        svg {
          fill: ${componentColor(dls.toggleButton.icon.inputQuiet.normalOn)};
        }
        &:hover {
          color: ${componentColor(dls.toggleButton.text.inputQuiet.hoverOn)};
          background-color: ${componentColor(
            dls.toggleButton.background.inputQuiet.hoverOn,
          )};
          border: ${em(1)} solid
            ${componentColor(dls.toggleButton.border.inputQuiet.hoverOn)};
          svg {
            fill: ${componentColor(dls.toggleButton.icon.inputQuiet.hoverOn)};
          }
        }
      }

      &.Mui-disabled {
        color: ${componentColor(dls.toggleButton.text.inputQuiet.disabledOn)};
        background-color: ${componentColor(
          dls.toggleButton.background.inputQuiet.disabledOn,
        )};
        border: ${em(1)} solid
          ${componentColor(dls.toggleButton.border.inputQuiet.disabledOn)};
        svg {
          fill: ${componentColor(dls.toggleButton.icon.inputQuiet.disabledOn)};
        }
      }
    }
  }
`;

interface ToggleButtonGroupCustomProps extends ToggleButtonGroupProps {
  dls_variant?: 'quietDefault';
}
export const ToggleButtonGroup: FC<ToggleButtonGroupCustomProps> = styled(
  MuiToggleButtonGroup,
).attrs((props: ToggleButtonGroupCustomProps) => {
  return {
    className: props.dls_variant,
    ...props,
  } as ToggleButtonGroupCustomProps;
})`
  && {
    & .MuiToggleButtonGroup-groupedHorizontal:not(:first-child) {
      margin-left: unset;
    }
    & .MuiToggleButtonGroup-grouped {
      padding: 0 ${em(12)};
      border: ${em(1)} solid
        ${componentColor(
          dls.toggleButton.separator.secondary.normalOffHoverFocusPressed,
        )};
      position: relative;
      &:hover {
        border-left: ${em(1)} solid
          ${componentColor(dls.toggleButton.separator.secondary.hoverOn)};
      }
      &:focus {
        border-left: ${em(1)} solid
          ${componentColor(dls.toggleButton.separator.secondary.focusOn)};
      }
      &:active {
        border-left: ${em(1)} solid
          ${componentColor(dls.toggleButton.separator.secondary.pressedOn)};
      }
      &.Mui-selected {
        border-left: ${em(1)} solid
          ${componentColor(dls.toggleButton.separator.secondary.normalOn)};
      }
      &.Mui-disabled {
        border-left: ${em(1)} solid
          ${componentColor(dls.toggleButton.separator.secondary.disabledOn)};
      }
      .pt-dense & {
        padding: 0 ${em(8)};
      }
    }
    &.quietDefault {
      button {
        border: 0;
        color: ${componentColor(dls.toggleButton.text.inputQuiet.normalOff)};
        background-color: ${componentColor(
          dls.toggleButton.background.inputQuiet.normalOff,
        )};
        svg {
          fill: ${componentColor(dls.toggleButton.icon.inputQuiet.normalOff)};
        }
        &:focus {
          color: ${componentColor(dls.toggleButton.text.inputQuiet.focusOn)};
          background-color: ${componentColor(
            dls.toggleButton.background.inputQuiet.focusOn,
          )};
          border: ${em(1)} solid
            ${componentColor(dls.toggleButton.border.inputQuiet.focusOn)};
          outline: ${em(3)} solid
            ${componentColor(
              dls.toggleButton.focusOutline.inputQuiet.focusPressed,
            )};
          outline-offset: ${em(1)};
          svg {
            fill: ${componentColor(dls.toggleButton.icon.inputQuiet.focusOn)};
          }
        }

        &:active {
          color: ${componentColor(dls.toggleButton.text.inputQuiet.pressedOn)};
          background-color: ${componentColor(
            dls.toggleButton.background.inputQuiet.pressedOn,
          )};
          border: ${em(1)} solid
            ${componentColor(dls.toggleButton.border.inputQuiet.pressedOn)};
          outline: ${em(3)} solid
            ${componentColor(
              dls.toggleButton.focusOutline.inputQuiet.focusPressed,
            )};
          outline-offset: ${em(1)};
          svg {
            fill: ${componentColor(dls.toggleButton.icon.inputQuiet.pressedOn)};
          }
        }

        &:hover {
          color: ${componentColor(dls.toggleButton.text.inputQuiet.hoverOn)};
          background-color: ${componentColor(
            dls.toggleButton.background.inputQuiet.hoverOn,
          )};
          border: ${em(1)} solid
            ${componentColor(dls.toggleButton.border.inputQuiet.hoverOn)};
          svg {
            fill: ${componentColor(dls.toggleButton.icon.inputQuiet.hoverOn)};
          }
        }
        &.Mui-selected {
          color: ${componentColor(dls.toggleButton.text.inputQuiet.normalOn)};
          background-color: ${componentColor(
            dls.toggleButton.background.inputQuiet.normalOn,
          )};
          border: ${em(1)} solid
            ${componentColor(dls.toggleButton.border.inputQuiet.normalOn)};
          svg {
            fill: ${componentColor(dls.toggleButton.icon.inputQuiet.normalOn)};
          }
          &:hover {
            color: ${componentColor(dls.toggleButton.text.inputQuiet.hoverOn)};
            background-color: ${componentColor(
              dls.toggleButton.background.inputQuiet.hoverOn,
            )};
            border: ${em(1)} solid
              ${componentColor(dls.toggleButton.border.inputQuiet.hoverOn)};
            svg {
              fill: ${componentColor(dls.toggleButton.icon.inputQuiet.hoverOn)};
            }
          }
        }

        &.Mui-disabled {
          color: ${componentColor(dls.toggleButton.text.inputQuiet.disabledOn)};
          background-color: ${componentColor(
            dls.toggleButton.background.inputQuiet.disabledOn,
          )};
          border: ${em(1)} solid
            ${componentColor(dls.toggleButton.border.inputQuiet.disabledOn)};
          svg {
            fill: ${componentColor(
              dls.toggleButton.icon.inputQuiet.disabledOn,
            )};
          }
        }
      }
    }
  }
`;

export const StyledCard: FC<CardProps> = styled(Card).attrs((props) => {
  return {
    ...props,
  } as CardProps;
})`
  && {
    display: flex;
    justify-content: center;
  }
`;
