import React, { FC } from 'react';
import { BaseComponent } from '@dls/react-beta-base-component';

export type AvatarProps = any;
const Avatar: FC<any> = ({
  children,
  src,
  alt,
  borderRadius = '50%',
  ...other
}) => {
  const tag = src ? 'img' : 'div';

  return (
    <BaseComponent
      src={src}
      alt={src && alt}
      as={tag}
      style={{ borderRadius }}
      {...other}>
      {children}
    </BaseComponent>
  );
};

export default Avatar;
