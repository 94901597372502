import React, { FunctionComponent, SVGProps } from 'react';

const ThreeDotsVertical = (props) => (
  <svg viewBox="0 0 12 24" width="1em" height="1em" {...props}>
    <circle cx={6} cy={6} r={2} />
    <circle cx={6} cy={12} r={2} />
    <circle cx={6} cy={18} r={2} />
  </svg>
);

export default ThreeDotsVertical;
