import React, { FunctionComponent, SVGProps } from 'react';

const MRscanWise32 = (props) => (
  <svg viewBox="0 0 32 32" width="1em" height="1em" {...props}>
    <path
      fill="#fff"
      d="M30 16c0 7.732-6.268 14-14 14S2 23.732 2 16 8.268 2 16 2s14 6.268 14 14z"
    />
  </svg>
);

export default MRscanWise32;
