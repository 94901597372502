import React, { useState, useCallback, FC } from 'react';
import PropTypes from 'prop-types';
import { ifProp } from 'styled-tools';
import componentColor, { dls } from '@dls/react-colors';
import styled, { css } from 'styled-components';
import { BaseButton } from '@dls/react-beta-base-button';
import { capitalizeCss } from '@dls/react-beta-utils';

export type ToggleButtonProps = any;

const getButtonStyle = (property, buttonType, state, pressed) => {
  state = pressed ? `${state}On` : `${state}Off`;
  return componentColor(
    dls.toggleButton[property][capitalizeCss(buttonType)][state],
  );
};
const getButtonBackground = ({ color: buttonType, pressed }, state) => {
  return css`
    background-color: ${getButtonStyle(
      'background',
      buttonType,
      state,
      pressed,
    )};
  `;
};
const getButtonText = ({ color: buttonType, pressed }, state) => {
  return css`
    color: ${getButtonStyle('text', buttonType, state, pressed)};
  `;
};
const getBorderColor = ({ color: buttonType, pressed }, state) => {
  return css`
    border: 1px solid ${getButtonStyle('border', buttonType, state, pressed)};
  `;
};
const getIconColor = ({ color: buttonType, pressed }, state) => {
  return css`
    color: ${getButtonStyle('icon', buttonType, state, pressed)};
  `;
};
const getIndicatorColor = (state, pressed) => {
  state = state = pressed ? `${state}On` : `${state}Off`;
  return css`
    background: ${componentColor(
      dls.toggleButton.ledIndicator.secondary[state],
    )};
  `;
};

const StyledToggleButton = styled(BaseButton)`
  position: relative;
  cursor: pointer;
  ${(props) => css`
    ${getButtonBackground(props, 'normal')}
    ${getButtonText(props, 'normal')}
    .icon-font {
      ${getIconColor(props, 'normal')}
      &:hover {
        ${getIconColor(props, 'hover')}
      }
      &:focus {
        ${getIconColor(props, 'focus')}
      }
    }
    &:hover {
      ${getButtonBackground(props, 'hover')}
      ${getButtonText(props, 'hover')}
    }
    &:focus {
      ${getButtonBackground(props, 'focus')}
      ${getButtonText(props, 'focus')}
    }
    ${ifProp(
      'pressed',
      css`
        ${getButtonBackground(props, 'pressed')}
        ${getButtonText(props, 'pressed')}
        .icon-font {
          ${getIconColor(props, 'pressed')}
        }
      `,
    )}
    ${ifProp(
      'border',
      css`
        ${getBorderColor(props, 'normal')}
      `,
    )}
    ${ifProp(
      'disabled',
      css`
        ${getButtonBackground(props, 'disabled')}
        ${getButtonText(props, 'disabled')}
        pointer-events: none;
        .icon-font {
          ${getIconColor(props, 'disabled')}
        }
        &:hover {
          ${getButtonBackground(props, 'disabled')}
          ${getButtonText(props, 'disabled')}
        }
      `,
    )}
    ${props.color === 'secondary' &&
    ifProp(
      'indicator',
      css`
        position: relative;
        &::after {
          content: '';
          position: absolute;
          right: 4px;
          bottom: 4px;
          width: 6px;
          height: 6px;
          ${getIndicatorColor('normal', props.pressed)}
          border-radius: 50%;
        }
        &:hover {
          &::after {
            ${getIndicatorColor('hover', props.pressed)}
          }
        }
        &:focus {
          &::after {
            ${getIndicatorColor('focus', props.pressed)}
          }
        }
        ${ifProp(
          'pressed',
          css`
            &::after {
              ${getIndicatorColor('pressed', props.pressed)}
            }
          `,
        )}
        ${ifProp(
          'disabled',
          css`
            &::after {
              ${getIndicatorColor('disabled', props.pressed)}
            }
          `,
        )}
      `,
    )}
  `}
`;

const ToggleButton: FC<ToggleButtonProps> = (props) => {
  const { onClick, value, pressed: pressedProp, ...other } = props;
  const [pressed, setPressed] = useState(pressedProp);
  const pressedValue = pressedProp !== undefined ? pressedProp : pressed;
  const handleClick = useCallback(
    (e) => {
      if (pressedProp === undefined) {
        setPressed(!pressed);
      }
      if (onClick) {
        onClick({ e, value, pressed });
      }
    },
    [pressedProp, onClick, pressed, value],
  );
  return (
    <StyledToggleButton
      className={pressedValue ? 'toggle-button pressed' : 'toggle-button'}
      data-value={value}
      pressed={pressedValue}
      onClick={handleClick}
      {...other}
    />
  );
};

ToggleButton.propTypes = {
  children: PropTypes.node,
  color: PropTypes.oneOf(['input-quiet', 'secondary']),
  indicator: PropTypes.bool,
  onClick: PropTypes.func,
  pressed: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

ToggleButton.defaultProps = {
  color: 'input-quiet',
  density: 'regular',
  tabIndex: 0,
  borderRadius: '2px',
};

export default React.memo(ToggleButton);
