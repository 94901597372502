import MuiRating, { RatingProps } from '@material-ui/lab/Rating';
import styled from 'styled-components';
import componentColor, { dls } from '@dls/react-colors';
import { Label } from '@dls/react-mui-typography';
import { em, svgScaling } from '@dls/react-theme';

export const RatingBar = styled(MuiRating).attrs((props: RatingProps) => {
  return {
    ...props,
  } as RatingProps;
})`
  && {
    &.MuiRating-root {
      font-size: unset;

      &.MuiRating-sizeLarge {
        font-size: unset;

        > label {
          &:not(:nth-last-child(2)) {
            margin-inline-end: ${em(8)};
          }
        }

        > span {
          &:not(:last-child) {
            margin-inline-end: ${em(8)};
          }
        }
      }

      & .MuiRating-label {
        color: ${componentColor(dls.ratingBar.icon.default.normalOn)};

        & .MuiRating-icon {
          ${svgScaling(em(16))};
        }

        & .MuiRating-icon.MuiRating-iconEmpty {
          color: ${componentColor(dls.ratingBar.icon.default.normalOff)};
        }

        & .MuiRating-iconActive {
          transform: scale(1);
        }

        & .MuiRating-iconHover {
          color: ${componentColor(dls.ratingBar.icon.default.hoverOn)};
        }
      }

      /** half rating styles*/
      & .MuiRating-decimal {
        height: ${em(16)};

        & .MuiRating-label .MuiRating-icon.MuiRating-iconFilled {
          span {
            flex-shrink: 0;
          }
        }
      }
      &.MuiRating-sizeLarge .MuiRating-decimal {
        height: ${em(32)};
        & .MuiRating-icon {
          ${svgScaling(em(32))};
        }
      }
      &.MuiRating-sizeLarge .MuiRating-label {
        height: ${em(32)};
        & .MuiRating-icon {
          ${svgScaling(em(32))};
        }
      }
      /**===========================*/

      /** ReadOnly Rating/ Display Rating */
      &.MuiRating-readOnly {
        > span {
          &:not(:last-child) {
            margin-inline-end: ${em(4)};
          }
        }

        height: ${em(16)};
        color: ${componentColor(dls.ratingBar.icon.default.normalOn)};
        span {
          & .MuiRating-icon {
            ${svgScaling(em(16))};
          }

          & .MuiRating-icon.MuiRating-iconEmpty {
            color: ${componentColor(dls.ratingBar.icon.default.normalOff)};
          }
        }

        /** Half style rating */
        & .MuiRating-decimal {
          span {
            & .MuiRating-iconFilled {
              span {
                flex-shrink: 0;
              }
            }
          }
        }
      }
      /**===========================*/
    }
  }
`;

export const RatingBarContent = styled.div`
  display: flex;
  align-items: center;

  /** children */
  > * {
    &:not(:last-child) {
      margin-inline-end: ${em(8)};
    }
  }
`;

/**
 * Rating Bar Input Container Props(Contain RatingBar and Button)
 */
export interface IRatingBarInputContent {
  width?: number;
  margingBottom?: number;
}

export const RatingBarInputContent = styled.div<IRatingBarInputContent>`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: ${(props: IRatingBarInputContent) =>
    props.width ? em(props.width) : em(200)};
  /** children */
  > * {
    &:not(:last-child) {
      margin-bottom: ${(props: IRatingBarInputContent) =>
        props.margingBottom ? em(props.margingBottom) : em(16)};
    }
  }
`;

export const RatingLabel: React.FC<any> = styled(Label)`
  &.MuiTypography-root {
    color: ${componentColor(dls.ratingBar.text.default.normalOn)} !important;
    line-height: ${em(16)} !important;
  }

  &.MuiTypography-root.disabled {
    color: ${componentColor(dls.ratingBar.text.default.normalOff)} !important;
    line-height: ${em(16)} !important;
  }
`;
