import getComponentColor, { dls } from '@dls/react-colors';
import MuiTooltip, { TooltipProps } from '@material-ui/core/Tooltip';
import { em } from '@dls/react-theme';
import styled from 'styled-components';
import React, { FC } from 'react';

export const Tooltip: FC<TooltipProps> = styled((props: TooltipProps) => (
  <MuiTooltip
    classes={{ popper: props.className, tooltip: props.className }}
    {...props}
  />
))`
  &.MuiTooltip-tooltip {
    background-color: ${getComponentColor(dls.tooltip.background.dark.normal)};
    color: ${getComponentColor(dls.tooltip.text.dark.normal)};
    border: ${em(1, 12)} solid
      ${getComponentColor(dls.tooltip.border.dark.normal)};
    box-shadow: 0 ${em(2, 12)} ${em(2, 12)}
      ${getComponentColor(dls.shadow.shadow.levelTwo.normal)};
    padding: ${em(7, 12)} ${em(12, 12)};
    font-size: ${em(12)};
    min-width: ${em(40, 12)};
    max-width: ${em(400, 12)};
    line-height: 1;
    font-family: CentraleSans, Tahoma, Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
    border-radius: ${em(2)};
    & .MuiTooltip-arrow {
      color: ${getComponentColor(dls.tooltip.background.dark.normal)};
    }
    &.Light-Tooltip {
      background-color: ${getComponentColor(
        dls.tooltip.background.light.normal,
      )};
      color: ${getComponentColor(dls.tooltip.text.light.normal)};
      border: ${em(1, 12)} solid
        ${getComponentColor(dls.tooltip.border.light.normal)};
      box-shadow: 0 ${em(2, 12)} ${em(2, 12)}
        ${getComponentColor(dls.shadow.shadow.levelTwo.normal)};
      & .MuiTooltip-arrow {
        color: ${getComponentColor(dls.tooltip.background.light.normal)};
        &:before {
          color: ${getComponentColor(dls.tooltip.background.light.normal)};
        }
      }
    }
  }
  & .MuiTooltip-tooltipPlacementBottom {
    margin: ${em(12, 12)} 0 0 0;
  }
  & .MuiTooltip-tooltipPlacementLeft {
    margin: 0 ${em(12, 12)} 0 0;
  }
  & .MuiTooltip-tooltipPlacementTop {
    margin: 0 0 ${em(12, 12)} 0;
  }
  & .MuiTooltip-tooltipPlacementRight {
    margin: 0 0 0 ${em(12, 12)};
  }
`;
