import componentColor, { dls } from '@dls/react-colors';
import styled from 'styled-components';
import MuiAutocomplete, {
  AutocompleteProps,
} from '@material-ui/lab/Autocomplete';
import { em, svgScaling, touchStyles } from '@dls/react-theme';

/** Autocomplete with its content */
export const Autocomplete = styled(MuiAutocomplete).attrs(
  (props: AutocompleteProps<string, true, true, true>) => {
    return {
      freeSolo: true,
      ...props,
    } as AutocompleteProps<string, true, true, true>;
  },
)`
  && {
    &.MuiAutocomplete-root {
      & .MuiInput-underline:after {
        border-bottom: none;
      }
      & .MuiInput-underline:before {
        border-bottom: none;
      }
      & .MuiInput-underline:hover:not(.Mui-disabled):before {
        border-bottom: none;
      }
      &.DLS-Autocomplete {
        width: ${em(200)};
        & .MuiAutocomplete-inputRoot {
          &[class*='MuiInput-root'] {
            width: ${em(240)};
          }
          border: ${em(1)} solid
            ${componentColor(dls.textBox.border.default.normal)};
          background-color: ${componentColor(
            dls.textBox.background.default.normal,
          )};
          color: ${componentColor(dls.textBox.text.default.normal)};
          min-height: ${em(40)};
          padding: 0 ${em(8)} 0 ${em(12)};
          width: ${em(200)};
          box-sizing: border-box;
          border-radius: ${em(2)};

          /** Font Styles start here */
          font-size: ${em(16)};
          /* stylelint-disable */
          font-family: CentraleSans, Tahoma, Arial, Helvetica, sans-serif;
          /* stylelint-enable */
          font-weight: normal;
          font-style: normal;

          .pt-dense & {
            height: ${em(32)};
            min-height: ${em(32)};
            padding: 0 ${em(8, 16)} 0 ${em(8, 16)};
          }
          .pt-touch & {
            ${touchStyles(em(5))};
          }
          & .MuiAutocomplete-input {
            &::placeholder {
              color: ${componentColor(dls.textBox.hintText.default.normal)};
              font-style: italic;
            }
            padding: ${em(0)};
            &:first-child {
              padding: 0 0 0 0;
            }
            .pt-dense & {
              padding: 0 0 0 0;
            }
          }
          &:active {
            background-color: ${componentColor(
              dls.textBox.background.default.pressed,
            )};
            border-color: ${componentColor(dls.textBox.border.default.pressed)};
          }
          &:hover {
            background-color: ${componentColor(
              dls.textBox.background.default.hover,
            )};
            border-color: ${componentColor(dls.textBox.border.default.hover)};
          }
          &.Mui-focused {
            background-color: ${componentColor(
              dls.textBox.background.default.focus,
            )};
            border-color: ${componentColor(dls.textBox.border.default.focus)};
          }

          /** Disabled styles start here*/
          &.Mui-disabled {
            background-color: ${componentColor(
              dls.textBox.background.default.disabled,
            )};
            border-color: ${componentColor(
              dls.textBox.border.default.disabled,
            )};
            color: ${componentColor(dls.textBox.text.default.disabled)};
            & .MuiInputAdornment-root {
              &.MuiInputAdornment-positionEnd {
                & span:nth-child(1) {
                  cursor: default;
                  color: ${componentColor(
                    dls.searchBox.iconButton.default.disabled,
                  )};
                  & svg {
                    & path {
                      fill: ${componentColor(
                        dls.searchBox.iconButton.default.disabled,
                      )};
                    }
                  }
                }
              }
            }
          }
          & .MuiInputAdornment-root {
            &.MuiInputAdornment-positionEnd {
              margin-right: ${em(0)};
              margin-left: ${em(0)};
              & .MuiIconButton-root {
                font-size: ${em(24)};
                color: ${componentColor(dls.textBox.icon.default.normal)};
                padding: 0;

                &:hover {
                  background: transparent;
                }

                & .MuiTouchRipple-root {
                  display: none;
                }

                & .MuiIconButton-label {
                  color: ${componentColor(
                    dls.textBox.showHideIcon.default.normal,
                  )};
                  /** Scaling icon*/
                  span {
                    ${svgScaling(em(16))};
                    & svg {
                      top: 0;
                      left: 0;
                    }
                  }
                }
              }
              /* Fix me by creating a style for Search Divider in Divider Component */
              & .MuiDivider-root {
                height: ${em(24)};
                background: ${componentColor(
                  dls.textBox.separator.default.normal,
                )};
                .pt-dense & {
                  margin: 0 ${em(8)} 0 ${em(8)};
                }
              }
            }
          }
        }
      }
    }
  }
`;
