import styled from 'styled-components';
import componentColor, { dls } from '@dls/react-colors';

import { ThemeProviderProps } from '@dls/react-beta-utils';
import { elevation, padding } from '@dls/react-beta-utils';
import { ContentProps, ContentVariants } from './types';

const getBackgroundColor = () => ({
  variant,
  theme,
}: ContentProps & ThemeProviderProps): string => {
  return componentColor(dls.content.background[variant || 'primary'].normal)({
    theme,
  });
};

const mapVariantToBrushName = (variant?: ContentVariants): string => {
  switch (variant) {
    case 'tertiary':
    case 'tertiaryNeutral':
      return 'tertiary';

    case 'secondary':
    case 'secondaryNeutral':
      return 'secondary';

    case 'primary':
    default:
      return 'primary';
  }
};

const getContentColor = () => ({
  variant,
  disabled,
  theme,
}: ContentProps & ThemeProviderProps): string => {
  const brushType = disabled ? 'disabled' : 'normal';
  const brushName = mapVariantToBrushName(variant);

  return componentColor(dls.contentItem.text[brushName][brushType])({
    theme,
  });
};

export const Content = styled.div<ContentProps>`
  background-color: ${getBackgroundColor()};
  color: ${getContentColor()};
  padding: ${padding()};
  ${elevation()}
`;
