import { SelectOption } from './types';
import { NoOptionSelected, NO_OPTION_SELECTED } from './NoOptionSelected';

export const findSelectedOption = <Value extends string>(
  options: SelectOption<Value>[],
  value: Value | NoOptionSelected,
) => {
  if (value === NO_OPTION_SELECTED) {
    return NO_OPTION_SELECTED;
  }

  return options.find((option) => option.value === value) || NO_OPTION_SELECTED;
};
