import React, { FC } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { ifProp } from 'styled-tools';
import componentColor, { dls } from '@dls/react-colors';
import { capitalizeCss, toPx, shadow } from '@dls/react-beta-utils';

export type BaseComponentProps = any;

const getStyle = (cssPropName, useCssUnit = false) => {
  const propName = capitalizeCss(cssPropName);
  return css`
    ${(props) =>
      `${cssPropName}: ${
        useCssUnit ? toPx(props[propName]) : props[propName]
      };`}
  `;
};

const getLayout = (layout) => {
  if (layout === 'horizontal-flex' || layout === 'hflex') {
    return css`
      display: flex;
      flex-direction: row;
    `;
  }
  return css`
    display: flex;
    flex-direction: column;
  `;
};

const StyledBaseComponent = styled.div<BaseComponentProps>`
  ${ifProp(
    'elevation',
    css<BaseComponentProps>`
      ${(props) => shadow[`shadowLevel${props.elevation}`]};
    `,
  )}
  ${ifProp(
    'flexWrap',
    css<BaseComponentProps>`
      flex-wrap: ${(props) => props.flexWrap};
    `,
  )}
  ${ifProp(
    'hidden',
    css<BaseComponentProps>`
      display: none;
    `,
    ifProp(
      'layout',
      (props) => getLayout(props.layout),
      ifProp('display', getStyle('display')),
    ),
  )}
  ${ifProp(
    'border',
    css`
      border: 1px solid
        ${componentColor(dls.separator.background.content.normal)};
      ${ifProp(
        'borderWidth',
        css<BaseComponentProps>`
          border-width: ${(props) => toPx(props.borderWidth)};
        `,
      )}
    `,
  )}
  ${ifProp('alignItems', getStyle('align-items'))}
  ${ifProp('justifyContent', getStyle('justify-content'))}
  ${ifProp('overflow', getStyle('overflow'))}
  ${ifProp('margin', getStyle('margin', true))}
  ${ifProp('padding', getStyle('padding', true))}
  ${ifProp('width', getStyle('width', true))}
  ${ifProp('height', getStyle('height', true))}
  ${ifProp('minWidth', getStyle('min-width', true))}
  ${ifProp('minHeight', getStyle('min-height', true))}
  ${ifProp('maxWidth', getStyle('max-width', true))}
  ${ifProp('maxHeight', getStyle('max-height', true))}
  ${ifProp('borderRadius', getStyle('border-radius', true))}
  ${ifProp('flex', getStyle('flex'))}
  transition: box-shadow linear 130ms, background-color linear 130ms, color linear 130ms;
`;

export const BaseComponent: FC<BaseComponentProps> = (props) => (
  <StyledBaseComponent {...props} />
);

const alignOptions = [
  'baseline',
  'center',
  'flex-end',
  'flex-start',
  'normal',
  'stretch',
  'unset',
];

const cssSizes = PropTypes.oneOfType([
  PropTypes.array,
  PropTypes.number,
  PropTypes.oneOf(['xxs', 'xs', 's', 'm', 'l', 'xl', 'xxl', false]),
  PropTypes.string,
]);

StyledBaseComponent.propTypes = {
  alignItems: PropTypes.oneOf(alignOptions),
  border: PropTypes.bool,
  borderRadius: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.number,
    PropTypes.string,
  ]),
  borderWidth: cssSizes,
  children: PropTypes.node,
  display: PropTypes.oneOf([
    'block',
    'flex',
    'initial',
    'inline-block',
    'inline-flex',
    'inline',
    'none',
  ]),
  elevation: PropTypes.oneOf([0, 1, 2, 3]),
  flex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  flexWrap: PropTypes.oneOf(['nowrap', 'wrap', 'wrap-reverse']),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hidden: PropTypes.bool,
  justifyContent: PropTypes.oneOf(alignOptions),
  layout: PropTypes.oneOf([
    'hflex',
    'horizontal-flex',
    'vertical-flex',
    'vflex',
  ]),
  margin: cssSizes,
  maxHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  minHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  minWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  overflow: PropTypes.oneOf(['visible', 'hidden', 'clip', 'scroll', 'auto']),
  padding: cssSizes,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

BaseComponent.defaultProps = {
  hidden: false,
  padding: false,
};
