import React, { FunctionComponent, SVGProps } from 'react';

const NumberBox = (props) => (
  <svg viewBox="0 0 48 48" width="1em" height="1em" {...props}>
    <path
      fill="#1d1d1b"
      d="M18.666 19.07L17 16l6-4h5v24h-6.002V17.88l-.068.12-3.264 1.07zM46 4v40H2V4h44zm-4 4H6v32h36V8z"
    />
  </svg>
);

export default NumberBox;
