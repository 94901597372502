import getComponentColor, { dls } from '@dls/react-colors';
import MuiCircularProgress, {
  CircularProgressProps,
} from '@material-ui/core/CircularProgress';
import { FC } from 'react';
import styled from 'styled-components';
import { em } from '@dls/react-theme';

export type dls_layer = 'top' | 'bottom';
export type dls_size = 'small' | 'medium' | 'large' | undefined;
export type ProgressIndicatorColorTypes = 'safe' | 'warning' | 'danger';

export interface CircularCustomProgress extends CircularProgressProps {
  dls_layer?: dls_layer;
  dls_size?: dls_size;
  color_variant?: ProgressIndicatorColorTypes;
}

function getThickness(size: string | undefined) {
  let thicknessNsize = [3, 48];
  switch (size) {
    case 'small':
      thicknessNsize = [2, 24];
      break;
    case 'medium':
      thicknessNsize = [3, 48];
      break;
    case 'large':
      thicknessNsize = [4, 88];
      break;
    default:
      thicknessNsize = [3, 48];
  }
  return thicknessNsize;
}

function getAnimationDuration(size: string | undefined) {
  let duration = '1.25s';
  switch (size) {
    case 'small':
      duration = '1s';
      break;
    case 'medium':
      duration = '1.25s';
      break;
    case 'large':
      duration = '1.5s';
      break;
    default:
      duration = '1.25s';
  }
  return duration;
}

export const CircularProgress: FC<CircularCustomProgress> = styled(
  MuiCircularProgress,
).attrs((props: CircularCustomProgress) => {
  return {
    className: props.dls_layer + ' ' + props.color_variant,
    thickness: getThickness(props.dls_size)[0],
    size: getThickness(props.dls_size)[1],
    ...props,
  } as CircularCustomProgress;
})`
  && {
    &.top {
      color: ${getComponentColor(dls.track.background.detail.normalOn)};
      animation-duration: ${(props) => getAnimationDuration(props.dls_size)};

      height: ${(props) => em(getThickness(props.dls_size)[1])} !important;
      width: ${(props) => em(getThickness(props.dls_size)[1])} !important;
      margin-bottom: ${(props: CircularCustomProgress) =>
        props.dls_size === 'large' ? em(6) : em(2)};
      /** Signal Color Styles */
      &.safe {
        color: ${getComponentColor(dls.track.background.success.normalOn)};
      }

      &.warning {
        color: ${getComponentColor(dls.track.background.warning.normalOn)};
      }

      &.danger {
        color: ${getComponentColor(dls.track.background.danger.normalOn)};
      }
    }

    &.bottom {
      color: ${getComponentColor(dls.track.background.detail.normalOff)};
      position: absolute;

      height: ${(props) => em(getThickness(props.dls_size)[1])} !important;
      width: ${(props) => em(getThickness(props.dls_size)[1])} !important;
    }
  }
`;

export const StyledBox = styled.div`
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const LabelContainer = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  width: fit-content;
`;
