import React, { FunctionComponent, SVGProps } from 'react';

const ViewPlaneCoronal = (props) => (
  <svg viewBox="0 0 48 48" width="1em" height="1em" {...props}>
    <path
      fill="#231f20"
      d="M4 14l19.5 6.462v26L4 40V14zm21.997-9L7.884 11.073l19.508 6.465.108.036v25.929l18-5.965v-26L25.997 5z"
    />
  </svg>
);

export default ViewPlaneCoronal;
