import React, { FunctionComponent, SVGProps } from 'react';

const Star = (props) => (
  <svg viewBox="0 0 24 24" width="1em" height="1em" {...props}>
    <path
      fillRule="evenodd"
      d="M16.031 13.721L20 9.812l-5.509-.83L12 4 9.509 8.982 4 9.812l3.969 3.909-.914 5.495L12 16.65l4.944 2.566z"
    />
  </svg>
);

export default Star;
