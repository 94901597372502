import styled from 'styled-components';
import MuiLinearProgress, {
  LinearProgressProps as MuiLinearProgressProps,
} from '@material-ui/core/LinearProgress';
import componentColor, { dls } from '@dls/react-colors';
import { em } from '@dls/react-theme';
import clsx from 'clsx';

/** ProgressIndicator */
export type ProgressIndicatorExtraColorTypes = 'safe' | 'warning' | 'danger';

interface LinearProgressProps extends MuiLinearProgressProps {
  color_variant: ProgressIndicatorExtraColorTypes;
}

export const LinearProgress = styled(MuiLinearProgress).attrs(
  (props: LinearProgressProps) => {
    return {
      ...props,
      className: clsx(props.color_variant),
    } as LinearProgressProps;
  },
)`
  && {
    &.MuiLinearProgress-root {
      height: ${em(4)};
      min-width: ${em(96)};
      width: 100%;
      margin-top: ${em(16)};
      margin-bottom: ${em(16)};
      &.MuiLinearProgress-colorPrimary {
        background-color: ${componentColor(
          dls.track.background.detail.normalOff,
        )};
      }

      & .MuiLinearProgress-bar {
        &.MuiLinearProgress-barColorPrimary {
          background-color: ${componentColor(
            dls.track.background.detail.normalOn,
          )};
        }
      }
      /** Signal Color Styles */
      &.safe {
        & .MuiLinearProgress-bar {
          &.MuiLinearProgress-barColorPrimary {
            background-color: ${componentColor(
              dls.track.background.success.normalOn,
            )};
          }
        }
      }

      &.warning {
        & .MuiLinearProgress-bar {
          &.MuiLinearProgress-barColorPrimary {
            background-color: ${componentColor(
              dls.track.background.warning.normalOn,
            )};
          }
        }
      }

      &.danger {
        & .MuiLinearProgress-bar {
          &.MuiLinearProgress-barColorPrimary {
            background-color: ${componentColor(
              dls.track.background.danger.normalOn,
            )};
          }
        }
      }

      & .MuiLinearProgress-dashed.MuiLinearProgress-dashedColorPrimary {
        background-size: unset;
        background-image: unset;
        background-position: unset;
      }

      &
        .MuiLinearProgress-bar.MuiLinearProgress-colorPrimary.MuiLinearProgress-bar2Buffer {
        background-color: ${componentColor(dls.track.background.detail.buffer)};
      }

      /** Indeterminate Styles */
      & .MuiLinearProgress-bar.MuiLinearProgress-bar1Indeterminate {
        width: 40%;
      }
    }
  }
`;
