import componentColor, { dls } from '@dls/react-colors';
import { em } from '@dls/react-theme';
import styled, { css } from 'styled-components';

export type AppBarItemProps = {
  active?: boolean;
};

export const hover = '&:hover';
export const pressed = '&:active';
export const focus = '&:focus';
export const validated = '.validated';
export const disabled =
  '&[disabled],&[aria-disabled="true"],&.disabled,[disabled] &,[aria-disabled="true"] &,.disabled &';

export const AppBarItem = styled.a<AppBarItemProps>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  padding: 0 ${em(24)};
  border: 0;
  outline: 0;
  cursor: pointer;

  ${(props) => {
    const normalOnOff = props.active ? 'normalOn' : 'normalOff';
    return css`
      background-color: ${componentColor(
        dls.navigationItem.background.default[normalOnOff],
      )};
      color: ${componentColor(dls.navigationItem.text.default[normalOnOff])};
    `;
  }}

  ${hover} {
    color: ${componentColor(dls.navigationItem.text.default.hover)};
  }

  ${focus} {
    color: ${componentColor(dls.navigationItem.text.default.focus)};
  }

  ${pressed} {
    color: ${componentColor(dls.navigationItem.text.default.pressed)};
  }

  ${disabled} {
    color: ${componentColor(dls.navigationItem.text.default.disabled)};
  }
`;
