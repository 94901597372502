import React, { FunctionComponent, SVGProps } from 'react';

const ProgrammingLogicTool = (props) => (
  <svg viewBox="0 0 48 48" width="1em" height="1em" {...props}>
    <path
      fill="#231f20"
      d="M16.142 15.828l-8.485 8.485 8.485 8.485-2.828 2.828L2 24.314 13.314 13l2.828 2.828zm.076 28.846l3.856 1.064L31.782 3.326l-3.856-1.065-11.708 42.413zM34.828 13L32 15.828l8.485 8.485L32 32.799l2.828 2.828 11.314-11.314L34.828 13z"
    />
  </svg>
);

export default ProgrammingLogicTool;
