import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import componentColor, { dls } from '@dls/react-colors';

import { Icon } from '@dls/react-icon';
import { density } from '@dls/react-beta-utils';
import { CheckboxProps } from './types';

type FocusSvgProps = {
  className?: string;
};
// Draws a 28 x 28 rounded rectangle (4px border radius) with a 22 x 22 rounded rectangle (2px border radius) cut out of the center
const FocusSvg: FunctionComponent<FocusSvgProps> = ({ className }) => (
  <svg width={28} height={28} viewBox="0 0 28 28" className={className}>
    <path
      d="M24 0a4 4 0 014 4v20a4 4 0 01-4 4H4a4 4 0 01-4-4V4a4 4 0 014-4h20zm-1 3H5a2 2 0 00-2 2v18a2 2 0 002 2h18a2 2 0 002-2V5a2 2 0 00-2-2z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);

const FocusOutline = styled(FocusSvg)`
  position: absolute;
  top: -4px;
  left: -4px;
  transition: all 0.15s ease-in-out;
  opacity: 0;
  color: ${componentColor(dls.radioButton.focusOutline.default.focusPressed)};

  input:focus ~ & {
    opacity: 1;
  }
`;

const CheckmarkIcon = styled(Icon).attrs({
  name: 'Checkmark',
})`
  position: absolute;
  width: 20px;
  height: 20px;
  transform: scale(0);
  line-height: 0;
`;

const BoxWrapper = styled.span`
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
`;

type BoxProps = Pick<CheckboxProps, 'disabled'>;
const Box = styled.span<BoxProps>`
  display: inline-block;
  position: absolute;
  width: 20px;
  height: 20px;
  transition: background-color 0.15s;
  border-radius: 2px;
  outline: none;

  background-color: ${(props) =>
    componentColor(
      dls.checkBox.background.default[
        props.disabled ? 'disabled' : 'normalOff'
      ],
    )};
  color: ${(props) =>
    componentColor(
      dls.checkBox.icon.default[props.disabled ? 'disabled' : 'normalOff'],
    )};

  input:checked ~ & {
    background-color: ${(props) =>
      componentColor(
        dls.checkBox.background.default[
          props.disabled ? 'disabled' : 'normalOn'
        ],
      )};
    color: ${(props) =>
      componentColor(
        dls.checkBox.icon.default[props.disabled ? 'disabled' : 'normalOn'],
      )};
  }

  input:checked ~ & > ${CheckmarkIcon} {
    transform: scale(1);
    transition: all 0.15s;
  }

  vertical-align: middle;
`;

type ContainerProps = Pick<CheckboxProps, 'density' | 'disabled'>;
const Container = styled.label<ContainerProps>`
  display: inline-flex;
  align-items: center;
  height: ${density({ touch: 40, regular: 40, dense: 32 })};
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  pointer-events: ${(props) => (props.disabled ? 'none' : null)};

  &:active {
    ${FocusOutline} {
      opacity: 1;
    }
  }
`;

type TextLabelProps = Pick<CheckboxProps, 'disabled'>;
const TextLabel = styled.span<TextLabelProps>`
  margin-left: 0.75rem;
  color: ${(props) =>
    props.disabled
      ? componentColor(dls.label.text.regular.disabled)
      : componentColor(dls.label.text.regular.normal)};
`;

export const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  (props, ref) => {
    const {
      children,
      className,
      density,
      name,
      value,
      checked,
      defaultChecked,
      disabled,
      onChange,
      ...otherProps
    } = props;

    return (
      <Container className={className} disabled={disabled} density={density}>
        <BoxWrapper>
          <input
            type="checkbox"
            name={name}
            value={value}
            checked={checked}
            defaultChecked={defaultChecked}
            disabled={disabled}
            onChange={onChange}
            ref={ref}
            {...otherProps}
          />
          <Box disabled={disabled}>
            <CheckmarkIcon />
          </Box>
          <FocusOutline />
        </BoxWrapper>
        {children && <TextLabel disabled={disabled}>{children}</TextLabel>}
      </Container>
    );
  },
);
Checkbox.displayName = 'Checkbox';
