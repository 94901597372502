import componentColor, { dls } from '@dls/react-colors';
import styled, { css } from 'styled-components';
import {
  density,
  disabled,
  focus,
  hover,
  pressed,
  NO_STYLES,
} from '@dls/react-beta-utils';
import { InputProps } from './types';

export const Input = styled.input<InputProps>`
  flex: 1;
  border-width: 1px;
  border-style: solid;
  border-radius: 2px;
  outline: 0;
  font-size: 16px;
  padding: ${density({ regular: 10, dense: 4, touch: 8 })}
    ${density({ regular: 12, dense: 8, touch: 12 })};
  text-align: ${(props) => props.alignment};

  ${(props) =>
    props.hasIcon
      ? css`
          padding-right: 44px;
        `
      : NO_STYLES}

  background-color: ${componentColor(dls.textBox.background.default.normal)};
  border-color: ${componentColor(dls.textBox.border.default.normal)};
  color: ${componentColor(dls.textBox.text.default.normal)};

  &::placeholder {
    color: ${componentColor(dls.textBox.hintText.default.normal)};
  }

  ${hover} {
    background-color: ${componentColor(dls.textBox.background.default.hover)};
    border-color: ${componentColor(dls.textBox.border.default.hover)};
  }

  ${focus} {
    background-color: ${componentColor(dls.textBox.background.default.focus)};
    border-color: ${componentColor(dls.textBox.border.default.focus)};
    box-shadow: 0 0 4px 0 ${componentColor(dls.textBox.outline.default.pressed)};
    &::selection {
      background-color: ${componentColor(
        dls.textBox.textSelectionBackground.default.focus,
      )};
    }
  }

  ${pressed} {
    background-color: ${componentColor(dls.textBox.background.default.pressed)};
    border-color: ${componentColor(dls.textBox.border.default.pressed)};
    box-shadow: 0 0 4px 0 ${componentColor(dls.textBox.outline.default.pressed)};
  }

  ${(props) =>
    props.hasError
      ? css`
          && {
            background-color: ${componentColor(
              dls.textBox.background.default.validated,
            )};
            border-color: ${componentColor(
              dls.textBox.border.default.validated,
            )};
            color: ${componentColor(dls.textBox.text.default.validated)};
          }
        `
      : null}

  ${disabled} {
    background-color: ${componentColor(
      dls.textBox.background.default.disabled,
    )};
    border-color: ${componentColor(dls.textBox.border.default.disabled)};
    color: ${componentColor(dls.textBox.text.default.disabled)};
    &::placeholder {
      color: ${componentColor(dls.textBox.hintText.default.disabled)};
    }
  }
`;
