import React, { FunctionComponent, SVGProps } from 'react';

const ViewPlaneTransversal = (props) => (
  <svg viewBox="0 0 48 48" width="1em" height="1em" {...props}>
    <path
      fill="#231f20"
      d="M24.5 16L5 9.538 24.503 3 44 9.538 24.5 16zM5 13.751v24.786L24.5 45 44 38.538V13.752l-19.5 6.462L5 13.751z"
    />
  </svg>
);

export default ViewPlaneTransversal;
