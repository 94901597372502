import componentColor, { dls } from '@dls/react-colors';
import MuiList, { ListProps } from '@material-ui/core/List';
import { FC } from 'react';
import styled from 'styled-components';
import { em } from '@dls/react-theme';

type DlsVariants =
  | 'primary'
  | 'secondary'
  | 'secondary-neutral'
  | 'tertiary'
  | 'tertiary-neutral';

interface ListCustomProps extends ListProps {
  dls_variant?: DlsVariants;
}

export const List: FC<ListCustomProps> = styled(MuiList).attrs(
  (props: ListCustomProps) => {
    return {
      className: props.dls_variant,
    } as ListCustomProps;
  },
)`
  && {
    &.MuiList-root {
      border: unset;
      overflow-y: auto;
      /* header style region */
      & .header {
        color: ${componentColor(dls.listItem.header.default.normal)};
        padding: ${em(8)} ${em(16)};

        .pt-dense & {
          padding: ${em(4)} ${em(16)};
        }

        .pt-touch & {
          padding: ${em(12)} ${em(16)};
        }
      }
      /* header style endregion */

      & .MuiListItem-root {
        min-height: ${em(40)};
        padding: ${em(8)} ${em(16)};
        background-color: ${componentColor(
          dls.listItem.background.default.normalOff,
        )};
        color: ${componentColor(dls.listItem.text.default.normalOff)};
        display: flex;
        font-family: CentraleSans, Tahoma, Arial, Helvetica, sans-serif;
        font-weight: normal;
        font-style: normal;
        svg {
          height: ${em(24)};
          width: ${em(24)};
          fill: ${componentColor(dls.listItem.icon.default.normalOff)};
        }
        & .MuiTypography-root {
          color: ${componentColor(dls.listItem.text.default.normalOff)};
        }

        & .MuiListItemText-root {
          & .MuiTypography-root {
            /* This is for wrapping of custom headers */
            width: 90%;
          }
        }
        & .MuiListItemAvatar-root {
          min-width: ${em(72)};
          & .MuiAvatar-root {
            font-size: ${em(16)};
            height: ${em(56)};
            width: ${em(56)};
            &.dls-image {
              border-radius: unset;
            }
          }
        }

        &.MuiList-padding {
          padding-top: ${em(8)};
          padding-bottom: ${em(8)};
        }
        &.Multiselect-checkbox {
          & .MuiCheckbox-root {
            padding: 0px;
            & .MuiIconButton-label {
              color: ${componentColor(dls.listItem.text.default.normalOff)};
              padding-right: ${em(12)};
            }
          }
          & span:nth-child(2) {
            padding-left: ${em(0)};
          }
          & span:nth-child(3) {
            padding-left: ${em(12)};
          }
          &.Mui-selected {
            color: ${componentColor(dls.listItem.text.default.normalOn)};
            & .MuiCheckbox-root {
              padding: ${em(0)};
              & .MuiIconButton-label {
                color: ${componentColor(dls.listItem.text.default.normalOn)};
              }
            }
            & .MuiTypography-root {
              color: ${componentColor(dls.listItem.text.default.normalOn)};
            }
          }
        }
        &:focus {
          background-color: ${componentColor(
            dls.listItem.background.default.focusOn,
          )};
          box-shadow: 0 0 0 ${em(2)}
            ${componentColor(dls.listItem.focusOutline.default.focusPressed)}
            inset;
        }

        &:hover {
          background-color: ${componentColor(
            dls.listItem.background.default.hover,
          )};
        }
        &:active {
          background-color: ${componentColor(
            dls.listItem.background.default.pressed,
          )};
          box-shadow: 0 0 0 ${em(2)}
            ${componentColor(dls.listItem.focusOutline.default.focusPressed)}
            inset;
        }
        &.Mui-selected {
          background-color: ${componentColor(
            dls.listItem.background.default.normalOn,
          )};
          color: ${componentColor(dls.listItem.text.default.normalOn)};
          & .MuiTypography-root {
            color: ${componentColor(dls.listItem.text.default.normalOn)};
          }
          & .MuiListItemSecondaryAction-root {
            right: ${em(16)};
            margin-left: ${em(16)};
            span {
              color: ${componentColor(dls.listItem.text.default.normalOn)};
            }
            svg {
              height: ${em(24)};
              width: ${em(24)};
              fill: ${componentColor(dls.listItem.text.default.normalOn)};
            }
          }
          & .MuiListItemIcon-root {
            svg {
              height: ${em(24)};
              width: ${em(24)};
              fill: ${componentColor(dls.listItem.icon.default.normalOn)};
            }
          }
        }
        &.Mui-disabled {
          background-color: ${componentColor(
            dls.listItem.background.default.disabledOn,
          )};

          & .MuiTypography-root {
            color: ${componentColor(dls.listItem.text.default.disabled)};
          }
          svg {
            height: ${em(24)};
            width: ${em(24)};
            fill: ${componentColor(dls.listItem.icon.default.disabled)};
          }
        }
      }

      /* secondary action styles startregion */
      & .MuiListItemSecondaryAction-root {
        right: ${em(16)};
        margin-left: ${em(16)};
        span {
          margin-left: ${em(16)};
          svg {
            height: ${em(24)};
            width: ${em(24)};
            color: ${componentColor(dls.listItem.icon.default.normalOff)};
          }
        }
        &.Mui-selected {
          color: ${componentColor(dls.listItem.text.default.normalOn)};
          svg {
            color: ${componentColor(dls.listItem.icon.default.normalOn)};
          }
        }
        &.Mui-disabled {
          svg {
            fill: ${componentColor(dls.listItem.icon.default.disabled)};
          }
        }
      }
      /* secondary action styles endregion */

      /* content styles region */
      &.primary {
        background-color: ${componentColor(
          dls.content.background.primary.normal,
        )};
      }
      &.secondary {
        background-color: ${componentColor(
          dls.content.background.secondary.normal,
        )};
      }
      &.tertiary {
        background-color: ${componentColor(
          dls.content.background.tertiary.normal,
        )};
      }
      &.secondary-neutral {
        background-color: ${componentColor(
          dls.content.background.secondaryNeutral.normal,
        )};
      }
      &.tertiary-neutral {
        background-color: ${componentColor(
          dls.content.background.tertiaryNeutral.normal,
        )};
      }

      /* content styles endregion */

      .pt-dense & {
        margin-top: ${em(0)};
        & .MuiListItem-root {
          min-height: ${em(32)};

          &.MuiListItem-gutters {
            padding: ${em(4)} ${em(16)};
          }

          &.Multiselect-checkbox {
            & .MuiCheckbox-root {
              padding: ${em(0)};
              & .MuiIconButton-label {
                padding-right: ${em(8)}, ${em(24)};
              }
            }

            &.Mui-selected {
              color: ${componentColor(dls.listItem.text.default.normalOn)};
              & .MuiCheckbox-root {
                padding: ${em(0)};
              }
            }
          }
        }
      }
      /* Menu Touch styles for both dropdown & multiselect */
      .pt-touch & {
        & .MuiListItem-root {
          min-height: ${em(48)};
          &.MuiListItem-gutters {
            padding: ${em(12)} ${em(16)};
          }
        }
      }
    }
  }
`;
