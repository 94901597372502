import { Color, TonalRange } from './types';
import React from 'react';
import { ThemeProvider as Provider } from 'styled-components';
import {
  ApplicationThemeConfiguration,
  ThemeProps,
  ThemeProviderProps,
} from './types';

export interface ThemeConfiguration {
  color: Color;
  tone: TonalRange;
  accent: Color;
}

export const defaultTheme: ApplicationThemeConfiguration = {
  color: 'group-blue',
  tone: 'very-light',
  accent: 'orange',
  navigation: 'bright',
  density: 'regular',
};

const ThemeContext: React.Context<ApplicationThemeConfiguration> = React.createContext(
  defaultTheme,
);

export function useTheme(): ApplicationThemeConfiguration {
  return React.useContext(ThemeContext);
}

export const ContentTheme: React.FC<ThemeProps> = (props) => {
  const { color, tone, accent } = useTheme();
  const defaultTheme: ThemeConfiguration = {
    color,
    tone,
    accent,
  };

  return <Provider {...props} theme={defaultTheme} />;
};

export const NavigationTheme: React.FC<ThemeProps> = (props) => {
  const { color, navigation: tone, accent } = useTheme();
  const navigationTheme: ThemeConfiguration = {
    color,
    tone,
    accent,
  };

  return <Provider {...props} theme={navigationTheme} />;
};

export const DialogTheme: React.FC<ThemeProps> = (props) => {
  const { color, tone, accent } = useTheme();
  const mappedTone =
    tone === 'very-light' || tone === 'bright' ? 'ultra-light' : tone;
  const dialogTheme: ThemeConfiguration = {
    color,
    tone: mappedTone,
    accent,
  };

  return <Provider {...props} theme={dialogTheme} />;
};

// Menu has the same tonal range mapping rules as Dialog
export const MenuTheme = DialogTheme;

export const ThemeProvider: React.FC<ThemeProviderProps> = ({
  theme,
  children,
}) => {
  return (
    <ThemeContext.Provider value={theme}>
      <ContentTheme>{children}</ContentTheme>
    </ThemeContext.Provider>
  );
};
