import React, { FunctionComponent, SVGProps } from 'react';

const BrandCE33X24 = (props) => (
  <svg viewBox="0 0 33 24" width="1em" height="1em" {...props}>
    <path
      fill="#000"
      fillRule="nonzero"
      d="M12.771 20.057v3.429c-.342 0-.771.085-1.114.085C5.314 23.571.171 18.43.171 12.086.171 5.743 5.314.6 11.657.6c.343 0 .772 0 1.114.086v3.428C12.43 4.03 12 4.03 11.657 4.03 7.2 4.029 3.6 7.714 3.6 12.17c0 4.458 3.686 8.058 8.143 8.058.257-.086.6-.086 1.028-.172zM23.4 10.286H30V13.8h-6.6c.943 4.114 4.886 6.857 9.086 6.257v3.429c-.343 0-.772.085-1.115.085-6.342 0-11.485-5.142-11.485-11.485C19.886 5.743 25.029.6 31.37.6c.429 0 .772 0 1.115.086v3.428c-4.2-.685-8.143 2.057-9.086 6.172z"
    />
  </svg>
);

export default BrandCE33X24;
