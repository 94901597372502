import React from 'react';
import {
  DateTimePickerProps,
  DateTimePicker,
} from '@dls/react-beta-date-time-picker';

// Proxy component for DateTimePicker
export const DatePicker = (props: DateTimePickerProps): JSX.Element => {
  return <DateTimePicker {...props} hideTimePicker />;
};
