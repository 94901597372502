import React, { FunctionComponent } from 'react';
import { v4 as uuidv4 } from 'uuid';
import styled, { css } from 'styled-components';
import componentColor, { dls } from '@dls/react-colors';
import { IconContainer, Icon } from '@dls/react-icon';
import { em, svgScaling } from '@dls/react-theme';
import * as Icons from '@dls/react-icons';
export type IconName = keyof typeof Icons;

/* istanbul ignore next */
export type TimelineProps = {
  track?: TimelineTrack;
};

export type TimelineEventContainerProps = {
  timeBased?: string;
};

export type TimelineHeaderProps = {
  label?: string;
};

export type TimelineCsplitProps = {
  type?: CSplitType;
  timeBased?: string;
  status?: EventStatusBackground;
  topEvents?: TimelineEventProps[];
  bottomEvents?: TimelineEventProps[];
};

export type TimelineCurlySplitProps = {
  type?: CSplitType;
  timeBased?: string;
  status?: EventStatusBackground;
  topEvents?: TimelineEventProps[];
  bottomEvents?: TimelineEventProps[];
};

export type TimelineGroupedEventProps = {
  timeBased?: string;
  groupStatus?: GroupEventBackgroundStatus;
  events?: TimelineEventProps[];
};

export type TimelineTrack = 'single' | 'double';

export type CSplitType = 'open' | 'close';

/* This is used for the background of the Event Status */
export type EventStatusBackground =
  | 'backgroundBackground'
  | 'eventFutureIncomplete'
  | 'eventFutureInprogress'
  | 'eventGroupFuture'
  | 'eventGroupPast'
  | 'eventGroupToday'
  | 'eventHistoryComplete'
  | 'eventHistoryIncomplete'
  | 'eventPastComplete'
  | 'eventPastIncomplete'
  | 'eventPastInprogress'
  | 'eventTodayComplete'
  | 'eventTodayIncomplete'
  | 'eventTodayInprogress';

/* This is used for the background-half of the Event Status */
export const EventStatusBackgroundHalf = [
  'eventFutureInprogress',
  'eventPastInprogress',
  'eventTodayInprogress',
];

/* This is used for the background of the GroupedEvent Status */
export type GroupEventBackgroundStatus =
  | 'eventGroupFuture'
  | 'eventGroupPast'
  | 'eventGroupToday';

/* This is used for the Event Border status*/
export const eventBorder = [
  'eventFutureIncomplete',
  'eventFutureInprogress',
  'eventFutureComplete',
  'eventHistoryComplete',
  'eventHistoryIncomplete',
  'eventPastComplete',
  'eventPastIncomplete',
  'eventPastInprogress',
  'eventTodayComplete',
  'eventTodayIncomplete',
  'eventTodayInprogress',
];

/* Different Types of Events in Timeline */
export type events =
  | 'large'
  | 'circle'
  | 'pill'
  | 'stacked'
  | 'break'
  | 'cSplitOpen'
  | 'cSplitClose';

/* Different Types of time Based in Timeline */
export type timeBasedType = 'recent' | 'present' | 'future';

export type TimelineEventProps = {
  timeBased?: timeBasedType;
  eventType?: events;
  status?: EventStatusBackground;
  icon?: IconName;
  label?: TimelineLabelProps;
  className?: string;
  stackedCount?: number;
  todayIndicator?: boolean;
  halfBackground?: boolean;
  pillLabel?: string;
};

export type TimelineLabelProps = {
  title?: string;
  subTitle?: string;
  timeStamp?: string;
};

export type BreakSvgProps = {
  className?: string;
  timeBased?: string;
};

export type CSplitSVGProps = {
  className?: string;
  timeBased?: string;
  type?: CSplitType;
};

export type GroupProps = {
  count?: number;
  status?: GroupEventBackgroundStatus;
};

/* istanbul ignore next */
/* This is used to fetch the color for the track based on recent, present, future */
const getTimeBasedColor = (props: string) => {
  switch (props) {
    case 'recent':
      return componentColor(dls.timeline.track.trackPast.normal);
    case 'present':
      return componentColor(dls.timeline.track.trackToday.normal);
    case 'future':
      return componentColor(dls.timeline.track.trackFuture.normal);
    default:
      return;
  }
};

/* Draws a 8 x 10 break track */
/* The SVG Code of the break SVG has been used here.
    This is another of using Styled Components along with SVG's
*/
const BreakSVG: FunctionComponent<BreakSvgProps> = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="8"
    height="10"
    viewBox="0 0 8 10"
    className={className}>
    <g fill="none" transform="translate(-2 -1)">
      <polygon fill="#FFF" points="5 1 9 1 7 11 3 11" />
      <polygon fill="#1474A4" points="4 1 6 1 4 11 2 11" />
      <polygon fill="#1474A4" points="8 1 10 1 8 11 6 11" />
    </g>
  </svg>
);

/* This renders the BreakSVG & masks it with extra props supplied from the component, also adds the colors to SVG */
const BreakEvent = styled(BreakSVG).attrs((props) => {
  return {
    ...props,
  } as BreakSvgProps;
})`
  && {
    g {
      polygon:nth-child(1) {
        /* Please fix this with dynamic color */
        fill: white;
      }
      polygon:nth-child(2),
      polygon:nth-child(3) {
        fill: ${(props) =>
          props.timeBased &&
          css`
            ${getTimeBasedColor(props.timeBased)}
          `};
      }
    }
  }
`;

/* Draws a CSplit-SVG */
/* The SVG Code of the Split SVG has been used here.
    This is another of using Styled Components along with SVG's
*/
const CSplitSVG: FunctionComponent<CSplitSVGProps> = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="82"
    viewBox="0 0 32 82"
    className={className}>
    <path
      fill="none"
      stroke="#1474A4"
      d="M31.030303 84L31.030303 75.030303C31.030303 57.8927399 17.1375631 44 0 44L0 44 0 44M31.030303 84L31.030303 92.969697C31.030303 110.10726 17.1375631 124 0 124L0 124 0 124"
      transform="translate(0 -43)"
    />
  </svg>
);

/* This renders the C-Split SVG & masks it with extra props supplied from the component, also adds the colors to SVG */
const CSplitEvent = styled(CSplitSVG).attrs((props) => {
  return {
    ...props,
  } as CSplitSVGProps;
})`
  && {
    width: ${em(32)};
    height: ${em(82)};
    stroke-width: 2;
    ${(props) =>
      props.type === 'open'
        ? css`
            transform: rotate(180deg);
          `
        : css`
            transform: none;
          `}
    path {
      stroke: ${(props) =>
        props.timeBased &&
        css`
          ${getTimeBasedColor(props.timeBased)}
        `};
    }
  }
`;

/* Draws a CurlySplit-SVG */
/* The SVG Code of the Curly SVG has been used here.
    This is another of using Styled Components along with SVG's
*/
const CurlySplitSVG: FunctionComponent<CSplitSVGProps> = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="38"
    height="82"
    viewBox="0 0 38 82"
    className={className}>
    <path
      fill="#1474A4"
      d="M0,0 C8.6242705,0 16.2422467,5.57501182 18.8681017,13.7594581 L18.9806967,14.1222201 L23.5096517,29.2187367 C25.3968311,35.509335 31.120674,39.8508712 37.6609949,39.9962348 L38,40 L38,42 C31.4324225,42 25.6292508,46.2349159 23.6106705,52.4576372 L23.5096517,52.7812633 L18.9806967,67.8777799 C16.5025308,76.1383328 8.97362858,81.8330628 0.379816973,81.9963924 L0,82 L0,80 C7.74683722,80 14.5905786,74.9967218 16.9573924,67.6492058 L17.0650441,67.3030841 L21.5939991,52.2065675 C23.1904232,46.8851536 27.2117817,42.7956297 32.2318395,40.9991583 C27.3202695,39.2431936 23.3646998,35.2905319 21.7008826,30.136697 L21.5939991,29.7934325 L17.0650441,14.6969159 C14.8390068,7.27679156 8.08020088,2.15935763 0.362457915,2.00365437 L0,2 L0,0 Z"
    />
  </svg>
);

/* This renders the Curly-Split SVG & masks it with extra props supplied from the component, also adds the colors to SVG */
const CurlySplitEvent = styled(CurlySplitSVG).attrs((props) => {
  return {
    ...props,
  } as CSplitSVGProps;
})`
  && {
    width: ${em(38)};
    height: ${em(82)};
    stroke-width: 0;
    ${(props) =>
      props.type === 'open'
        ? css`
            transform: rotate(180deg);
          `
        : css`
            transform: none;
          `}
    path {
      fill: ${(props) =>
        props.timeBased &&
        css`
          ${getTimeBasedColor(props.timeBased)}
        `};
    }
  }
`;

/* istanbul ignore next */
/* This is used to fetch the color for the Icon's used in inside the `Large` event */
const getIconColor = (props: string) => {
  if (eventBorder.includes(props)) {
    return css`
      ${componentColor(dls.timeline.icon[props].normal)}
    `;
  }
  return;
};

/* istanbul ignore next */
/* This is used to fetch the color for Event's Border */
const getEventBorderColor = (props: string) => {
  if (eventBorder.includes(props)) {
    return css`
      ${componentColor(dls.timeline.border[props].normal)}
    `;
  }
  return;
};

/* istanbul ignore next */
/* This is used to fetch the color for Event's Border */
const getEventOutlineColor = (props: string) => {
  if (eventBorder.includes(props)) {
    return css`
      ${componentColor(dls.timeline.focusOutline[props].focusPressed)}
    `;
  }
  return;
};

/* istanbul ignore next */
/* This is used to fetch the color for Event's half background */
const getEventHalfBackground = (props: string) => {
  if (EventStatusBackgroundHalf.includes(props)) {
    return css`
      background: linear-gradient(
        to right,
        ${componentColor(dls.timeline.backgroundHalf[props].normal)} 50%,
        ${componentColor(dls.timeline.background[props].normal)} 50%
      );
    `;
  }
  return;
};

/* istanbul ignore next */
/* This is used to fetch the color for the text rendered inside the `Pill` event */
const getEventTextColor = (props: string) => {
  if (eventBorder.includes(props)) {
    return css`
      ${componentColor(dls.timeline.text[props].normal)}
    `;
  }
  return;
};

/* istanbul ignore next */
/* This is used to fetch the Styles for the events `[circle, large, pill, stacked]` */
const getEventStyles = (props: any) => {
  switch (props.eventType) {
    case 'circle':
      return css`
        width: ${em(8)};
        height: ${em(8)};
        border-radius: 50%;
        ${props.halfBackground && getEventHalfBackground(props.status!)};
        background-color: ${componentColor(
          dls.timeline.background[props.status].normal,
        )};
        border: ${em(2)} solid ${getEventBorderColor(props.status!)};
        &:focus {
          box-shadow: 0 0 ${em(2)} ${em(2)}
            ${getEventOutlineColor(props.status!)};
          outline: none;
        }
        flex: none;
      `;
    case 'large':
      return css`
        border-radius: 50%;
        margin-top: 0;
        width: ${em(36)};
        height: ${em(36)};
        color: #ffffff;
        ${props.halfBackground && getEventHalfBackground(props.status!)};
        background-color: ${componentColor(
          dls.timeline.background[props.status].normal,
        )};
        border: ${em(2)} solid ${getEventBorderColor(props.status!)};
        &:focus {
          box-shadow: 0 0 ${em(2)} ${em(2)}
            ${getEventOutlineColor(props.status!)};
          outline: none;
        }
      `;
    case 'pill':
      return css`
        max-width: 100%;
        height: ${em(24)};
        border-radius: ${em(20)};
        ${props.halfBackground && getEventHalfBackground(props.status!)};
        background-color: ${componentColor(
          dls.timeline.background[props.status].normal,
        )};
        border: ${em(2)} solid ${getEventBorderColor(props.status!)};
        &:focus {
          box-shadow: 0 0 ${em(2)} ${em(2)}
            ${getEventOutlineColor(props.status!)};
          outline: none;
        }
        font-size: ${em(16)};
        padding-left: ${em(16)};
        padding-right: ${em(16)};
        color: #ffffff;
      `;
    case 'stacked':
      return css`
        height: ${em(16, 12)};
        border-radius: ${em(30, 12)};
        ${props.halfBackground && getEventHalfBackground(props.status!)};
        background-color: ${componentColor(
          dls.timeline.background[props.status].normal,
        )};
        border: ${em(2, 12)} solid ${getEventBorderColor(props.status!)};
        &:focus {
          box-shadow: 0 0 ${em(2, 12)} ${em(2, 12)}
            ${getEventOutlineColor(props.status!)};
          outline: none;
        }
        font-size: ${em(12)};
        padding-left: ${em(12, 12)};
        padding-right: ${em(12, 12)};
        color: #ffffff;
      `;
    default:
      return;
  }
};

/* This is used to apply the styles for the root `ul` of timeline component */
export const Timeline = styled.ul<TimelineProps>`
  position: relative;
  display: flex;
  padding: 0;
  margin: 0;
  align-items: center;
  list-style-type: none;
  box-sizing: content-box;
  background-color: ${componentColor(
    dls.timeline.background.background.normal,
  )};
  ${(props) =>
    props.track === 'single'
      ? css`
          height: ${em(132)};
        `
      : css`
          height: ${em(172)};
        `}
  padding-top: ${em(12)};
  padding-bottom: ${em(8)};
`;

/* This is used for styling the timeline track */
export const TimelineEventContainer = styled.li<TimelineEventContainerProps>`
  position: relative;
  height: ${em(2)};
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  flex-direction: column;
  padding: ${em(0)} ${em(12)};
  ${(props) =>
    props.timeBased &&
    css`
      background: ${getTimeBasedColor(props.timeBased)};
    `}
  &.branch {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
`;

/* This is used for styling event's container */
export const TimelineEventFigure = styled.div<TimelineEventProps>`
  min-height: ${em(40)};
  min-width: ${em(40)};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: ${em(40)};
`;

/* This is to style the events `[circle, large, pill, stacked]` also to style the pill-label & Icon-container */
export const TimeLineEventShape = styled.div<TimelineEventProps>`
  max-width: ${em(40)};
  max-height: ${em(40)};
  position: relative;
  flex: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  ${(props) =>
    props.eventType &&
    css`
      ${getEventStyles(props)};
    `}
  ${(props) =>
    props.status &&
    css`
      & .pill-label {
        color: ${getEventTextColor(props.status)};
        font-family: CentraleSans, Tahoma, Arial, Helvetica, sans-serif;
        font-weight: normal;
        font-style: normal;
        width: max-content;
      }
      & .stacked-pill-label {
        color: ${getEventTextColor(props.status)};
        font-family: CentraleSans, Tahoma, Arial, Helvetica, sans-serif;
        font-weight: normal;
        font-style: normal;
      }
      & .icon-container {
        ${svgScaling(em(24))};
        & .large-icon {
          fill: ${getIconColor(props.status!)};
        }
      }
    `}
`;
/* This is to style the Timestamp present of Events */
export const TodayTimeStamp = styled.div<any>`
  position: absolute;
  top: ${em(-80)};
  font-family: CentraleSans, Tahoma, Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-style: normal;
  color: ${componentColor(dls.timeline.text.eventTodayOntimeline.normal)};
  display: flex;
  align-items: center;
  flex-direction: column;
`;

/* This is to style the Timestamp of Events */
export const TimeStamp = styled.span<TimelineLabelProps>`
  min-height: ${em(24)};
  position: absolute;
  top: ${em(-80)};
  font-family: CentraleSans, Tahoma, Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-style: normal;
  color: ${componentColor(dls.timeline.text.eventDateOntimeline.normal)};
`;

/* This is to style the Timestamp of present */
export const PresentTimeStamp = styled.span<TimelineLabelProps>`
  min-height: ${em(24)};
  position: absolute;
  top: ${em(-80)};
  font-family: CentraleSans, Tahoma, Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-style: normal;
  color: ${componentColor(dls.timeline.text.eventDateOntimeline.normal)};
`;

/* This is to style the Title of Events */
export const Title = styled.span<TimelineLabelProps>`
  margin: 0;
  min-height: ${em(20)};
  font-size: ${em(16)};
  font-family: CentraleSans, Tahoma, Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-style: normal;
  color: ${componentColor(dls.timeline.text.eventTitle.normal)};
`;

/* This is to style the Title of Events */
export const TodayTitle = styled(Title)`
  color: ${componentColor(dls.timeline.text.eventTodayOntimeline.normal)};
`;

/* This is to style the today indicator of Events */
export const Indicator = styled.span<any>`
  width: ${em(4)};
  height: ${em(4)};
  border-radius: 50%;
  margin: ${em(4)} 0;
  background-color: ${componentColor(
    dls.timeline.text.eventTodayOntimeline.normal,
  )};
  display: inline-flex;
`;

/* This is to style the Sub-Title of Events */
export const SubTitle = styled.span<TimelineLabelProps>`
  margin: 0;
  min-height: ${em(20, 12)};
  font-size: ${em(12)};
  font-family: CentraleSans, Tahoma, Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-style: normal;
  color: ${componentColor(dls.timeline.text.eventSubtitle.normal)};
`;

/* This is to style the Header-Timescale (Header-Timestamp) */
export const HeaderScale = styled.li<TimelineHeaderProps>`
  height: ${em(152)};
  font-family: CentraleSans, Tahoma, Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-style: normal;
  color: ${componentColor(dls.timeline.text.eventSubtitle.normal)};
  border-left: ${em(1)} solid
    ${componentColor(dls.timeline.track.trackFuture.normal)};
  position: relative;
  top: ${em(-2)};
  & .label {
    position: absolute;
    padding-left: ${em(10)};
    top: ${em(-3)};
    padding-right: ${em(10)};
    width: max-content;
  }
`;

/* This is the Root-code of the Timeline Event */
export const TimeLineEvent = (props: TimelineEventProps) => {
  const {
    timeBased,
    eventType,
    status,
    icon,
    label,
    stackedCount,
    todayIndicator,
    halfBackground,
    pillLabel,
  } = props;
  const IconComponent = Icons[icon as IconName];
  return (
    <TimelineEventContainer timeBased={timeBased} {...props}>
      {label && timeBased === 'present' ? (
        <TodayTimeStamp>
          {label!.timeStamp}
          {label!.timeStamp && <Icon name="ArrowDown" size={'s'} />}
        </TodayTimeStamp>
      ) : (
        <TimeStamp>{label!.timeStamp}</TimeStamp>
      )}
      <TimelineEventFigure label={label}>
        <TimeLineEventShape
          eventType={eventType}
          status={status}
          halfBackground={halfBackground}>
          {eventType === 'large' && (
            <IconContainer className={'icon-container'} size={'m'}>
              <IconComponent className={'large-icon'} />
            </IconContainer>
          )}
          {eventType === 'pill' && (
            <span className={'pill-label'}>{pillLabel}</span>
          )}
          {eventType === 'stacked' && (
            <span className={'stacked-pill-label'}>{stackedCount}</span>
          )}
          {eventType === 'break' && <BreakEvent timeBased={timeBased} />}
        </TimeLineEventShape>
      </TimelineEventFigure>
      {todayIndicator ? (
        <TodayTitle>
          Today <Indicator />
        </TodayTitle>
      ) : (
        <Title>{label!.title}</Title>
      )}
      {label && <SubTitle>{label!.subTitle}</SubTitle>}
    </TimelineEventContainer>
  );
};
/* This is the ul wrapper for the Top-events in CSplit & Curly-Split */
const TopEvents = styled.ul`
  position: relative;
  display: flex;
  padding: 0;
  margin: 0;
  align-items: center;
  list-style-type: none;
  margin-bottom: ${em(83)}; /* from spike it is 78 */
  li > span {
    top: ${em(-40)};
  }
`;

/* This is the ul wrapper for the Bottom-events in CSplit & Curly-Split */
const BottomEvents = styled.ul`
  position: relative;
  display: flex;
  padding: 0;
  margin: 0;
  align-items: center;
  list-style-type: none;
  top: ${em(-5)};
`;

/* This is used for the styling the grouping logic */
export const GroupEventsContainer = styled.ul<GroupProps>`
  position: relative;
  display: flex;
  padding: 0;
  margin: 0;
  align-items: center;
  list-style-type: none;
  &:before {
    content: '';
    position: absolute;
    border-radius: ${em(20)};
    margin: -45%;
    left: 50%;
    width: calc(90% - ${em(24)});
    ${(props) =>
      props.status &&
      css`
        border: ${em(12)} solid
          ${componentColor(dls.timeline.background[props.status].normal)};
      `}
  }
`;

/* This is used for the rendering the events in the Top & bottom line inside the C-Split & Curly-Split */
export const BranchEvents = (props: TimelineCsplitProps) => {
  const { topEvents, bottomEvents } = props;
  return (
    <li style={{ marginTop: `${em(4)}` }}>
      <TopEvents>
        {topEvents &&
          topEvents.map((data: any) => {
            return (
              <TimeLineEvent
                key={uuidv4()}
                timeBased={data.timeBased}
                status={data.status}
                eventType={data.eventType}
                label={{
                  title: data.label.title,
                  subTitle: data.label.subTitle,
                  timeStamp: data.label.timeStamp,
                }}
                {...props}
              />
            );
          })}
      </TopEvents>
      <BottomEvents>
        {bottomEvents &&
          bottomEvents.map((data: any) => {
            return (
              <TimeLineEvent
                key={uuidv4()}
                timeBased={data.timeBased}
                status={data.status}
                eventType={data.eventType}
                label={{
                  title: data.label.title,
                  subTitle: data.label.subTitle,
                }}
                {...props}
              />
            );
          })}
      </BottomEvents>
    </li>
  );
};

/* This is used for rendering the CSplit events */
export const CSplit = (props: TimelineCsplitProps) => {
  const { timeBased } = props;
  return (
    <>
      <li style={{ marginTop: `${em(4)}` }}>
        <CSplitEvent
          className={'c-split'}
          type={'open'}
          timeBased={timeBased}
          {...props}
        />
      </li>
      <BranchEvents {...props} />
      <li style={{ marginTop: `${em(4)}` }}>
        <CSplitEvent
          className={'c-split'}
          type={'close'}
          timeBased={timeBased}
          {...props}
        />
      </li>
    </>
  );
};

/* This is used for rendering the Curly-Split events */
export const CurlySplit = (props: TimelineCurlySplitProps) => {
  const { timeBased } = props;
  return (
    <>
      <li style={{ marginTop: `${em(4)}` }}>
        <CurlySplitEvent
          className={'curly-split'}
          type={'open'}
          timeBased={timeBased}
          {...props}
        />
      </li>
      <BranchEvents {...props} />
      <li style={{ marginTop: `${em(4)}` }}>
        <CurlySplitEvent
          className={'curly-split'}
          type={'close'}
          timeBased={timeBased}
          {...props}
        />
      </li>
    </>
  );
};

/* This is used for rendering the Sinle line track Grouped events */
export const GroupedEvent = (props: TimelineGroupedEventProps) => {
  const { groupStatus, events } = props;
  return (
    <>
      <li>
        <GroupEventsContainer status={groupStatus} count={events!.length}>
          {events &&
            events.map((data: any) => {
              return (
                <TimeLineEvent
                  key={uuidv4()}
                  timeBased={data.timeBased}
                  status={data.status}
                  className={'grouped-event'}
                  eventType={data.eventType}
                  icon={data.icon}
                  pillLabel={data.pillLabel}
                  todayIndicator={data.todayIndicator}
                  halfBackground={data.halfBackground}
                  label={{
                    title: data.label.title,
                    subTitle: data.label.subTitle,
                    timeStamp: data.label.timeStamp,
                  }}
                  stackedCount={data.stackedCount}
                  {...props}
                />
              );
            })}
        </GroupEventsContainer>
      </li>
    </>
  );
};

/* istanbul ignore next */
/* This is used for rendering the Double line track of the CSplit & Curly-Split events */
export const GroupedBranchEvents = (props: TimelineCsplitProps) => {
  const { topEvents, bottomEvents } = props;
  return (
    <li style={{ marginTop: `${em(4)}` }}>
      <TopEvents>
        {topEvents &&
          topEvents.map((data: any) => {
            if (data.grouped) {
              return (
                <GroupedEvent
                  key={uuidv4()}
                  groupStatus={data.groupStatus}
                  events={data.grouped}
                />
              );
            } else {
              return (
                <TimeLineEvent
                  key={uuidv4()}
                  timeBased={data.timeBased}
                  status={data.status}
                  eventType={data.eventType}
                  label={{
                    title: data.label.title,
                    subTitle: data.label.subTitle,
                    timeStamp: data.label.timeStamp,
                  }}
                  {...props}
                />
              );
            }
          })}
      </TopEvents>
      <BottomEvents>
        {bottomEvents &&
          bottomEvents.map((data: any) => {
            if (data.grouped) {
              return (
                <GroupedEvent
                  key={uuidv4()}
                  groupStatus={data.groupStatus}
                  events={data.grouped}
                />
              );
            } else {
              return (
                <TimeLineEvent
                  key={uuidv4()}
                  timeBased={data.timeBased}
                  status={data.status}
                  eventType={data.eventType}
                  label={{
                    title: data.label.title,
                    subTitle: data.label.subTitle,
                  }}
                  {...props}
                />
              );
            }
          })}
      </BottomEvents>
    </li>
  );
};

/* istanbul ignore next */
/* This is used for rendering the CSplit grouped events */
export const CSplitGroup = (props: TimelineCsplitProps) => {
  const { timeBased } = props;
  return (
    <>
      <li style={{ marginTop: `${em(4)}` }}>
        <CSplitEvent
          className={'c-split'}
          type={'open'}
          timeBased={timeBased}
          {...props}
        />
      </li>
      <GroupedBranchEvents {...props} />
      <li style={{ marginTop: `${em(4)}` }}>
        <CSplitEvent
          className={'c-split'}
          type={'close'}
          timeBased={timeBased}
          {...props}
        />
      </li>
    </>
  );
};

/* istanbul ignore next */
/* This is used for rendering the Curly-Split grouped events */
export const CurlySplitGroup = (props: TimelineCsplitProps) => {
  const { timeBased } = props;
  return (
    <>
      <li style={{ marginTop: `${em(4)}` }}>
        <CurlySplitEvent
          className={'c-split'}
          type={'open'}
          timeBased={timeBased}
          {...props}
        />
      </li>
      <GroupedBranchEvents {...props} />
      <li style={{ marginTop: `${em(4)}` }}>
        <CurlySplitEvent
          className={'c-split'}
          type={'close'}
          timeBased={timeBased}
          {...props}
        />
      </li>
    </>
  );
};

/* This is used for rendering Timeline header scale */
export const TimeLineHeader = (props: TimelineHeaderProps) => {
  return (
    <HeaderScale>
      {props.label === 'Today' ? (
        <TodayTitle className={'label'}>
          Today <Indicator />
        </TodayTitle>
      ) : (
        <span className={'label'}>{props.label}</span>
      )}
    </HeaderScale>
  );
};
