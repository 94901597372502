import componentColor, { dls } from '@dls/react-colors';
import MuiRadio, { RadioProps } from '@material-ui/core/Radio';
import MuiRadioGroup, { RadioGroupProps } from '@material-ui/core/RadioGroup';
import { FC } from 'react';
import styled from 'styled-components';
import { em } from '@dls/react-theme';

export const RadioGroup: FC<RadioGroupProps> = styled(MuiRadioGroup).attrs(
  (props) => {
    return {
      id: props.id,
      name: props.name,
      ...props,
    } as RadioGroupProps;
  },
)`
  && {
    /* Radiogroup related css */
  }
`;

export const Radio: FC<RadioProps> = styled(MuiRadio).attrs((props) => {
  return {
    ...props,
  } as RadioProps;
})`
  && {
    &.MuiRadio-colorSecondary {
      height: ${em(28, 24)};
      width: ${em(28, 24)};
      margin-left: ${em(3, 24)};
      margin-right: ${em(3, 24)};
      font-size: ${em(24)};
      color: transparent;
      &:hover {
        background-color: transparent;
      }
      &:focus-within {
        background-color: ${componentColor(
          dls.radioButton.focusOutline.default.focusPressed,
        )};
      }
      &:active {
        background-color: ${componentColor(
          dls.radioButton.focusOutline.default.focusPressed,
        )};
      }
      & .MuiIconButton-label {
        border-radius: 50%;
        width: ${em(20, 24)};
        height: ${em(20, 24)};
        font-size: ${em(24, 24)};
        display: flex;
        color: transparent;
        background-color: ${componentColor(
          dls.radioButton.background.default.normalOff,
        )};
      }

      &.Mui-checked {
        color: transparent;
        &:focus-within:hover {
          background-color: ${componentColor(
            dls.radioButton.focusOutline.default.focusPressed,
          )};
        }
        &:active {
          background-color: ${componentColor(
            dls.radioButton.focusOutline.default.focusPressed,
          )};
        }
        &:hover {
          background-color: transparent;
        }
        & .MuiIconButton-label {
          outline: none;
          box-shadow: 0 0 0 1px
            ${componentColor(dls.radioButton.icon.default.normalOn)};
          background-color: ${componentColor(
            dls.radioButton.background.default.normalOn,
          )};
          &:before {
            border-radius: 50%;
            width: ${em(8, 24)};
            height: ${em(8, 24)};
            padding-left: ${em(8, 24)};
            margin-left: ${em(36, 24)};
            background-color: ${componentColor(
              dls.radioButton.icon.default.normalOn,
            )};
            content: '""';
          }
        }
      }

      &.Mui-disabled {
        background-color: transparent;
        /* & .MuiFormControlLabel-label.Mui-disabled {
        color: ${componentColor(dls.label.text.regular.disabled)}
      } */
        & .MuiIconButton-label {
          background-color: ${componentColor(
            dls.radioButton.background.default.disabled,
          )};
          &:before {
            background-color: ${componentColor(
              dls.radioButton.icon.default.disabled,
            )};
          }
        }
      }
    }
    &.MuiRadio-root {
      &.dlsRadioIcon {
        & .MuiSvgIcon-root {
          font-size: ${em(24)};
        }
      }
    }
  }
`;
