import React, { FunctionComponent, SVGProps } from 'react';

const PrivacyGlass = (props) => (
  <svg viewBox="0 0 24 24" width="1em" height="1em" {...props}>
    <path d="M23 3v18H1V3h22zm-2 2H3v14h18V5zm-4 6c1.7 0 3 1.3 3 3v3l-2-1.5V14c0-.3-.2-.5-.5-.5s-.5.2-.5.5v.8L12 11h5zm-2.5-5c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2z" />
    <path d="M4 6v12h16z" opacity={0.5} />
  </svg>
);

export default PrivacyGlass;
