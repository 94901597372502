import React, { FunctionComponent, SVGProps } from 'react';

const Pipelines = (props) => (
  <svg viewBox="0 0 48 48" width="1em" height="1em" {...props}>
    <path
      fill="#231f20"
      d="M41 29a5 5 0 000-10c-1.638 0-3.079.799-3.991 2.017l-8.163-3.841A4.96 4.96 0 0029 16c0-.489-.092-.951-.223-1.398l8.381-4.437C38.076 11.277 39.446 12 41 12a5 5 0 10-5-5c0 .489.092.952.223 1.398l-8.381 4.438C26.925 11.724 25.554 11 24 11a5 5 0 00-5 5c0 .407.062.797.154 1.175l-8.163 3.841C10.079 19.799 8.638 19 7 19a5 5 0 000 10c1.554 0 2.924-.723 3.841-1.835l8.381 4.437c-.13.447-.222.909-.222 1.398a5 5 0 005 5c1.554 0 2.924-.723 3.841-1.835l8.208 4.345c-.016.164-.049.322-.049.49a5 5 0 105-5c-1.874 0-3.488 1.043-4.344 2.569l-7.879-4.171c.131-.447.223-.909.223-1.398s-.092-.951-.223-1.398l8.381-4.437C38.076 28.277 39.446 29 41 29zm-13.159.835C26.924 28.724 25.554 28 24 28c-1.554 0-2.924.723-3.841 1.835l-8.381-4.437c.13-.446.222-.909.222-1.398a4.96 4.96 0 00-.154-1.176l8.162-3.842C20.92 20.201 22.361 21 24 21c1.638 0 3.079-.799 3.991-2.017l8.163 3.841A4.96 4.96 0 0036 24c0 .489.092.951.223 1.398l-8.382 4.437z"
    />
  </svg>
);

export default Pipelines;
