import React, { FunctionComponent, SVGProps } from 'react';

const DirectionalIndicator = (props) => (
  <svg viewBox="0 0 48 48" width="1em" height="1em" {...props}>
    <style>
      {
        '.st1{display:none}.st2{opacity:.5;fill:none;stroke:#00adee;stroke-width:.1}.st2,.st3{display:inline}.st4{fill:#f58e5f}'
      }
    </style>
    <g id="Layer_6">
      <path d="M18 24c0-5.27 3.42-9.75 8.15-11.36l-4.12-8.69-10.94 23.06h.01c-.7 1.53-1.1 3.22-1.1 5.01 0 6.64 5.39 12.03 12.03 12.03 5.45 0 10.04-3.62 11.52-8.59-1.12.35-2.31.54-3.55.54-6.62 0-12-5.38-12-12z" />
      <path d="M30 14c-5.52 0-10 4.48-10 10s4.48 10 10 10 10-4.48 10-10-4.48-10-10-10zm1.98 10c0 1.1-.89 2-1.99 2S28 25.1 28 24s.89-2 1.99-2c.08 0 .16.01.23.02L35 18l1 1-4.04 4.8c.01.07.02.13.02.2z" />
    </g>
    <g id="main-division" className="st1">
      <path
        d="M0 8h48M0 12h48M0 4h48M0 16h48M0 20h48M0 24h48M0 28h48M0 32h48M0 36h48M0 40h48M0 44h48M8 48V0M4 48V0M16 48V0M20 48V0M24 48V0M28 48V0M32 48V0M12 48V0M36 48V0M40 48V0M44 48V0"
        className="st2"
      />
    </g>
    <g id="grid" className="st1">
      <g className="st3">
        <path
          d="M24 2.1c12.08 0 21.9 9.82 21.9 21.9S36.08 45.9 24 45.9 2.1 36.08 2.1 24 11.92 2.1 24 2.1m0-.1C11.85 2 2 11.85 2 24s9.85 22 22 22 22-9.85 22-22S36.15 2 24 2z"
          className="st4"
        />
      </g>
      <g className="st3">
        <path d="M39.9 2.1v43.8H8.1V2.1h31.8M40 2H8v44h32V2z" className="st4" />
      </g>
      <g className="st3">
        <path
          d="M45.9 8.08v31.8H2.1V8.08h43.8m.1-.1H2v32h44v-32z"
          className="st4"
        />
      </g>
      <g className="st3">
        <path
          d="M24 18.1c3.25 0 5.9 2.65 5.9 5.9s-2.65 5.9-5.9 5.9-5.9-2.65-5.9-5.9 2.65-5.9 5.9-5.9m0-.1c-3.31 0-6 2.69-6 6s2.69 6 6 6 6-2.69 6-6-2.69-6-6-6z"
          className="st4"
        />
      </g>
      <g className="st3">
        <path
          d="M24 20.1c2.15 0 3.9 1.75 3.9 3.9s-1.75 3.9-3.9 3.9-3.9-1.75-3.9-3.9 1.75-3.9 3.9-3.9m0-.1c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4z"
          className="st4"
        />
      </g>
      <g className="st3">
        <path
          d="M24 14.1c5.46 0 9.9 4.44 9.9 9.9s-4.44 9.9-9.9 9.9-9.9-4.44-9.9-9.9 4.44-9.9 9.9-9.9m0-.1c-5.52 0-10 4.48-10 10s4.48 10 10 10 10-4.48 10-10-4.48-10-10-10z"
          className="st4"
        />
      </g>
      <g className="st3">
        <path
          d="M24 8.1c8.77 0 15.9 7.13 15.9 15.9S32.77 39.9 24 39.9 8.1 32.77 8.1 24 15.23 8.1 24 8.1m0-.1C15.16 8 8 15.16 8 24s7.16 16 16 16 16-7.16 16-16S32.84 8 24 8z"
          className="st4"
        />
      </g>
      <g className="st3">
        <path d="M45.9 2.1v43.8H2.1V2.1h43.8M46 2H2v44h44V2z" className="st4" />
      </g>
      <g className="st3">
        <path d="M41.9 6.1v35.8H6.1V6.1h35.8M42 6H6v36h36V6z" className="st4" />
      </g>
      <g className="st3">
        <path
          d="M41.84 6.1L24 41.78 6.16 6.1h35.68M42 6H6l18 36L42 6z"
          className="st4"
        />
      </g>
      <g className="st3">
        <path
          d="M24 6.22L41.84 41.9H6.16L24 6.22M24 6L6 42h36L24 6z"
          className="st4"
        />
      </g>
    </g>
    <g id="bounding-box" className="st1">
      <path fill="#eb008b" d="M47.8.2v47.6H.2V.2h47.6M48 0H0v48h48V0z" />
    </g>
  </svg>
);

export default DirectionalIndicator;
