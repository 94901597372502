import React, { FunctionComponent, SVGProps } from 'react';

const BrandECREP52X24 = (props) => (
  <svg viewBox="0 0 52 24" width="1em" height="1em" {...props}>
    <g fill="#000" fillRule="nonzero">
      <path d="M27 24v.04h-2V24H0V0h52v24H27zm0-1.846h23.143V1.846H27v20.308zm-2 0V1.846H1.857v20.308H25z" />
      <path d="M12.637 9.231v.923h-2.88v1.44h2.465v.923H9.757v1.329h2.945v.923h-4.09V9.231h4.025zM13.57 12a2.852 2.852 0 013.11-2.852c.606.01 1.202.164 1.735.452l-.378.775a3.425 3.425 0 00-1.385-.313 1.93 1.93 0 100 3.858c.479-.007.95-.114 1.385-.314l.425.785a3.794 3.794 0 01-1.736.452A2.852 2.852 0 0113.57 12zm20.242-2.77a1.69 1.69 0 011.736 1.69 1.625 1.625 0 01-1.071 1.57l1.292 2.28h-1.31l-1.136-2.124H32.4v2.123h-1.172V9.231h2.584zm-1.449 2.428h1.33a.71.71 0 00.729-.775.748.748 0 00-.831-.73h-1.219v1.515l-.009-.01zM40.828 9.231v.923h-2.88v1.44h2.464v.923h-2.464v1.329h2.944v.923h-4.08V9.231h4.025zM44.603 9.23a1.754 1.754 0 011.745 1.755 1.726 1.726 0 01-1.726 1.79h-1.385v1.994h-1.135V9.231h2.501zm-1.366 2.622h1.135a.803.803 0 00.859-.849.84.84 0 00-.923-.83h-1.062v1.698l-.01-.019z" />
    </g>
  </svg>
);

export default BrandECREP52X24;
