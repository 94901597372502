import React, { FunctionComponent, SVGProps } from 'react';

const ExternalContent = (props) => (
  <svg viewBox="0 0 48 48" width="1em" height="1em" {...props}>
    <path
      fillRule="evenodd"
      d="M20 24L2 34V14l18 10zM2 6h44v36H2v-4l4-2h36V12H6l-4-2V6z"
      clipRule="evenodd"
    />
  </svg>
);

export default ExternalContent;
