export const MILLISECONDS_IN_SECOND = 1000;
export const SECONDS_IN_MINUTE = 60;
export const MINUTES_IN_HOUR = 60;
export const HOURS_IN_DAY = 24;
export const DAYS_IN_WEEK = 7;
export const DAYS_IN_MONTH = 30;
export const MONTHS_IN_YEAR = 12;

export const SECOND = MILLISECONDS_IN_SECOND;
export const MINUTE = SECONDS_IN_MINUTE * SECOND;
export const HOUR = MINUTES_IN_HOUR * MINUTE;
export const DAY = HOURS_IN_DAY * HOUR;
export const WEEK = DAYS_IN_WEEK * DAY;
export const MONTH = DAYS_IN_MONTH * DAY;
