import { css } from 'styled-components';
import { DateTimePickerCellType } from '../types';
import componentColor, { dls, DlsStyle } from '@dls/react-colors';
import { NO_STYLES } from '@dls/react-beta-utils';

export const getCellStylesByType = (
  cellType: DateTimePickerCellType,
): DlsStyle => {
  switch (cellType) {
    case DateTimePickerCellType.Neutral: {
      return NO_STYLES;
    }
    case DateTimePickerCellType.Disabled: {
      return css`
        opacity: 0.35;
        cursor: not-allowed;
      `;
    }
    case DateTimePickerCellType.Selected: {
      return css`
        background-color: ${componentColor(
          dls.datePicker.dayNumberBackground.desktop.normalOnFocus,
        )};

        color: ${componentColor(
          dls.datePicker.dayNumberText.desktop.normalOnFocus,
        )};
      `;
    }
    case DateTimePickerCellType.Highlighted: {
      return css`
        background-color: ${componentColor(
          dls.datePicker.dayNumberBackground.desktop.normalOffFocus,
        )};

        border: 1px solid
          ${componentColor(dls.datePicker.currentDayBorder.desktop.normal)};
      `;
    }
    default: {
      const exhaustive: never = cellType;
      throw new Error(exhaustive);
    }
  }
};
