import componentColor, { dls } from '@dls/react-colors';
import { GaugeComponent } from './types';

const GaugeComponentColors: Record<GaugeComponent, string> = {
  [GaugeComponent.ValueIndicator]:
    dls.dataVisualization.referenceBackground.statusIndicator.normal,
  [GaugeComponent.GaugeBackground]:
    dls.dataVisualization.background.statusIndicator.normal,
  [GaugeComponent.ValueText]:
    dls.dataVisualization.valueText.statusIndicator.normal,
  [GaugeComponent.UnitText]:
    dls.dataVisualization.valueLabelText.statusIndicator.normal,
  [GaugeComponent.Icon]: dls.dataVisualization.valueIcon.statusIndicator.normal,
  [GaugeComponent.TitleText]: dls.dataVisualization.titleText.default.normal,
  [GaugeComponent.SubtitleText]:
    dls.dataVisualization.subtitleText.default.normal,
};

export const getColorFor = (component: GaugeComponent): string => {
  return componentColor(GaugeComponentColors[component])();
};
