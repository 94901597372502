import { checkStringArg } from './exceptions';
import { uniq, isNil } from 'ramda';
import spacing from './spacing';

export const capitalize = (input: string): string => {
  checkStringArg(input);
  return input.charAt(0).toUpperCase() + input.slice(1);
};

export const capitalizeCss = (input: string): string => {
  checkStringArg(input);
  const regex = /-([a-z])/gi;
  return input.replace(regex, (all, letter) => letter.toUpperCase());
};

export const dashlize = (input: string): string => {
  checkStringArg(input);

  const regex = /(?!^)[A-Z]/;
  return input.replace(regex, (letter) => '-' + letter.toLowerCase());
};

export const pxToRem = (
  value: number | string,
  defaultFontSize = 16,
): string => {
  const convertedValue =
    typeof value === 'string' ? parseInt(value, 10) : value;

  if (isNaN(convertedValue)) {
    return null;
  }
  return `${convertedValue / defaultFontSize}rem`;
};

export const isNumeric = (obj: any): boolean => {
  const realStringObj = obj && obj.toString();
  return (
    !Array.isArray(obj) && realStringObj - parseFloat(realStringObj) + 1 >= 0
  );
};

type ToPixelInput = string | string[] | number | number[];

const stringToPixel = (input: string | number): string => {
  if (input === undefined) {
    return undefined;
  }

  if (isNil(input)) {
    return null;
  }

  if (spacing[input]) {
    return spacing[input];
  }

  if (input === 0) {
    return '0';
  }

  if (isNumeric(input)) {
    return `${input}px`;
  }

  return input.toString();
};

export const toPx = (input: ToPixelInput): string => {
  if (Array.isArray(input)) {
    const length = input.length;
    const allEqual = uniq<ToPixelInput>(input).length === 1 && length > 1;

    const horizontalEqual = input[0] && input[2] && input[0] === input[2];
    const verticalEqual = input[1] && input[3] && input[1] === input[3];

    if (allEqual || horizontalEqual || verticalEqual) {
      console.warn(
        `Use shorthand css properties to write more concise and readable stylesheets,
                                      saving time and reducing the flow of client-side data. Current value: ${JSON.stringify(
                                        input,
                                      )}
                                      E.g.
                                        1) "10px 10px 10px 10px" to "10px"
                                        2) "p30px 15px 30px 15px" to "30px 15px"
                                        2) "10px 15px 20px 15px" to "10px 15px 20px"
                                      `,
      );
    }

    const output = [];
    input.forEach((x) => output.push(stringToPixel(x)));
    return output.join(' ');
  }

  return stringToPixel(input);
};
