import React, { FunctionComponent, SVGProps } from 'react';

const CheckmarkCircle = (props) => (
  <svg viewBox="0 0 24 24" width="1em" height="1em" {...props}>
    <path
      fillRule="evenodd"
      d="M12 4a8 8 0 110 16 8 8 0 010-16zm3.968 4.383l-5.158 5.158-2.755-2.766L7 11.806l3.81 3.81L17 9.438l-1.032-1.055z"
    />
  </svg>
);

export default CheckmarkCircle;
