import React, { useState, useCallback, useMemo } from 'react';
import moment from 'moment';
import { getWeekDayNames } from './getWeekDayNames';
import { getCalendarDays } from './getCalendarDays';
import { DateTimePickerVisibleMonth } from './VisibleMonth/VisibleMonth';
import { DateTimePickerCellWeekDay } from '../Cell/CellWeekDay';
import { DateTimePickerCellDay } from '../Cell/CellDay/CellDay';
import { DateTimePickerCalendarProps } from './types';
import { StyledCalendarRow } from './StyledCalendarRow';

export const DateTimePickerCalendar = ({
  selected,
  onSelect,
  maxDate,
  weekDaysFormat = 'dddd',
}: DateTimePickerCalendarProps): JSX.Element => {
  const startOfSelected = useMemo<Date>(
    () => moment(selected).startOf('day').toDate(),
    [selected],
  );

  const startOfSelectedMonth = useMemo<Date>(
    () => moment(selected).startOf('month').toDate(),
    [selected],
  );

  const [visibleMonth, setVisibleMonth] = useState<Date>(startOfSelectedMonth);
  const weekDayNames = useMemo(() => getWeekDayNames(weekDaysFormat), [
    weekDaysFormat,
  ]);
  const calendarDays = getCalendarDays(visibleMonth);

  const goToNext = useCallback(() => {
    setVisibleMonth((month) =>
      moment(month).startOf('month').add(1, 'month').toDate(),
    );
  }, []);

  const goToPrev = useCallback(() => {
    setVisibleMonth((month) =>
      moment(month).startOf('month').subtract(1, 'month').toDate(),
    );
  }, []);

  return (
    <div>
      <DateTimePickerVisibleMonth
        onNext={goToNext}
        onPrev={goToPrev}
        month={visibleMonth}
        maxDate={maxDate}
      />
      <StyledCalendarRow>
        {weekDayNames.map((weekDay) => (
          <DateTimePickerCellWeekDay key={weekDay}>
            {weekDay.charAt(0)}
          </DateTimePickerCellWeekDay>
        ))}
      </StyledCalendarRow>
      {calendarDays.map((week) => (
        <StyledCalendarRow key={week[0].toISOString()}>
          {week.map((day: Date) => {
            return (
              <DateTimePickerCellDay
                day={day}
                maxDate={maxDate}
                startOfSelected={startOfSelected}
                visibleMonth={visibleMonth}
                onSelect={onSelect}
                key={day.toISOString()}
              />
            );
          })}
        </StyledCalendarRow>
      ))}
    </div>
  );
};
