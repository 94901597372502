import React, { FunctionComponent, SVGProps } from 'react';

const CurveDelete = (props) => (
  <svg viewBox="0 0 48 48" width="1em" height="1em" {...props}>
    <path
      fill="#231f20"
      d="M8.636 11.464L5.101 15l-2.828-2.828 3.536-3.536L2.272 5.1 5.1 2.272l3.536 3.536 3.536-3.536L15 5.1l-3.536 3.536L15 12.172 12.172 15l-3.536-3.536zm1.672 4.501l-1.672-1.672-.602.602c2.254 8.871 9.294 24.945 30.866 27.1l.199-1.99c-19.442-1.943-26.353-15.478-28.791-24.04z"
    />
  </svg>
);

export default CurveDelete;
