import React, { FunctionComponent, SVGProps } from 'react';

const BrandReadManual31X24 = (props) => (
  <svg viewBox="0 0 31 24" width="1em" height="1em" {...props}>
    <path d="M30.797 2.366v19.68a.686.686 0 01-.608.797c-3.086.98-6.446.554-9.189-1.166a5.391 5.391 0 00-5.143-.317c-.276.102-.58.102-.857 0a5.511 5.511 0 00-5.271.343 10.971 10.971 0 01-9.343 1.071C0 22.646.05 22.39.05 22.131V2.4c.54.154 1.029.309 1.526.429 2.66.674 5.48.218 7.792-1.26a6.411 6.411 0 015.605-.532c.305.093.63.093.935 0a6.42 6.42 0 015.657.566 9.909 9.909 0 008.571.977l.66-.214zM1.071 3.814V21.48a.506.506 0 00.489.583c2.63.654 5.411.218 7.714-1.209a6.283 6.283 0 014.286-.917c.471.06.934.163 1.414.257a2.657 2.657 0 000-.308v-17.4a.497.497 0 00-.403-.54 5.4 5.4 0 00-4.8.523A10.577 10.577 0 013.343 4.08c-.772-.043-1.5-.171-2.272-.266zm28.698-.043l-.532.095a10.783 10.783 0 01-8.108-1.397 5.434 5.434 0 00-4.586-.6.703.703 0 00-.6.814V20.22l.497-.111a6.694 6.694 0 015.083.685c1.048.59 2.17 1.04 3.334 1.337a8.949 8.949 0 004.912-.197V3.771z" />
    <path d="M19.903 11.76c-.112-.969-.112-.969.737-.969h4.406v5.649c0 .446.103.66.583.6.305-.016.611-.016.917 0V18h-6.18c-.369 0-.523-.086-.523-.489a.437.437 0 01.531-.54c1.055 0 1.055 0 1.055-1.045v-3.669c0-.454-.146-.591-.575-.557-.428.034-.617.06-.951.06zm5.117-3.986A1.8 1.8 0 1123.229 6a1.817 1.817 0 011.791 1.774z" />
  </svg>
);

export default BrandReadManual31X24;
