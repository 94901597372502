import React, { FunctionComponent, SVGProps } from 'react';

const WlanConnectionBroken = (props) => (
  <svg viewBox="0 0 48 48" width="1em" height="1em" {...props}>
    <path
      fill="#231f20"
      d="M42 35.314L39.686 33H42v2.314zM25 11h7v4h10V5H32v4h-9v7.314l2 2V11zm17 8H32v4h-2.314l2 2H32v.314L35.686 29H42V19zm-26 4v-2.373L14.373 19H6v10h10v-4h4.373l-2-2H16zM6.387 2.529L3.559 5.358l39.113 39.113 2.828-2.828L6.387 2.529zM32 37h-7v-7.373l-2-2V39h9v4h6.373L32 36.627V37z"
    />
  </svg>
);

export default WlanConnectionBroken;
