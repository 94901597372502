import styled, { css } from 'styled-components';
import { NO_STYLES } from '@dls/react-beta-utils';

type TextBoxContainerProps = {
  gutterBottom?: boolean;
};

export const TextBoxContainer = styled.span<TextBoxContainerProps>`
  display: flex;
  flex: 1;
  position: relative;

  ${(props) =>
    props.gutterBottom
      ? css`
          margin-bottom: 8px;
        `
      : NO_STYLES}
`;
