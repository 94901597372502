import { Density } from '@dls/react-beta-utils';
import { BaseComponentProps } from '@dls/react-beta-base-component';

export const ColumnDensities = {
  touch: {
    minHeight: 56,
    horzPadding: 12,
    horzSpace: 8,
  },
  regular: {
    minHeight: 48,
    horzPadding: 8,
    horzSpace: 8,
  },
  dense: {
    minHeight: 40,
    horzPadding: 4,
    horzSpace: 4,
  },
};

export interface ColumnProps extends BaseComponentProps {
  children?: React.ReactNode;
  className?: string;
  density?: Density;
  hidden?: boolean;
  key?: string | number;
}
