import React, { FunctionComponent, SVGProps } from 'react';

const BrandGS137X24 = (props) => (
  <svg viewBox="0 0 37 24" width="1em" height="1em" {...props}>
    <g fill="#000" fillRule="nonzero">
      <path d="M35.15 1.846v20.308H1.85V1.846h33.3zM37 0H0v24h37V0z" />
      <path d="M16.117 7.69l-.619 1.292a4.015 4.015 0 00-1.929-.573c-1.689 0-2.483 1.477-2.483 3.194 0 1.846.766 3.139 2.474 3.139.481.012.948-.163 1.302-.49v-1.726h-1.939v-1.31h3.48v3.553a3.748 3.748 0 01-3.055 1.45c-2.363 0-4.071-1.847-4.071-4.68 0-2.834 1.846-4.68 4.237-4.68a4.55 4.55 0 012.603.83zm1.255 7.938l.508-1.366c.607.33 1.284.51 1.975.526 1.071 0 1.45-.508 1.45-1.034 0-.766-.748-1.09-1.616-1.477-1.107-.508-2.252-1.172-2.252-2.843 0-1.67 1.218-2.539 2.898-2.539.863-.002 1.714.2 2.483.591l-.461 1.33a5.05 5.05 0 00-1.846-.453 1.108 1.108 0 00-1.33 1.062c0 .747.656.997 1.634 1.449 1.2.563 2.262 1.126 2.262 2.788 0 1.846-1.31 2.603-3.065 2.603a5.806 5.806 0 01-2.64-.637zM25.782 7.052h1.227v9.056h-1.661V9.055l-1.2.517-.517-1.19z" />
    </g>
  </svg>
);

export default BrandGS137X24;
