import React from 'react';
import styled from 'styled-components';
import { Typography } from '@dls/react-beta-typography';
import { PopoverContentProps } from './types';
import { wrapStringChildWith } from '@dls/react-beta-utils';

const wrapChildren = wrapStringChildWith(({ children }) => (
  <Typography as="p">{children}</Typography>
));

export const PopoverContent: any = styled.div.attrs(
  wrapChildren,
)<PopoverContentProps>`
  padding: ${(props): number => (props.noSpaceAbove ? 0 : 16)}px 16px 16px 16px;
`;
