import React, { FunctionComponent, SVGProps } from 'react';

const Warning = (props) => (
  <svg viewBox="0 0 24 24" width="1em" height="1em" {...props}>
    <path
      fillRule="evenodd"
      d="M11.019 16.5h2V15h-2v1.5zm0-2.5h2V9.5h-2V14zm7.906 3.188c.125.229.145.416.062.562a.489.489 0 01-.437.25H5.487a.488.488 0 01-.437-.25c-.083-.146-.063-.333.062-.562l6.469-10.907c.104-.187.25-.281.438-.281.187 0 .333.094.437.281l6.469 10.907z"
    />
  </svg>
);

export default Warning;
