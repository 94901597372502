import React, { FunctionComponent, SVGProps } from 'react';

const ExclamationCircle16 = (props) => (
  <svg viewBox="0 0 16 16" width="1em" height="1em" {...props}>
    <path
      fill="#000"
      fillRule="nonzero"
      d="M8 1a7 7 0 100 14A7 7 0 008 1zm.018 12c-.576 0-1.026-.42-1.026-.993 0-.574.45-1.007 1.026-1.007.562 0 .974.433.974 1.007 0 .56-.4.993-.975.993zM9 10H7V2.5h2V10z"
    />
  </svg>
);

export default ExclamationCircle16;
