import React, { FunctionComponent, SVGProps } from 'react';

const BrandRxonly54X24 = (props) => (
  <svg viewBox="0 0 54 24" width="1em" height="1em" {...props}>
    <path d="M11.433 7.117a2.582 2.582 0 011.954.746c.515.513.786 1.218.746 1.94a2.482 2.482 0 01-1.63 2.493l1.863 3.858h-1.471l-1.75-3.692H9.542v3.692H8.156V7.117h3.277zM9.57 11.253h1.76c.802 0 1.452-.645 1.452-1.44 0-.796-.65-1.44-1.453-1.44H9.543v2.88h.028zM20.585 16.164h-1.49l-1.406-2.456-1.396 2.456h-1.397l1.993-3.324-1.993-3.396h1.443l1.443 2.464 1.406-2.464h1.36l-1.983 3.387zM30.165 12.804c0 2.03-1.2 3.489-3.026 3.489-1.824 0-3.025-1.459-3.025-3.49 0-2.03 1.2-3.489 3.025-3.489s3.026 1.45 3.026 3.49zm-4.655 0c0 1.273.559 2.298 1.639 2.298s1.648-1.025 1.648-2.298c0-1.274-.55-2.299-1.648-2.299-1.099 0-1.658 1.006-1.658 2.299h.02zm6.955-3.36l.233 1.07a1.947 1.947 0 011.862-1.2c1.098 0 2.057.748 2.057 2.77v4.07h-1.34V12c0-1.006-.466-1.523-1.173-1.523a1.53 1.53 0 00-1.053.575 1.504 1.504 0 00-.307 1.152v3.923h-1.34V9.444h1.07-.01zm7.094-2.742v7.985c0 .313.093.452.354.452.132-.006.263-.025.391-.055v1.061c-.302.09-.615.136-.93.139-.727 0-1.118-.462-1.118-1.459V6.702h1.34-.037zM42.176 9.444l1.517 5.058 1.425-5.058h1.433l-2.793 8.907h-1.452l.642-2.067-2.178-6.85h1.406z" />
    <path d="M52.138 1.846v20.308H1.862V1.846h50.276zM54 0H0v24h54V0z" />
  </svg>
);

export default BrandRxonly54X24;
