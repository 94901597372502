enum CalendarOverlayTestIds {
  OverlayOuter = 'DateTimePicker-CalendarOverlay-StyledOverlayOuter',
  TimePicker = 'DateTimePicker-CalendarOverlay-TimePicker',
}

enum InputTestIds {
  InputIconButton = 'DateTimePicker-InputTest-InputIconButton',
}

type DateTimePickerTestIds = Readonly<{
  CalendarOverlay: typeof CalendarOverlayTestIds;
  Input: typeof InputTestIds;
}>;

export const dateTimePickerTestIds: DateTimePickerTestIds = {
  CalendarOverlay: CalendarOverlayTestIds,
  Input: InputTestIds,
};
