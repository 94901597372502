import componentColor, { dls } from '@dls/react-colors';
import styled, { css } from 'styled-components';
import { Content } from '@dls/react-beta-content';
import { NavigationBarProps } from './types';

export const NavigationBar = styled(Content)<NavigationBarProps>`
  display: flex;
  align-items: center;
  min-height: 48px;
  ${(props) => {
    const variant = props.variant || 'primary';
    return css`
      background-color: ${componentColor(
        dls.navigation.background[variant].normal,
      )};
      color: ${componentColor(dls.navigation.text[variant].normal)};
    `;
  }}
`;
