import componentColor, { dls } from '@dls/react-colors';
import styled from 'styled-components';
import { em } from '@dls/react-theme';

export const StyledBanner = styled.div`
  & .MuiBox-root {
    padding: ${em(16)};
    padding-left: ${em(12)};

    border-radius: ${em(2)};
    overflow: hidden;
    position: relative;
    display: block;
    border: 1px solid ${componentColor(dls.notification.border.default.normal)};
    border-left: ${em(4)} solid;
    border-left-color: ${componentColor(dls.notification.icon.default.normal)};
    background-color: ${componentColor(
      dls.notification.background.default.normal,
    )};

    box-shadow: 0 ${em(8)} ${em(24)}
      ${componentColor(dls.notification.shadowLevelTwo.default.normal)};

    color: ${componentColor(dls.notification.text.default.normal)};
    &.localBanner {
      margin-top: ${em(8)};
    }
    & .MuiGrid-container {
      margin: unset;
      & .MuiGrid-item {
        display: flex;
        width: 100%;
        padding: unset;

        & .withIcon {
          color: ${componentColor(dls.notification.icon.default.normal)};
          height: ${em(24)};
          width: ${em(24)};
          margin-right: 8px;
        }
        &.iconHide,
        &.buttonHide {
          display: none;
        }
        &.iconShow,
        &.buttonShow {
          display: flex;
          flex-direction: row-reverse;
          padding: unset;
          button {
            padding: 0 ${em(8)};
            margin: ${em(-8)} 0;
          }
        }

        & .actionContainer {
          height: ${em(40)};
          width: ${em(40)};
          display: flex;
          margin: ${em(-8)} ${em(8)};
        }
        & h6 {
          margin: 0 ${em(8)} 0 0;
          display: flex;

          line-height: ${em(24)};
          font-size: ${em(16)};
          vertical-align: middle;

          color: ${componentColor(dls.notification.header.default.normal)};
        }
        & .message {
          margin: 0;
          padding: 0;
          line-height: ${em(24, 14)};
          display: flex;
          font-size: ${em(14)};
          align-items: center;
          p {
            margin-top: ${em(8)};
          }
        }

        & .closeIcon {
          height: ${em(24)};
          width: ${em(24)};
          display: flex;
          margin: auto;
        }
      }
    }

    &.Info {
      border-left-color: ${componentColor(
        dls.notification.iconInfo.default.normal,
      )};
      & .dlsIcon {
        height: ${em(24)};
        width: ${em(24)};
        color: ${componentColor(dls.notification.iconInfo.default.normal)};
      }
      h6 {
        color: ${componentColor(
          dls.notification.headerInfo.default.normal,
        )} !important;
      }
    }
    &.InfoHigh {
      border-left-color: ${componentColor(
        dls.notification.iconInfo.default.normal,
      )};
      & .dlsIcon {
        color: ${componentColor(dls.notification.iconInfo.default.normal)};
      }
      h6 {
        color: ${componentColor(
          dls.notification.headerInfoHigh.default.normal,
        )} !important;
      }
    }
    &.InfoHigher {
      border-left-color: ${componentColor(
        dls.notification.iconInfo.default.normal,
      )};
      & .dlsIcon {
        color: ${componentColor(dls.notification.iconInfo.default.normal)};
      }

      h6 {
        color: ${componentColor(
          dls.notification.headerInfoHigher.default.normal,
        )} !important;
      }
    }
    &.InfoHighest {
      border-left-color: ${componentColor(
        dls.notification.iconInfo.default.normal,
      )};
      & .dlsIcon {
        color: ${componentColor(dls.notification.iconInfo.default.normal)};
      }

      h6 {
        color: ${componentColor(
          dls.notification.headerInfoHighest.default.normal,
        )} !important;
      }
    }
    &.InfoOnPar {
      border-left-color: ${componentColor(
        dls.notification.iconInfo.default.normal,
      )};
      & .dlsIcon {
        color: ${componentColor(dls.notification.iconInfo.default.normal)};
      }
      h6 {
        color: ${componentColor(
          dls.notification.headerInfoOnpar.default.normal,
        )} !important;
      }
    }
    &.Warning {
      border-left-color: ${componentColor(
        dls.notification.iconWarning.default.normal,
      )};
      & .dlsIcon {
        color: ${componentColor(dls.notification.iconWarning.default.normal)};
      }

      h6 {
        color: ${componentColor(
          dls.notification.headerWarning.default.normal,
        )} !important;
      }
    }
    &.WarningHigh {
      border-left-color: ${componentColor(
        dls.notification.iconWarning.default.normal,
      )};
      & .dlsIcon {
        color: ${componentColor(dls.notification.iconWarning.default.normal)};
      }

      h6 {
        color: ${componentColor(
          dls.notification.headerWarningHigh.default.normal,
        )} !important;
      }
    }
    &.Success {
      border-left-color: ${componentColor(
        dls.notification.iconSuccess.default.normal,
      )};
      & .dlsIcon {
        color: ${componentColor(dls.notification.iconSuccess.default.normal)};
      }

      h6 {
        color: ${componentColor(
          dls.notification.headerSuccess.default.normal,
        )} !important;
      }
    }
    &.Error {
      border-left-color: ${componentColor(
        dls.notification.iconError.default.normal,
      )};
      & .dlsIcon {
        color: ${componentColor(dls.notification.iconError.default.normal)};
      }

      h6 {
        color: ${componentColor(
          dls.notification.headerError.default.normal,
        )} !important;
      }
    }

    &.ErrorHigh {
      border-left-color: ${componentColor(
        dls.notification.iconError.default.normal,
      )};
      & .dlsIcon {
        color: ${componentColor(dls.notification.iconError.default.normal)};
      }

      h6 {
        color: ${componentColor(
          dls.notification.headerErrorHigh.default.normal,
        )} !important;
      }
    }
  }

  & .hide {
    display: none;
    transition: 1s;
  }
  & .loaded {
    top: 0;
    display: block;
    transition: 1s;
  }
`;
