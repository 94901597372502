import componentColor, { dls } from '@dls/react-colors';
import styled, { css } from 'styled-components';
import { pressed, disabled, focus, hover } from '@dls/react-beta-utils';
import { NavigationBarItemProps } from './types';

export const NavigationBarItem = styled.a<NavigationBarItemProps>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  padding: 0 24px;
  border: 0;
  outline: 0;

  ${(props) => {
    const normalOnOff = props.active ? 'normalOn' : 'normalOff';
    return css`
      background-color: ${componentColor(
        dls.navigationItem.background.default[normalOnOff],
      )};
      color: ${componentColor(dls.navigationItem.text.default[normalOnOff])};
    `;
  }}

  ${hover} {
    color: ${componentColor(dls.navigationItem.text.default.hover)};
  }

  ${focus} {
    color: ${componentColor(dls.navigationItem.text.default.focus)};
  }

  ${pressed} {
    color: ${componentColor(dls.navigationItem.text.default.pressed)};
  }

  ${disabled} {
    color: ${componentColor(dls.navigationItem.text.default.disabled)};
  }
`;
