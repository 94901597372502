import getComponentColor, { dls } from '@dls/react-colors';
import MuiChip, { ChipProps as MuiChipProps } from '@material-ui/core/Chip';
import { FC } from 'react';
import styled from 'styled-components';
import { Icon } from '@dls/react-icon';
import * as React from 'react';
import { em, svgScaling } from '@dls/react-theme';

export const Chip: FC<MuiChipProps> = styled(MuiChip).attrs((props) => {
  return {
    clickable: true,
    disableRipple: true,
    disabled: props.disabled,
    deleteIcon: <Icon name={'CrossCircle'} size={'m'} />,
    ...props,
  } as MuiChipProps;
})`
  && {
    &.MuiChip-root {
      font-size: ${em(16)};
      margin: ${em(4)} ${em(4)};
      height: ${em(32)};
      border-radius: ${em(32)};
      background-color: ${getComponentColor(
        dls.token.background.default.normal,
      )};
      .pt-touch & {
        margin: ${em(8)} ${em(4)};
      }
      & .MuiChip-label {
        padding-left: ${em(12)};
        padding-right: ${em(12)};
        height: ${em(24)};
        line-height: ${em(22)};
        color: ${getComponentColor(dls.token.text.default.normal)};
        font-family: CentraleSans, Tahoma, Arial, Helvetica, sans-serif;
        font-weight: normal;
        font-style: normal;
      }
      &:hover {
        background-color: ${getComponentColor(
          dls.token.background.default.hover,
        )};
      }
      &:focus {
        background-color: ${getComponentColor(
          dls.token.background.default.focus,
        )};
        & .MuiChip-label {
          color: ${getComponentColor(dls.token.text.default.focus)};
        }
      }
      &:active {
        background-color: ${getComponentColor(
          dls.token.background.default.pressed,
        )};
        & .MuiChip-label {
          color: ${getComponentColor(dls.token.text.default.normal)};
        }
      }
      &.MuiChip-clickable {
        &:hover {
          background-color: ${getComponentColor(
            dls.token.background.default.hover,
          )};
        }
        &:focus {
          background-color: ${getComponentColor(
            dls.token.background.default.focus,
          )};
        }
        &:active {
          background-color: ${getComponentColor(
            dls.token.background.default.pressed,
          )};
          & .MuiChip-label {
            color: ${getComponentColor(dls.token.text.default.normal)};
          }
          box-shadow: none;
        }
        &.Mui-focusVisible {
          background-color: ${getComponentColor(
            dls.token.background.default.focus,
          )};
          & .MuiChip-label {
            color: ${getComponentColor(dls.token.text.default.focus)};
          }
        }
      }
      &.MuiChip-deletable {
        & .MuiChip-label {
          padding: 0 0 0 ${em(12)}; /* This is eleimate the 12px right padding of label when delete icon is used */
          color: ${getComponentColor(dls.token.text.default.normal)};
        }
        & .MuiChip-deleteIcon {
          margin: 0 ${em(4)};
          width: ${em(24)};
          height: ${em(24)};
          ${svgScaling(em(24))};
          path {
            fill: ${getComponentColor(dls.token.closeIcon.default.normal)};
          }
          &:hover {
            path {
              fill: ${getComponentColor(dls.token.closeIcon.default.hover)};
            }
          }
        }
        &:focus {
          & .MuiChip-label {
            color: ${getComponentColor(dls.token.text.default.focus)};
          }
          & .MuiChip-deleteIcon {
            path {
              fill: ${getComponentColor(dls.token.closeIcon.default.focus)};
            }
          }
        }
        &:active {
          & .MuiChip-label {
            color: ${getComponentColor(dls.token.text.default.normal)};
          }
          & .MuiChip-deleteIcon {
            path {
              fill: ${getComponentColor(dls.token.closeIcon.default.pressed)};
            }
          }
        }
      }
      & .MuiChip-avatar {
        width: ${em(32)};
        height: ${em(32)};
        font-size: ${em(16)};
        margin-left: 0px;
        margin-right: ${em(
          -4,
        )}; /* This is distribute the 4px towards avatar and 8px as label padding */
        background-color: ${getComponentColor(
          dls.token.circleBackground.default.normal,
        )};
        color: ${getComponentColor(dls.token.circleText.default.normal)};
        font-family: CentraleSans, Tahoma, Arial, Helvetica, sans-serif;
        font-weight: normal;
        font-style: normal;
        &:focus {
          color: ${getComponentColor(dls.token.circleText.default.focus)};
        }

        span {
          ${svgScaling(em(24))};
        }
      }
      &.Mui-disabled {
        background-color: ${getComponentColor(
          dls.token.background.default.disabled,
        )};
        & .MuiChip-label {
          color: ${getComponentColor(dls.token.text.default.disabled)};
        }
        & .MuiChip-deletable {
          & .MuiChip-label {
            color: ${getComponentColor(dls.token.text.default.disabled)};
          }
        }
        & .MuiChip-deleteIcon {
          path {
            fill: ${getComponentColor(dls.token.closeIcon.default.disabled)};
          }
        }
        & .MuiChip-avatar {
          background-color: ${getComponentColor(
            dls.token.circleBackground.default.disabled,
          )};
          color: ${getComponentColor(dls.token.circleText.default.disabled)};
          &.avatar-image {
            opacity: 0.35;
          }
        }
      }
    }
  }
`;
