import React, { FunctionComponent, SVGProps } from 'react';

const TrimTool = (props) => (
  <svg viewBox="0 0 48 48" width="1em" height="1em" {...props}>
    <path
      fill="#231f20"
      d="M2 36a4 4 0 004 4h15v-8H6a4 4 0 00-4 4zm17 2H6c-1.103 0-2-.897-2-2s.897-2 2-2h13v4zm-5-14a4 4 0 004 4h3v-8h-3a4 4 0 00-4 4zm5 2h-1c-1.103 0-2-.897-2-2s.897-2 2-2h1v4zm27-2a4 4 0 01-4 4H27v4h3a4 4 0 010 8h-3v6h-4V2h4v6h11a4 4 0 010 8H27v4h15a4 4 0 014 4zM5 12a4 4 0 004 4h12V8H9a4 4 0 00-4 4zm14 2H9c-1.103 0-2-.897-2-2s.897-2 2-2h10v4z"
    />
  </svg>
);

export default TrimTool;
