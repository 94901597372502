import React, { useState, useMemo, useCallback, FC } from 'react';
import PropTypes from 'prop-types';
import { isFunction } from 'lodash';
import { BaseComponent } from '@dls/react-beta-base-component';
import ExpanderContext from './ExpanderContext';

export type ExpanderProps = any;

export const expanderDensities = {
  regular: {
    minHeight: 48,
    padding: 12,
    separatePadding: 16,
  },
  dense: {
    minHeight: 40,
    padding: 8,
    separatePadding: 8,
  },
};

const updateChildrenProps = (
  children,
  allowMultipleExpanded,
  currentItemState,
) => {
  let expanded;
  let initialExpander;
  return React.Children.map(children, (child, index) => {
    expanded = false;
    if (React.isValidElement(child)) {
      if (allowMultipleExpanded) {
        expanded = (child.props as any).expanded;
      } else if (currentItemState.index !== -1) {
        expanded =
          currentItemState.index === index && currentItemState.expanded;
      } else if (!initialExpander && (child.props as any).expanded) {
        initialExpander = true;
        expanded = (child.props as any).expanded;
      }
      return React.cloneElement(child, {
        index,
        expanded: expanded,
      } as any);
    }
  });
};

const Expander: FC<ExpanderProps> = (props) => {
  const {
    allowMultipleExpanded,
    children,
    density,
    divider,
    onChange,
    quiet,
    separateActiveExpander,
    ...other
  } = props;
  const [currentItemState, setCurrentItemState] = useState({
    index: -1,
    expanded: false,
    value: null,
  });
  const onChangeItem = useCallback(
    (itemState) => {
      const { index, value, expanded } = itemState;
      setCurrentItemState(itemState);
      if (isFunction(onChange)) {
        onChange({ index, value, expanded });
      }
    },
    [onChange],
  );
  const context = useMemo(
    () => ({
      allowMultipleExpanded,
      currentItemState,
      density,
      divider,
      onChangeItem,
      quiet,
      separateActiveExpander,
    }),
    [
      allowMultipleExpanded,
      currentItemState,
      density,
      divider,
      onChangeItem,
      quiet,
      separateActiveExpander,
    ],
  );
  return (
    <ExpanderContext.Provider value={context}>
      <BaseComponent className="expander" {...other}>
        {updateChildrenProps(children, allowMultipleExpanded, currentItemState)}
      </BaseComponent>
    </ExpanderContext.Provider>
  );
};

Expander.propTypes = {
  allowMultipleExpanded: PropTypes.bool,
  children: PropTypes.node,
  density: PropTypes.oneOf(['regular', 'dense']),
  divider: PropTypes.bool,
  onChange: PropTypes.func,
  quiet: PropTypes.bool,
  separateActiveExpander: PropTypes.bool,
};

Expander.defaultProps = {
  allowMultipleExpanded: true,
  density: 'regular',
  divider: true,
  quiet: false,
};

export default Expander;
