import moment from 'moment';
import { takeWhile, range } from 'lodash';
import { DAYS_IN_WEEK } from '@dls/react-beta-utils';

const MAX_WEEKS_IN_MONTH = 6;

export const getCalendarDays = (current: Date) => {
  const firstWeekOfMonth = moment(current).startOf('month').startOf('week');
  const availableWeeks = takeWhile(
    range(MAX_WEEKS_IN_MONTH).map((i) =>
      firstWeekOfMonth.clone().add(i, 'week'),
    ),
    (weekStart, i) => i < 1 || weekStart.month() === current.getMonth(),
  );
  const weekDays = new Array(DAYS_IN_WEEK).fill(undefined);
  const weeksWithDays = availableWeeks.map((weekStart) =>
    weekDays.map((_, index) => weekStart.clone().add(index, 'day').toDate()),
  );
  return weeksWithDays;
};
