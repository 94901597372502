import React, { useCallback, useMemo } from 'react';
import reactDom from 'react-dom';

import { DialogTheme } from '@dls/react-beta-utils';

import DialogContext from './DialogContext';
import { DialogBase, OverlayContainer } from './styles';
import { DialogProps } from './types';

export const Dialog: React.FC<DialogProps> = (props) => {
  const handleBodyClick = useCallback((e) => {
    e.stopPropagation();
  }, []);

  const { open, children, type, handleClose, ...otherProps } = props;
  const context = useMemo(
    () => ({
      handleClose,
    }),
    [handleClose],
  );

  const dialog = (
    <DialogContext.Provider value={context}>
      <OverlayContainer
        type={type}
        onClick={type !== 'modeless' ? handleClose : null}>
        <DialogTheme>
          <DialogBase
            className="dialog"
            as="aside"
            {...otherProps}
            onClick={handleBodyClick}>
            {children}
          </DialogBase>
        </DialogTheme>
      </OverlayContainer>
    </DialogContext.Provider>
  );

  return open && reactDom.createPortal([dialog], document.body);
};

Dialog.displayName = 'Dialog';
