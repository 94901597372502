import componentColor, { dls } from '@dls/react-colors';
import styled from 'styled-components';
import { em, svgScaling } from '@dls/react-theme';

//Paper is used by most components where PopUp comes(ex: Dropdown, token, etc).
//This is a common code for the above components.

export const Paper = styled.div`
  &.DLSDropdownList,
  &.DLSTokenOverflowList {
    margin-top: ${em(6)};
    background-color: ${componentColor(dls.popup.background.default.normal)};
    border: 1px solid ${componentColor(dls.popup.border.default.normal)};

    & .MuiList-root,
    &.DLSTokenOverflowList {
      /* Reusing the Dropdown List component
         for the Token Overflow List by just adding comma separated classname
         & it doesnot break any dropdown functionality */
      & .MuiListItem-root {
        height: ${em(40)};
        background-color: ${componentColor(
          dls.listItem.background.default.normalOff,
        )};
        color: ${componentColor(dls.listItem.text.default.normalOff)};
        display: flex;
        font-family: CentraleSans, Tahoma, Arial, Helvetica, sans-serif;
        font-weight: normal;
        font-style: normal;
        &.MuiListItem-gutters {
          padding-left: ${em(12)};
          padding-right: ${em(12)};
          justify-content: left;
        }
        & span:nth-child(2) {
          padding-left: ${em(12)};
        }
        & .DLSIcon {
          color: ${componentColor(dls.listItem.icon.default.normalOff)};
        }
        &.Multiselect-checkbox {
          & .MuiCheckbox-root {
            padding: 0px;
            & .MuiIconButton-label {
              color: ${componentColor(dls.listItem.text.default.normalOff)};
              padding-right: ${em(12)};
            }
          }
          & span:nth-child(2) {
            padding-left: ${em(0)};
          }
          & span:nth-child(3) {
            padding-left: ${em(12)};
          }
          &.Mui-selected {
            & .MuiCheckbox-root {
              padding: ${em(0)};
              & .MuiIconButton-label {
                color: ${componentColor(dls.listItem.text.default.normalOn)};
              }
            }
          }
        }
        &:focus {
          background-color: ${componentColor(
            dls.listItem.background.default.focusOn,
          )};
        }

        &:hover {
          background-color: ${componentColor(
            dls.listItem.background.default.hover,
          )};
        }
        &:active {
          background-color: ${componentColor(
            dls.listItem.background.default.pressed,
          )};
        }
        &.Mui-selected {
          background-color: ${componentColor(
            dls.listItem.background.default.normalOn,
          )};
          color: ${componentColor(dls.listItem.text.default.normalOn)};
          & .DLSIcon {
            color: ${componentColor(dls.listItem.icon.default.normalOn)};
          }
        }
        &.Mui-disabled {
          background-color: ${componentColor(
            dls.listItem.background.default.disabledOn,
          )};
          color: ${componentColor(dls.listItem.text.default.disabled)};
        }
      }
    }
    /* Menu Dense styles for both dropdown & multiselect */
    &.pt-dense {
      margin-top: ${em(0)};
      & .MuiList-root {
        &.MuiList-padding {
          padding-top: ${em(4)};
          padding-bottom: ${em(4)};
        }
        & .MuiListItem-root {
          height: ${em(32)};
          &.MuiListItem-gutters {
            padding-left: ${em(8)};
            padding-right: ${em(8)};
          }
          & span:nth-child(2) {
            padding-left: ${em(8)};
          }

          &.Multiselect-checkbox {
            & .MuiCheckbox-root {
              padding: ${em(0)};
              & .MuiIconButton-label {
                padding-right: ${em(8)}, ${em(24)};
              }
            }
            & span:nth-child(2) {
              padding-left: ${em(0)};
            }
            & span:nth-child(3) {
              padding-left: ${em(8)};
            }
            &.Mui-selected {
              & .MuiCheckbox-root {
                padding: ${em(0)};
              }
            }
          }
        }
      }
    }
    /* Menu Touch styles for both dropdown & multiselect */
    &.pt-touch {
      & .MuiList-root {
        & .MuiListItem-root {
          height: ${em(48)};
        }
      }
    }

    /* This is very specific to Tabs Overflow example from @dls/react-mui-tabs */
    &.DLSTabsDropdownList {
      margin-top: ${em(2)};

      & .MuiList-padding {
        padding-top: 0;
        & .MuiListItem-root {
          &.Mui-selected {
            & .overflow-tab-icon {
              visibility: visible;
            }
          }
          & .overflow-tab-icon {
            ${svgScaling(em(24))};
            visibility: hidden;
            margin: 0 ${em(8)};
          }
          &.MuiListItem-gutters {
            padding-left: 0;
          }
        }
      }
    }
  }
  /* Styles for Autocomplete Paper Component (Popup) Starts here */
  &.DLS-Autocomplete {
    margin: 0;
    min-width: max-content;
    background: ${componentColor(dls.popup.background.default.normal)};
    border: ${em(1)} solid ${componentColor(dls.popup.border.default.normal)};
    box-shadow: 0 ${em(8)} ${em(24)}
      ${componentColor(dls.shadow.shadow.levelOne.normal)};
    &.DLS-ComboBox {
      box-shadow: 0 ${em(1)} ${em(2)}
        ${componentColor(dls.shadow.shadow.levelOne.normal)};
    }
    /* Styles for ListBox Component (option container) Starts here */
    & .MuiAutocomplete-listbox {
      padding: ${em(8, 16)} 0;
      background: ${componentColor(dls.popup.background.default.normal)};
      & .MuiAutocomplete-option {
        background: ${componentColor(
          dls.listItem.background.default.normalOff,
        )};
        color: ${componentColor(dls.listItem.text.default.normalOff)};
        &:hover {
          background: ${componentColor(dls.listItem.background.default.hover)};
        }
        &:focus {
          background: ${componentColor(
            dls.listItem.background.default.focusOn,
          )};
        }
        &[data-focus='true'] {
          background-color: ${componentColor(
            dls.listItem.background.default.focusOn,
          )};
        }
        &:active {
          background-color: ${componentColor(
            dls.listItem.background.default.pressed,
          )};
        }
        &[aria-selected='true'] {
          background: ${componentColor(
            dls.listItem.background.default.normalOn,
          )};
          color: ${componentColor(dls.listItem.text.default.normalOn)};
        }
        height: ${em(40, 16)};
        padding-top: ${em(0)};
        padding-bottom: ${em(0)};
        padding-left: ${em(12, 16)};
        padding-right: ${em(12, 16)};

        /** Font Styles start here */
        font-size: ${em(16)};
        /* stylelint-disable */
        font-family: CentraleSans, Tahoma, Arial, Helvetica, sans-serif;
        /* stylelint-enable */
        font-weight: normal;
        font-style: normal;
      }
    }
    &.pt-dense {
      & .MuiAutocomplete-listbox {
        padding: ${em(4, 16)} 0;
        & .MuiAutocomplete-option {
          height: ${em(32, 16)};
          padding-top: ${em(0)};
          padding-bottom: ${em(0)};
          padding-left: ${em(8, 16)};
          padding-right: ${em(8, 16)};
        }
      }
    }
    &.pt-touch {
      & .MuiAutocomplete-option {
        height: ${em(48, 16)};
      }
    }
    & .MuiAutocomplete-noOptions {
      font-size: ${em(16)};
      font-family: CentraleSans, Tahoma, Arial, Helvetica, sans-serif;
      font-weight: normal;
      font-style: normal;
      background: ${componentColor(dls.listItem.background.default.normalOff)};
      color: ${componentColor(dls.listItem.text.default.normalOff)};
    }
    /* Styles for ListBox Component (option container) Ends here */
  }

  /********************** Styles for Table Component Starts here ************************/
  &.dlsTable {
    /* Used for Table Header purpose (Starts) */
    & .dlsTableHeader {
      min-height: ${em(56)};
      padding-left: ${em(24)};
      padding-right: ${em(8)};
      border-top: ${em(1)} solid
        ${componentColor(dls.separator.background.content.normal)};
      border-right: ${em(1)} solid
        ${componentColor(dls.separator.background.content.normal)};
      border-left: ${em(1)} solid
        ${componentColor(dls.separator.background.content.normal)};
      .pt-dense & {
        min-height: ${em(48)};
      }
      .pt-touch & {
        min-height: ${em(64)};
      }
    }
    /* To remove the border-top of the table-container */
    & .dlsTableHeader + .MuiTableContainer-root {
      border-top: none;
    }
    /* Used for Table Header purpose (Ends) */
    & .MuiTablePagination-root {
      font-size: ${em(14)};
      border-right: ${em(1)} solid
        ${componentColor(dls.separator.background.content.normal)};
      border-bottom: ${em(1)} solid
        ${componentColor(dls.separator.background.content.normal)};
      border-left: ${em(1)} solid
        ${componentColor(dls.separator.background.content.normal)};
      & .MuiTablePagination-toolbar {
        min-height: ${em(55, 14)};
        padding-right: ${em(8)};
        & .MuiTablePagination-spacer {
          flex: none;
        }
        & .MuiTablePagination-selectRoot {
          margin-left: ${em(12, 14)};
          margin-right: 0;
          & .MuiTablePagination-select {
            font-size: ${em(12, 14)};
            font-family: CentraleSans, Tahoma, Arial, Helvetica, sans-serif;
            font-weight: normal;
            font-style: normal;
            border-color: ${componentColor(dls.textBox.border.quiet.normal)};
            color: ${componentColor(dls.textBox.text.quiet.normal)};
          }
          & .MuiTablePagination-selectIcon {
            /* top: ${em(2, 24)}; */
            fill: ${componentColor(dls.textBox.icon.quiet.normal)};
          }
        }
        & .MuiTablePagination-caption {
          font-size: ${em(14)};
          display: contents;
          & .labelDisplayedRows {
            position: absolute;
            right: ${em(
              148,
              14,
            )}; /* Revisit once the quiet default buttons has been added to actions */
          }
        }
        & .MuiTablePagination-actions {
          position: absolute;
          right: 0;
          font-size: ${em(24, 16)};
          margin-left: ${em(27, 24)};
          margin-right: ${em(8, 24)};
          & .MuiIconButton-root {
            background-color: transparent;
            font-size: ${em(24, 16)};
            & .MuiIconButton-label {
              svg {
                fill: ${componentColor(dls.button.icon.quietDefault.normal)};
              }
            }
            &.Mui-disabled {
              & .MuiIconButton-label {
                svg {
                  fill: ${componentColor(
                    dls.button.icon.quietDefault.disabled,
                  )};
                }
              }
            }
          }
        }
        .pt-dense & {
          min-height: ${em(47, 14)};
        }
        .pt-touch & {
          min-height: ${em(63, 14)};
        }
      }
    }
  }
  /* Styles for Table Component Ends here */
  /* Styles for Paper Component (Popup) Ends here */

  /* datepicker popover styles add here */
  &.DlsDatePicker {
    background-color: ${componentColor(
      dls.datePicker.background.desktop.normal,
    )};
    box-shadow: 0 ${em(2)} ${em(4)}
      ${componentColor(dls.datePicker.shadowLevelTwo.desktop.normal)};
    margin-top: ${em(6)};
    border-radius: ${em(2)};
    border: 1px solid ${componentColor(dls.dialogAlert.border.default.normal)};
    & .MuiTouchRipple-root {
      display: none;
    }
    & .MuiPickersBasePicker-container {
      .MuiPickersBasePicker-pickerView {
        min-width: auto;
        min-height: auto;
        font-style: normal;
        /* header for navigation */
        & .MuiPickersCalendarHeader-switchHeader {
          margin-top: ${em(0)};
          margin-bottom: ${em(12)};
          padding: ${em(12)} ${em(12)} 0 ${em(12)};
          height: ${em(40)};
          & .MuiPickersCalendarHeader-iconButton {
            height: ${em(40, 24)};
            width: ${em(40, 24)};
            padding: ${em(0)};
            color: ${componentColor(dls.datePicker.monthText.desktop.normal)};
            background-color: transparent;
            &:hover {
              background-color: transparent;
            }
          }
          & .MuiPickersCalendarHeader-transitionContainer {
            height: ${em(40)};
            padding: 0 ${em(8)};
            color: ${componentColor(dls.datePicker.monthText.desktop.normal)};
            & .MuiTypography-root {
              line-height: ${em(40)};
              font-family: CentraleSans, Tahoma, Arial, Helvetica, sans-serif;
              font-weight: normal;
              font-style: normal;
            }
          }
          .pt-dense & {
            height: ${em(32)};
            & .MuiPickersCalendarHeader-iconButton {
              height: ${em(32, 24)};
            }
            & .MuiPickersCalendarHeader-transitionContainer {
              height: ${em(32)};
              & .MuiTypography-root {
                line-height: ${em(32)};
              }
            }
          }
        }
        /* header with Week days */
        & .MuiPickersCalendarHeader-daysHeader {
          max-height: ${em(40)};
          padding: 0 ${em(12)};
          & .MuiPickersCalendarHeader-dayLabel {
            font-size: ${em(16)};
            line-height: ${em(40)};
            margin: 0;
            font-family: CentraleSans, Tahoma, Arial, Helvetica, sans-serif;
            font-weight: normal;
            font-style: normal;
            width: ${em(40)};
            color: ${componentColor(
              dls.datePicker.weekLetterText.desktop.normal,
            )};
          }
        }
        &
          .MuiPickersSlideTransition-transitionContainer.MuiPickersCalendar-transitionContainer {
          margin-top: ${em(4)};
          min-height: auto;
          > div {
            position: relative;
            &.MuiPickersSlideTransition-slideExitActiveLeft-left,
            &.MuiPickersSlideTransition-slideExitActiveLeft-right {
              position: absolute;
            }
          }
          & .MuiPickersCalendar-week {
            padding: 0 ${em(12)};
            &:last-child {
              padding-bottom: ${em(12)};
            }
            & .MuiPickersDay-day {
              width: ${em(40, 12)};
              height: ${em(40, 12)};
              margin: 0;
              transition: none;
              background-color: ${componentColor(
                dls.datePicker.dayNumberBackground.desktop.normalOffFocus,
              )};
              color: ${componentColor(
                dls.datePicker.dayNumberText.desktop.normalOffFocus,
              )};
              &:hover {
                background-color: ${componentColor(
                  dls.datePicker.dayNumberBackground.desktop.hoverOff,
                )};
                color: ${componentColor(
                  dls.datePicker.dayNumberText.desktop.hoverOff,
                )};
              }
              &:focus {
                background-color: ${componentColor(
                  dls.datePicker.dayNumberBackground.desktop.normalOnFocus,
                )};
                color: ${componentColor(
                  dls.datePicker.dayNumberText.desktop.normalOnFocus,
                )};
                box-shadow: 0 0 0 ${em(1)}
                    ${componentColor(dls.datePicker.background.desktop.normal)},
                  0 0 0 ${em(4)}
                    ${componentColor(
                      dls.datePicker.focusOutline.desktop.focusPressed,
                    )};
              }
              & .MuiTypography-root {
                font-size: ${em(16, 12)};
                font-family: CentraleSans, Tahoma, Arial, Helvetica, sans-serif;
                font-weight: normal;
                font-style: normal;
                line-height: ${em(22)};
              }
              &.MuiPickersDay-current {
                border: ${em(1)} solid
                  ${componentColor(
                    dls.datePicker.currentDayBorder.desktop.normal,
                  )};
                color: ${componentColor(
                  dls.datePicker.currentDayBorder.desktop.normal,
                )};
                &.MuiPickersDay-dayDisabled {
                  border: ${em(1)} solid
                    ${componentColor(
                      dls.datePicker.currentDayBorder.desktop.disabled,
                    )};
                  color: ${componentColor(
                    dls.datePicker.currentDayBorder.desktop.disabled,
                  )};
                }
              }
              &.MuiPickersDay-daySelected,
              &.MuiPickersDay-current.MuiPickersDay-daySelected {
                background-color: ${componentColor(
                  dls.datePicker.dayNumberBackground.desktop.normalOnFocus,
                )};
                color: ${componentColor(
                  dls.datePicker.dayNumberText.desktop.normalOnFocus,
                )};
                box-shadow: 0 0 0 ${em(1)}
                    ${componentColor(dls.datePicker.background.desktop.normal)},
                  0 0 0 ${em(4)}
                    ${componentColor(
                      dls.datePicker.focusOutline.desktop.focusPressed,
                    )};
                &:hover {
                  background-color: ${componentColor(
                    dls.datePicker.dayNumberBackground.desktop.hoverOn,
                  )};
                  color: ${componentColor(
                    dls.datePicker.dayNumberText.desktop.hoverOn,
                  )};
                }
              }
              &.MuiPickersDay-dayDisabled {
                background-color: ${componentColor(
                  dls.datePicker.dayNumberBackground.desktop.disabledOff,
                )};
                color: ${componentColor(
                  dls.datePicker.dayNumberText.desktop.disabledOff,
                )};
                &.MuiPickersDay-daySelected,
                &.MuiPickersDay-current.MuiPickersDay-daySelected {
                  background-color: ${componentColor(
                    dls.datePicker.dayNumberBackground.desktop.disabledOn,
                  )};
                  color: ${componentColor(
                    dls.datePicker.dayNumberText.desktop.disabledOn,
                  )};
                }
              }
            }
          }
        }
      }
    }
  }
`;
