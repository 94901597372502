import componentColor, { dls } from '@dls/react-colors';
import styled from 'styled-components';
import { FC } from 'react';
import MuiDivider, { DividerProps } from '@material-ui/core/Divider';
import { em } from '@dls/react-theme';

/** Divider with its content */
export const Divider: FC<DividerProps> = styled(MuiDivider).attrs((props) => {
  return {
    ...props,
  } as DividerProps;
})`
  && {
    &.DLS-Typography {
      font-size: ${em(16)};
    }
    /** Divider styles start here*/
    &.DLS-divider {
      margin: ${em(8)} 0 ${em(8)} 0;
      border-image-width: 0px;
      height: ${em(1)};
      background-color: ${componentColor(
        dls.separator.background.content.normal,
      )};
      .pt-dense & {
        margin: ${em(4)} 0 ${em(4)} 0;
      }
    }

    /** Divider styles for list view*/
    &.DLS-divider-in-list {
      margin: 0px;
      border-image-width: 0px;
      height: ${em(1)};
      background-color: ${componentColor(
        dls.separator.background.content.normal,
      )};
      .pt-dense & {
        margin: 0px;
      }
    }

    /** Vertical Divider styles start here*/
    &.DLS-divider-vertical {
      margin: 0 ${em(8)} 0 ${em(8)};
      width: ${em(1)};
      background-color: ${componentColor(
        dls.separator.background.content.normal,
      )};
      .pt-dense & {
        margin: 0 ${em(4)} 0 ${em(4)};
      }
    }

    /** Vertical Divider styles with no padding*/
    &.DLS-divider-vertical-no-padding {
      margin: 0px;
      width: ${em(1)};
      background-color: ${componentColor(
        dls.separator.background.content.normal,
      )};
      .pt-dense & {
        margin: 0px;
      }
    }
  }
`;
