import componentColor, { dls } from '@dls/react-colors';
import { useThemeTone, useTheme } from '@dls/react-beta-utils';

export const useDefaultOptions = () => {
  const themeTone = useThemeTone();
  const theme = useTheme();
  return {
    responsive: true,
    maintainAspectRatio: false,
    title: {
      display: true,
      position: 'left',
      fontSize: 14,
      lineHeight: '22px',
      fontFamily: 'CentraleSans',
      fontColor: componentColor(
        dls.dataVisualization.titleText.default.normal,
      )({ theme }),
    },
    legend: {
      display: false,
    },
    tooltips: {
      mode: 'interpolate',
      intersect: false,
      backgroundColor: componentColor(
        dls.tooltip.background[themeTone].normal,
      )({ theme }),
      bodyFontColor: componentColor(dls.tooltip.text[themeTone].normal)({
        theme,
      }),
      titleFontColor: componentColor(dls.tooltip.text[themeTone].normal)({
        theme,
      }),
      titleFontFamily: 'CentraleSans',
      bodyFontFamily: 'CentraleSans',
      titleFontSize: 12,
      bodyFontSize: 12,
      xPadding: 12,
      yPadding: 8,
      cornerRadius: 2,
      shadowOffsetX: 0,
      shadowOffsetY: 2,
      shadowBlur: 4,
      shadowColor: componentColor(dls.shadow.shadow.levelTwo.normal)({ theme }),
    },
    plugins: {
      crosshair: {
        line: {
          color: componentColor(
            dls.dataVisualization.targetLine.charts.normal,
          )({ theme }),
        },
        zoom: {
          enabled: false,
        },
      },
    },
    spanGaps: true,
    scales: {
      xAxes: [
        {
          gridLines: {
            color: componentColor(
              dls.dataVisualization.gridlines.charts.normal,
            )({ theme }),
            zeroLineColor: componentColor(
              dls.dataVisualization.axis.charts.normal,
            )({ theme }),
          },
          type: 'time',
          ticks: {
            padding: 8,
            fontColor: componentColor(
              dls.dataVisualization.axisLabelText.charts.normal,
            )({ theme }),
            fontFamily: 'CentraleSans',
            fontStyle: 'normal',
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            color: componentColor(
              dls.dataVisualization.gridlines.charts.normal,
            )({ theme }),
            zeroLineColor: componentColor(
              dls.dataVisualization.axis.charts.normal,
            )({ theme }),
          },
          ticks: {
            padding: 8,
            fontColor: componentColor(
              dls.dataVisualization.axisLabelText.charts.normal,
            )({ theme }),
            fontFamily: 'CentraleSans',
            fontStyle: 'normal',
          },
        },
      ],
    },
  };
};
