import { css } from 'styled-components';
import componentColor, { dls } from '@dls/react-colors';

const shadowLevel1 = css`
  box-shadow: 0 1px 2px 0 ${componentColor(dls.shadow.shadow.levelOne.normal)};
`;

const shadowLevel1Disabled = css`
  box-shadow: 0 1px 2px 0 ${componentColor(dls.shadow.shadow.levelOne.disabled)};
`;

const shadowLevel2 = css`
  box-shadow: 0 2px 4px 0 ${componentColor(dls.shadow.shadow.levelTwo.normal)};
`;

const shadowLevel3 = css`
  box-shadow: 0 8px 24px 0
    ${componentColor(dls.shadow.shadow.levelThree.normal)};
`;

const shadow = {
  shadowLevel1,
  shadowLevel1Disabled,
  shadowLevel2,
  shadowLevel3,
};

export default shadow;
