import styled from 'styled-components';
import componentColor, { dls } from '@dls/react-colors';

export const StyledRow = styled.div`
  display: flex;
  border-bottom: 1px solid
    ${componentColor(dls.separator.background.content.normal)};
  :hover {
    background: ${componentColor(dls.separator.background.content.normal)};
  }
  :active {
    background: ${componentColor(dls.token.background.default.pressed)};
  }
  &:last-child {
    border-bottom: 0;
  }
`;
