import { DlsIconFontIcon } from '@dls/react-beta-dls-icon-font';

export enum GaugeComponent {
  ValueIndicator = 'valueIndicator',
  GaugeBackground = 'gaugeBackground',
  ValueText = 'valueText',
  UnitText = 'unitText',
  Icon = 'icon',
  TitleText = 'titleText',
  SubtitleText = 'subtitleText',
}

export type GaugeComponentProps = {
  customColor?: string;
};

export type GaugeProps = {
  unit: string;
  value?: string;
  icon?: React.ReactNode | DlsIconFontIcon;
  iconSize?: number;
  title?: string;
  subtitle?: string;
  colors?: Partial<Record<GaugeComponent, string>>;
};
