/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-empty-pattern */

import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Icon } from '@dls/react-icon';
import IconButton from '@material-ui/core/IconButton';
import { CircularProgress } from '@dls/react-mui-circularprogress';
import { Button } from '@dls/react-mui-button';
import styled from 'styled-components';
import { Typography } from '@dls/react-mui-typography';
import { em, svgScaling } from '@dls/react-theme';
import { Link } from '@dls/react-mui-link';
import componentColor, { dls } from '@dls/react-colors';

const StyledFileUpload = styled.div`
  & button & button {
    margin: ${em(8)} 0;
  }
  & ul {
    margin: ${em(8)} 0;
  }

  & .drop_area {
    &.error {
      border: ${em(1)} solid
        ${componentColor(dls.fileUpload.border.draganddrop.validated)};
      background-color: ${componentColor(
        dls.fileUpload.background.draganddrop.validated,
      )};
    }
    width: calc(100% - ${em(150)});
    margin: 0 auto;
    border: ${em(1)} solid
      ${componentColor(dls.fileUpload.border.draganddrop.normal)};
    background-color: ${componentColor(
      dls.fileUpload.background.draganddrop.normal,
    )};
    display: block;
    text-align: center;
    padding: ${em(24)} 0;
    margin-bottom: ${em(12)};
    border-radius: ${em(2)};

    &:hover {
      background-color: ${componentColor(
        dls.fileUpload.background.draganddrop.hover,
      )};
    }
    > div {
      &:focus {
        outline: unset;
      }
    }
    & .MuiTypography-root {
      margin-bottom: ${em(12)} !important;
      color: ${componentColor(
        dls.fileUpload.infoText.draganddrop.normal,
      )} !important;
    }
    & button {
      margin: unset;
    }
  }
  & .default_files {
    & ul {
      padding: unset;
    }
  }
  & .dropzone_files {
    width: calc(100% - ${em(150)});
    margin: 0 auto;
    & ul {
      padding: unset;
    }
  }
`;

/**
 * Interface for the dot container
 */
interface IFileContainer {
  status?: string;
}

const StyledFileContainer = styled.div<IFileContainer>`
  display: flex;
  align-items: center;
  width: ${em(350)};
  border: ${em(1)} solid ${componentColor(dls.fileUpload.border.default.normal)};
  margin-bottom: ${em(4)};
  background-color: ${componentColor(dls.fileUpload.background.default.normal)};
  margin-top: ${(props: IFileContainer) =>
    props.status === 'pending' ? em(8) : 0};

  & .MuiTypography-root {
    &.MuiTypography-subtitle1 {
      color: ${componentColor(
        dls.fileUpload.fileName.default.normal,
      )} !important;
      word-break: break-word;
    }
    &.MuiTypography-subtitle2:not(.errorText) {
      color: ${componentColor(
        dls.fileUpload.secondaryText.default.normal,
      )} !important;
    }
  }
  & .reviseIcon {
    ${svgScaling(em(16))};
    path {
      height: ${em(16)};
      width: ${em(16)};
    }
  }
  & .statusIcon {
    margin-left: ${em(20)};
    margin-right: ${em(16)};
  }

  & .successIcon {
    ${svgScaling(em(24))};
    svg {
      fill: ${componentColor(dls.fileUpload.uploadSuccessIcon.default.normal)};
    }
  }

  & .errorIcon {
    ${svgScaling(em(16))};
    path {
      height: ${em(16)};
      width: ${em(16)};
    }
  }

  & .dls_pending {
    margin-left: auto;
    display: flex;
    align-items: center;
  }
  & .dls_success {
    margin: unset;
    margin-left: auto;
    padding: unset !important;
  }

  & .MuiIconButton-root {
    font-size: ${em(16)};
    color: unset;
    &:hover {
      background-color: unset;
    }
  }

  & .rightIcon {
    font-size: ${em(16)};
    margin: 0 ${em(20)};

    &:not(.cross) {
      svg {
        height: ${em(16)};
        width: ${em(16)};
      }
    }
  }
`;

export const StyledContainer = styled.div`
  display: flex;
  & .MuiTypography-root {
    margin-bottom: unset !important;
  }
  & .MuiButtonBase-root {
    align-items: baseline;
    min-height: unset !important;
    min-width: unset !important;
    height: unset !important;
    width: unset !important;
    padding: unset !important;
  }
`;

export interface IFileProps {
  acceptedDLSFiles: any;
  onDeleteClick: (fileName: any) => void;
  maxCharsize?: number;
  onDrop: (acceptedFiles: any, retry: boolean) => void;
}
export interface IUploadProps {
  type?: 'default' | 'dropzone';
  text?: string;
  dropvalues?: any;
  onFileDrop: (acceptedFiles: any, retry: boolean) => void;
  onDelete: (fileName: any) => void;
  files: any;
  disabled?: boolean;
  maxCharsize?: number;
  maxFilesize: number;
}

function formatBytes(bytes: number, decimals = 2) {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return (
    '( ' +
    parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) +
    ' ' +
    sizes[i] +
    ' )'
  );
}
//eslint-disable no-empty-pattern
export const FileDisplay = (props: IFileProps) => {
  const { acceptedDLSFiles, onDeleteClick, maxCharsize, onDrop } = props;
  const [progress, setProgress] = React.useState(0);
  /* istanbul ignore next */
  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 0 : prevProgress + 10,
      );
    }, 800);

    setTimeout(() => {
      clearInterval(timer);
    }, 10000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  /* istanbul ignore next */
  const deleteFile = (file: { path: any }) => {
    onDeleteClick(file);
  };

  function truncate(str: string, max = 30, sep = '...') {
    if (str) {
      const len = str.length;
      max = maxCharsize || max;
      /* istanbul ignore next */
      if (len > max) {
        const seplen = sep.length;
        if (seplen > max) {
          return str.substr(len - max);
        }

        const n = -0.5 * (max - len - seplen);
        const center = len / 2;
        return str.substr(0, center - n) + sep + str.substr(len - center + n);
      }
    }
    return str;
  }

  const errorClass = { className: 'errorIcon statusIcon' };
  const successClass = { className: 'successIcon statusIcon' };
  const rightIconCross = { className: 'rightIcon cross' };
  const rightIcon = { className: 'rightIcon' };
  const revise = { className: 'reviseIcon' };
  //eslint-disable-next-line  no-empty-pattern
  const files = acceptedDLSFiles.map(
    (file: any) =>
      file && (
        <StyledFileContainer key={file.path} status={file.status}>
          {/* Change below warning color when the JSON of file error icon is fixed */}
          {file.error ? (
            <Typography dls_contenttype="signal" dls_contentstate="Warning">
              <Icon name="ExclamationCircle16" {...errorClass} />
            </Typography>
          ) : file.status === 'pending' ? (
            <div className="statusIcon">
              <CircularProgress
                variant="determinate"
                dls_layer="bottom"
                dls_size="small"
                value={100}
              />
              <CircularProgress
                dls_size="small"
                dls_layer="top"
                variant="static"
                value={progress}
              />
            </div>
          ) : (
            <Icon name="CheckmarkCircle" {...successClass} />
          )}
          <div>
            <Link>
              <Typography variant="subtitle1">{truncate(file.path)}</Typography>
            </Link>
            {/* Change below warning color when the JSON of file error text is fixed */}
            {file.error && (
              <Typography
                dls_contenttype="signal"
                dls_contentstate="Warning"
                variant="subtitle2"
                className={'errorText'}>
                {file.error}
              </Typography>
            )}
            {!file.error && (
              <Typography variant="subtitle2">
                {formatBytes(file.size)}
              </Typography>
            )}
          </div>

          {
            /* istanbul ignore next */
            file.status === 'pending' ? (
              <div className="dls_pending">
                {file.error && (
                  <div onClick={() => onDrop(file, true)}>
                    <Icon name="Revise16" {...revise} />
                  </div>
                )}
                <div
                  data-test-id={'deleteFile'}
                  onClick={() => deleteFile(file)}>
                  <Icon name="CrossCircle" {...rightIconCross} />
                </div>
              </div>
            ) : (
              <IconButton
                className="dls_success"
                onClick={() => deleteFile(file)}>
                <Icon name="DeleteTrash" {...rightIcon} />
              </IconButton>
            )
          }
        </StyledFileContainer>
      ),
  );

  return files;
};

export const FileUpload = (props: IUploadProps) => {
  const {
    type,
    text,
    dropvalues,
    onFileDrop,
    onDelete,
    files,
    maxCharsize,
    maxFilesize,
    disabled,
  } = props;
  const [sizeError, setSizeError] = React.useState('');
  /* istanbul ignore next */
  const onDrop = useCallback((acceptedFiles, rejectedFiles, retry = false) => {
    if (rejectedFiles.length === 0) {
      onFileDrop(acceptedFiles, retry);
      setSizeError('');
    } else {
      rejectedFiles.map((file: any) => {
        if (file.errors && file.errors[0].code === 'file-invalid-type')
          setSizeError(
            `Invalid File Format. Supported file formats are jpeg, png, gif, doc, docx and txt`,
          );
        else
          setSizeError(
            `Please select a file < ${formatBytes(maxFilesize, 2)} Mb`,
          );
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    accept: '.jpeg, .png, .gif, .pdf, .doc, .docx, .txt',
    maxSize: maxFilesize,
    disabled: disabled || false,
    noDragEventsBubbling: type === 'dropzone',
    noDrag: type === 'default',
    noClick: true,
    noKeyboard: true,
  });

  /* istanbul ignore next */
  const Reload = (file: any, retry: any) => {
    onFileDrop(file, retry);
  };

  return (
    <StyledFileUpload>
      <section className="dls_fileupload">
        <div {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} role="button" />
          {type === 'default' ? (
            <>
              <Button dls_variant="secondary" onClick={open}>
                {text || 'Add Files'}
              </Button>
              {/* Change below warning color when the JSON of file error text is fixed */}
              {sizeError && (
                <Typography
                  dls_contenttype="signal"
                  dls_contentstate="Warning"
                  variant="subtitle2"
                  className={'errorText'}>
                  {sizeError}
                </Typography>
              )}
            </>
          ) : (
            <>
              <div className={`drop_area ${sizeError ? 'error' : ''}`}>
                <div
                  {...getRootProps({
                    className: `${disabled ? 'disabled' : ''}`,
                  })}
                  /* istanbul ignore next */
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}>
                  <input {...getInputProps()} />
                  {dropvalues}
                  <Button dls_variant="secondary" onClick={open}>
                    {text}
                  </Button>
                </div>
              </div>
              {/* Change below warning color when the JSON of file error text is fixed */}
              {sizeError && (
                <Typography
                  dls_contenttype="signal"
                  dls_contentstate="Warning"
                  variant="subtitle2"
                  className={'errorText'}>
                  {sizeError}
                </Typography>
              )}
            </>
          )}
        </div>
        <aside
          className={type === 'default' ? 'default_files' : 'dropzone_files'}>
          <ul>
            <FileDisplay
              acceptedDLSFiles={files}
              onDeleteClick={onDelete}
              maxCharsize={maxCharsize}
              onDrop={Reload}
            />
          </ul>
        </aside>
      </section>
    </StyledFileUpload>
  );
};
