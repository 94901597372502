import React, { FC } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Content } from '@dls/react-beta-content';
import {
  expanderDensities,
  useExpanderContext,
  useExpanderItemContext,
} from '@dls/react-beta-expander';
import { toPx } from '@dls/react-beta-utils';
import { FONT_DEFAULT } from '@dls/react-beta-utils';

export type ExapanderItemHeaderProps = any;

const StyledExpanderItemHeader = styled(Content)<ExapanderItemHeaderProps>`
  font-family: ${FONT_DEFAULT};
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  min-height: inherit;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  padding: ${(props) =>
    toPx([
      expanderDensities[props.density].padding,
      56,
      expanderDensities[props.density].padding,
      16,
    ])};
  .icon-font {
    margin-right: 12px;
  }
`;
const StyledLabel = styled(Content)`
  line-height: 22px;
  overflow: hidden;
  /* stylelint-disable property-no-vendor-prefix, value-no-vendor-prefix */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  /* stylelint-enable */
`;

const ExpanderItemHeader: FC<ExapanderItemHeaderProps> = (props) => {
  const { children, icon, label, itemHeaderProps, ...other } = props;
  const expanderContext = useExpanderContext();
  const expanderItemContext = useExpanderItemContext();
  const { density = 'regular', disabled = false } = {
    ...expanderContext,
    ...expanderItemContext,
  };
  return (
    <StyledExpanderItemHeader
      disabled={disabled}
      density={density}
      className="expander-item-header"
      {...other}>
      {icon}
      <StyledLabel disabled={disabled} {...itemHeaderProps}>
        {label}
      </StyledLabel>
      {children}
    </StyledExpanderItemHeader>
  );
};

ExpanderItemHeader.propTypes = {
  children: PropTypes.node,
  icon: PropTypes.node,
  label: PropTypes.string,
};

export default ExpanderItemHeader;
