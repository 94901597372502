import componentColor, { dls } from '@dls/react-colors';
import React, { FC, ReactNode, useMemo } from 'react';
import { createPortal } from 'react-dom';
import { Popper } from 'react-popper';
import styled, { css } from 'styled-components';
import { PopoverContent } from './PopoverContent';
import { PopoverProps } from './types';
import { wrapStringChildWith } from '@dls/react-beta-utils';

const Arrow = styled.div<{ hide?: boolean }>`
  position: absolute;
  width: 0;
  height: 0;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent
    ${componentColor(dls.popover.background.default.normal)}
    ${componentColor(dls.popover.background.default.normal)};
  transform-origin: 5px 5px;
  box-shadow: -1px 1px 2px 0
    ${componentColor(dls.shadow.shadow.levelOne.normal)};
  ${(props) => (props.hide ? 'display:none' : undefined)};
`;

export const PopoverContainer = styled.div.attrs(
  wrapStringChildWith(({ children }) => (
    <PopoverContent>{children}</PopoverContent>
  )),
)<Partial<PopoverProps>>((props) => {
  const { hideArrow } = props;
  const arrowSize = 10;
  const background = componentColor(dls.popover.background.default.normal);
  const shadow = componentColor(dls.shadow.shadow.levelOne.normal);

  return [
    css`
      display: flex;
      flex-direction: column;
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0 1px 2px 0 ${shadow};
      margin: 0;
      background-color: ${background};
      border-radius: 2px;
    `,
    !hideArrow &&
      css`
        &[data-placement*='bottom'] {
          margin-top: ${arrowSize + 2}px;
          ${Arrow} {
            top: -5px;
            transform: rotate(135deg);
          }
        }
        &[data-placement*='top'] {
          margin-bottom: ${arrowSize + 2}px;
          ${Arrow} {
            bottom: -5px;
            transform: rotate(-45deg);
          }
        }
        &[data-placement*='right'] {
          margin-left: ${arrowSize + 2}px;
          ${Arrow} {
            left: -5px;
            transform: rotate(45deg);
          }
        }
        &[data-placement*='left'] {
          margin-right: ${arrowSize + 2}px;
          ${Arrow} {
            right: -5px;
            transform: rotate(225deg);
          }
        }
      `,
  ];
});

export const Popover: FC<PopoverProps> = (props) => {
  const {
    children,
    anchorElement,
    placement: placementProp,
    hideArrow,
  } = props;

  // use unique key for redraw
  const key = useMemo(() => (hideArrow ? 'no-arrow' : 'with-arrow'), [
    hideArrow,
  ]);

  return createPortal(
    <Popper
      key={key}
      referenceElement={anchorElement}
      placement={placementProp}>
      {({ ref, style, placement, arrowProps }): ReactNode => (
        <PopoverContainer
          ref={ref}
          style={{ ...style, ...(!anchorElement ? { display: 'none' } : null) }}
          data-placement={placement}
          hideArrow={hideArrow}>
          {children}
          <Arrow
            ref={arrowProps.ref}
            hide={hideArrow}
            style={arrowProps.style}
          />
        </PopoverContainer>
      )}
    </Popper>,
    document.body,
  );
};
