import React from 'react';
import moment from 'moment';
import { Icon } from '@dls/react-icon';
import styled from 'styled-components';
import { getFont, FontWeight } from '@dls/react-beta-utils';
import componentColor, { dls } from '@dls/react-colors';
import { DateTimePickerVisibleMonthProps } from './types';

export const DateTimePickerVisibleMonth = ({
  month,
  onPrev,
  onNext,
  maxDate,
}: DateTimePickerVisibleMonthProps): JSX.Element => {
  const monthMoment = moment(month);
  const shouldShowNextButton =
    !maxDate ||
    monthMoment.startOf('month').isBefore(moment(maxDate).startOf('month'));

  return (
    <StyledVisibleMonth>
      <IconButton type="button" onClick={onPrev}>
        <Icon name="NavigationLeft" />
      </IconButton>
      <div>{monthMoment.format('MMMM YYYY')}</div>
      {!shouldShowNextButton ? null : (
        <IconButton type="button" onClick={onNext}>
          <Icon name="NavigationRight" />
        </IconButton>
      )}
    </StyledVisibleMonth>
  );
};

const StyledVisibleMonth = styled.div`
  display: grid;
  grid-template-areas: 'navigate-left weeknumber navigate-right';
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 21px;
  margin-bottom: 21px;
  color: ${componentColor(dls.datePicker.monthText.desktop.normal)};
  ${getFont(FontWeight.Normal)};
`;

const IconButton = styled.button`
  padding: 0;
  margin: 0;
  border: 0;
  background: transparent;
  cursor: pointer;
  color: ${componentColor(dls.datePicker.monthText.desktop.normal)};
`;
