import { DlsStyle } from '@dls/react-colors';
import { ApplicationThemeConfiguration } from '../ThemeProvider/types';
import shadow from './shadow';
import { NO_STYLES } from './styles/constants';

export type Size = number | string;

export const formatCSSUnit = (unit: Size): string => {
  return typeof unit === 'number' ? `${unit}px` : unit;
};

type Density = 'touch' | 'regular' | 'dense';

export type DensityProps = {
  density?: Density;
  theme: ApplicationThemeConfiguration;
};

export const density = (config: Record<Density, Size>) => (
  props: DensityProps,
): Size => {
  const densityValue: Density =
    props.density || props.theme.density || 'regular';
  const returnValue: Size = config[densityValue] || config['regular'];

  return formatCSSUnit(returnValue);
};

export type Elevation = 0 | 1 | 2 | 3;

export type ElevationProps = {
  elevation?: Elevation;
  disabled?: boolean;
};

export const elevation = () => (props: ElevationProps): DlsStyle => {
  switch (props.elevation) {
    case 1:
      if (props.disabled) {
        return shadow.shadowLevel1Disabled;
      }
      return shadow.shadowLevel1;

    case 2:
      return shadow.shadowLevel2;

    case 3:
      return shadow.shadowLevel3;

    default:
      return NO_STYLES;
  }
};

export type PredefinedPaddings = 'xxs' | 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl';
export type Paddings = PredefinedPaddings | string | number;

export interface PaddingProps {
  padding?: Paddings;
}

export const padding = () => (props: PaddingProps): string | null => {
  if (!props.padding) {
    return null;
  }

  switch (props.padding) {
    case 'xxs':
      return '4px';

    case 'xs':
      return '8px';

    case 's':
      return '16px';

    case 'm':
      return '24px';

    case 'l':
      return '36px';

    case 'xl':
      return '48px';

    case 'xxl':
      return '60px';

    default:
      return formatCSSUnit(props.padding);
  }
};

export const hover = '&:hover';
export const pressed = '&:active';
export const focus = '&:focus';
export const validated = '.validated';
export const disabled =
  '&[disabled],&[aria-disabled="true"],&.disabled,[disabled] &,[aria-disabled="true"] &,.disabled &';
