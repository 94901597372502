import React, { FunctionComponent, SVGProps } from 'react';

const NavigationRightAlternate = (props) => (
  <svg viewBox="0 0 24 24" width="1em" height="1em" {...props}>
    <g fill="none" stroke="#000" strokeMiterlimit={10} strokeWidth={2}>
      <path d="M11.3 22.3L21.5 12 11.3 1.7M20.5 12H1" />
    </g>
  </svg>
);

export default NavigationRightAlternate;
