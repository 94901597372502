import componentColor, { dls } from '@dls/react-colors';
import styled, { css } from 'styled-components';
import { disabled } from '@dls/react-beta-utils';
import { TypographyProps, TypographyVariant, VariantMapping } from './types';
import { FONT_DEFAULT } from '@dls/react-beta-utils';

type TypographyStyle = {
  fontSize: string;
  lineHeight: string;
  marginBottom: string;
};

const variants: Record<TypographyVariant, TypographyStyle> = {
  h1: { fontSize: '48px', lineHeight: '54px', marginBottom: '20px' },
  h2: { fontSize: '32px', lineHeight: '36px', marginBottom: '16px' },
  h3: { fontSize: '24px', lineHeight: '28px', marginBottom: '12px' },
  h4: { fontSize: '20px', lineHeight: '24px', marginBottom: '8px' },
  h5: { fontSize: '20px', lineHeight: '28px', marginBottom: '8px' },
  h6: { fontSize: '16px', lineHeight: '22px', marginBottom: '6px' },
  subtitle1: { fontSize: '14px', lineHeight: '20px', marginBottom: '6px' },
  subtitle2: { fontSize: '12px', lineHeight: '18px', marginBottom: '6px' },
  badge: { fontSize: '10px', lineHeight: '10px', marginBottom: '6px' },
  'body-large': { fontSize: '20px', lineHeight: '28px', marginBottom: '20px' },
  body: { fontSize: '16px', lineHeight: '22px', marginBottom: '16px' },
  'body-small': { fontSize: '14px', lineHeight: '20px', marginBottom: '14px' },
  'body-xsmall': { fontSize: '12px', lineHeight: '18px', marginBottom: '12px' },
};

// TODO: Move variantMapping to theme
const defaultVariantMapping: VariantMapping = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  subtitle1: 'div',
  subtitle2: 'div',
  badge: 'div',
  'body-large': 'p',
  body: 'p',
  'body-small': 'p',
  'body-xsmall': 'p',
};

const weightMapping: Record<string, string> = {
  thin: '100',
  'extra-light': '200',
  light: '300',
  normal: '400',
  medium: '500',
  'semi-bold': '600',
  bold: '700',
  'extra-bold': '800',
  black: '900',
};

const createTypographyAttrs = () => (props: TypographyProps) => {
  const { variant } = props;
  return {
    as: defaultVariantMapping[variant] as string,
  };
};

const getVariantStyles = (props: TypographyProps) => {
  const { variant, gutterBottom } = props;
  const { marginBottom, ...style } = variants[variant] || {};
  if (gutterBottom) {
    return { marginBottom, ...style };
  }
  return { ...style };
};

const getColorStyles = (props: TypographyProps) => {
  const { color = 'primary' } = props;
  if (color === 'inherit') {
    return null;
  }
  return css`
    color: ${componentColor(dls.typography.text[color].normal)};
    ${disabled} {
      color: ${componentColor(dls.typography.text.disabled.normal)};
    }
  `;
};

const getWeightStyles = (props: TypographyProps) =>
  props.weight !== undefined &&
  css`
    font-weight: ${weightMapping[props.weight] || props.weight};
  `;

const getWrappingStyles = (props: TypographyProps) =>
  props.noWrap &&
  css`
    white-space: nowrap;
    overflow: hidden;
    ${!props.noEllipsis &&
    css`
      text-overflow: ellipsis;
    `}
  `;

export const Typography = styled.p.attrs(
  createTypographyAttrs(),
)<TypographyProps>`
  font-family: ${FONT_DEFAULT};
  padding: 0;
  margin: 0;

  ::selection {
    background-color: ${componentColor(
      dls.typography.textSelectionBackground.default.normal,
    )};
  }

  ${getWeightStyles};
  ${getWrappingStyles};
  ${getVariantStyles};
  ${getColorStyles};
`;
