import React, { FunctionComponent, SVGProps } from 'react';

const InformationCircle = (props) => (
  <svg viewBox="0 0 24 24" width="1em" height="1em" {...props}>
    <path
      fillRule="evenodd"
      d="M20 12a8 8 0 11-16 0 8 8 0 0116 0zm-6.934-2.02H10.4v1.244l.89.312v5.111h1.777V9.98zm0-2a1.1 1.1 0 00-1.11-1.111 1.1 1.1 0 00-1.113 1.111c0 .622.445 1.111 1.112 1.111s1.111-.489 1.111-1.111z"
    />
  </svg>
);

export default InformationCircle;
