import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { ifProp, prop } from 'styled-tools';
import componentColor, { dls } from '@dls/react-colors';
import { Content } from '@dls/react-beta-content';
import { TabsContext } from '@dls/react-beta-tabs';
import { FONT_DEFAULT } from '@dls/react-beta-utils';

const getBackgroundColor = (state) =>
  componentColor(dls.tabs.background.default[state]);

const getForgroundColor = (state) => {
  return componentColor(dls.tabs.text.default[state]);
};

const StyledTab = styled<TabsContext>(Content)`
  font-family: ${FONT_DEFAULT};
  font-weight: normal;
  font-style: normal;
  font-size: 14px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  min-height: 48px;
  user-select: none;
  padding: 0 16px;
  padding-top: 2px;
  outline: none;
  border-bottom: 2px solid transparent;
  color: ${getForgroundColor('normalOff')};
  min-width: ${ifProp('minWidth', prop('minWidth'), null)};
  ${ifProp(
    'disabled',
    css`
      color: ${getForgroundColor('disabled')};
    `,
    css`
      cursor: pointer;
      &:hover {
        color: ${getForgroundColor('hoverOff')};
      }
      &:active {
        color: ${getForgroundColor('pressedOff')};
      }
      &:focus {
        background: ${getBackgroundColor('focusOn')};
      }
    `,
  )}
  ${(props) =>
    !props.disabled &&
    props.activated &&
    css`
      color: ${getForgroundColor('normalOn')};
      border-bottom: 2px solid
        ${componentColor(dls.tabs.indicator.default.normalOn)};
      &:hover {
        color: ${getForgroundColor('normalOn')};
      }
    `}
    ${ifProp(
    'leftIcon',
    css`
      > .icon-font,
      > svg {
        margin-right: 8px;
      }
    `,
  )}
    ${ifProp(
    'topIcon',
    css`
      flex-direction: column;
      min-height: 72px;
      > .icon-font,
      > svg {
        margin-bottom: 8px;
      }
    `,
  )}
    ${ifProp(
    'fixedWidth',
    css`
      flex-grow: 1;
    `,
  )}
    >.tab-text {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;

const Tab = (props) => {
  const { activatedTab, minWidthTab, fixedWidth } = useContext(TabsContext);
  const { index, leftIcon, topIcon, disabled, value } = props;
  const { children, ...other } = props;
  return (
    <StyledTab
      activated={index === activatedTab}
      className="tab"
      data-value={value}
      minWidth={minWidthTab}
      fixedWidth={fixedWidth}
      tabIndex={disabled ? -1 : 0}
      {...other}>
      {topIcon}
      {leftIcon}
      <div className="tab-text">{children}</div>
    </StyledTab>
  );
};

Tab.propTypes = {
  activated: PropTypes.bool,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  fixedWidth: PropTypes.bool,
  index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  leftIcon: PropTypes.node,
  topIcon: PropTypes.node,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default Tab;
