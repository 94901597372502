import React, { useContext } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { ifProp } from 'styled-tools';
import { Content } from '@dls/react-beta-content';
import componentColor, { dls } from '@dls/react-colors';
import { ListViewContext } from '@dls/react-beta-list-view';
import { toPx } from '@dls/react-beta-utils';

export type ListItemProps = any;

const densities = {
  touch: {
    minHeight: 48,
    padding: 12,
  },
  regular: {
    minHeight: 40,
    padding: 8,
  },
  dense: {
    minHeight: 32,
    padding: 4,
  },
};
const getListItemForground = (state) => {
  return css`
    color: ${componentColor(dls.listItem.text.default[state])};
  `;
};
const getListItemBackground = (state) => {
  return css`
    background-color: ${componentColor(dls.listItem.background.default[state])};
  `;
};

const StyledListItem = styled(Content)<ListItemProps>`
  display: flex;
  outline: none;
  flex-direction: column;
  align-items: flex-start;
  user-select: none;
  line-height: 22px;
  padding: ${(props) => toPx(densities[props.density].padding)} 16px;
  min-height: ${(props) => toPx(densities[props.density].minHeight)};
  ${ifProp(
    'divider',
    css`
      border-bottom: 1px solid
        ${componentColor(dls.separator.background.content.normal)};
      :last-of-type {
        border-bottom-width: 0;
      }
    `,
  )}
  ${getListItemForground('normalOff')}
  ${(props) =>
    !props.disabled &&
    css`
      &:hover,
      &:focus {
        ${getListItemBackground('hover')}
      }
      &:active {
        ${getListItemBackground('pressed')}
      }
    `}
  ${(props) =>
    !props.disabled &&
    props.selected &&
    css`
      ${getListItemBackground('normalOn')};
    `}
  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.35;
      pointer-events: none;
      ${getListItemForground('disabled')};
    `}
`;

const ListItem = (props) => {
  const listViewcontext = useContext(ListViewContext);
  const { tabIndex, disabled, selected, ...other }: any = {
    ...props,
    ...listViewcontext,
  };
  return (
    <StyledListItem
      className="list-item"
      tabIndex={disabled ? -1 : tabIndex}
      disabled={disabled}
      selected={selected}
      {...other}
    />
  );
};

ListItem.propTypes = {
  children: PropTypes.node,
  density: PropTypes.oneOf(['touch', 'regular', 'dense']),
  disabled: PropTypes.bool,
  divider: PropTypes.bool,
  selected: PropTypes.bool,
  tabIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

ListItem.defaultProps = {
  color: 'primary',
  density: 'touch',
  tabIndex: 0,
};

export default ListItem;
