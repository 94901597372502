import React, { useState, FC } from 'react';
import PropTypes from 'prop-types';
import { isFunction } from 'lodash';
import { default as Tab } from '../Tab';
import { Tabs } from '@dls/react-beta-tabs';
import { Content } from '@dls/react-beta-content';

export type TabPanelProps = any;

const TabPanel: FC<TabPanelProps> = (props) => {
  const {
    children,
    colorTabs,
    padding,
    activatedTabIndex = 0,
    tabsBorderBottom,
    centeredTab,
    fixedWidthTab,
    minWidthTab,
    onBeforeTabChange,
    onTabChange,
    ...other
  } = props;
  const [activatedTab, setActivatedTab] = useState(activatedTabIndex);
  const defaultTabContentProps = {
    flex: 1,
    overflow: 'auto',
    padding,
  };
  const onTabChangeHandler = ({ e, index, tab, value }) => {
    setActivatedTab(index);
    if (isFunction(onTabChange)) {
      onTabChange({ e, index, tab, value });
    }
  };
  const buildTabs = (children) => {
    return React.Children.map(children, (child) => {
      if (React.isValidElement(child)) {
        const {
          disabled,
          icon,
          label,
          leftIcon,
          topIcon,
          value,
        } = child.props as any;
        return (
          <Tab
            value={value}
            leftIcon={leftIcon}
            topIcon={topIcon}
            disabled={disabled}>
            {icon || label}
          </Tab>
        );
      }
    });
  };
  const buildTabContent = (tabIndex, children, childProps) => {
    if (React.Children.count(children)) {
      const tabContent = children[tabIndex] as { props: { disabled: boolean } };
      if (React.isValidElement(tabContent) && !tabContent.props.disabled) {
        return React.cloneElement(tabContent, {
          ...childProps,
          ...tabContent.props,
        });
      }
    }
  };
  return (
    <Content {...other}>
      <Tabs
        activatedTabIndex={activatedTabIndex}
        borderBottom={tabsBorderBottom}
        centered={centeredTab}
        color={colorTabs}
        fixedWidth={fixedWidthTab}
        minWidthTab={minWidthTab}
        onBeforeTabChange={onBeforeTabChange}
        onTabChange={onTabChangeHandler}>
        {buildTabs(children)}
      </Tabs>
      {buildTabContent(activatedTab, children, defaultTabContentProps)}
    </Content>
  );
};

TabPanel.defaultProps = {
  color: 'secondaryNeutral',
  colorTabs: 'primary',
  layout: 'vflex',
  tabsBorderBottom: true,
};

TabPanel.propTypes = {
  activatedTabIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  centeredTab: PropTypes.bool,
  children: PropTypes.node,
  colorTabs: PropTypes.oneOf([
    'primary',
    'secondary',
    'tertiary',
    'secondaryNeutral',
    'tertiaryNeutral',
  ]),
  fixedWidthTab: PropTypes.bool,
  minWidthTab: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onBeforeTabChange: PropTypes.func,
  onTabChange: PropTypes.func,
  padding: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.number,
    PropTypes.oneOf(['xxs', 'xs', 's', 'm', 'l', 'xl', 'xxl', false]),
    PropTypes.string,
  ]),
  tabsBorderBottom: PropTypes.bool,
};

export default TabPanel;
