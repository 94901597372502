import React, { FC } from 'react';
import componentColor, { dls } from '@dls/react-colors';
import MuiBreadcrumbs, {
  BreadcrumbsProps,
} from '@material-ui/core/Breadcrumbs';
import { Icon } from '@dls/react-icon';
import styled from 'styled-components';
import { em } from '@dls/react-theme';

export const Breadcrumbs: FC<BreadcrumbsProps> = styled(MuiBreadcrumbs).attrs(
  () => {
    return {
      separator: <Icon name="NavigationRight" size={'m'} />,
    } as BreadcrumbsProps;
  },
)`
  && {
    &.MuiBreadcrumbs-root {
      & .MuiButtonBase-root[class*='PrivateBreadcrumbCollapsed-root-'] {
        color: ${componentColor(dls.breadcrumb.text.default.normalOff)};
        background-color: transparent;
        margin-left: unset;
        margin-right: unset;
      }
      & .MuiBreadcrumbs-li {
        font-size: ${em(14)};
        & .MuiLink-underlineHover {
          color: ${componentColor(dls.breadcrumb.text.default.normalOff)};
          &:hover {
            color: ${componentColor(dls.breadcrumb.text.default.hoverOff)};
          }
          &:focus {
            color: ${componentColor(dls.breadcrumb.text.default.focusOff)};
            text-decoration: underline;
          }
          &:active {
            color: ${componentColor(dls.breadcrumb.text.default.pressedOff)};
          }
          &.pt-selected:not(:hover) {
            color: ${componentColor(dls.breadcrumb.text.default.normalOn)};
            text-decoration: none;
          }
        }
      }
      & .MuiBreadcrumbs-separator {
        color: ${componentColor(dls.breadcrumb.icon.default.normal)};
        display: flex;
        margin-left: ${em(8, 16)};
        margin-right: ${em(8, 16)};
        & > span {
          position: relative;
          width: ${em(24)};
          height: ${em(24)};
          & svg {
            position: absolute;
            width: ${em(24)};
            height: ${em(24)};
            fill: ${componentColor(dls.breadcrumb.icon.default.normal)};
          }
        }
      }
    }
  }
`;
