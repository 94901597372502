import React, { FunctionComponent, SVGProps } from 'react';

const Scroll = (props) => (
  <svg viewBox="0 0 24 24" width="1em" height="1em" {...props}>
    <path
      fill="#010101"
      d="M1 11h11v11H1V11zM11 1v4h7v7h4V1H11zm-5 9h7v7h4V6H6v4z"
    />
  </svg>
);

export default Scroll;
