import styled from 'styled-components';

export const TextBoxIconContainer = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: -30px;

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
`;
