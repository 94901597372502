import React, { FunctionComponent, SVGProps } from 'react';

const HeavyPhysicalExercise = (props) => (
  <svg viewBox="0 0 48 48" width="1em" height="1em" {...props}>
    <path
      fill="#231f20"
      d="M30.886 6.965a4 4 0 117.028 3.822 4 4 0 01-7.028-3.822zm10.472 10.638l-4.298 2.033-2.873-3.788-.029.022-6.16-4.93-.016.02v-.02l-7.969.073-.016-.013-.013.015h-.002v.002L14.594 16.9l2.95 2.702 4.216-4.603 3.013-.028-6.726 8.404-.021-.013-5.225 5.027-6.562-3.094-1.706 3.618 9.045 4.265.005-.01.01.01 7.551-7.228 3.105 2.485.008-.011 5.478 6.473-2.925 6.898 3.683 1.561 3.861-9.107.042-.036-.018-.021.018-.042-.083-.035-7.48-8.909 4.842-6.05 4.16 5.484.001-.001v.001l7.232-3.421-1.71-3.616z"
    />
  </svg>
);

export default HeavyPhysicalExercise;
