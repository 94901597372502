import getComponentColor, { dls } from '@dls/react-colors';
import MuiAppBar, {
  AppBarProps as MuiAppBarProps,
} from '@material-ui/core/AppBar';
import { FC } from 'react';
import styled from 'styled-components';
import { createMuiTheme } from '@material-ui/core/styles';
import { em } from '@dls/react-theme';

const theme = createMuiTheme();

export type DrawerStyleVariants = 240 | 280;

export interface DLSAppBarProps extends MuiAppBarProps {
  drawerwidth?: DrawerStyleVariants;
}
// Side bar related appbar styles

export const AppBar: FC<DLSAppBarProps> = styled(MuiAppBar).attrs(
  (props: DLSAppBarProps) => {
    return {
      ...props,
    } as DLSAppBarProps;
  },
)`
  && {
    &.MuiAppBar-root {
      /** Font Styles start here */
      font-size: ${em(16)};
      /* stylelint-disable */
      font-family: CentraleSans, Tahoma, Arial, Helvetica, sans-serif;
      /* stylelint-enable */
      font-weight: normal;
      font-style: normal;

      /* REGION side-bar related appbar  styles */
      &.appBar {
        z-index: ${() => theme.zIndex.drawer + 1};
        position: absolute;
      }
      &.rightAppBar {
        width: ${(props: DLSAppBarProps) =>
          `calc(100% - ${props.drawerwidth! + 32}px)`};
        margin-right: ${(props: DLSAppBarProps) => props.drawerwidth + 'px'};
        position: absolute;
        z-index: ${() => theme.zIndex.drawer + 1};
      }
      &.persistentAppBar {
        transition: ${theme.transitions.create(['margin', 'width'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        })};
        z-index: ${() => theme.zIndex.drawer + 1};
        position: absolute;
      }
      &.appBarShift {
        width: ${(props: DLSAppBarProps) =>
          `calc(100% - ${props.drawerwidth}px)`};
        margin-left: ${(props: DLSAppBarProps) => props.drawerwidth + 'px'};
        transition: ${theme.transitions.create(['margin', 'width'], {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        })};
      }

      /* END REGION side-bar related appbar */

      &.MuiAppBar-colorPrimary {
        color: ${getComponentColor(dls.navigation.text.primary.normal)};
        background-color: ${getComponentColor(
          dls.navigation.background.primary.normal,
        )};
        box-shadow: none;
      }
      &.MuiAppBar-colorSecondary {
        color: ${getComponentColor(dls.navigation.text.secondary.normal)};
        background-color: ${getComponentColor(
          dls.navigation.background.secondary.normal,
        )};
        box-shadow: none;
      }
    }
  }
`;
