import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { ifProp } from 'styled-tools';
import componentColor, { dls } from '@dls/react-colors';
import { capitalizeCss, toPx } from '@dls/react-beta-utils';
import { FONT_DLS_ICON_24 } from '@dls/react-beta-utils';
import { DlsIconFontProps } from './DlsIconFont.types';
import dlsIcons24 from './icons/dlsIcons24';

const getIconForground = ({ color: iconType }: DlsIconFontProps, state) => {
  if (iconType) {
    if (iconType.startsWith('quiet')) {
      return css`
        color: ${componentColor(
          dls.button.text[capitalizeCss(iconType)][state],
        )};
      `;
    }
    return css`
      color: ${componentColor(
        dls.button.background[capitalizeCss(iconType)][state],
      )};
    `;
  }
};

const StyledDlsIconFont = styled.i<DlsIconFontProps>`
  ${(props) => css`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: ${toPx(props.width || props.fontSize)};
    height: ${toPx(props.height || props.fontSize)};
    font-size: ${toPx(props.fontSize)};
    ${ifProp(
      'color',
      css`
        ${getIconForground(props, props.disabled ? 'disabled' : 'normal')}
      `,
      css`
        color: inherit;
      `,
    )}
  `};
  ${(props) => css`
    :before {
      font-family: ${FONT_DLS_ICON_24} !important;
      display: inline-flex;
      font-style: normal;
      font-weight: normal;
      text-decoration: inherit;
      text-transform: none;
      text-rendering: auto;
      flex: 0;
      align-items: center;
      justify-content: center;
      font-size: inherit;
      height: inherit;
      ${ifProp(
        'icon',
        css`
          content: '${props.icon}';
        `,
      )}
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      -webkit-text-stroke-width: 0.2px;
    }
  `}
`;

const DlsIconFont: FC<DlsIconFontProps> = ({ icon, children, ...other }) => (
  <StyledDlsIconFont
    className="icon-font"
    icon={dlsIcons24[icon] || dlsIcons24[children] || children}
    {...other}
  />
);

DlsIconFont.defaultProps = {
  fontSize: 24,
};

export default DlsIconFont;
