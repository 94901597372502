// import React from 'react';
import styled from 'styled-components';
import { em } from '@dls/react-theme';
import componentColor, { dls } from '@dls/react-colors';
import MuiTreeView, {
  TreeViewProps as MuiTreeViewProps,
} from '@material-ui/lab/TreeView';

import MuiTreeItem, {
  TreeItemProps as MuiTreeItemProps,
} from '@material-ui/lab/TreeItem';

export const TreeView = styled(MuiTreeView).attrs((props: MuiTreeViewProps) => {
  return {
    ...props,
  } as MuiTreeViewProps;
})`
  && {
    &.MuiTreeView-root {
      padding: 0 ${em(24)};
      width: auto;
      position: relative;
    }
  }
`;

export const TreeItem = styled(MuiTreeItem).attrs((props: MuiTreeItemProps) => {
  return {
    ...props,
  } as MuiTreeItemProps;
})`
  && {
    &.MuiTreeItem-root {
      & .MuiTreeItem-content {
        &:hover::before,
        &:focus::before,
        &:active::before,
        &.Mui-selected::before {
          content: '';
          position: absolute;
          left: 0;
          height: ${em(40)};
          width: 100%;
          pointer-events: none;

          .pt-dense & {
            height: ${em(32)};
          }
        }
        &:hover::before {
          background-color: ${componentColor(
            dls.treeView.background.default.hoverOff,
          )};
        }

        &:focus::before {
          background-color: ${componentColor(
            dls.treeView.background.default.focusOff,
          )};
          outline: ${em(1)} solid
            ${componentColor(dls.treeView.focusOutline.default.focusPressed)};
        }

        &:active::before {
          background-color: ${componentColor(
            dls.treeView.background.default.pressedOff,
          )};
          outline: ${em(1)} solid
            ${componentColor(dls.treeView.focusOutline.default.focusPressed)};
        }

        height: ${em(40)};
        padding-top: ${em(8)};
        padding-bottom: ${em(8)};
        outline: none;

        .pt-dense & {
          height: ${em(32)};
          padding-top: ${em(4)};
          padding-bottom: ${em(4)};
        }

        > .MuiTreeItem-iconContainer {
          color: ${componentColor(dls.treeView.arrowIcon.default.normalOff)};
          margin-right: 0;
          min-width: ${em(24)};
          width: fit-content;
          height: ${em(24)};
          font-family: CentraleSans, Tahoma, Arial, Helvetica, sans-serif;
          font-weight: normal;
          font-style: normal;
          display: inline-block;
          &:before {
            font-size: ${em(24)};
          }
          span {
            position: relative;
            width: ${em(24)};
            height: ${em(24)};
            & svg {
              position: absolute;
            }
          }
        }

        > .MuiTreeItem-label {
          color: ${componentColor(dls.treeView.text.default.normalOff)};
          &:hover {
            background-color: transparent;
          }
          &:focus {
            background-color: transparent;
          }
          font-family: CentraleSans, Tahoma, Arial, Helvetica, sans-serif;
          font-weight: normal;
          font-style: normal;
          font-size: ${em(16)};
          padding-left: ${em(4)};
          svg {
            fill: ${componentColor(dls.treeView.icon.default.normalOff)};
          }
          span {
            position: relative;
            width: ${em(24)};
            height: ${em(24)};
            & svg {
              position: absolute;
            }
          }
        }
      }
    }

    &.MuiTreeItem-root.Mui-expanded.Mui-selected {
      &:focus-within::before {
        background-color: ${componentColor(
          dls.treeView.background.default.focusOn,
        )};
        outline: ${em(1)} solid
          ${componentColor(dls.treeView.focusOutline.default.focusPressed)};
        content: '';
        position: absolute;
        left: 0;
        height: ${em(40)};
        width: 100%;
        pointer-events: none;
        .pt-dense & {
          height: ${em(32)};
        }
      }
      & .MuiTreeItem-content {
        &:hover::before {
          background-color: ${componentColor(
            dls.treeView.background.default.hoverOff,
          )};
          content: '';
          position: absolute;
          left: 0;
          height: ${em(40)};
          width: 100%;
          pointer-events: none;

          .pt-dense & {
            height: ${em(32)};
          }
        }

        &:focus::before {
          background-color: ${componentColor(
            dls.treeView.background.default.focusOff,
          )};
          outline: ${em(1)} solid
            ${componentColor(dls.treeView.focusOutline.default.focusPressed)};
          content: '';
          position: absolute;
          left: 0;
          height: ${em(40)};
          width: 100%;
          pointer-events: none;

          .pt-dense & {
            height: ${em(32)};
          }
        }

        &:active::before {
          background-color: ${componentColor(
            dls.treeView.background.default.pressedOff,
          )};
          outline: ${em(1)} solid
            ${componentColor(dls.treeView.focusOutline.default.focusPressed)};
          content: '';
          position: absolute;
          left: 0;
          height: ${em(40)};
          width: 100%;
          pointer-events: none;

          .pt-dense & {
            height: ${em(32)};
          }
        }

        height: ${em(40)};
        padding-top: ${em(8)};
        padding-bottom: ${em(8)};
        outline: none;

        .pt-dense & {
          height: ${em(32)};
          padding-top: ${em(4)};
          padding-bottom: ${em(4)};
        }

        > .MuiTreeItem-iconContainer {
          color: ${componentColor(dls.treeView.arrowIcon.default.normalOff)};
          min-width: ${em(24)};
          width: fit-content;
          height: ${em(24)};
          font-family: CentraleSans, Tahoma, Arial, Helvetica, sans-serif;
          font-weight: normal;
          font-style: normal;
          display: inline-block;
          margin-right: 0;
          &:before {
            font-size: ${em(24)};
          }
          span {
            position: relative;
            width: ${em(24)};
            height: ${em(24)};
            & svg {
              position: absolute;
            }
          }
        }

        > .MuiTreeItem-label {
          color: ${componentColor(dls.treeView.text.default.normalOff)};
          &:hover {
            background-color: transparent;
          }
          &:focus {
            background-color: transparent;
          }
          font-family: CentraleSans, Tahoma, Arial, Helvetica, sans-serif;
          font-weight: normal;
          font-style: normal;
          font-size: ${em(16)};
          svg {
            fill: ${componentColor(dls.treeView.icon.default.normalOff)};
          }
          span {
            position: relative;
            width: ${em(24)};
            height: ${em(24)};
            & svg {
              position: absolute;
            }
          }
        }
      }
    }

    &.MuiTreeItem-root.Mui-selected {
      &:focus-within::before {
        background-color: ${componentColor(
          dls.treeView.background.default.focusOn,
        )};
        outline: ${em(1)} solid
          ${componentColor(dls.treeView.focusOutline.default.focusPressed)};
        content: '';
        position: absolute;
        left: 0;
        height: ${em(40)};
        width: 100%;
        pointer-events: none;
        .pt-dense & {
          height: ${em(32)};
        }
      }
      & .MuiTreeItem-content {
        &:hover::before,
        &:focus::before {
          content: '';
          position: absolute;
          left: 0;
          height: ${em(40)};
          width: 100%;
          pointer-events: none;
          background-color: inherit;
          .pt-dense & {
            height: ${em(32)};
          }
        }

        &:active::before {
          content: '';
          position: absolute;
          left: 0;
          height: ${em(40)};
          width: 100%;
          pointer-events: none;
          background-color: ${componentColor(
            dls.treeView.background.default.pressedOff,
          )};
          outline: ${em(1)} solid
            ${componentColor(dls.treeView.focusOutline.default.focusPressed)};
          .pt-dense & {
            height: ${em(32)};
          }
        }

        height: ${em(40)};
        padding-top: ${em(8)};
        padding-bottom: ${em(8)};
        outline: none;

        .pt-dense & {
          height: ${em(32)};
          padding-top: ${em(4)};
          padding-bottom: ${em(4)};
        }

        > .MuiTreeItem-iconContainer {
          color: ${componentColor(dls.treeView.arrowIcon.default.normalOff)};
          min-width: ${em(24)};
          width: fit-content;
          height: ${em(24)};
          font-family: CentraleSans, Tahoma, Arial, Helvetica, sans-serif;
          font-weight: normal;
          font-style: normal;
          display: inline-block;
          margin-right: 0;
          &:before {
            font-size: ${em(24)};
          }
          span {
            position: relative;
            width: ${em(24)};
            height: ${em(24)};
            & svg {
              position: absolute;
            }
          }
        }

        > .MuiTreeItem-label {
          color: ${componentColor(dls.treeView.text.default.normalOff)};
          background-color: transparent;
          &:hover {
            background-color: transparent;
          }
          &:focus {
            background-color: transparent;
          }
          font-family: CentraleSans, Tahoma, Arial, Helvetica, sans-serif;
          font-weight: normal;
          font-style: normal;
          font-size: ${em(16)};
          svg {
            fill: ${componentColor(dls.treeView.icon.default.normalOff)};
          }
          span {
            position: relative;
            width: ${em(24)};
            height: ${em(24)};
            & svg {
              position: absolute;
            }
          }
        }
      }
    }

    &.MuiTreeItem-root:not(.leaf-node) > .MuiTreeItem-content {
      > .MuiTreeItem-iconContainer {
        color: ${componentColor(dls.treeView.arrowIcon.default.normalOff)};
      }

      > .MuiTreeItem-label {
        color: ${componentColor(dls.treeView.text.default.normalOff)};
        &:hover {
          background-color: transparent;
        }
        &:focus {
          background-color: transparent;
        }
        svg {
          fill: ${componentColor(dls.treeView.icon.default.normalOff)};
        }
      }
    }

    &.MuiTreeItem-root.Mui-expanded.Mui-selected > .MuiTreeItem-content {
      &::before {
        background-color: ${componentColor(
          dls.treeView.background.default.normalOn,
        )};
      }

      &:hover::before {
        background-color: inherit;
      }

      &:focus::before {
        background-color: inherit;
        outline: ${em(1)} solid
          ${componentColor(dls.treeView.focusOutline.default.focusPressed)};
      }

      &:active::before {
        background-color: ${componentColor(
          dls.treeView.background.default.pressedOn,
        )};
        outline: ${em(1)} solid
          ${componentColor(dls.treeView.focusOutline.default.focusPressed)};
      }

      > .MuiTreeItem-iconContainer {
        color: ${componentColor(dls.treeView.arrowIcon.default.normalOn)};
      }

      > .MuiTreeItem-label {
        color: ${componentColor(dls.treeView.text.default.normalOn)};
        &:hover {
          background-color: transparent;
        }
        &:focus {
          background-color: transparent;
        }
        svg {
          fill: ${componentColor(dls.treeView.icon.default.normalOff)};
        }
      }
    }
    & .MuiTreeItem-group {
      margin-left: ${em(24)};
    }
  }
`;
