import { css, FlattenSimpleInterpolation } from 'styled-components';
import { FONT_DEFAULT } from '../../constants/font';

export enum FontWeight {
  Thin = 100,
  ExtraLight = 200,
  Light = 300,
  Normal = 400,
  Medium = 500,
  Bold = 700,
  ExtraBold = 800,
}

export const getFont = (weight: FontWeight): FlattenSimpleInterpolation => {
  return css`
    font-family: ${FONT_DEFAULT};
    font-weight: ${weight};
  `;
};
