import React, { FunctionComponent, SVGProps } from 'react';

const ImageGeneral = (props) => (
  <svg viewBox="0 0 48 48" width="1em" height="1em" {...props}>
    <path
      fillRule="evenodd"
      d="M44 6H2v36h44V6h-2zm0 32H4V10h40v28z"
      clipRule="evenodd"
    />
  </svg>
);

export default ImageGeneral;
