import React from 'react';
import { getCellType } from '../getCellType';
import { DateTimePickerCell } from '../Cell';
import { DateTimePickerCellType } from '../types';
import { DateTimePickerCellDayProps } from './types';

export const DateTimePickerCellDay = (props: DateTimePickerCellDayProps) => {
  const cellType: DateTimePickerCellType = getCellType(
    props.startOfSelected,
    props.day,
    props.visibleMonth,
    props.maxDate,
  );

  const onClick = () => {
    if (cellType !== DateTimePickerCellType.Disabled) {
      props.onSelect(props.day);
    }
  };

  return (
    <DateTimePickerCell type={cellType} onClick={onClick}>
      {props.day.getDate()}
    </DateTimePickerCell>
  );
};
