import * as Icons from '@dls/react-icons';
import React from 'react';
import { IconContainer } from './styles';
import { IconProps } from './types';

export const Icon: React.FunctionComponent<IconProps> = React.memo((props) => {
  const { name } = props;
  const IconComponent = Icons[name];
  return (
    <IconContainer {...props}>
      <IconComponent />
    </IconContainer>
  );
});

Icon.displayName = 'Icon';
