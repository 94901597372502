import React, { FunctionComponent, SVGProps } from 'react';

const MobiViewSeries = (props) => (
  <svg viewBox="0 0 48 48" width="1em" height="1em" {...props}>
    <path
      fillRule="evenodd"
      d="M22 2v8h-8v8H6v28h20v-8h8v-8h8V2H22zm2 42H8V32h16v12zm0-22v8H8V20h16v2zm8 14h-6V24h6v12zm0-22v8h-6v-4H16v-6h16v2zm8 14h-6V16h6v12zm0-14h-6v-4H24V4h16v10z"
      clipRule="evenodd"
    />
  </svg>
);

export default MobiViewSeries;
