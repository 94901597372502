import React, { FunctionComponent, SVGProps } from 'react';

const CurveEdit = (props) => (
  <svg viewBox="0 0 48 48" width="1em" height="1em" {...props}>
    <path
      fill="#231f20"
      d="M33.616 21.474l-7.09-7.09L38.91 2 46 9.089 33.616 21.474zm-1.414 1.414l-7.09-7.09-.048.048L23 25l9.154-2.064.048-.048zM11 16v-5h5V7h-5V2H7v5H2v4h5v5h4zm-.049 2H8.956C11.477 25.518 17.184 35.714 30 40.088V38c-11.453-4.115-16.676-13.096-19.049-20zM41 32h-4v5h-5v4h5v5h4v-5h5v-4h-5v-5z"
    />
  </svg>
);

export default CurveEdit;
