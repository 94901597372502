import componentColor, { dls } from '@dls/react-colors';
import styled, { css } from 'styled-components';
import { FC } from 'react';
import MuiDrawer, { DrawerProps } from '@material-ui/core/Drawer';
import { em, svgScaling } from '@dls/react-theme';
import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme();

/// REGION:- DrawerStyles
const DrawerStyles = (props: DLSDrawerProps) =>
  props.drawerwidth !== undefined &&
  css`
    &.MuiDrawer-root {
      min-width: ${(props: DLSDrawerProps) => props.drawerwidth + 'px'};
      flex-shrink: 0;

      & .MuiDrawer-paper {
        min-width: ${(props: DLSDrawerProps) => props.drawerwidth + 'px'};
        position: absolute;

        &.drawerTemporaryPaper {
          z-index: 1202;
        }
      }
    }
  `;
/// EndREGION

type DrawerStyleVariants = 240 | 320;

interface DLSDrawerProps extends DrawerProps {
  drawerwidth?: DrawerStyleVariants;
}

/** Drawer with its content */
export const Drawer: FC<DLSDrawerProps> = styled(MuiDrawer).attrs(
  (props: DLSDrawerProps) => {
    return {
      ...props,
      className: props.className + ' drawer-container',
      classes: props.classes || { paper: 'drawerPaper' },
      ModalProps: {
        container: document.getElementById('drawer'),
        style: { position: 'absolute' },
      },
    } as DLSDrawerProps;
  },
)`
  && {
    ${DrawerStyles}

    &.MuiDrawer-root {
      &.drawer-container {
        flex-shrink: 0;
        max-width: ${em(400)};
        margin-right: ${(props: DLSDrawerProps) =>
          props.anchor && props.anchor === 'right' ? 0 : em(56)};

        & .MuiDrawer-paper {
          position: relative;
        }
        [role='button'] {
          &:focus {
            outline: none !important;
          }
        }
        & .dls-toolbar {
          justify-content: space-between;
        }

        & .drawerHeader {
          padding: ${em(12)};
          padding-left: 0;
          display: flex;
          align-items: center;
          justify-content: flex-end;
        }

        & .side-bar {
          margin: ${em(10)} 0;
        }

        & .dls-drawerToggleIcon {
          &.open {
            justify-content: center;
            padding-left: 0;
          }
          padding-left: ${em(16)};
          padding-bottom: ${em(16)};
          display: flex;
          margin-top: auto;
          width: 100%;
        }

        &.drawer-open {
          width: ${(props: DLSDrawerProps) => props.drawerwidth + 'px'};
          transition: ${theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          })};
        }
        &.drawer-closed {
          margin-right: 0;
          min-width: unset;
          transition: ${theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          })};
          overflow-x: hidden;
        }

        & .mini-persistent {
          padding: 0;
          width: ${em(56)} !important;
          background: ${componentColor(dls.content.background.primary.normal)};
          border-right: ${em(1)} solid
            ${componentColor(dls.shadow.shadow.levelThree.normal)};
          min-width: unset;
          transition: ${theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          })};
          overflow-x: hidden;

          & .MuiList-root {
            padding: unset;
            overflow-x: hidden;
          }
          /* Mui styles - Mui styles are overridden by button base styles so explictly mentioning here */
          & .MuiListItem-root {
            width: 100%;
            display: flex;
            position: relative;
            box-sizing: border-box;
            text-align: left;
            align-items: center;
            padding-top: 8px;
            padding-bottom: 8px;
            padding-left: 16px;
            padding-right: 16px;
            justify-content: flex-start;
            text-decoration: none;
          }
          /* Mui Styles */
          & .DLSIcon {
            ${svgScaling(em(24))};
            color: ${componentColor(dls.listItem.icon.default.normalOff)};
          }
        }

        & .drawer-open {
          width: ${(props: DLSDrawerProps) => props.drawerwidth + 'px'};
          transition: ${theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          })};
        }

        & .drawerPaper {
          padding: 0 ${em(16)};
          background: ${componentColor(dls.content.background.primary.normal)};
          border-right: ${em(1)} solid
            ${componentColor(dls.shadow.shadow.levelThree.normal)};
          & .DLSIcon {
            ${svgScaling(em(24))};
            color: ${componentColor(dls.listItem.icon.default.normalOff)};
          }
          &.right {
            border: none;
            border-left: ${em(1)} solid
              ${componentColor(dls.shadow.shadow.levelThree.normal)};
          }
        }

        & .drawer-content {
          overflow: auto;
          & .dls-divider {
            background: ${componentColor(
              dls.separator.background.content.normal,
            )};
          }

          & .MuiList-root {
            & .MuiListItem-root {
              height: em(40);
              background-color: ${componentColor(
                dls.listItem.background.default.normalOff,
              )};
              color: ${componentColor(dls.listItem.text.default.normalOff)};
              &.MuiListItem-gutters {
                padding-left: em(12);
                padding-right: em(12);
              }
              & span:nth-child(2) {
                padding-left: em(12);
              }
              & .DLSIcon {
                ${svgScaling(em(24))};
                color: ${componentColor(dls.listItem.icon.default.normalOff)};
              }

              &:focus {
                background-color: ${componentColor(
                  dls.listItem.background.default.focusOn,
                )};
              }

              &:hover {
                background-color: ${componentColor(
                  dls.listItem.background.default.hover,
                )};
              }
              &:active {
                background-color: ${componentColor(
                  dls.listItem.background.default.pressed,
                )};
              }
              &.Mui-selected {
                background-color: ${componentColor(
                  dls.listItem.background.default.normalOn,
                )};
                color: ${componentColor(dls.listItem.text.default.normalOn)};
                & .DLSIcon {
                  ${svgScaling(em(24))};
                  color: ${componentColor(dls.listItem.icon.default.normalOn)};
                }
              }
              &.Mui-disabled {
                background-color: ${componentColor(
                  dls.listItem.background.default.disabledOn,
                )};
                color: ${componentColor(dls.listItem.text.default.disabled)};
              }
            }
          }
        }
      }
    }
    &.MuiDrawer-paper {
      z-index: 1000;
    }
  }
`;
