import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { ifProp } from 'styled-tools';
import componentColor, { dls } from '@dls/react-colors';
import { FONT_DEFAULT } from '@dls/react-beta-utils';

const StylesText = styled.div`
  ${ifProp(
    'noWrap',
    css`
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      display: block;
    `,
  )}
`;
const StylesTextPrimary = styled(StylesText)`
  font-family: ${FONT_DEFAULT};
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  color: ${componentColor(dls.contentItem.text.primary.normal)};
`;
const StylesTextSecondary = styled(StylesText)`
  font-family: ${FONT_DEFAULT};
  font-weight: normal;
  font-style: normal;
  font-size: 14px;
  color: ${componentColor(dls.contentItem.text.secondary.normal)};
`;
const StyledListItemText = styled(StylesText)`
  width: 100%;
  display: block;
`;

const ListItemText = (props) => {
  const {
    noWrap,
    children,
    primary: primaryProp,
    secondary: secondaryProp,
    primaryTextProps,
    secondaryTextProps,
  } = props;

  let primary = primaryProp || children;
  if (primary) {
    primary = (
      <StylesTextPrimary
        className="primary"
        noWrap={noWrap}
        {...primaryTextProps}>
        {primary}
      </StylesTextPrimary>
    );
  }

  let secondary = secondaryProp;
  if (secondary) {
    secondary = (
      <StylesTextSecondary
        className="secondary"
        noWrap={noWrap}
        {...secondaryTextProps}>
        {secondary}
      </StylesTextSecondary>
    );
  }

  return (
    <StyledListItemText noWrap={noWrap} {...props}>
      {primary}
      {secondary}
    </StyledListItemText>
  );
};

ListItemText.propTypes = {
  children: PropTypes.node,
  noWrap: PropTypes.bool,
  primary: PropTypes.node,
  primaryTextProps: PropTypes.object,
  secondary: PropTypes.node,
  secondaryTextProps: PropTypes.object,
};

ListItemText.defaultProps = {
  color: 'primary',
};

export default ListItemText;
