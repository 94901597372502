import React, { FunctionComponent, SVGProps } from 'react';

const BrandWarning28X24 = (props) => (
  <svg viewBox="0 0 28 24" width="1em" height="1em" {...props}>
    <path d="M15.133 17.023h-1.954l-.335-9.943h2.572l-.24 9.943h-.043zm-2.117 3.72v-2.529h2.383v2.52h-2.426l.043.009z" />
    <path d="M14.087.429a.78.78 0 00-.66.377L.947 22.423a.763.763 0 00.66 1.148h24.969a.763.763 0 00.66-1.148L14.756.806a.771.771 0 00-.669-.377zm0 2.297l11.143 19.32H2.936l11.151-19.32z" />
  </svg>
);

export default BrandWarning28X24;
