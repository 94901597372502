import React, { useMemo, useState, FC } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { ifProp, ifNotProp } from 'styled-tools';
import componentColor, { dls } from '@dls/react-colors';
import { NavigationDown } from '@dls/react-icons';
import { Content } from '@dls/react-beta-content';
import { ExpanderItemHeader } from '@dls/react-beta-expander-item-header';
import { ExpanderItemContent } from '@dls/react-beta-expander-item-content';
import { toPx } from '@dls/react-beta-utils';
import { ExpanderItemContext } from '@dls/react-beta-expander';
import {
  useExpanderContext,
  expanderDensities,
} from '@dls/react-beta-expander';

export type ExpanderItemProps = any;

const StyledExpanderItem = styled(Content)<ExpanderItemProps>`
  ${ifNotProp(
    'variant',
    css`
      background-color: null;
    `,
  )}
  display: flex;
  flex-direction: column;
  outline: none;
  position: relative;
  user-select: none;
  line-height: 22px;
  min-height: ${(props) => toPx(expanderDensities[props.density].minHeight)};
  ${(props) =>
    props.divider &&
    css<ExpanderItemProps>`
      border-bottom: 1px solid
        ${componentColor(dls.separator.background.content.normal)};
      :last-of-type {
        border-bottom-width: 0;
      }
    `}
  ${(props) =>
    props.expanded &&
    props.separateActiveExpander &&
    css<ExpanderItemProps>`
      ${ifProp(
        'elevation',
        css`
          border-bottom-width: 0;
        `,
      )}
      margin: ${(props) =>
        toPx([expanderDensities[props.density].separatePadding, 0])};
      :first-of-type {
        margin-top: 0;
      }
      :last-of-type {
        margin-bottom: 0;
      }
    `}
  ${(props) =>
    props.disabled &&
    css<ExpanderItemProps>`
      pointer-events: none;
    `}
    .navigation-icon {
    align-self: flex-start;
    width: 24px;
    height: 24px;
    font-size: 24px;
    top: ${(props) => toPx(expanderDensities[props.density].padding)};
    right: 16px;
    position: absolute;
    z-index: 1;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: transform linear 130ms;
    ${ifProp(
      'expanded',
      css`
        transform: rotate(-180deg);
      `,
    )}
  }
`;
const renderChildren = (
  label,
  icon,
  children,
  disabled,
  expanded,
  itemHeaderProps,
) => {
  const renderContent = !disabled && expanded;
  if (label || icon) {
    return [
      <ExpanderItemHeader
        key="it1"
        icon={icon}
        label={label}
        {...itemHeaderProps}
      />,
      renderContent && (
        <ExpanderItemContent key="it2" {...itemHeaderProps}>
          {children}
        </ExpanderItemContent>
      ),
    ];
  } else {
    return React.Children.map(children, (child) => {
      if (React.isValidElement(child)) {
        if (
          renderContent ||
          (child.type as any).name === ExpanderItemHeader.name
        ) {
          return child;
        }
      }
    });
  }
};
const ExpanderItem: FC<ExpanderItemProps> = (props) => {
  const expanderContext = useExpanderContext();
  const {
    allowMultipleExpanded,
    children,
    density,
    disabled,
    expanded: expandedProp,
    icon,
    index,
    itemHeaderProps,
    label,
    onChangeItem: onChange,
    quiet,
    value,
    ...other
  } = {
    ...props,
    ...expanderContext,
  } as any;
  const context = useMemo(() => ({ disabled }), [disabled]);
  const [expandedState, setExpandedState] = useState(expandedProp);
  const expanded = allowMultipleExpanded ? expandedState : expandedProp;
  const handleClick = (e) => {
    const expanderHeader = e.target.closest('.expander-item-header');
    if (expanderHeader) {
      const itemState = {
        index,
        value,
        expanded: !expanded,
      };
      if (allowMultipleExpanded) {
        setExpandedState(itemState.expanded);
      }
      onChange(itemState);
    }
  };
  return (
    <ExpanderItemContext.Provider value={context}>
      <StyledExpanderItem
        className="expander-item"
        variant={quiet ? undefined : 'primary'}
        data-value={value}
        density={density}
        disabled={disabled}
        expanded={expanded}
        onClick={disabled ? null : handleClick}
        tabIndex={disabled ? -1 : 0}
        {...other}>
        {renderChildren(
          label,
          icon,
          children,
          disabled,
          expanded,
          itemHeaderProps,
        )}
        <NavigationDown className="navigation-icon" />
      </StyledExpanderItem>
    </ExpanderItemContext.Provider>
  );
};

ExpanderItem.propTypes = {
  allowMultipleExpanded: PropTypes.bool,
  children: PropTypes.node,
  density: PropTypes.oneOf(['regular', 'dense']),
  disabled: PropTypes.bool,
  expanded: PropTypes.bool,
  icon: PropTypes.node,
  label: PropTypes.string,
  quiet: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

ExpanderItem.defaultProps = {
  allowMultipleExpanded: true,
  density: 'regular',
  divider: true,
  elevation: 0,
  quiet: false,
};

export default ExpanderItem;
